import React, { useContext, useState } from "react";

import "./TaskList.css";

import ListFilter from "../../commons/list-filter";
import ListView from "../../commons/list-view/ListView";
import { DataContext } from "../../../contexts";
import TaskItem from "./task-item/TaskItem";

export default function TaskList({ isItemSelected }) {
    const { phases, isLoadingPhases: isLoading } = useContext(DataContext);
    const [filter, setFilter] = useState("");

    const applyFilter = () => {
        const toUpper = value => {
            return value ? value.toString().toUpperCase() : value;
        };

        const normalize = value => {
            return value
                ? value.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                : value;
        };

        const toFilter = value => {
            value = toUpper(value);
            value = normalize(value);
            return value;
        };

        const filtredPhases = filter
            ? phases.filter(
                  c =>
                      toFilter(c.id).includes(toFilter(filter)) ||
                      toFilter(c.name).includes(toFilter(filter))
              )
            : phases;

        return filtredPhases;
    };

    const handleFilterChange = value => {
        setFilter(...value);
    };

    const filtredPhases = applyFilter();

    const Filter = () => {
        return (
            <ListFilter
                placeholder="Digite para pesquisar ..."
                onFilterChange={handleFilterChange}
            />
        );
    };

    const Info = () => {
        return `${filtredPhases.length} de ${phases.length}`;
    };

    return (
        <ListView
            filter={Filter}
            info={Info}
            isItemSelected={isItemSelected}
            isLoading={isLoading}
        >
            {filtredPhases.map((phase, i) => (
                <TaskItem
                    key={i}
                    routePath={`/tasks/${phase.documentId}`}
                    {...phase}
                />
            ))}
        </ListView>
    );
}
