import { useContext } from "react";

import { useConfirm } from "material-ui-confirm";
import { Store as notifications } from "react-notifications-component";

import notificationOpts from "../constants/notification-options";
import notificationType from "../constants/notification-types";
import { AuthContext } from "../contexts";
import { deleteOrder } from "../services";

export default function useDeleteOrder() {
    const { user: currentUser } = useContext(AuthContext);
    const confirm = useConfirm();
    return order =>
        confirm({
            title: "Confirmar exclusão",
            description: "Deseja remover esta ordem?",
            confirmationText: "Sim",
            cancellationText: "Não",
        }).then(() => {
            return deleteOrder(order, currentUser).catch(error => {
                console.error("Error deleting order", order, error);
                notifications.addNotification({
                    ...notificationOpts,
                    type: notificationType.ERROR,
                    message: `Não foi possivel excluir, tente novamente`,
                });
            });
        });
}
