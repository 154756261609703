const Forms = [
    {
        id: "F001",
        name: "F001 - Lista de Verificação de Equipamentos e Veículos",
        shortName: "LVEV",
    },
    {
        id: "F002",
        name: "F002 - Monitoramento e Controle de Poços",
        shortName: "MCP",
    },
    {
        id: "F003",
        name: "F003 - Lista Verificação EPI",
        shortName: "LVEPI",
    },
    {
        id: "F004",
        name: "F004 - Boletim de Levantamento Topográfico",
        shortName: "BLT",
    },
    {
        id: "F006",
        name: "F006 - Roteiro de Campo",
        shortName: "RC",
    },
    {
        id: "F008",
        name: "F008 - Teste de Fundo de Cava",
        shortName: "TFC",
    },
    {
        id: "F009",
        name: "F009 - Medição de Distânciamento",
        shortName: "MD",
    },
    {
        id: "F101",
        name: "F101 - Roteiro de Atividades e Equipamentos",
        shortName: "RAE",
    },
    {
        id: "F031",
        name: "F031 - Ordem de Serviço",
        fileName: "F031_Rev05_OS_Ordem de Serviço.xlsx",
        shortName: "OS",
    },
    {
        id: "F037",
        name: "F037 - Boletim de Amostragem de Água Subterrânea",
        fileName:
            "F037_Rev07_BAAS_Boletim de Amostragem de Água Subterrânea.xlsx",
        shortName: "BAAS",
        perWell: true,
    },
    {
        id: "F038",
        name: "F038 - Registro de Monitoramento de Armazenamento",
        shortName: "RMA",
    },
    {
        id: "F046",
        name: "F046 - Check List de Verificação de Posto",
        shortName: "CVP",
    },
    {
        id: "F048",
        name: "F048 - Plano de Amostragem de Campo",
        fileName: "F048_Rev05_PAC_Plano de Amostragem de Campo.xlsx",
        shortName: "PAC",
    },
    {
        id: "F049",
        name: "F049 - Teste de Bombeamento",
        shortName: "TB",
    },
    {
        id: "F050",
        name: "F050 - Relatório de Visita Técnica",
        shortName: "RVT",
    },
    {
        id: "F053",
        name: "F053 - Ficha de Sondagem",
        shortName: "FS",
    },
    {
        id: "F058",
        name: "F058 - Registro de Verificação de Parâmetros em Campo",
        shortName: "RVPC",
    },
    {
        id: "F060",
        name: "F060 - Check List de Equipamentos e Acessórios",
        shortName: "CLEA",
    },
    {
        id: "F061",
        name: "F061 - Folha de Croqui de Campo",
        shortName: "FCC",
    },
    {
        id: "F062",
        name: "F062 - Medição de Coordenadas Georreferênciadas",
        shortName: "MCG",
    },
    {
        id: "F064",
        name: "F064 - Ensaio de Permeabilidade no Solo",
        shortName: "EPS",
    },
    {
        id: "F065",
        name: "F065 - Cadeia de Custódia",
        shortName: "CC",
    },
    {
        id: "F068",
        name: "F068 - Planilha de Serviços Fechados",
        shortName: "PSF",
    },
    {
        id: "F073",
        name: "F073 - Controle de Relatório de Ensaio",
        shortName: "CRE",
    },
    {
        id: "F074",
        name: "F074 - Ficha de Sondagem Mecanizada",
        shortName: "FSM",
    },
    {
        id: "F076",
        name: "F076 - Controle de Plano de Amostragem de Campo",
        shortName: "CPAC",
    },
    {
        id: "F079",
        name: "F079 - Check List de Manutenção Preventiva de Veículos",
        shortName: "CLMPV",
    },
    {
        id: "F080",
        name: "F080 - Registro de Saída de Materiais e Insumos",
        shortName: "RSMI",
    },
    {
        id: "F081",
        name: "F081 - Registro de Monitoramento do Condutivímetro",
        shortName: "RMC",
    },
    {
        id: "F090",
        name: "F090 - Estanqueidade e Amostragem no poço de vapores",
        shortName: "EAPV",
    },
    {
        id: "F091",
        name: "F091 - Medição VOC e UV Matriz Solo",
        shortName: "MVU",
    },
    {
        id: "F092",
        name: "F092 - Relatório de Pausa do Sistema",
        shortName: "RPS",
    },
    {
        id: "F093",
        name: "F093 - Controle de Abastecimento",
        shortName: "CA",
    },
    {
        id: "F103",
        name: "F103 - Controle de Manifesto para Transporte de Resíduos Perigosos",
        shortName: "MRT",
    },
];

export default Forms;
