/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";

import { faCommentDots } from "@fortawesome/free-regular-svg-icons";
import {
    faUserEdit,
    faBan,
    faExclamationCircle,
    faCheckCircle,
    faPlayCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Box from "@mui/material/Box";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import moment from "moment";

import "./ProjectInfo.css";

import { MentionsInput, Mention } from "react-mentions";
import { Store as notifications } from "react-notifications-component";

import classNames from "./mention.module.css";
import notificationOpts from "../../../../../../constants/notification-options";
import notificationType from "../../../../../../constants/notification-types";
import { AuthContext } from "../../../../../../contexts";
import clone from "../../../../../../lib/clone";
import {
    updateDocument,
    deleteDocument,
} from "../../../../../../services/data-service";

const useStyles = makeStyles(() => ({
    popover: {
        pointerEvents: "none",
    },
}));

export default function ProjectInfo(props) {
    const classes = useStyles();

    const [info, setInfo] = useState(clone(props.info));
    const [anchorEl, setAnchorEl] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [document, setDocument] = useState("");

    const { user: currentUser, users } = useContext(AuthContext);

    useEffect(() => {
        if (!isEditing) setInfo(props.info);
    }, [props.info]);

    useEffect(() => {
        if (info) {
            if (info.type === "info") {
                setDocument(`projects/${info.project.id}/infos/${info.id}`);
            } else {
                setDocument(`projects/${info.project.id}/events/${info.id}`);
            }
        }
    }, [info]);

    const renderIcon = type => {
        let icon;
        switch (type) {
            case "surveyor":
                icon = faUserEdit;
                break;
            case "assignee":
                icon = faUserEdit;
                break;
            case "drafter":
                icon = faUserEdit;
                break;
            case "cancel":
                icon = faBan;
                break;
            case "released":
                icon = faPlayCircle;
                break;
            case "completed":
                icon = faCheckCircle;
                break;
            case "pendency":
                icon = faExclamationCircle;
                break;
            default:
                icon = faCommentDots;
                break;
        }
        return (
            <div className={"info-icon"}>
                <FontAwesomeIcon
                    icon={icon}
                    style={{ color: renderColor(type) }}
                />
            </div>
        );
    };

    const renderColor = type => {
        let color;
        switch (type) {
            case "pendency":
                color = "#EE5A24";
                break;
            case "info":
                color = "#4192d9";
                break;
            default:
                color = "#718093";
                break;
        }
        return color;
    };

    const onInputChange = (event, newValue, newPlainTextValue, mentions) => {
        info.message = newValue;
        info.plainText = newPlainTextValue;
        info.mentions = mentions.map(mention => mention.id);
        info.updatedAt = moment();
        setInfo({ ...info });
    };

    const onEditComment = async () => {
        if (isEditing) {
            updateDocument(document, {
                message: info.message,
                plainText: info.plainText,
                mentions: info.mentions,
            })
                .then(() => {
                    notifications.addNotification({
                        ...notificationOpts,
                        type: notificationType.SUCCESS,
                        message: "Informação atualizada com sucesso",
                    });
                })
                .catch(error => {
                    notifications.addNotification({
                        ...notificationOpts,
                        type: notificationType.ERROR,
                        message:
                            "Não foi possível atualizar a informação, tente novamente",
                    });
                    console.error("Error updating info: ", error);
                })
                .finally(() => setIsEditing(false));
        } else {
            setIsEditing(true);
        }
    };

    const onDeleteComment = async () => {
        if (isEditing) {
            setInfo({ ...clone(props.info) });
            setIsEditing(false);
        } else {
            try {
                deleteDocument(document)
                    .then(() => {
                        notifications.addNotification({
                            ...notificationOpts,
                            type: notificationType.SUCCESS,
                            message: "Informação removida com sucesso",
                        });
                    })
                    .catch(error => {
                        notifications.addNotification({
                            ...notificationOpts,
                            type: notificationType.ERROR,
                            message:
                                "Não foi possível remover a informação, tente novamente",
                        });
                        console.error("Error deleting info: ", error);
                    });
            } catch (errors) {
                console.error(errors);
            }
        }
    };

    const handlePopoverOpen = event => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const renderUserSuggestion = (
        suggestion,
        search,
        highlightedDisplay,
        index,
        focused,
    ) => {
        return (
            <div className={`user ${focused ? "focused" : ""}`}>
                {highlightedDisplay}
            </div>
        );
    };

    return (
        <div className="info-container">
            <div className="info-header">
                {renderIcon(info.type)}
                <div
                    className="info-step"
                    style={{ color: renderColor(info.type) }}
                >
                    {info.author ? info.author.name : ""}
                </div>
                <div className="info-dot">&#9679;</div>
                <div className="info-date">
                    {moment(info.createdAt, moment.ISO_8601).calendar()}
                </div>
                {info.author.id === currentUser.id &&
                    info.type === "info" &&
                    info.updatedAt && (
                        <div
                            className="info-edited"
                            aria-owns={open ? "mouse-over-popover" : undefined}
                            aria-haspopup="true"
                            onMouseEnter={handlePopoverOpen}
                            onMouseLeave={handlePopoverClose}
                        >
                            <div className="info-edited-dot">&#9679;</div>
                            <div className="info-edited-label">Editado</div>
                        </div>
                    )}
            </div>

            {isEditing ? (
                <MentionsInput
                    value={info.message}
                    placeholder="Adicionar informação"
                    onChange={onInputChange}
                    className="mentions"
                    classNames={classNames}
                >
                    <Mention
                        trigger="@"
                        markup="@[__display__](__id__)"
                        data={users.map(d => ({
                            id: d.id,
                            display: d.name,
                        }))}
                        renderSuggestion={renderUserSuggestion}
                        className={classNames.mentions__mention}
                    />
                </MentionsInput>
            ) : (
                <div className="info-message">
                    {info.plainText ? info.plainText : info.message}
                </div>
            )}

            {info.author.id === currentUser.id && info.type === "info" && (
                <div className={"info-icons"}>
                    <Box component="p" onClick={onEditComment}>
                        {isEditing ? "Salvar" : "Editar"}
                    </Box>
                    <div className="info-dot">&#9679;</div>
                    <Box component="p" onClick={onDeleteComment}>
                        {isEditing ? "Cancelar" : "Excluir"}
                    </Box>
                </div>
            )}

            {info.updatedAt && (
                <Popover
                    id="mouse-over-popover"
                    className={classes.popover}
                    classes={{
                        paper: classes.paper,
                    }}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                >
                    <Typography>
                        {moment(info.updatedAt).format("DD/MM/YYYY, h:mm:ss")}
                    </Typography>
                </Popover>
            )}
        </div>
    );
}
