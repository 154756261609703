import React, { useContext, useState } from "react";

import BoltIcon from "@mui/icons-material/Bolt";
import { useConfirm } from "material-ui-confirm";
import { Store as notifications } from "react-notifications-component";
import { useHistory } from "react-router-dom";

import notificationOpts from "../../../constants/notification-options";
import notificationType from "../../../constants/notification-types";
import OrderActions from "../../../constants/order-actions";
import { AuthContext } from "../../../contexts";
import { getOrderAction } from "../../../services";
import MenuButton from "../../commons/menu-button";

/**
 * Renders a button component with a dropdown menu of actions that can be performed on an order.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.order - The order object.
 * @returns {JSX.Element} - The rendered component.
 */
export default function OrderActionsButton({ order = {}, type = "button" }) {
    const confirm = useConfirm(); // Hook to request user confirmation
    const history = useHistory(); // Hook to manipulate browser history
    const { currentUser, hasAnyRole } = useContext(AuthContext); // Access authentication context
    const [isLoading, setIsLoading] = useState(false); // State to track loading status
    const { status = {} } = order; // Destructure status from order, default to empty object
    const { actions = [] } = status; // Destructure actions from status, default to empty array

    // Function to handle action execution
    const executeAction = action => {
        setIsLoading(true); // Set loading to true
        return confirm({
            // Request user confirmation before proceeding
            title: "Confirmar Ação", // Title of the confirmation dialog
            description: `Deseja realizar a ação "${action.name}"?`, // Confirmation message
            confirmationText: "Sim", // Text for the confirmation button
            cancellationText: "Não", // Text for the cancellation button
        })
            .then(() => {
                // If confirmed, execute the action
                return action
                    .execute(order, currentUser)
                    .then(value => {
                        // If the executed action is COPY, navigate to the new order page
                        if (action.id === OrderActions.COPY) {
                            history.push(`/orders/${value.id}`);
                        }
                    })
                    .catch(error => {
                        // Log and notify any errors during execution
                        console.error(
                            `Error executing action: ${action.id}`,
                            error,
                        );
                        notifications.addNotification({
                            ...notificationOpts,
                            type: notificationType.ERROR,
                            message:
                                "Não foi possível realizar esta ação, tente novamente!",
                        });
                    });
            })
            .catch(() => {
                // Catch any errors from the confirmation process
            })
            .finally(() => {
                setIsLoading(false); // Reset loading state
            });
    };

    // Generate the list of action items that the current user is allowed to perform
    const items = actions
        .map(value => getOrderAction(value))
        .filter(action => hasAnyRole(action.requiredRoles)) // Filter actions by role
        .map(action => {
            return {
                icon: action.icon, // Action icon
                title: action.name, // Action name
                tooltip: action.tooltip, // Tooltip for the action
                onClick: () => {
                    return executeAction(action); // Attach the executeAction function to onClick
                },
            };
        });

    // Render the MenuButton component with the actions available to the user
    return (
        <MenuButton
            title="Ações"
            items={items}
            startIcon={<BoltIcon />}
            loading={isLoading}
            loadingIndicator="Loading..."
            type={type}
            icon={<BoltIcon />}
        />
    );
}
