import { strToBoolean } from "./string-utils";

export function loadEnvBool(key, defaultValue = false) {
    return process.env[key] ? strToBoolean(process.env[key]) : defaultValue;
}

export function loadEnvStr(key, defaultValue = "") {
    return process.env[key] ? process.env[key] : defaultValue;
}

export function loadEnvFloat(key, defaultValue = "") {
    return process.env[key]
        ? Number.parseFloat(process.env[key])
        : defaultValue;
}

export function loadEnvInt(key, defaultValue = "") {
    return process.env[key] ? Number.parseInt(process.env[key]) : defaultValue;
}
