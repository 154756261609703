import React, { useState } from "react";

import ReasonList from "./reason-list";
import ReasonForm from "./reason-form";

import "./Reasons.css";

export default function Reasons() {
    const [state, setState] = useState({
        isLoading: false,
        selectedReason: {},
        errors: [],
    });

    const onReasonSelected = reason => {
        setState({
            ...state,
            selectedReason: reason,
        });
    };

    return (
        <div className="reasons-form">
            <div className="created-reasons-form">
                <ReasonList onReasonSelected={onReasonSelected} />
            </div>
            <div className="new-reason-form">
                <ReasonForm
                    reasonId={state.selectedReason.id}
                    currentReason={state.selectedReason}
                    onCreated={onReasonSelected}
                />
            </div>
        </div>
    );
}
