import React from "react";
import { Route } from "react-router-dom";
import { makeStyles } from '@mui/styles';

import classNames from "classnames";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        width: "100%",
        marginTop: "0px",
        boxSizing: "border-box",
    },
    listView: {
        display: "flex",
        height: "100vh",
        "-webkit-box-pack": "start",
        "justify-content": "flex-start",
        position: "sticky",
        top: "0px",
        "z-index": "500",
    },
    detailView: {
        minWidth: "0px",
        minHeight: "100%",
        flex: "1 1 0px",
        overflow: "hidden",
    },
    fullList: {
        flex: "0 0 100%",
    },
    selectedList: {
        width: "380px",
        top: "0px",
        height: "100vh",
        display: "flex",
        zIndex: "500",
        position: "sticky",
        justifyContent: "flex-start",
        [theme.breakpoints.down("md")]: {
            display: "none",
        },
    },
}));

export default function ListDetailView({
    datatableView: DatatableView,
    listView: ListView,
    detailView: DetailsView,
    detailsPath,
    ...props
}) {
    const classes = useStyles();

    const isItemSelected = () => {
        return !props.match.isExact;
    };

    return (
        <div className={classes.root}>
            <div
                className={classNames(
                    // classes.listView,
                    isItemSelected() ? classes.selectedList : classes.fullList
                )}
            >
                {isItemSelected() ? (
                    <ListView isItemSelected={isItemSelected()} />
                ) : (
                        <DatatableView />
                    )}
            </div>
            <div className={classes.detailView}>
                <Route path={detailsPath} component={DetailsView} />
            </div>
        </div>
    );
}
