/* eslint-disable react/prop-types */
import React, { useContext, useState, useEffect } from "react";

import { TextField, FormControlLabel, Checkbox } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { makeStyles } from "@mui/styles";

import "./ProjectCustomerDetailsDialog.css";
import classNames from "classnames";

import { DataContext } from "../../../../contexts";
import { cnpjFormatter } from "../../../../lib/form-utils";
import {
    getDocumentData,
    getCollectionData,
} from "../../../../services/data-service";

const useStyles = makeStyles(() => ({
    dialog: {},
    data: {
        marginTop: "0.5em",
        marginBottom: "0.5em",
        flex: 1,
        display: "flex",
        flexDirection: "column",
    },
    dataHeader: {
        flex: 1,
        display: "flex",
        marginBottom: 15,
    },
    dataTitle: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
    dataContent: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
    },
    row: {
        display: "flex",
    },
    inputField: {
        "& > *": {
            marginLeft: 15,
            marginRight: 5,
            marginBottom: 10,
        },
    },
    nameField: {
        width: 300,
    },
    cnpjField: {
        width: 200,
    },
    addressField: {
        width: 400,
    },
    numberField: {
        width: 100,
    },
    neighborhoodField: {
        width: 200,
    },
    cityField: {
        width: 184,
    },
    stateField: {
        width: 100,
    },
    raizenCheckboxArea: {
        marginLeft: 15,
        flex: 1,
        marginTop: "0.5em",
    },
    raizenCheckboxLabel: {
        paddingTop: "1px",
    },

    cancelButton: {
        color: "red",
    },
}));

export default function ProjectCustomerDetailsDialog(props) {
    const classes = useStyles();

    const { open, onClose } = props;

    const { project, isLoading } = useContext(DataContext);
    const [customer, setCustomer] = useState({});
    const [studyArea, setStudyArea] = useState({});

    useEffect(() => {
        if (project && !isLoading) {
            if (project && project.customer) {
                getDocumentData(`customers/${project.customer.id}`).then(
                    customer => {
                        setCustomer({ ...customer });
                    },
                );
                getCollectionData(
                    `customers/${project.customer.id}/addresses`,
                ).then(addreses => {
                    if (project && project.studyArea) {
                        const address = addreses.find(
                            a => a.id === project.studyArea.id,
                        );
                        setStudyArea({ ...address });
                    } else {
                        const address = addreses.find(
                            a => a.type === "default",
                        );
                        setStudyArea({ ...address });
                    }
                });
            }
        }
    }, [isLoading, project]);

    const handleClose = () => {
        if (onClose) onClose();
    };

    return (
        <div className="project-customer-show-dialog">
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                max
                open={open}
                onClose={handleClose}
                aria-labelledby="customer-show-dialog"
                className={classes.dialog}
            >
                <DialogTitle id="customer-show-dialog">
                    Dados do Cliente
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <div className={classes.data}>
                        <div className={classes.dataHeader}>
                            <div className={classes.dataTitle}>
                                <h5>Dados do Empreendimento</h5>
                            </div>
                        </div>
                        <div className={classes.dataContent}>
                            <div className={classes.row}>
                                <TextField
                                    variant="standard"
                                    className={classNames(
                                        classes.inputField,
                                        classes.cnpjField,
                                    )}
                                    label="CNPJ"
                                    value={cnpjFormatter(customer.cnpj)}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                                <TextField
                                    variant="standard"
                                    className={classNames(
                                        classes.inputField,
                                        classes.nameField,
                                    )}
                                    label="Razão Social do Empreendimento"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    value={customer.name || ""}
                                />
                            </div>
                            <div className={classes.row}>
                                <div className={classes.raizenCheckboxArea}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                value="secondary"
                                                color="primary"
                                                checked={
                                                    customer.raizenDeadlines ||
                                                    false
                                                }
                                            />
                                        }
                                        label={
                                            <h5
                                                className={
                                                    classes.raizenCheckboxLabel
                                                }
                                            >
                                                Prazos Raízen
                                            </h5>
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.data}>
                        <div className={classes.dataHeader}>
                            <div className={classes.dataTitle}>
                                <h5>Area de Estudo</h5>
                            </div>
                        </div>
                        <div className={classes.dataContent}>
                            <div className={classes.row}>
                                <TextField
                                    variant="standard"
                                    className={classNames(
                                        classes.zipCodeField,
                                        classes.inputField,
                                    )}
                                    label="CEP"
                                    value={studyArea.zipCode || ""}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </div>
                            <div className={classes.row}>
                                <TextField
                                    variant="standard"
                                    className={classNames(
                                        classes.addressField,
                                        classes.inputField,
                                    )}
                                    label="Endereço"
                                    value={studyArea.address || ""}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                                <TextField
                                    variant="standard"
                                    className={classNames(
                                        classes.inputField,
                                        classes.numberField,
                                    )}
                                    label="nº"
                                    value={studyArea.number || ""}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </div>
                            <div className={classes.row}>
                                <TextField
                                    variant="standard"
                                    className={classNames(
                                        classes.neighborhoodField,
                                        classes.inputField,
                                    )}
                                    label="Bairro"
                                    value={studyArea.neighborhood || ""}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                                <TextField
                                    variant="standard"
                                    className={classNames(
                                        classes.inputField,
                                        classes.cityField,
                                    )}
                                    label="Cidade"
                                    value={studyArea.city || ""}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                                <TextField
                                    variant="standard"
                                    className={classNames(
                                        classes.inputField,
                                        classes.stateField,
                                    )}
                                    label="Estado"
                                    value={studyArea.state || ""}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions className={classes.actionContainer}>
                    <Button
                        className={classes.cancelButton}
                        onClick={handleClose}
                    >
                        Cancelar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
