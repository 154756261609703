import * as React from "react";
import { useEffect } from "react";

import BoltIcon from "@mui/icons-material/Bolt";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { LoadingButton } from "@mui/lab";
import { IconButton } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { alpha, styled } from "@mui/material/styles";

const StyledMenu = styled(props => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right",
        }}
        {...props}
    />
))(({ theme }) => ({
    "& .MuiPaper-root": {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === "light"
                ? "rgb(55, 65, 81)"
                : theme.palette.grey[300],
        boxShadow:
            "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
        "& .MuiMenu-list": {
            padding: "4px 0",
        },
        "& .MuiMenuItem-root": {
            "& .MuiSvgIcon-root": {
                fontSize: 18,
                // color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            "&:active": {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

export default function MenuButton({
    title,
    items = [],
    type,
    icon,
    ...props
}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        if (props.loading && open) {
            handleClose();
        }
    }, [props.loading, open]);

    return (
        <div>
            {type === "iconButton" ? (
                <IconButton
                    {...props}
                    id="menu-button"
                    aria-controls={open ? "menu-itens" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    variant="contained"
                    disableElevation
                    onClick={handleClick}
                    endIcon={<KeyboardArrowDownIcon />}
                >
                    {icon}
                </IconButton>
            ) : (
                <LoadingButton
                    {...props}
                    id="menu-button"
                    aria-controls={open ? "menu-itens" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    variant="contained"
                    disableElevation
                    onClick={handleClick}
                    endIcon={<KeyboardArrowDownIcon />}
                >
                    {title}
                </LoadingButton>
            )}
            <StyledMenu
                id="menu-itens"
                MenuListProps={{
                    "aria-labelledby": "menu-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {items.length === 0 && (
                    <MenuItem disableRipple>Nenhum item disponível</MenuItem>
                )}
                {items.map(item => {
                    const { title, onClick, icon } = item;
                    return (
                        <MenuItem key={title} onClick={onClick} disableRipple>
                            {icon && icon}
                            {title}
                        </MenuItem>
                    );
                })}
            </StyledMenu>
        </div>
    );
}
