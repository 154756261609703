import React, { useState } from "react";
import { makeStyles } from '@mui/styles';
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";

import { ArrowDropDownCircle, ArrowDropDown, Clear } from "@mui/icons-material";

import "./ListFilter.css";

const useStyles = makeStyles(theme => ({
    root: {
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
        backgroundColor: "#e6ecf2",
    },
    input: {
        // width: '100%',
        marginLeft: 15,
        flex: 1,
        backgroundColor: "#e6ecf2",
    },
    iconButton: {
        padding: 10,
    },
}));

export default function ListFilter(props) {
    const classes = useStyles();

    const [filter, setFilter] = useState("");

    const handleClear = () => {
        setFilter("");
        if (props.onFilterChange) props.onFilterChange("");
    };

    const handleFilterChange = event => {
        const value = event.target.value;
        setFilter(value);
        if (props.onFilterChange) props.onFilterChange(value);
    };

    const hasAdvancedFilters = af => {
        return (
            JSON.stringify(af) !== JSON.stringify(props.initialAdvancedFilter)
        );
    };

    return (
        <div className="list-filter">
            <Paper className={classes.root} elevation={0}>
                <InputBase
                    className={classes.input}
                    fullWidth
                    value={filter}
                    onChange={handleFilterChange}
                    placeholder={props.placeholder}
                />
                <IconButton
                    className={classes.iconButton}
                    aria-label="Filtro"
                    onClick={handleClear}
                >
                    <Clear />
                </IconButton>
                {props.advancedFilters && (
                    <React.Fragment>
                        <Divider className={classes.divider} />
                        <IconButton
                            color="primary"
                            className={classes.iconButton}
                            aria-label="Filtros avançados"
                            onClick={props.toggleAdvancedFilter}
                        >
                            {hasAdvancedFilters(props.advancedFilter) ? (
                                <ArrowDropDownCircle />
                            ) : (
                                    <ArrowDropDown />
                                )}
                        </IconButton>
                    </React.Fragment>
                )}
            </Paper>
        </div>
    );
}
