import React from "react";

import { List, ListItem } from "@mui/material";
import { NavLink } from "react-router-dom";

import { makeStyles } from '@mui/styles';

import Skeleton from "react-loading-skeleton";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: "#f5f5f5",
        width: "100%",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
    },
    listFilter: {
        display: "flex",
    },
    listItems: {
        width: "100%",
        flex: "1 1 auto",
        position: "relative",
        overflowY: "auto",
        overflowX: "hidden",
        "&::-webkit-scrollbar": {
            width: "4px",
        },
        "&::-webkit-scrollbar-track": {
            boxShadow: "none",
            borderRadius: "10px",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#28456c",
            borderRadius: "10px",
        },
    },
    list: {
        padding: 0,
    },
    listItem: {
        // minHeight: "148px",
        height: "100%",
        borderTop: "1px solid #385cac",
        borderBottom: "1px solid #2851aa",
        marginBlockEnd: "-1px",
        backgroundColor: "var(--very-light-blue)",
        padding: "0px",
        "&:hover": {
            backgroundColor: "#bfddf7",
        },
        "&:before": {
            boxSizing: "unset",
        },
        "&:after": {
            boxSizing: "unset",
        },
    },
    listItemSelected: {
        backgroundColor: "#b5dcfc",
    },
    listInfo: {
        paddingTop: "7px",
        paddingLeft: "15px",
        paddingRight: "8px",
        paddingBottom: "8px",
        fontSize: "12px",
        fontFamily: "Roboto",
        color: "#747474",
    },
}));

export default function ListView({
    filter: ListFilter,
    info: ListInfo,
    isItemSelected,
    isLoading,
    children: items,
}) {
    const classes = useStyles();

    const renderLoading = () => {
        const skeletonRows = () => {
            let rows = [];
            for (let i = 0; i < 20; i++) {
                rows.push(
                    <Skeleton key={i} height={isItemSelected ? 124 : 55} />
                );
            }
            return rows;
        };

        return skeletonRows();
    };

    // avoid navigate to same location
    const handleNavigation = path => e => {
        if (path === window.location.pathname) e.preventDefault();
    };

    const renderItems = () => {
        return React.Children.map(items, (item, i) => {
            return (
                <ListItem
                    key={i}
                    className={classes.listItem}
                    component={NavLink}
                    onClick={handleNavigation(item.props.routePath)}
                    to={
                        item.props.routePath ||
                        `${window.location.pathname}/${i}`
                    }
                    activeClassName={classes.listItemSelected}
                    exact
                    replace
                >
                    {item}
                </ListItem>
            );
        });
    };

    return (
        <div className={classes.root}>
            <div className={classes.listFilter}>
                <ListFilter />
            </div>
            <div className={classes.listInfo}>
                <ListInfo />
            </div>
            <div className={classes.listItems}>
                <List className={classes.list}>
                    {isLoading ? renderLoading() : renderItems()}
                </List>
            </div>
        </div>
    );
}
