import React, { useContext } from "react";

import ClearAllIcon from "@mui/icons-material/ClearAll";
import CloseIcon from "@mui/icons-material/Close";
import MarkunreadMailboxIcon from "@mui/icons-material/MarkunreadMailbox";
import NotificationsIcon from "@mui/icons-material/Notifications";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
    Typography,
    Toolbar,
    Paper,
    List,
    ListItem,
    ListItemAvatar,
    Avatar,
    ListItemText,
    Divider,
    IconButton,
    Tooltip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Skeleton from "react-loading-skeleton";
import { store } from "react-notifications-component";

import notificationOpts from "../../constants/notification-options";
import notificationType from "../../constants/notification-types";
import { NotificationContext } from "../../contexts";

import "./Notifications.css";
import moment from "moment";
import { Link } from "react-router-dom";

import { firestore } from "../../configs/firebase/firebase.config";
import {
    updateDocument,
    deleteDocument,
    writeBatch,
    getDocumentReference,
} from "../../services/data-service";

const useStyles = makeStyles(() => ({
    root: {
        flex: 1,
    },
    content: {},
    divider: {
        marginRight: "2em",
    },
    title: {
        flexGrow: 1,
    },
    closeButton: {},
    notFound: {
        padding: "2em",
    },
}));

export default function Notifications() {
    const classes = useStyles();

    const { notifications, isLoading } = useContext(NotificationContext);

    const renderLoading = () => {
        const skeletonRows = () => {
            let rows = [];
            for (let i = 0; i < 10; i++) {
                rows.push(<Skeleton key={i} height={55} />);
            }
            return rows;
        };
        return skeletonRows();
    };

    const toogleRead = async notification => {
        updateDocument(
            `users/${notification.user.id}/notifications/${notification.id}`,
            {
                read: !notification.read,
            },
        ).then(() => {
            store.addNotification({
                ...notificationOpts,
                type: notificationType.SUCCESS,
                message: "Notificação foi atualizada",
            });
        });
    };

    const remove = notification => {
        deleteDocument(
            `users/${notification.user.id}/notifications/${notification.id}`,
        ).then(() => {
            store.addNotification({
                ...notificationOpts,
                type: notificationType.SUCCESS,
                message: "Notificação foi removida",
            });
        });
    };

    const readAll = () => {
        const batch = writeBatch(firestore);
        notifications.forEach(notification => {
            if (!notification.read) {
                const docRef = getDocumentReference(
                    `users/${notification.user.id}/notifications/${notification.id}`,
                );
                batch.update(docRef, { read: true });
            }
        });
        // Commit the batch
        batch
            .commit()
            .then(function () {
                store.addNotification({
                    ...notificationOpts,
                    type: notificationType.SUCCESS,
                    message: "Todas as notificação foram lidas",
                });
            })
            .catch(function (error) {
                console.error("Erro updating notifications", error);
                store.addNotification({
                    ...notificationOpts,
                    type: notificationType.ERROR,
                    message:
                        "Não foi possivel atualizar as notificação, tente novamente",
                });
            });
    };

    const removeAll = () => {
        const batch = writeBatch(firestore);
        notifications.forEach(notification => {
            const docRef = getDocumentReference(
                `users/${notification.user.id}/notifications/${notification.id}`,
            );
            batch.delete(docRef);
        });
        // Commit the batch
        batch
            .commit()
            .then(function () {
                store.addNotification({
                    ...notificationOpts,
                    type: notificationType.SUCCESS,
                    message: "Todas as notificação foram removidas",
                });
            })
            .catch(function (error) {
                console.error("Erro deleting notifications", error);
                store.addNotification({
                    ...notificationOpts,
                    type: notificationType.ERROR,
                    message:
                        "Não foi possivel excluir as notificação, tente novamente",
                });
            });
    };

    return (
        <Paper className={classes.root}>
            <Toolbar className={classes.toolbar} variant="regular">
                <Typography
                    className={classes.title}
                    variant="h6"
                    color="inherit"
                >
                    Notificações
                </Typography>
                <IconButton
                    aria-label="readAll"
                    className={classes.closeButton}
                    onClick={readAll}
                >
                    <Tooltip title="Marcar todas como lidas">
                        <MarkunreadMailboxIcon fontSize="small" />
                    </Tooltip>
                </IconButton>
                <IconButton
                    aria-label="clearAll"
                    className={classes.closeButton}
                    onClick={removeAll}
                >
                    <Tooltip title="Remover todas">
                        <ClearAllIcon fontSize="small" />
                    </Tooltip>
                </IconButton>
            </Toolbar>
            <Divider />
            {isLoading ? (
                renderLoading()
            ) : notifications.length ? (
                <List className={classes.content}>
                    {notifications
                        .sort(
                            (a, b) =>
                                new Date(b.createdAt) - new Date(a.createdAt),
                        )
                        .map((notification, key) => {
                            return (
                                <React.Fragment key={key}>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <NotificationsIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: notification.message,
                                                    }}
                                                />
                                            }
                                            secondary={
                                                <React.Fragment>
                                                    {moment(
                                                        notification.createdAt,
                                                    ).fromNow()}
                                                </React.Fragment>
                                            }
                                        />

                                        <IconButton
                                            aria-label="read"
                                            className={classes.closeButton}
                                            onClick={() =>
                                                toogleRead(notification)
                                            }
                                        >
                                            <Tooltip title="Marcar como lida">
                                                {notification.read ? (
                                                    <RadioButtonCheckedIcon fontSize="small" />
                                                ) : (
                                                    <RadioButtonUncheckedIcon fontSize="small" />
                                                )}
                                            </Tooltip>
                                        </IconButton>

                                        <Link to={notification.action}>
                                            <IconButton
                                                aria-label="view"
                                                className={classes.closeButton}
                                            >
                                                <Tooltip title="Visualizar">
                                                    <VisibilityIcon fontSize="small" />
                                                </Tooltip>
                                            </IconButton>
                                        </Link>

                                        <IconButton
                                            aria-label="close"
                                            className={classes.closeButton}
                                            onClick={() => remove(notification)}
                                        >
                                            <Tooltip title="Remover">
                                                <CloseIcon fontSize="small" />
                                            </Tooltip>
                                        </IconButton>
                                    </ListItem>
                                    <Divider
                                        className={classes.divider}
                                        variant="inset"
                                    />
                                </React.Fragment>
                            );
                        })}
                </List>
            ) : (
                <div className={classes.notFound}>
                    <Typography
                        variant="subtitle1"
                        color="inherit"
                        align="center"
                    >
                        Nenhum registro encontrado
                    </Typography>
                </div>
            )}
        </Paper>
    );
}
