import React from "react";

import AddIcon from "@mui/icons-material/Add";
import { Box, IconButton } from "@mui/material";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import { useHistory } from "react-router-dom";

import textLabels from "../../../constants/mui-datatable-labels";
import OrderStatus from "../../../constants/order-status";
import useListenOrders from "../../../hooks/useListenOrders";
import { getOrderStatus } from "../../../services";
import DatatableView from "../../commons/datatable-view";
import StatusLabel from "../../commons/status-label/StatusLabel";

const FILTERS = {
    1: [
        OrderStatus.NEW,
        OrderStatus.IN_PROGRESS,
        OrderStatus.ON_HOLD,
        OrderStatus.RELEASED,
        OrderStatus.WORK_COMPLETED,
        OrderStatus.VERIFICATION,
        OrderStatus.LAUNCH,
        OrderStatus.COMPLETED,
    ],
};

export default function OrderDatatable() {
    const { orders, isLoading } = useListenOrders(getStatusFilters(FILTERS));
    const history = useHistory();

    const renderDatatable = () => {
        const data = [
            ...orders.map(order => {
                return {
                    status: order.status,
                    description: order.description,
                    customer: order.customer ? order.customer.name : "",
                    number: order.number,
                    projectRACP: order.project ? order.project.number : "",
                    date: order.date
                        ? moment(order.date.toDate()).toISOString()
                        : "",
                };
            }),
        ];

        const columns = [
            {
                name: "number",
                label: "Número",
                options: {
                    filter: true,
                    sort: true,
                    // filterList: filters[0],
                },
            },
            {
                name: "status",
                label: "Status",
                options: {
                    filter: true,
                    filterType: "multiselect",
                    // filterList: filters[1],
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <StatusLabel
                                status={value.name}
                                color={value.color}
                            />
                        );
                    },
                    filterOptions: {
                        names: FILTERS[1],
                        renderValue: v => getOrderStatus(v).name,
                        logic: (status, filters, row) =>
                            !filters.includes(status.id),
                    },
                    customFilterListOptions: {
                        render: v => getOrderStatus(v).name,
                        update: (filterList, filterPos, index) => {
                            filterList[index].splice(filterPos, 1);
                            return filterList;
                        },
                    },
                    sort: true,
                    sortCompare:
                        order =>
                        ({ data: a }, { data: b }) => {
                            if (a.name === b.name) return 0;
                            return (
                                (a.name > b.name ? 1 : -1) *
                                (order === "asc" ? 1 : -1)
                            );
                        },
                },
            },
            {
                name: "date",
                label: "Data",
                options: {
                    filter: true,
                    // filterList: filters[2],
                    sort: true,
                    customBodyRender: value => getDateValue(value),
                },
            },
            {
                name: "description",
                label: "Descrição",
                options: {
                    filter: true,
                    // filterList: filters[3],
                    sort: true,
                },
            },
            {
                name: "projectRACP",
                label: "RACP Nº",
                options: {
                    filter: true,
                    // filterList: filters[4],
                    sort: true,
                },
            },
            {
                name: "customer",
                label: "Cliente",
                options: {
                    filter: true,
                    // filterList: filters[5],
                    sort: true,
                },
            },
        ];

        const options = {
            // serverSide: true,
            confirmFilters: false,
            selectableRows: "none",
            filterType: "textField",
            elevation: 1,
            textLabels: textLabels,
            downloadOptions: {
                filename: "ordens.csv",
            },
            onDownload: (buildHead, buildBody, columns, data) => {
                columns = columns.map(column => {
                    column.name = column.label;
                    return column;
                });
                return "\uFEFF" + buildHead(columns) + buildBody(data);
            },
            onRowClick: (rowData, rowMeta) => {
                const id = orders[rowMeta.dataIndex].id;
                history.push(`/orders/${id}`);
            },
            onFilterChange: (column, filterList, type) => {
                // setFilters({ ...filterList });
            },
            customToolbar: () => {
                return (
                    <IconButton
                        style={{ order: -1 }}
                        onClick={() => {
                            history.push(`/orders/new`);
                        }}
                    >
                        <AddIcon />
                    </IconButton>
                );
            },
        };

        const title = "Ordens de Serviço";

        return (
            <DatatableView
                title={title}
                data={data}
                columns={columns}
                options={options}
            />
        );
    };

    const renderLoading = () => {
        const skeletonRows = () => {
            let rows = [];
            for (let i = 0; i < 20; i++) {
                rows.push(<Skeleton key={i} height={55} />);
            }
            return rows;
        };

        return skeletonRows();
    };

    return isLoading ? renderLoading() : renderDatatable();
}

function getStatusFilters(filters) {
    return {
        status: filters[1] ? filters[1] : undefined,
    };
}

function getDateValue(date) {
    return date ? moment(new Date(date)).format("DD/MM/YYYY") : "";
}
