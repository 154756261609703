const notificationOpts = {
    insert: "top",
    type: "default", // 'default', 'success', 'info', 'warning'
    container: "top-center", // where to position the notifications
    animationIn: ["animated", "fadeIn"], // animate.css classes that's applied
    animationOut: ["animated", "fadeOut"], // animate.css classes that's applied
    dismiss: {
        duration: 3000,
        showIcon: true,
    },
};

export default notificationOpts;
