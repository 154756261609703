import * as React from "react";
import { useContext } from "react";

import DownloadIcon from "@mui/icons-material/Download";
import fileDownload from "react-file-download";
import { Store as notifications } from "react-notifications-component";

import Forms from "../../../constants/form-types";
import notificationOpts from "../../../constants/notification-options";
import notificationType from "../../../constants/notification-types";
import { AuthContext } from "../../../contexts";
import { calHttplFunction } from "../../../services/data-service";
import MenuButton from "../menu-button";

const downloadOptions = [
    ...Forms.filter(form => ["F031", "F037", "F048"].includes(form.id)),
    {
        id: "Tabela05",
        name: "Tabela 05 - Parâmetros físico-químicos estabilizados",
        shortName: "Tabela05",
        fileName: "Tabela 05 - Parâmetros_físico-químicos_estabilizados.xlsx",
    },
];

export default function DownloadFormsButton({ order = {} }) {
    const orderId = order.id;
    const [isDownloading, setIsDownloading] = React.useState(false);
    const { currentUser } = useContext(AuthContext);

    const downloadReport = (formId, filename) => {
        setIsDownloading(true);
        calHttplFunction(
            `downloadReport?formId=${formId}&orderId=${orderId}&userId=${currentUser}`,
        )
            .then(response => response.blob())
            .then(blob => {
                fileDownload(blob, filename);
            })
            .catch(error => {
                console.error("Error downloading file", error);
                notifications.addNotification({
                    ...notificationOpts,
                    type: notificationType.ERROR,
                    message: "Não foi possivel baixar o arquivo",
                });
            })
            .finally(() => {
                setIsDownloading(false);
            });
    };

    const items = downloadOptions.map(form => ({
        ...form,
        title: form.name,
        icon: <DownloadIcon />,
        onClick: downloadReport.bind(null, form.id, form.fileName),
    }));

    return (
        <MenuButton
            title="Formulários"
            icon={DownloadIcon}
            items={items}
            startIcon={<DownloadIcon />}
            loading={isDownloading}
            loadingIndicator="Downloading..."
        />
    );
}
