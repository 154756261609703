export const pendencyName = pendencyType => {
    switch (pendencyType) {
        case "comercial":
            return "Pendência Comercial";

        case "installation":
            return "Pendência Instalação";

        case "aprovation":
            return "Pendência Aprovação";

        default:
            return "Pendência";
    }
};
