import { ptBR as coreptBR } from "@mui/material/locale";
import { createTheme } from "@mui/material/styles";
import { ptBR } from "@mui/x-data-grid/locales";
// import { ptBR as pickersptBR } from "@mui/x-date-pickers/locales";

export const theme = createTheme(
    {
        typography: {
            fontFamily: ["Roboto", "sans-serif"].join(","),
            h1: {
                fontFamily: "Roboto",
                fontSize: "26px",
                fontWeight: "500",
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "1.15",
                letterSpacing: "normal",
                textAlign: "left",
            },
            h2: {
                fontFamily: "Roboto",
                fontSize: "24px",
                fontWeight: "500",
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "1.17",
                letterSpacing: "normal",
                textAlign: "left",
            },
            h3: {
                fontFamily: "Roboto",
                fontSize: "20px",
                fontWeight: "500",
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "1.2",
                letterSpacing: "normal",
                textAlign: "left",
            },
            h4: {
                fontFamily: "Roboto",
                fontSize: "18px",
                fontWeight: "500",
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "1.17",
                letterSpacing: "normal",
                textAlign: "left",
            },
            h5: {
                fontFamily: "Roboto",
                fontSize: "16px",
                fontWeight: "500",
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "1.19",
                letterSpacing: "normal",
                textAlign: "left",
            },
            p: {
                fontFamily: "Roboto",
                fontSize: "14px",
                fontWeight: "normal",
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "1.71",
                letterSpacing: "normal",
                textAlign: "left",
            },
            span: {
                fontFamily: "Roboto",
                fontSize: "13px",
                fontWeight: "normal",
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "1.15",
                letterSpacing: "normal",
                textAlign: "left",
            },
            small: {
                fontFamily: "Roboto",
                fontSize: "12px",
                fontWeight: "normal",
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "1.17",
                letterSpacing: "normal",
                textAlign: "left",
            },
            caption: {
                fontFamily: "Roboto",
                fontSize: "13px",
                fontWeight: "500",
                fontStyle: "normal",
                fontStretch: "normal",
                lineHeight: "1.15",
                letterSpacing: "normal",
                textAlign: "left",
            },
        },
        palette: {
            primary: {
                light: "#3366ab",
                main: "#004b8d",
                dark: "#00305a",
                contrastText: "#fff",
            },
            secondary: {
                light: "#33577a",
                main: "#00305a",
                dark: "#001c3a",
                contrastText: "#fff",
            },
            error: {
                light: "#e57373",
                main: "#f44336",
                dark: "#d32f2f",
                contrastText: "#fff",
            },
            warning: {
                light: "#ffb74d",
                main: "#ffa726",
                dark: "#f57c00",
                contrastText: "#fff",
            },
            info: {
                light: "#64b5f6",
                main: "#2196f3",
                dark: "#1976d2",
                contrastText: "#fff",
            },
            success: {
                light: "#81c784",
                main: "#4caf50",
                dark: "#388e3c",
                contrastText: "#fff",
            },
            status: {
                draft: {
                    main: "#FFD700",
                    contrastText: "#fff",
                },
                new: {
                    main: "#4FC3F7",
                    contrastText: "#fff",
                },
                released: {
                    main: "#4caf50",
                    contrastText: "#fff",
                },
                inProgress: {
                    main: "#1976d2",
                    contrastText: "#fff",
                },
                workCompleted: {
                    main: "#FF9800",
                    contrastText: "#fff",
                },
                onHold: {
                    main: "#BDBDBD",
                    contrastText: "#fff",
                },
                verification: {
                    main: "#3F51B5",
                    contrastText: "#fff",
                },
                launch: {
                    main: "#7E57C2",
                    contrastText: "#fff",
                },
                completed: {
                    main: "#00796B",
                    contrastText: "#fff",
                },
                cancelled: {
                    main: "#E53935",
                    contrastText: "#fff",
                },
                unknown: {
                    main: "#37474F",
                    contrastText: "#fff",
                },
            },
            background: {
                default: "#f4f6f8",
                paper: "#ffffff",
            },
            text: {
                primary: "#333333",
                secondary: "#757575",
            },
        },
    },
    ptBR,
    coreptBR,
    // pickersptBR,
);
