export function getPropByString(obj, propString) {
    if (!propString) return obj;

    var prop,
        props = propString.split(".");

    for (var i = 0, iLen = props.length - 1; i < iLen; i++) {
        prop = props[i];

        var candidate = obj[prop];
        if (candidate !== undefined) {
            obj = candidate;
        } else {
            break;
        }
    }
    return obj[props[i]];
}

export function isEmpty(obj) {
    for (var prop in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, prop)) {
            return false;
        }
    }

    return true;
}

export function isObject(val) {
    return val instanceof Object;
}

export function toJSON(obj) {
    return JSON.parse(JSON.stringify(obj));
}
