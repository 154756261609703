import React, { useState } from "react";

import CustomerList from "./customer-list";
import CustomerForm from "./customer-form";

import "./Customers.css";

export default function Customers() {
    const [state, setState] = useState({
        isLoading: false,
        selectedCustomer: {},
        errors: [],
    });

    const onCustomerSelected = customer => {
        setState({
            ...state,
            selectedCustomer: customer,
        });
    };

    return (
        <div className="customers-form">
            <div className="created-customers-form">
                <CustomerList onCustomerSelected={onCustomerSelected} />
            </div>
            <div className="new-customer-form">
                <CustomerForm
                    customerId={state.selectedCustomer.id}
                    currentCustomer={state.selectedCustomer}
                />
            </div>
        </div>
    );
}
