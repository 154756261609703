import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { IconButton, TextField } from "@mui/material";

import ArrowDropIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

import classNames from "classnames";
import { cpfFormatter, phoneFormatter } from "../../../../../lib/form-utils";

const useStyles = makeStyles(theme => ({
    data: {
        marginTop: "0.5em",
        flex: 1,
        display: "flex",
        flexDirection: "column",
    },
    dataHeader: {
        flex: 1,
        display: "flex",
    },
    dataTitle: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
    dataContent: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
    },
    row: {
        display: "flex",
    },
    inputField: {
        "& > *": {
            marginLeft: 15,
            marginRight: 5,
            marginBottom: 10,
        },
    },
    legalResponsibleField: {
        "& > *": {
            marginLeft: 0,
        },
        width: 300,
    },
    cpfField: {
        width: 200,
    },
    phoneField: {
        "& > *": {
            marginLeft: 0,
        },
        width: 200,
    },
    emailField: {
        width: 300,
    },
}));

const LegalOfficerInfo = ({ onInputChange, customer }) => {
    const classes = useStyles();
    const [isDisplayed, setIsDisplayed] = useState(true);

    const info = (
        <div className={classes.data}>
            <div className={classes.dataHeader}>
                <div className={classes.dataTitle}>
                    <h5>Responsável legal</h5>
                </div>
                <IconButton onClick={() => setIsDisplayed(!isDisplayed)}>
                    {isDisplayed ? <ArrowDropIcon /> : <ArrowRightIcon />}
                </IconButton>
            </div>
            <div
                className={classes.dataContent}
                style={{ display: isDisplayed ? "" : "none" }}
            >
                <div className={classes.row}>
                    <TextField
                        variant="standard"
                        className={classNames(
                            classes.inputField,
                            classes.legalResponsibleField
                        )}
                        label="Responsável Legal"
                        value={customer.legalResponsibleName || ""}
                        onChange={onInputChange("legalResponsibleName")}
                    />
                    <TextField
                        variant="standard"
                        className={classNames(
                            classes.inputField,
                            classes.cpfField
                        )}
                        label="CPF"
                        value={cpfFormatter(customer.legalResponsibleCpf) || ""}
                        onChange={onInputChange("legalResponsibleCpf")}
                    />
                </div>
                <div className={classes.row}>
                    <TextField
                        variant="standard"
                        className={classNames(
                            classes.inputField,
                            classes.phoneField
                        )}
                        label="Telefone"
                        value={
                            phoneFormatter(customer.legalResponsiblePhone) || ""
                        }
                        onChange={onInputChange("legalResponsiblePhone")}
                    />
                    <TextField
                        variant="standard"
                        className={classNames(
                            classes.inputField,
                            classes.emailField
                        )}
                        label="E-mail"
                        type="email"
                        value={customer.legalResponsibleEmail || ""}
                        onChange={onInputChange("legalResponsibleEmail")}
                    />
                </div>
            </div>
        </div>
    );

    return info;
};

export default LegalOfficerInfo;
