/* eslint-disable react/jsx-no-target-blank */
import React, { useContext } from "react";

import {
    faBookmark,
    faChartBar,
    faFolder,
    faListAlt,
    faUser,
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Grid from "@mui/material/Grid";

import UserRoles from "../../constants/user-roles";
import { AuthContext } from "../../contexts/auth.context";
import "./Home.css";

import { NavLink } from "react-router-dom";

export default function Home(props) {
    const { hasAnyRole } = useContext(AuthContext);

    return (
        <div className="home">
            <div className="containers">
                <div className="container-general">
                    <h1>Geral</h1>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={3} sm={12}>
                            <NavLink to={"/tasks"}>
                                <div className="item">
                                    <FontAwesomeIcon
                                        icon={faListAlt}
                                        className="icon"
                                    />
                                    <p>Minhas atividades</p>
                                </div>
                            </NavLink>
                        </Grid>

                        <Grid item xs={12} md={3} sm={12}>
                            <NavLink to={"/projects"}>
                                <div className="item">
                                    <FontAwesomeIcon
                                        icon={faFolder}
                                        className="icon"
                                    />
                                    <p>Projetos</p>
                                </div>
                            </NavLink>
                        </Grid>

                        <Grid item xs={12} md={3} sm={12}>
                            <NavLink to={"/graphs"}>
                                <div className="item">
                                    <FontAwesomeIcon
                                        icon={faChartBar}
                                        className="icon"
                                    />
                                    <p>Gráficos</p>
                                </div>
                            </NavLink>
                        </Grid>
                    </Grid>
                </div>
                {hasAnyRole(["CDC", "CDU", "CDJ", "CDE", "CDP", "CDW"]) && (
                    <div className="container-registrations">
                        <h1>Cadastros</h1>

                        <Grid container spacing={2}>
                            {hasAnyRole([UserRoles.CADASTRAR_PROJETO]) && (
                                <Grid item xs={12} md={3} sm={12}>
                                    <NavLink to={"/projects/new"}>
                                        <div className="item">
                                            <FontAwesomeIcon
                                                icon={faFolder}
                                                className="icon"
                                            />
                                            <p>Cadastrar projetos</p>
                                        </div>
                                    </NavLink>
                                </Grid>
                            )}

                            {hasAnyRole([UserRoles.CADASTRAR_CLIENTE]) && (
                                <Grid item xs={12} md={3} sm={12}>
                                    <NavLink to={"/customers/new"}>
                                        <div className="item">
                                            <FontAwesomeIcon
                                                icon={faBookmark}
                                                className="icon"
                                            />
                                            <p>Cadastrar cliente</p>
                                        </div>
                                    </NavLink>
                                </Grid>
                            )}
                            {hasAnyRole([UserRoles.CADASTRAR_USUARIO]) && (
                                <Grid item xs={12} md={3} sm={12}>
                                    <NavLink to={"/users/new"}>
                                        <div className="item">
                                            <FontAwesomeIcon
                                                icon={faUser}
                                                className="icon"
                                            />
                                            <p>Cadastrar usuário</p>
                                        </div>
                                    </NavLink>
                                </Grid>
                            )}
                        </Grid>
                    </div>
                )}
            </div>
        </div>
    );
}
