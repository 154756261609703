const OrderStatus = {
    DRAFT: "DRAFT",
    NEW: "NEW",
    RELEASED: "RELEASED",
    IN_PROGRESS: "IN_PROGRESS",
    WORK_COMPLETED: "WORK_COMPLETED",
    ON_HOLD: "ON_HOLD",
    VERIFICATION: "VERIFICATION",
    LAUNCH: "LAUNCH",
    COMPLETED: "COMPLETED",
    CANCELLED: "CANCELLED",
};
export default OrderStatus;
