import React, { useState } from "react";

import {
    Autocomplete,
    Button,
    DialogActions,
    DialogTitle,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { createTheme } from "@mui/material/styles";
import { Box } from "@mui/system";

import Forms from "../../../constants/form-types";
import { OrderTaskModel } from "../../../models";

export default function OrderTaskDialog({ onClose, onSave, open, data = {} }) {
    const [task, setTask] = useState(new OrderTaskModel({ ...data }));
    const [error, setError] = useState({});

    const handleSaveClick = () => {
        if (!task.description) {
            setError({ description: true });
        } else {
            onSave && onSave(task);
        }
    };

    return (
        <Dialog open={open} fullWidth>
            <DialogTitle sx={{ m: 1, p: 2 }}>Tarefa</DialogTitle>
            <DialogContent dividers>
                <Autocomplete
                    id="task-form"
                    sx={{ mb: theme.spacing(3) }}
                    options={Forms}
                    getOptionLabel={option => option.name}
                    renderOption={(props, option) => {
                        return (
                            <Box {...props} key={option.id} component="li">
                                <Stack>
                                    <Typography
                                        variant="h5"
                                        component="div"
                                        gutterBottom
                                    >
                                        {option.name}
                                    </Typography>
                                </Stack>
                            </Box>
                        );
                    }}
                    isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                    }
                    value={task.form || null}
                    onChange={(event, value) =>
                        setTask({
                            ...task,
                            form: value,
                            ...(value && { description: value.name }),
                        })
                    }
                    renderInput={params => (
                        <TextField
                            {...params}
                            variant="standard"
                            label="Formulário"
                        />
                    )}
                />

                <TextField
                    sx={{ pb: theme.spacing(2) }}
                    fullWidth
                    id="task-description"
                    value={task.description}
                    onChange={event =>
                        setTask({
                            ...task,
                            description: event.target.value,
                        })
                    }
                    label="Descrição"
                    variant="standard"
                    error={error.description}
                    required
                />

                <TextField
                    sx={{ pb: theme.spacing(2) }}
                    fullWidth
                    id="task-amount"
                    value={task.amount}
                    onChange={event =>
                        setTask({
                            ...task,
                            amount: event.target.value,
                        })
                    }
                    label="Quantidade"
                    type="number"
                    variant="standard"
                    error={error.amount}
                    required
                />

                <TextField
                    fullWidth
                    multiline
                    minRows={4}
                    id="task-note"
                    value={task.note}
                    onChange={event =>
                        setTask({
                            ...task,
                            note: event.target.value,
                        })
                    }
                    label="Observações"
                    variant="standard"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancelar</Button>
                <Button onClick={handleSaveClick}>Salvar</Button>
            </DialogActions>
        </Dialog>
    );
}

const theme = createTheme();
