import React, { Component } from "react";

import { faClipboardList } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Fab from "@mui/material/Fab";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import SnackbarContent from "@mui/material/SnackbarContent";
import { withStyles } from "@mui/styles";
import classNames from "classnames";
import PropTypes from "prop-types";

import "./ProjectReopenDialog.css";
import { callFunction } from "../../../../services/data-service";

const styles = theme => ({
    actionIcon: {
        margin: theme.spacing(1),
    },
    actionButton: {
        margin: theme.spacing(1) / 2,
        fontFamily: "Roboto",
        fontSize: "14px",
        fontWeight: "normal",
        fontStyle: "normal",
        fontStretch: "normal",
        lineHeight: "1.14",
        letterSpacing: "normal",
        textAlign: "left",
        color: "#4192d9",
        textTransform: "none",
        boxShadow: "none",
    },
    actionButtonLabel: {
        paddingRight: theme.spacing(1),
    },
    cancelButton: {
        color: "red",
    },
    submitButton: {
        color: "green",
    },
    loadingIndicator: {
        color: "red",
        marginLeft: theme.spacing(2),
    },
    error: {
        backgroundColor: theme.palette.error.dark,
        marginBottom: theme.spacing(2),
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
});

// TODO: Convert to functional component
class ProjectReopenDialog extends Component {
    static propTypes = {
        classes: PropTypes.object,
        projectId: PropTypes.string,
    };

    constructor(props) {
        super(props);
        this.state = {
            isReopening: false,
            open: false,
            errors: [],
        };
    }

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClickReopen = event => {
        const { projectId } = this.props;

        this.setState({
            isReopening: true,
        });

        callFunction("reopenProject", { projectId })
            .then(response => {
                if (this.props.onUpdated) this.props.onUpdated(response.entity);
                this.handleClose();
                this.setState({
                    isReopening: false,
                });
            })
            .catch(error => {
                console.error("Error reopening project", error);
                this.setState({
                    isReopening: false,
                    errors: [
                        "Não foi possivel reabrir o projeto, tente novamente",
                    ],
                });
            });
    };

    onCloseError = key => {
        const { errors } = this.state;
        errors.splice(key, 1);
        this.setState({
            errors,
        });
    };

    handleClose = () => {
        this.setState({
            errors: [],
            open: false,
        });

        if (this.props.onClose) this.props.onClose();
    };

    render() {
        const { classes, isLoading } = this.props;
        return (
            <div className="project-reopen-dialog">
                <Fab
                    variant="extended"
                    size="small"
                    aria-label="Add"
                    classes={{
                        root: classes.actionButton,
                        label: classes.actionButtonLabel,
                    }}
                    disabled={isLoading}
                    onClick={this.handleClickOpen}
                >
                    <FontAwesomeIcon
                        className={classes.actionIcon}
                        icon={faClipboardList}
                    />
                    Reabrir
                </Fab>
                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Reabrir projeto?
                    </DialogTitle>
                    <DialogContent>
                        {this.renderErrors()}
                        <DialogContentText id="alert-dialog-description">
                            Você tem certeza que deseja Reabrir este projeto?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            className={classes.submitButton}
                            onClick={this.handleClickReopen}
                            disabled={isLoading}
                        >
                            Sim
                            {this.state.isReopening && (
                                <CircularProgress
                                    size={18}
                                    thickness={4}
                                    className={classes.loadingIndicator}
                                />
                            )}
                        </Button>
                        <Button
                            className={classes.cancelButton}
                            onClick={this.handleClose}
                        >
                            Não
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

    renderErrors = () => {
        const { classes } = this.props;
        const { errors } = this.state;

        if (!errors) return "";

        return (
            <div className="form-errors">
                {errors.map((error, key) => {
                    return (
                        <SnackbarContent
                            key={key}
                            className={classNames(classes.error)}
                            aria-describedby="error-message"
                            message={
                                <span
                                    id="error-message"
                                    className={classes.message}
                                >
                                    <Icon
                                        className={classNames(
                                            classes.icon,
                                            classes.iconVariant,
                                        )}
                                    />
                                    {error}
                                </span>
                            }
                            action={[
                                <IconButton
                                    key="close"
                                    aria-label="Close"
                                    color="inherit"
                                    className={classes.close}
                                    onClick={() => this.onCloseError(key)}
                                >
                                    <CloseIcon className={classes.icon} />
                                </IconButton>,
                            ]}
                        />
                    );
                })}
            </div>
        );
    };
}

export default withStyles(styles)(ProjectReopenDialog);
