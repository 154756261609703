import React from "react";
import classNames from "classnames";

import { Tooltip } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";

import {
    deadlineStatus,
    deadlineStatusOutput,
    deadlineStatusColor,
} from "../../../lib/status.utils";

export default function DeadlineStatusIcon({
    startDate,
    daysToDelivery,
    size,
}) {
    const status = deadlineStatus(startDate, daysToDelivery);
    return (
        <span style={{ fontSize: size }}>
            <Tooltip title={deadlineStatusOutput(status) || ""}>
                <span>
                    <FontAwesomeIcon
                        className={classNames(deadlineStatusColor(status))}
                        icon={faCircle}
                    />
                </span>
            </Tooltip>
        </span>
    );
}
