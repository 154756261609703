/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState } from "react";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import Skeleton from "react-loading-skeleton";

import "./ProjectInfos.css";

import ProjectInfo from "./project-info/ProjectInfo";
import { DataContext } from "../../../../../contexts";

export default function ProjectInfos(props) {
    const { events, isLoadingEvents, infos, isLoadingInfos } = useContext(
        DataContext
    );

    const [activeTab, setActiveTab] = useState(0);

    const handleChangeTab = (event, value) => {
        setActiveTab(value);
    };

    const renderItems = type => {
        const list = type === "events" ? events : infos;
        const response =
            isLoadingEvents || isLoadingInfos
                ? renderLoading()
                : list.map((info, index) => (
                      <ProjectInfo key={index} info={info} index={index} />
                  ));
        return response;
    };

    const renderLoading = () => {
        const skeletonRows = () => {
            let rows = [];
            for (let i = 0; i < 4; i++) {
                rows.push(
                    <div key={i} className="info-container">
                        <div className="info-header">
                            <Skeleton width={210} />
                        </div>
                        <div className="info-message">
                            <Skeleton height={70} width={340} />
                        </div>
                    </div>
                );
            }
            return rows;
        };

        return skeletonRows();
    };

    return (
        <div className="project-infos">
            <div className="infos-container">
                <div className="infos-title">
                    <Tabs
                        value={activeTab}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handleChangeTab}
                    >
                        <Tab label="INFORMAÇÕES" />
                        <Tab label="EVENTOS" />
                    </Tabs>
                </div>
                <div className="infos-items">
                    {activeTab === 0 && renderItems("infos")}
                    {activeTab === 1 && renderItems("events")}
                </div>
            </div>
        </div>
    );
}
