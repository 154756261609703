/* eslint-disable react/prop-types */
import React, { useState, useEffect, useContext } from "react";

import { Close as CloseIcon } from "@mui/icons-material";
import {
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    CircularProgress,
    Icon,
    IconButton,
    SnackbarContent,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";

import sortBy from "../../../../lib/sort-by";

import "./ProjectPendencyDialog.css";
import { DataContext, AuthContext } from "../../../../contexts";
import {
    getCollectionData,
    where,
    callFunction,
} from "../../../../services/data-service";

const useStyles = makeStyles(theme => ({
    actionIcon: {
        margin: theme.spacing(1),
    },
    actionButton: {
        margin: theme.spacing(2),
        fontFamily: "Roboto",
        fontSize: "14px",
        fontWeight: "normal",
        fontStyle: "normal",
        fontStretch: "normal",
        lineHeight: "1.14",
        letterSpacing: "normal",
        textAlign: "left",
        color: "#4192d9",
        textTransform: "none",
    },
    actionButtonLabel: {
        paddingRight: theme.spacing(1),
    },
    cancelButton: {
        color: "red",
    },
    submitButton: {
        color: "green",
    },
    loadingIndicator: {
        color: "red",
        marginLeft: theme.spacing(2),
    },
    error: {
        backgroundColor: theme.palette.error.dark,
        marginBottom: theme.spacing(2),
    },
    info: {
        backgroundColor: theme.palette.primary.dark,
        marginBottom: theme.spacing(2),
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        width: "480px",
    },
    reasonSelect: {
        width: "auto",
        minWidth: 450,
    },
    phaseSelect: {
        width: "auto",
        minWidth: 300,
    },
    studySelect: {
        width: "auto",
        minWidth: 200,
    },
}));

const theme = createTheme({
    palette: {
        primary: {
            main: "#3fb165",
        },
        secondary: {
            light: "#0066ff",
            main: "#0044ff",
            contrastText: "#ffcc00",
        },
    },
    typography: {
        useNextVariants: true,
    },
});

const initialData = {
    message: "",
    projectId: "",
    phase: {},
    reason: {},
    study: {},
    phases: [],
    reasons: [],
    studies: [],
};

export default function ProjectPendencyDialog(props) {
    const classes = useStyles();

    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState({
        ...initialData,
        projectId: props.projectId,
        message: props.message,
    });
    const [errors, setErrors] = useState([]);

    const {
        project,
        phase: currentPhase,
        setIsLoadingProject,
    } = useContext(DataContext);

    const { currentUser } = useContext(AuthContext);

    const {
        projectId,
        message,
        phase = {},
        reason = {},
        study = {},
        phases,
        reasons,
        studies,
    } = data;

    const reasonId = reason ? reason.id : "";
    const studyId = study ? study.id : "";
    const phaseId = phase ? phase.id : "";

    // fetch data
    useEffect(() => {
        if (project.id !== projectId) {
            fetchData();
        }
    }, [project.id, projectId]);

    const fetchData = async () => {
        setIsLoading(true);

        const reasons = await getCollectionData("reasons", [
            where("type", "==", "pendency"),
        ]);
        const studies = await getCollectionData("studies");
        const phases = await getCollectionData(
            `studies/${project.study.id}/phases`,
        );

        // Remove forward phases
        let filteredPhases = phases.filter(p => p.order < project.phase.order);

        // Remove phases for wells instalation if not needed
        if (!project.hasWellsInstallation) {
            filteredPhases = filteredPhases.filter(
                p => !p.forWellsInstallation,
            );
        }

        setData({
            ...data,
            phases: filteredPhases
                .sort(sortBy("order", false, a => a))
                .map(p => ({
                    id: p.id,
                    name: p.name,
                })),
            reasons: reasons.map(reason => ({
                id: reason.id,
                name: reason.name,
            })),
            studies: studies
                .map(study => ({
                    id: study.id,
                    name: study.name,
                }))
                .sort(sortBy("name", false, a => a.toUpperCase())),
            projectId: project.id,
        });
        setIsLoading(false);
    };

    const handleConfirmClick = () => {
        const errors = [];
        if (!reasonId) errors.push("Selecione um motivo");
        if (!message) errors.push("Informação não poder ficar em branco");
        if (reasonId === "R-P-6") {
            if (!studyId) errors.push("Selecione um estudo");
        } else {
            if (!phaseId) errors.push("Selecione uma etapa");
        }

        if (errors.length) {
            setErrors([...errors]);
            return;
        }
        setIsLoading(true);

        if (reasonId === "R-P-6") {
            callFunction("startNewStudyOnProjectExecution", {
                projectId,
                studyId,
                reasonId,
                rootPhaseId: currentPhase.id,
                message,
                user: currentUser,
            })
                .then(() => {
                    setIsLoading(false);
                    setIsLoadingProject(true);
                    if (props.onConfirm) props.onConfirm(message);
                })
                .catch(error => {
                    console.error("Error creating pendency", error);
                    if (props.onError) props.onError(error);
                })
                .finally(() => {
                    handleClose();
                });
        } else {
            callFunction("startNewProjectExecution", {
                projectId,
                studyId,
                reasonId,
                phaseId,
                rootPhaseId: currentPhase.id,
                message,
                user: currentUser,
            })
                .then(() => {
                    setIsLoading(false);
                    setIsLoadingProject(true);
                    if (props.onConfirm) props.onConfirm();
                })
                .catch(error => {
                    console.error("Error creating pendency", error);
                    if (props.onError) props.onError(error);
                })
                .finally(() => {
                    handleClose();
                });
        }
    };

    const onCloseError = key => {
        errors.splice(key, 1);
        setErrors([...errors]);
    };

    const handleClose = () => {
        setErrors([]);
        if (props.onClose) props.onClose();
    };

    const handleMessageChange = () => event => {
        setData({ ...data, message: event.target.value });
    };

    const handleSelectChange = name => value => {
        data[name] = value;
        setData({ ...data });
    };

    const renderOptions = () => {
        if (!reasonId) return null;
        if (reasonId === "R-P-6") {
            return (
                <div>
                    <Autocomplete
                        className={classes.studySelect}
                        id="studyId"
                        name="pendencyStudy"
                        value={study}
                        size="small"
                        onChange={(event, data) => {
                            handleSelectChange("study")(data);
                        }}
                        options={studies}
                        getOptionLabel={option => option.name || ""}
                        loading={isLoading}
                        disabled={isLoading}
                        renderInput={params => (
                            <TextField
                                {...params}
                                label="Selecionar estudo"
                                margin="normal"
                                placeholder="Selecionar estudo"
                                required={true}
                                variant="filled"
                                fullWidth
                            />
                        )}
                    />
                </div>
            );
        } else {
            if (phases.length === 0) {
                const infoMessage =
                    "Não é possivel gerar pendência na primeira etapa do estudo!";
                return (
                    <div className="form-infos">
                        <SnackbarContent
                            className={classNames(classes.info)}
                            aria-describedby="info-message"
                            message={
                                <span
                                    id="info-message"
                                    className={classes.message}
                                >
                                    {infoMessage}
                                </span>
                            }
                        />
                    </div>
                );
            } else {
                return (
                    <div>
                        <Autocomplete
                            className={classes.phaseSelect}
                            id="phaseId"
                            name="surveyorrSelector"
                            size="small"
                            value={phase}
                            onChange={(event, data) => {
                                handleSelectChange("phase")(data);
                            }}
                            options={phases}
                            getOptionLabel={option => option.name || ""}
                            loading={isLoading}
                            disabled={isLoading}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    label="Atribuir para etapa"
                                    margin="normal"
                                    placeholder="Atribuir para etapa"
                                    required={true}
                                    fullWidth
                                    variant="filled"
                                />
                            )}
                        />
                    </div>
                );
            }
        }
    };

    const renderErrors = () => {
        if (!errors) return "";
        return (
            <div className="form-errors">
                {errors.map((error, key) => {
                    return (
                        <SnackbarContent
                            key={key}
                            className={classNames(classes.error)}
                            aria-describedby="error-message"
                            message={
                                <span
                                    id="error-message"
                                    className={classes.message}
                                >
                                    <Icon
                                        className={classNames(
                                            classes.icon,
                                            classes.iconVariant,
                                        )}
                                    />
                                    {error}
                                </span>
                            }
                            action={[
                                <IconButton
                                    key="close"
                                    aria-label="Close"
                                    color="inherit"
                                    className={classes.close}
                                    onClick={() => onCloseError(key)}
                                >
                                    <CloseIcon className={classes.icon} />
                                </IconButton>,
                            ]}
                        />
                    );
                })}
            </div>
        );
    };

    return (
        <div className="project-pendency-dialog">
            <Dialog
                open={props.open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Deseja abrir pendência?
                </DialogTitle>
                <DialogContent>
                    {renderErrors()}
                    <div>
                        <Autocomplete
                            className={classes.reasonSelect}
                            id="reasonId"
                            name="pendencyReason"
                            value={reason}
                            size="small"
                            onChange={(event, data) => {
                                handleSelectChange("reason")(data);
                            }}
                            options={reasons}
                            getOptionLabel={option => option.name || ""}
                            loading={isLoading}
                            disabled={isLoading}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    label="Motivo da pendência"
                                    placeholder="Motivo da pendência"
                                    required={true}
                                    fullWidth
                                    variant="filled"
                                />
                            )}
                        />
                    </div>
                    {renderOptions()}
                    <div>
                        <ThemeProvider theme={theme}>
                            <TextField
                                id="pendency-message"
                                value={message}
                                onChange={handleMessageChange("message")}
                                label="Adicionar informação"
                                multiline
                                rows="4"
                                className={classes.message}
                                margin="normal"
                                disabled={isLoading}
                            />
                        </ThemeProvider>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        className={classes.submitButton}
                        onClick={handleConfirmClick}
                        disabled={isLoading}
                    >
                        Sim
                        {isLoading && (
                            <CircularProgress
                                size={18}
                                thickness={4}
                                className={classes.loadingIndicator}
                            />
                        )}
                    </Button>
                    <Button
                        className={classes.cancelButton}
                        onClick={handleClose}
                        disabled={isLoading}
                    >
                        Não
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
