import "typeface-roboto";
import React from "react";

import { Redirect, Route, Switch } from "react-router-dom";

import "animate.css";
import "react-notifications-component/dist/theme.css";
import "react-loading-skeleton/dist/skeleton.css";

import { projectFilterType } from "../../constants/project-filter-types";
import Customers from "../customers";
import Dashboard from "../dashboard";
import ProjectCreateForm from "../forms/project/project-create-form";
import ProjectEditForm from "../forms/project/project-edit-form";
import ProjectHistoryForm from "../forms/project/project-history-form";
import ProvisioningsForm from "../forms/provisionings-form/ProvisioningsForm";
import StudyForm from "../forms/study-form";
import Graphs from "../graphs";
import Home from "../home";
import Layout from "../layout";
import Login from "../login";
import Logout from "../logout";
import Notifications from "../notifications";
import PageNotFound from "../page-not-found";
import { PlanningBoard } from "../planning";
import Projects from "../projects";
import Provisionings from "../provisionings";
import Reasons from "../reasons";
import Tasks from "../tasks";
import Users from "../users";
import UserProfile from "../users/user-profile";

import "./App.css";
import WellsForm from "../wells/Wells-Form";
import Orders from "../orders/Orders";
import OrderDetails from "../orders/order-details";
import OrderTaskView from "../orders/order-form/OrderTaskView";

export default function App(props) {
    const ProjectHistory = props => {
        return <Projects {...props} filterType={projectFilterType.HISTORY} />;
    };

    const ProjectDrafter = props => {
        return <Projects {...props} filterType={projectFilterType.DRAFTER} />;
    };

    const ProjectSurveyor = props => {
        return <Projects {...props} filterType={projectFilterType.SURVEYOR} />;
    };

    const Documentation = () => {
        window.open(process.env.REACT_APP_DOCUMENTATION_URL);
        return <Redirect to="/" />;
    };

    return (
        <Switch>
            <Layout
                path="/provisionings/:id/edit"
                component={ProvisioningsForm}
                exact
            />
            <Layout path="/provisionings" component={Provisionings} />

            <Layout path="/projects/new" component={ProjectCreateForm} exact />
            <Layout
                path="/projects/:id/edit"
                component={ProjectEditForm}
                exact
            />

            <Layout path="/wells/form" component={WellsForm} exact />

            <Layout path="/orders/new" component={OrderDetails} exact />
            <Layout
                path="/orders/:orderId/tasks/:taskId"
                component={OrderTaskView}
                exact
            />
            <Layout path="/orders/:id" component={OrderDetails} exact />
            <Layout path="/orders" component={Orders} />

            <Layout path="/studies/new" component={StudyForm} />
            <Layout path="/reasons" component={Reasons} />
            <Layout path="/customers" component={Customers} />

            <Layout path="/notifications" component={Notifications} />
            <Layout path="/tasks" component={Tasks} />
            <Layout
                path="/project-history/:id/edit"
                component={ProjectHistoryForm}
                exact
            />
            <Layout path="/projects/desenhos" component={ProjectDrafter} />
            <Layout path="/projects/relatorios" component={ProjectSurveyor} />
            <Layout path="/project-history" component={ProjectHistory} />
            <Layout path="/projects" component={Projects} />
            <Layout path="/dashborad" component={Dashboard} />
            <Layout path="/graphs" component={Graphs} />
            <Layout path="/users/profile" component={UserProfile} exact />
            <Layout path="/users" component={Users} />
            <Layout path="/planning" component={PlanningBoard} />
            <Layout path="/" component={Home} exact />
            <Route path="/login" component={Login} />
            <Route path="/logout" component={Logout} />
            <Route path="/documentation" component={Documentation} />
            <Route render={PageNotFound} />
        </Switch>
    );
}
