/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useEffect, useState } from "react";

import { Box, Button, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { makeStyles } from "@mui/styles";
import Skeleton from "react-loading-skeleton";
import { Store as notifications } from "react-notifications-component";
import { Link } from "react-router-dom";

import TaskChecklist from "./task-checklist/TaskChecklist";
import TaskSidebar from "./task-sidebar/TaskSidebar";
import notificationOpts from "../../../constants/notification-options";
import notificationType from "../../../constants/notification-types";
import ProjectStatus from "../../../constants/project.status";
import UserRoles from "../../../constants/user-roles";
import { AuthContext, DataContext } from "../../../contexts";
import { callFunction, updateDocument } from "../../../services/data-service";
import DetailView from "../../commons/detail-view";
import ProjectStatusLabel from "../../commons/project-status-label";
import ProjectInfo from "../../projects/project-data/project-info";
import CompleteTaskDialog from "../dialogs/complete-task-dialog";
import ProjectPendencyDialog from "../dialogs/project-pendency-dialog";

const useStyles = makeStyles(() => ({
    completeTaskButton: {
        lineHeight: "1.43",
        color: "#4192d9",
        textTransform: "none",
        padding: "10px 16px",
        backgroundColor: "rgba(153, 153, 153, 0.2)",
        border: "none",
    },
    projectButton: {
        padding: "1px 8px",
        opacity: "0.69",
        fontFamily: "Roboto",
        fontSize: "15px",
        fontWeight: "500",
        fontStyle: "normal",
        fontStretch: "normal",
        lineHeight: "1.19",
        letterSpacing: "normal",
        textAlign: "left",
    },
    assigneeField: {
        backgroundColor: "rgba(153, 153, 153, 0.2)",
        lineHeight: "1.43",
        textAlign: "left",
        color: "#6a6a6a",
        borderStyle: "none",
        "& .MuiTextField-root": {
            "& label": {
                fontFamily: "Roboto",
                fontSize: 14,
                fontWeight: 500,
                fontStyle: "normal",
                fontStretch: "normal",
                letterSpacing: "normal",
                textAlign: "center",
                margin: 0,
            },
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                border: "none",
            },
            "& input": {
                fontFamily: "Roboto",
                fontSize: 14,
                fontWeight: 500,
                fontStyle: "normal",
                fontStretch: "normal",
                lineHeight: "1.43",
                letterSpacing: "normal",
                textAlign: "left",
                color: "#6a6a6a",
            },
        },
    },
}));

// Fetch all needed data
function useFetchTaskData({ phaseId, executionId, projectId, key }) {
    const {
        users,
        phase,
        fetchPhase,
        isLoadingPhase,
        isLoadingUsers,
        notFound,
        unfetchPhase,
    } = useContext(DataContext);

    // fetch project data
    useEffect(() => {
        fetchPhase(projectId, executionId, phaseId);
        return function cleanup() {
            unfetchPhase();
        };
    }, [projectId, executionId, phaseId, key]);

    return {
        isLoading: !phase || isLoadingUsers || isLoadingPhase,
        phase,
        notFound,
        users,
    };
}

export default function TaskDetail({ match, location = {} }) {
    const [projectId, executionId, phaseId] = match.params.id.split("__");

    const classes = useStyles();

    const {
        isLoading: loading,
        phase,
        users,
        notFound,
    } = useFetchTaskData({
        projectId,
        executionId,
        phaseId,
        key: location.key,
    });

    const [openCompleteTaskDialog, setOpenCompleteTaskDialog] = useState(false);
    const [openPendencyDialog, setOpenPendencyDialog] = useState(false);
    const [isPhaseCompleted, setIsPhaseCompleted] = useState(false);
    const [isLoading, setIsLoading] = useState(loading);

    useEffect(() => {
        setIsLoading(loading);
    }, [loading]);

    useEffect(() => {
        setOpenCompleteTaskDialog(
            phase.completed && phase.status === ProjectStatus.IN_PROGRESS,
        );
        setIsPhaseCompleted(
            phase.completed && phase.status === ProjectStatus.IN_PROGRESS,
        );
    }, [phase.completed, phase.status]);

    const onAssigneeChange = assignee => {
        const assigneeId = assignee ? assignee.id : null;
        const assigneeData = assignee
            ? {
                  id: assignee.id,
                  name: assignee.name,
                  email: assignee.email,
              }
            : null;

        updateDocument(phase.documentName, {
            assigneeId: assigneeId,
            assignee: assigneeData,
        })
            .then(() => {
                notifications.addNotification({
                    ...notificationOpts,
                    type: notificationType.SUCCESS,
                    message: "Atividade atribuída com sucesso",
                });
            })
            .catch(error => {
                notifications.addNotification({
                    ...notificationOpts,
                    type: notificationType.ERROR,
                    message:
                        "Não foi possível atribuir a atividade, tente novamente",
                });
                console.error("Error updating assignee: ", error);
            });
    };

    const onCompleteTaskButtonClick = () => {
        setOpenCompleteTaskDialog(true);
    };

    const onReleaseTaskButtonClick = () => {
        setIsLoading(true);
        callFunction("releasePhase", {
            projectId,
            phaseId,
            executionId,
            assignee:
                phase.assignee && phase.assignee.id ? phase.assignee : null,
        })
            .then(() => {
                notifications.addNotification({
                    ...notificationOpts,
                    type: notificationType.SUCCESS,
                    message: "Atividade liberada com sucesso",
                });
            })
            .catch(error => {
                notifications.addNotification({
                    ...notificationOpts,
                    type: notificationType.ERROR,
                    message:
                        "Não foi possível liberar a atividade, tente novamente",
                });
                console.error("Error releasing assignee: ", error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const onCompleteTaskDialogClose = () => {
        setOpenCompleteTaskDialog(false);
    };

    const onCompleteTaskDialogError = () => {
        setOpenCompleteTaskDialog(false);
        notifications.addNotification({
            ...notificationOpts,
            type: notificationType.ERROR,
            message: "Não foi possivel concluir a etapa, tente novamente",
        });
    };

    const onCompleteTaskDialog = () => {
        setOpenCompleteTaskDialog(false);
        notifications.addNotification({
            ...notificationOpts,
            type: notificationType.SUCCESS,
            message: "Etapa concluida com sucesso",
        });
    };

    const onOpenPendencyButtonClick = () => {
        setOpenPendencyDialog(true);
    };

    const onPendencyDialogClose = () => {
        setOpenPendencyDialog(false);
    };

    const onPendencyDialogError = () => {
        setOpenPendencyDialog(false);
        notifications.addNotification({
            ...notificationOpts,
            type: notificationType.ERROR,
            message: "Não foi possivel abrir uma pendência, tente novamente",
        });
    };

    const HeaderTitle = () => {
        return "Atividade";
    };

    const HeaderActions = () => {
        return isLoading && <Skeleton width={300} />;
    };

    const SubHeader1 = () => {
        return isLoading ? (
            <Skeleton width={280} />
        ) : (
            <Box display="flex">
                <Box>
                    <Link to={`/projects/${phase.projectId}`}>
                        <Button
                            color="primary"
                            variant="outlined"
                            className={classes.projectButton}
                        >
                            {phase.project.number}
                        </Button>
                    </Link>
                </Box>
                <Box paddingLeft={2}>
                    <ProjectStatusLabel
                        size={12}
                        status={phase.status}
                        pendencyType={phase.pendencyType}
                    />
                </Box>
            </Box>
        );
    };

    const SubHeader2 = () => {
        return isLoading ? (
            <Skeleton width={200} />
        ) : (
            <span>{phase.customer.name}</span>
        );
    };

    const Actions = () => {
        if (isLoading)
            return (
                <Box component="div" width="100%">
                    <Skeleton height={40} />
                </Box>
            );

        return (
            <Box
                sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    pt: 1,
                    pb: 1,
                    pl: 0,
                    pr: 0,
                }}
            >
                <CompleteTaskButton
                    classes={classes}
                    isPhaseCompleted={isPhaseCompleted}
                    phase={phase}
                    onClick={onCompleteTaskButtonClick}
                />
                <ReleaseTaskButton
                    classes={classes}
                    phase={phase}
                    onClick={onReleaseTaskButtonClick}
                />
                <Box sx={{ pt: 1, pb: 1, pl: 0, pr: 0 }}>
                    <PendencyButton
                        classes={classes}
                        phase={phase}
                        onClick={onOpenPendencyButtonClick}
                    />
                </Box>
                <Box sx={{ pt: 1, pb: 1, pl: 1, pr: 0 }}>
                    <AssigneeField
                        classes={classes}
                        phase={phase}
                        users={users}
                        isLoading={isLoading}
                        onClick={onAssigneeChange}
                    />
                </Box>
            </Box>
        );
    };

    const Content = () => {
        return <TaskChecklist />;
    };

    const Footer = () => {
        return <ProjectInfo />;
    };

    const SideBar = () => {
        return <TaskSidebar />;
    };

    if (notFound) {
        return (
            <Box display="flex">
                <Box paddingLeft={8} paddingTop={2}>
                    <h2>Atividade não encontrada.</h2>
                    <small>{"Id: " + match.params.id}</small>
                </Box>
            </Box>
        );
    }

    return (
        <React.Fragment>
            <DetailView
                headerTitle={HeaderTitle}
                headerActions={HeaderActions}
                subHeader1={SubHeader1}
                subHeader2={SubHeader2}
                actions={Actions}
                content={Content}
                footer={Footer}
                sideBar={SideBar}
            />
            {openCompleteTaskDialog && (
                <CompleteTaskDialog
                    open={openCompleteTaskDialog}
                    onClose={onCompleteTaskDialogClose}
                    onError={onCompleteTaskDialogError}
                    onComplete={onCompleteTaskDialog}
                />
            )}
            {openPendencyDialog && (
                <ProjectPendencyDialog
                    open={openPendencyDialog}
                    onClose={onPendencyDialogClose}
                    onError={onPendencyDialogError}
                />
            )}
        </React.Fragment>
    );
}

function AssigneeField({ classes, phase, users, isLoading, onClick }) {
    return (
        <Autocomplete
            className={classes.assigneeField}
            value={phase.assignee}
            id="assignee"
            size="small"
            options={[...users]}
            getOptionLabel={option => option.name || ""}
            loading={isLoading}
            disabled={phase.completed}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(event, value) => {
                onClick(value);
            }}
            style={{ width: 300 }}
            renderInput={params => (
                <TextField
                    {...params}
                    label="Atribuir para"
                    variant="outlined"
                />
            )}
        />
    );
}

function PendencyButton({ classes, onClick, phase }) {
    if (
        [
            ProjectStatus.COMPLETED,
            ProjectStatus.CANCELLED,
            ProjectStatus.PENDENCY,
            ProjectStatus.TASK_CANCELLED,
            ProjectStatus.TASK_INCOMPLETED,
        ].includes(phase.status)
    ) {
        return "";
    }

    return (
        <Button
            variant="outlined"
            className={classes.completeTaskButton}
            onClick={onClick}
        >
            Abrir Pendência
        </Button>
    );
}

function CompleteTaskButton({ classes, isPhaseCompleted, phase, onClick }) {
    if (isPhaseCompleted) {
        return (
            <Box sx={{ pt: 1, pb: 1, pl: 0, pr: 1 }}>
                <Button
                    variant="outlined"
                    component="span"
                    className={classes.completeTaskButton}
                    disabled={phase.status === ProjectStatus.PENDENCY}
                    onClick={onClick}
                >
                    Concluir Etapa
                </Button>
            </Box>
        );
    } else {
        return "";
    }
}

function ReleaseTaskButton({ classes, phase, onClick }) {
    const { hasRole } = useContext(AuthContext);
    if (
        [
            ProjectStatus.COMPLETED,
            ProjectStatus.CANCELLED,
            ProjectStatus.PENDENCY,
            ProjectStatus.IN_PROGRESS,
            ProjectStatus.TASK_CANCELLED,
            ProjectStatus.TASK_INCOMPLETED,
        ].includes(phase.status)
    ) {
        return "";
    }

    if (!hasRole(UserRoles.RELEASE_TASK)) return "";

    return (
        <Box sx={{ pt: 1, pb: 1, pl: 0, pr: 1 }}>
            <Button
                variant="outlined"
                component="span"
                className={classes.completeTaskButton}
                onClick={onClick}
            >
                Liberar Atividade
            </Button>
        </Box>
    );
}
