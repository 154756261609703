import React, { useReducer, createContext, useEffect, useContext } from "react";

import { AuthContext } from "./auth.context";

import { Store as notifications } from "react-notifications-component";
import notificationOpts from "../constants/notification-options";
import Notification from "../components/notifications/Notification";
import { onCollectionSnapshot } from "../services/data-service";

const moment = require("moment");

const initialState = {
    isLoading: true,
    notifications: [],
    userId: null,
    loadDate: new Date().toISOString(),
};

const NotificationContext = createContext({
    isLoading: true,
    notifications: [],
    userId: null,
    unread: [],
    loadDate: new Date().toISOString(),
});

function authReducer(state, action) {
    switch (action.type) {
        case "SET_NOTIFICATIONS":
            return {
                ...state,
                notifications: [...action.payload],
                isLoading: false,
            };
        case "SET_USER_ID":
            return {
                ...state,
                userId: action.payload,
            };
        default:
            return state;
    }
}

function NotificationProvider(props) {
    const [state, dispatch] = useReducer(authReducer, initialState);

    const { user: currentUser } = useContext(AuthContext);

    // load notifications
    useEffect(() => {
        try {
            if (state.userId)
                onCollectionSnapshot({
                    path: `users/${state.userId}/notifications`,
                    callback: snapshot => {
                        if (!snapshot.empty) {
                            const notifications = snapshot.docs.map(doc =>
                                doc.data(),
                            );
                            dispatch({
                                type: "SET_NOTIFICATIONS",
                                payload: notifications,
                            });
                        } else {
                            dispatch({
                                type: "SET_NOTIFICATIONS",
                                payload: [],
                            });
                        }
                        // display notifications for new entries
                        snapshot.docChanges().forEach(function (change) {
                            if (change.type === "added") {
                                const notification = change.doc.data();
                                if (
                                    moment(notification.createdAt).isAfter(
                                        moment(state.loadDate),
                                    )
                                ) {
                                    showNotification(notification);
                                }
                            }
                        });
                    },
                });
        } catch (error) {
            console.error("Error loading notifications", error);
        }
    }, [state.loadDate, state.userId]);

    useEffect(() => {
        if (currentUser && currentUser.id !== state.userId)
            dispatch({
                type: "SET_USER_ID",
                payload: currentUser.id,
            });
    }, [currentUser, state.userId]);

    function showNotification(props) {
        let notificationId;
        const close = () => {
            notifications.removeNotification(notificationId);
        };

        const notification = {
            ...props,
            close,
        };

        const content = <Notification {...notification} />;

        notificationId = notifications.addNotification({
            ...notificationOpts,
            insert: "top",
            container: "bottom-right", // where to position the notifications
            content: content,
            dismiss: {
                duration: 10000,
                showIcon: true,
                click: false,
                pauseOnHover: true,
            },
        });
    }

    function getUnread() {
        if (state.notifications) {
            return state.notifications.filter(n => !n.read);
        } else {
            return [];
        }
    }

    return (
        <NotificationContext.Provider
            value={{
                notifications: state.notifications,
                isLoading: state.isLoading,
                unread: getUnread(),
            }}
            {...props}
        />
    );
}

export { NotificationContext, NotificationProvider };
