export default function OrderModel({
    id,
    description,
    notes = "",
    number,
    pacNumber,
    pacGoal,
    pacRequirements,
    status,
    date,
    project = {},
    customer = {},
    address = {},
    assignees = [],
    tasks = {},
    samples = {},
    createdAt,
    createdBy,
    updatedAt,
    updatedBy,
    releasedAt,
    releasedBy,
    onHoldAt,
    onHoldBy,
}) {
    this.id = id;
    this.number = number;
    this.pacNumber = pacNumber;
    this.pacGoal = pacGoal;
    this.pacRequirements = pacRequirements;
    this.description = description;
    this.notes = notes;
    this.status = status;
    this.date = date;
    this.project = project;
    this.customer = customer;
    this.address = address;
    this.tasks = tasks;
    this.samples = samples;
    this.assignees = assignees;
    this.assigneeIds = assignees ? assignees.map(a => a.id) : [];

    this.createdAt = createdAt;
    this.createdBy = createdBy;

    this.updatedAt = updatedAt;
    this.updatedBy = updatedBy;

    this.releasedAt = releasedAt;
    this.releasedBy = releasedBy;

    this.onHoldAt = onHoldAt;
    this.onHoldBy = onHoldBy;
}
