/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import AssessmentIcon from "@mui/icons-material/Assessment";
import DeleteIcon from "@mui/icons-material/Delete";
import EditAttributesIcon from "@mui/icons-material/EditAttributes";
import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";
import {
    Alert,
    Chip,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { createTheme } from "@mui/material/styles";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import {
    DataGrid,
    GridActionsCellItem,
    GridToolbarExport,
    GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { Promise } from "bluebird";
import { useConfirm } from "material-ui-confirm";
import moment from "moment";
import "moment/locale/pt-br";
import { Store as notifications } from "react-notifications-component";

import notificationOpts from "../../constants/notification-options";
import notificationType from "../../constants/notification-types";
import WellStatus from "../../constants/well-status";
import {
    wellStatusColor,
    wellStatusItem,
    wellStatusList,
    wellStatusName,
} from "../../lib/status.utils";
import { WellModel } from "../../models";
import {
    createDocument,
    deleteDocument,
    getCollectionData,
    onCollectionSnapshot,
    updateDocument,
    where,
} from "../../services/data-service";
import CustomerSelector from "../commons/customer-selector";
import HtmlTooltip from "../commons/html-tooltip";
import SearchTextField from "../commons/search-text-field";
import StudyAreaSelector from "../commons/study-area-selector";

export default function WellsForm() {
    const [customer, setCustomer] = useState(null);
    const [studyArea, setStudyArea] = useState(null);

    const onCustomerSelected = value => {
        setCustomer(value);
    };

    const onStudyAreaSelected = value => {
        setStudyArea(value);
    };

    return (
        <Box
            sx={{
                bgcolor: "#f7f7f7",
                height: "100vh",
                fontWeight: "bold",
                padding: theme.spacing(4),
            }}
        >
            <Typography
                variant="h1"
                gutterBottom
                component="div"
                sx={{ paddingTop: 2 }}
            >
                Cadastrar Poços
            </Typography>
            <Box spacing={1} sx={{ maxWidth: 450, paddingTop: 1 }}>
                <Typography
                    variant="h7"
                    gutterBottom
                    component="div"
                    sx={{ paddingTop: 3 }}
                >
                    Dados do cliente
                </Typography>
                <CustomerSelector
                    customer={customer}
                    onSelected={onCustomerSelected}
                />
            </Box>
            <Box spacing={1} sx={{ width: 450, paddingTop: 1 }}>
                <StudyAreaSelector
                    customer={customer}
                    studyArea={studyArea}
                    onSelected={onStudyAreaSelected}
                />
            </Box>
            <WellsCreator customer={customer} studyArea={studyArea} />
            <WellsList customer={customer} studyArea={studyArea} />
        </Box>
    );
}

function WellsCreator({ customer, studyArea }) {
    const [isLoading, setIsLoading] = useState(false);
    const [wellsInput, setWellsInput] = useState("");

    useEffect(() => {
        if (!customer || !studyArea) {
            setWellsInput("");
        }
    }, [customer, studyArea]);

    const onWellChanged = event => {
        setWellsInput(event.target.value);
    };

    const getWellsFromInput = () => {
        return wellsInput
            ? wellsInput
                  .split(/;|,| |\n/gm)
                  .map(w => w.replace(/ /g, ""))
                  .filter(w => !(w === ""))
            : [];
    };

    const handleOnWellCreate = async () => {
        const wellsList = getWellsFromInput();
        if (!(customer && studyArea && wellsList)) {
            return;
        }

        const wellsError = [];
        const wellsPath = `customers/${customer.id}/addresses/${studyArea.id}/wells`;
        const wells = await getCollectionData(wellsPath);
        setIsLoading(true);
        Promise.mapSeries(wellsList, async wellName => {
            const well = wells.find(w => w.name === wellName);
            if (well) {
                wellsError.push(wellName);
                return;
            }
            await createDocument(wellsPath, {
                name: wellName,
                customer,
                address: studyArea,
                status: WellStatus.ACTIVE,
            });
        })
            .then(() => {
                if (
                    wellsList.length > 0 &&
                    wellsError.length !== wellsList.length
                ) {
                    notifications.addNotification({
                        ...notificationOpts,
                        type: notificationType.SUCCESS,
                        message: `Poços cadastrados com sucesso`,
                    });
                }
                if (wellsError.length > 0) {
                    notifications.addNotification({
                        ...notificationOpts,
                        dismiss: {
                            duration: 3000,
                            showIcon: true,
                        },
                        type: notificationType.WARNING,
                        message: `Não foi possivel cadastrar os poços: [${wellsError.join(
                            ", ",
                        )}] pois já existem`,
                    });
                }
                setWellsInput("");
            })
            .catch(rejection => {
                console.error("Catch: " + rejection);
            })
            .finally(() => setIsLoading(false));
    };

    return (
        <Box>
            <Typography
                variant="h7"
                gutterBottom
                component="div"
                sx={{ paddingTop: theme.spacing(4) }}
            >
                Cadastro de poços
            </Typography>
            <Box>
                <TextareaAutosize
                    aria-label="minimum height"
                    value={wellsInput}
                    onChange={onWellChanged}
                    minRows={5}
                    placeholder="Novos poços *"
                    style={{ width: 450 }}
                    disabled={!customer || !studyArea || isLoading}
                    loading={isLoading}
                />
            </Box>
            <Box>
                <Button
                    variant="contained"
                    color="success"
                    onClick={handleOnWellCreate}
                    disabled={!customer || !studyArea || isLoading}
                    loading={isLoading}
                >
                    {isLoading && <CircularProgress size={18} thickness={4} />}
                    Salvar
                </Button>
            </Box>
        </Box>
    );
}

function WellsList({ customer, studyArea }) {
    const [isLoading, setIsLoading] = useState(false);
    const [well, setWell] = useState([]);
    const [wells, setWells] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [listening, setListening] = useState({});
    const [openStatusChangeDialog, setOpenStatusChangeDialog] = useState(false);
    const confirm = useConfirm();

    useEffect(() => {
        if (customer && studyArea) {
            setIsLoading(true);
            if (listening.unsubscribe) listening.unsubscribe();
            const unsubscribe = onCollectionSnapshot({
                path: `customers/${customer.id}/addresses/${studyArea.id}/wells`,
                callback: snapshot => {
                    let data = [];
                    if (!snapshot.empty) {
                        data = snapshot.docs.map(doc => doc.data());
                    }
                    setWells([...data.map(well => new WellModel(well))]);
                    setIsLoading(false);
                },
            });
            setListening({ unsubscribe });
        } else {
            setWells([]);
        }
    }, [customer, studyArea]);

    const getFilteredWells = () => {
        const searchRegex = new RegExp(escapeRegExp(searchText), "i");
        return wells.filter(row => {
            return Object.keys(row).some(field => {
                const value = row[field];
                return value ? searchRegex.test(value.toString()) : value;
            });
        });
    };

    const onDeleteWellClick = params => {
        const well = params.row;
        confirm({
            title: "Confirmar exclusão",
            description: `Deseja remover poço '${well.name}' ?`,
            confirmationText: "Sim",
            cancellationText: "Não",
        }).then(() => {
            deleteDocument(
                `customers/${customer.id}/addresses/${studyArea.id}/wells/${well.id}`,
            ).catch(error => {
                console.error("Error deleting well", well, error);
                notifications.addNotification({
                    ...notificationOpts,
                    type: notificationType.ERROR,
                    message: `Não foi possivel excluir, tente novamente`,
                });
            });
        });
    };

    const onEditWellCommit = ({ field, value, id }) => {
        const well = wells.find(w => w.id === id);
        well[field] = value;
        setIsLoading(true);
        updateDocument(
            `customers/${well.customer.id}/addresses/${well.address.id}/wells/${well.id}`,
            {
                ...well,
            },
        )
            .catch(error => {
                console.error("Error updating well status ", error);
            })
            .finally(() => setIsLoading(false));
    };

    const onWellStatusChangeClick = params => {
        setWell({ ...params.row });
        setOpenStatusChangeDialog(true);
    };

    const columns = [
        { field: "name", headerName: "Nome", width: 200, editable: true },
        {
            field: "createdAt",
            headerName: "Criado em",
            type: "dateTime",
            valueFormatter: ({ value }) =>
                moment(value).format("DD/MM/YYYY HH:mm:ss"),
            width: 170,
        },
        {
            field: "status",
            headerName: "Status",
            valueFormatter: ({ value }) => wellStatusName(value),
            valueGetter: value => wellStatusName(value),
            renderCell: ({ row }) => (
                <Chip
                    size="small"
                    label={wellStatusName(row.status)}
                    color={wellStatusColor(row.status)}
                />
            ),
        },
        {
            field: "statusNote",
            headerName: "Observação",
            width: 110,
            renderCell: ({ row }) => (
                <HtmlTooltip
                    title={
                        <React.Fragment>
                            {row.statusReason && (
                                <Typography color="inherit">
                                    {row.statusReason.name}
                                </Typography>
                            )}
                            {row.statusNote}
                        </React.Fragment>
                    }
                >
                    <Typography variant="body2" gutterBottom>
                        {row.statusNote &&
                            row.statusNote.replace(/(.{12})..+/gms, "$1…")}
                    </Typography>
                </HtmlTooltip>
            ),
        },
        {
            field: "actions",
            type: "actions",
            width: 80,
            getActions: params => [
                <GridActionsCellItem
                    key={1}
                    icon={<DeleteIcon />}
                    label="Delete"
                    onClick={() => onDeleteWellClick(params)}
                />,
                <GridActionsCellItem
                    key={2}
                    icon={<EditAttributesIcon />}
                    label="Definir status"
                    onClick={() => onWellStatusChangeClick(params)}
                    showInMenu
                />,
                <GridActionsCellItem
                    key={3}
                    icon={<AssessmentIcon />}
                    label="Ver dados de coletas"
                    // onClick={toggleAdmin(params.id)}
                    showInMenu
                />,
            ],
        },
    ];

    return (
        <div style={{ height: 500, width: "100%" }}>
            <div style={{ display: "flex", height: "100%" }}>
                <div style={{ flexGrow: 1, paddingTop: theme.spacing(4) }}>
                    <DataGrid
                        rows={getFilteredWells()}
                        getRowId={row => `${row.id}-${row.name}`}
                        columns={columns}
                        slots={{ toolbar: DataGridToolbar }}
                        slotProps={{
                            toolbar: {
                                value: searchText,
                                onChange: event =>
                                    setSearchText(event.target.value),
                                clearSearch: () => setSearchText(""),
                            },
                        }}
                        loading={isLoading}
                        onCellEditCommit={params => onEditWellCommit(params)}
                        onCellDoubleClick={params => {
                            params.field === "status" &&
                                onWellStatusChangeClick(params);
                        }}
                    />
                    {openStatusChangeDialog && (
                        <WellStatusChangeDialog
                            well={well}
                            onClose={() => setOpenStatusChangeDialog(false)}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

function DataGridToolbar(props) {
    return (
        <Box
            sx={{
                p: 0.5,
                pb: 0,
                justifyContent: "space-between",
                display: "flex",
                alignItems: "flex-start",
                flexWrap: "wrap",
            }}
        >
            <div>
                <GridToolbarExport />
                <GridToolbarFilterButton />
            </div>
            <SearchTextField {...props} />
        </Box>
    );
}

function WellStatusChangeDialog({ well, onClose }) {
    const [isLoading, setIsLoading] = useState(true);
    const [errors, setErrors] = useState([]);
    const [wellData, setWellData] = useState(well);
    const [reasons, setReasons] = useState([]);

    // fetch data
    useEffect(() => {
        getCollectionData(`reasons`, [where("type", "==", "wells-status")])
            .then(data => setReasons([...data]))
            .finally(() => setIsLoading(false));
    }, []);

    useEffect(() => {
        setWellData(well);
    }, [well]);

    useEffect(() => {}, [wellData]);

    const onCloseError = key => {
        errors.splice(key, 1);
        setErrors([...errors]);
    };

    const handleClose = () => {
        setErrors([]);
        if (onClose) onClose();
    };

    const onConfirm = () => {
        if (wellData.status === WellStatus.INACTIVE && !wellData.statusReason) {
            errors.push("O motivo deve ser informado.");
            setErrors([...errors]);
            return;
        }

        setIsLoading(true);
        updateDocument(
            `customers/${well.customer.id}/addresses/${well.address.id}/wells/${well.id}`,
            {
                ...wellData,
            },
        )
            .then(() => {
                handleClose();
            })
            .catch(error => {
                console.error("Error updating well status ", error);
            })
            .finally(() => setIsLoading(false));
    };

    const renderErrors = () => {
        if (!errors) return "";
        return errors.map((error, key) => (
            <Alert
                onClose={() => onCloseError(key)}
                severity="error"
                sx={{ width: "100%", marginBottom: theme.spacing(3) }}
                key={key}
            >
                {error}
            </Alert>
        ));
    };

    return (
        <Dialog
            open
            fullWidth
            // onClose={handleClose}
            aria-labelledby="well-status-change-dialog-title"
            aria-describedby="well-status-change-dialog-description"
        >
            <DialogTitle id="well-status-change-dialog-title">
                {`Alterar status do poço - ${wellData.name}`}
            </DialogTitle>
            <DialogContent>
                {renderErrors()}
                <Autocomplete
                    sx={{ paddingBottom: theme.spacing(2) }}
                    {...autocompleteDefaultProps}
                    id="well-status"
                    value={wellStatusItem(well.status)}
                    onChange={(event, value) => {
                        value &&
                            setWellData({
                                ...wellData,
                                status: value.id,
                            });
                    }}
                    options={wellStatusList}
                    loading={isLoading}
                    renderInput={params => (
                        <TextField
                            {...params}
                            label="Status"
                            variant="standard"
                            required
                        />
                    )}
                />
                <Autocomplete
                    sx={{ paddingBottom: theme.spacing(2) }}
                    {...autocompleteDefaultProps}
                    id="well-status-reason"
                    value={well.statusReason}
                    onChange={(event, value) => {
                        setWellData({
                            ...wellData,
                            statusReason: value,
                        });
                    }}
                    options={reasons}
                    loading={isLoading}
                    renderInput={params => (
                        <TextField
                            {...params}
                            label="Motivo"
                            variant="standard"
                            required={wellData.status === WellStatus.INACTIVE}
                        />
                    )}
                />
                <TextField
                    fullWidth
                    minRows={4}
                    id="well-status-note"
                    label="Observação"
                    placeholder="Observação do status"
                    multiline
                    variant="standard"
                    value={wellData.statusNote}
                    onChange={event => {
                        setWellData({
                            ...wellData,
                            statusNote: event.target.value,
                        });
                    }}
                />
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    color="success"
                    onClick={onConfirm}
                    loading={isLoading}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                >
                    Alterar
                </LoadingButton>
                <Button variant="contained" onClick={handleClose}>
                    Cancelar
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

const theme = createTheme();

const autocompleteDefaultProps = {
    getOptionLabel: option => option.name,
};
