/* eslint-disable react/prop-types */
import React, { useState, useEffect, useContext } from "react";

import CloseIcon from "@mui/icons-material/Close";
import { LocalizationProvider, DatePicker } from "@mui/lab";
import AdapterMoment from "@mui/lab/AdapterMoment";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import LinearProgress from "@mui/material/LinearProgress";
import SnackbarContent from "@mui/material/SnackbarContent";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import moment from "moment";
import { Redirect } from "react-router-dom";

import "moment/locale/pt-br";
import { Store as notifications } from "react-notifications-component";

import notificationOpts from "../../../../constants/notification-options";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";

import { AuthContext } from "../../../../contexts/auth.context";
import InputCurrency from "../../../commons/input-currency";

import "./ProjectEditForm.css";

import { makeStyles } from "@mui/styles";

import {
    instalments,
    paymentConditions,
    fetchData,
    fetchStudyAreas,
} from "./../project-create-form/data";
import {
    getDocumentData,
    callFunction,
    where,
    getCollectionGroupData,
} from "../../../../services/data-service";
import UserRoles from "../../../../constants/user-roles";

export const useStyles = makeStyles(theme => ({
    addStudyButton: {
        color: "#4192d9",
        fontFamily: "Roboto",
        fontSize: "14px",
        fontWeight: "normal",
        fontStyle: "normal",
        fontStretch: "normal",
        lineHeight: "1.14",
        letterSpacing: "normal",
        textAlign: "left",
    },
    newStudyButton: {
        color: "#4192d9",
        fontFamily: "Roboto",
        fontSize: "14px",
        fontWeight: "normal",
        fontStyle: "normal",
        fontStretch: "normal",
        lineHeight: "1.14",
        letterSpacing: "normal",
        textAlign: "left",
    },
    newCustomerButton: {
        color: "#4192d9",
        "text-transform": "none",
    },
    racpNumberField: {
        marginTop: theme.spacing(1),
        marginButton: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    customerField: {
        marginTop: theme.spacing(2),
        marginButton: theme.spacing(1),
        marginRight: theme.spacing(2),
        width: 400,
    },
    formControl: {
        marginTop: theme.spacing(1),
        marginButton: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    studiesField: {
        marginTop: theme.spacing(2),
        marginRight: theme.spacing(1),
        width: 285,
    },
    deadlineField: {
        width: 80,
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(2),
    },
    projectValueField: {
        width: 300,
        marginTop: theme.spacing(1),
        marginButton: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    projectPaymentDateField: {
        width: 200,
        marginLeft: theme.spacing(3),
        marginTop: theme.spacing(1),
        marginButton: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    paymentConditionField: {
        width: 200,
        marginTop: theme.spacing(1),
        marginButton: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    instalmentsField: {
        width: 100,
        marginLeft: theme.spacing(3),
        marginTop: theme.spacing(1),
        marginButton: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    invoiceResponsibleField: {
        width: 300,
        marginTop: theme.spacing(1),
        marginButton: theme.spacing(1),
        marginRight: theme.spacing(3),
    },
    invoiceDateField: {
        width: 100,
        marginTop: theme.spacing(1),
        marginButton: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    submitButton: {
        backgroundColor: "var(--dark-seafoam-green)",
        "&:hover": {
            backgroundColor: "var(--green)",
            borderColor: "#385cac",
        },
    },
    loadingIndicator: {
        color: "green",
        marginLeft: theme.spacing(2),
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    customerNameField: {
        marginTop: theme.spacing(1),
        marginButton: theme.spacing(1),
        marginRight: theme.spacing(2),
        width: 480,
    },
}));

const defaultOption = { id: "", name: "" };

export default function ProjectEditForm(props) {
    const { hasRole } = useContext(AuthContext);
    const classes = useStyles();
    const [projectId, setProjectId] = useState();
    const [state, setState] = useState({
        isLoading: true,
        errors: [],
        customers: [],
        studies: [],
        studyAreas: [],
        project: {
            studies: [{ id: 0, deadline: "" }],
        },
        hasCampaign: false,
        currentProject: {},
        hasActivities: false,
    });

    // fetch data
    useEffect(() => {
        const newProjectId = props.projectId || props.match.params.id;
        if (newProjectId !== projectId) {
            setProjectId(newProjectId);
            setState({
                ...state,
                isLoading: true,
            });
            getDocumentData(`projects/${newProjectId}`).then(async project => {
                const projectActivities = [];
                const activitiesData = await getCollectionGroupData(
                    "activities",
                    [where("projectId", "==", `${project.id}`)],
                );
                activitiesData.forEach(doc => projectActivities.push(doc));

                const hasActivities = projectActivities.some(
                    activity => activity.completed || activity.notRelevant,
                );
                fetchData(project).then(async response => {
                    let { customer, study, studyArea } = project;

                    // set study area
                    if (studyArea) {
                        studyArea = response.studyAreas.find(
                            s => s.id === studyArea.id,
                        );
                    } else {
                        const studyAreas = response.studyAreas || [];
                        studyArea = studyAreas.find(s => s.type === "default");
                    }

                    setState({
                        ...state,
                        ...response,
                        studies: response.studies.map(c => {
                            return {
                                id: c.data.id,
                                name: c.data.name,
                                hasCampaign: c.data.hasCampaign,
                                deadline: c.data.deadline,
                            };
                        }),
                        studyAreas: response.studyAreas.map(c => {
                            return {
                                id: c.id,
                                name: c.label,
                            };
                        }),
                        customers: response.customers.map(c => {
                            return {
                                id: c.data.id,
                                name: c.data.name,
                            };
                        }),
                        project: {
                            ...project,
                            customer: customer && {
                                id: customer.id,
                                name: customer.name,
                            },
                            customerId: customer && customer.id,

                            study: study && {
                                id: study.id,
                                name: study.name,
                                hasCampaign: study.hasCampaign,
                                deadline: study.deadline,
                            },
                            studyId: study && study.id,

                            studyArea: studyArea && {
                                id: studyArea.id,
                                name: studyArea.label
                                    ? studyArea.label
                                    : studyArea.name,
                            },
                            studyAreaId: studyArea && studyArea.id,
                        },
                        projectId: project.id,
                        projectNotFound: !project,
                        currentProject: project,
                        hasActivities,
                        isLoading: false,
                    });
                });
            });
        }
    }, [props.projectId, props.match.params.id, state, projectId]);

    const onInputChange = name => event => {
        let project = state.project;

        if (name === "projectValue") {
            if (event.type === "currency") {
                project[name] = event.values.floatValue;
            }
        } else {
            // change deadline and recalculate project deadline date
            if (name === "deadlineDays" && event.type === "change") {
                project.deadlineDate = moment(project.createdAt)
                    .add(event.target.value, "days")
                    .format();
            }
            project[name] = event.target.value;
        }

        setState({
            ...state,
            project,
        });
    };

    const onSubmit = async event => {
        event.preventDefault();
        const { project } = state;

        setState({
            ...state,
            isLoading: true,
        });

        callFunction("updateProjectData", {
            number: project.number,
            projectId: project.id,
            studyId: project.studyId,
            studyAreaId: project.studyAreaId,
            customerId: project.customerId,
            deadline: project.deadlineDays,
            campaign: project.campaign,
        })
            .then(() => {
                setState({
                    ...state,
                    updated: true,
                });
                notifications.addNotification({
                    ...notificationOpts,
                    type: "success",
                    message: `Projeto ${project.number} atualizado com sucesso`,
                });
            })
            .catch(error => {
                console.error("Error updating project", error);
                const { errors } = state;
                errors.push("Não foi possivel atualizar o projeto");
                setState({
                    ...state,
                    errors,
                });
            })
            .finally(() => {
                setState({
                    ...state,
                    isLoading: false,
                });
            });
    };

    const onDateChange = name => date => {
        let project = state.project;
        project[name] = date.format();
        setState({
            ...state,
            project,
        });
    };

    const onCustomerChange = async value => {
        if (value) {
            const project = state.project;
            const studyAreas = await fetchStudyAreas(value.id);
            const studyArea =
                studyAreas && studyAreas.find(a => a.type === "default");

            project.customerId = value.id;
            project.customer = value;

            project.studyArea = studyArea && {
                id: studyArea.id,
                name: studyArea.label,
            };
            project.studyAreaId = project.studyArea && project.studyArea.id;

            setState({
                ...state,
                project: project,
                studyAreas: studyAreas.map(c => {
                    return {
                        id: c.id,
                        name: c.label,
                    };
                }),
            });
        }
    };

    const onStudyAreaChange = async value => {
        if (value) {
            const project = state.project;
            project.studyAreaId = value.id;
            project.studyArea = value;
            setState({
                ...state,
                project: project,
            });
        }
    };
    const onStudyChange = async value => {
        if (value) {
            let { hasCampaign, studies, project } = state;
            const study = studies.find(study => study.id === value.id);
            hasCampaign = study.hasCampaign;

            project.studyId = value.id;
            project.study = value;
            project.deadlineDays = study.deadline;
            setState({
                ...state,
                project: project,
                hasCampaign,
            });
        }
    };

    const onSelectChange = name => value => {
        let { hasCampaign, studies, project } = state;
        if (name === "studyId") {
            const study = studies.find(study => study.value === value);
            hasCampaign = study.hasCampaign;
        }
        project[name] = value;
        setState({
            ...state,
            project,
            hasCampaign,
        });
    };

    const onCloseError = key => {
        const { errors } = state;
        errors.splice(key, 1);
        setState({
            ...state,
            errors,
        });
    };

    const renderErrors = () => {
        const { errors } = state;

        if (!errors || !errors.length) return "";

        return (
            <div className="form-errors">
                {errors.map((error, key) => {
                    return (
                        <SnackbarContent
                            key={key}
                            className={classNames(classes.error)}
                            aria-describedby="error-message"
                            message={
                                <span
                                    id="error-message"
                                    className={classes.message}
                                >
                                    <Icon
                                        className={classNames(
                                            classes.icon,
                                            classes.iconVariant,
                                        )}
                                    />
                                    {error}
                                </span>
                            }
                            action={[
                                <IconButton
                                    key="close"
                                    aria-label="Close"
                                    color="inherit"
                                    className={classes.close}
                                    onClick={() => onCloseError(key)}
                                >
                                    <CloseIcon className={classes.icon} />
                                </IconButton>,
                            ]}
                        />
                    );
                })}
            </div>
        );
    };

    const {
        project,
        isLoading,
        projectNotFound,
        updated,
        customers,
        studies,
        hasActivities,
        studyAreas,
        hasCampaign,
    } = state;
    const { match } = props;

    if (projectNotFound) {
        return (
            <div className="project-data">
                <div className="data-content">
                    <h2>{"Projeto " + match.params.id + " não encontrado."}</h2>
                </div>
            </div>
        );
    }

    if (updated) {
        const path = "/projects/" + project.id;
        return (
            <Redirect
                to={{
                    pathname: path,
                }}
            />
        );
    }

    return (
        <div className={"project-edit-form"}>
            {isLoading && <LinearProgress />}
            <form className={"form"} autoComplete="off" onSubmit={onSubmit}>
                <div className={"header"}>
                    <div className={"header-title"}>Editar Projeto</div>
                    <div className={"header-actions"} />
                </div>
                <div className={"content"}>
                    {renderErrors()}
                    <div className={"project-data"}>
                        <h5>Dados do projeto</h5>
                        <div className={"number"}>
                            <TextField
                                className={classes.racpNumberField}
                                required={true}
                                variant="standard"
                                label="Nº RACP"
                                margin="normal"
                                placeholder="Nº RACP"
                                id="number"
                                value={project.number || ""}
                                onChange={onInputChange("number")}
                                fullWidth
                            />
                        </div>
                        <div className={"customer-name"}>
                            <Autocomplete
                                className={classes.customerNameField}
                                id="customerName"
                                value={
                                    project.customer
                                        ? project.customer
                                        : defaultOption
                                }
                                onChange={(event, customer) => {
                                    onCustomerChange(customer);
                                }}
                                options={customers}
                                getOptionLabel={option => option.name}
                                loading={isLoading}
                                disabled={isLoading}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        label="Razão Social do Empreendimento"
                                        margin="normal"
                                        variant="standard"
                                        placeholder="Razão Social do Empreendimento"
                                        required={true}
                                        fullWidth
                                    />
                                )}
                            />
                        </div>

                        <div className={classes.studyArea}>
                            <Autocomplete
                                className={classes.customerNameField}
                                id="studyArea"
                                value={
                                    project.studyArea
                                        ? project.studyArea
                                        : defaultOption
                                }
                                onChange={(event, studyArea) => {
                                    onStudyAreaChange(studyArea);
                                }}
                                options={studyAreas}
                                getOptionLabel={option => option.name}
                                loading={isLoading}
                                disabled={isLoading}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        label="Área de Estudo"
                                        variant="standard"
                                        margin="normal"
                                        placeholder="Área de Estudo"
                                        required={true}
                                        fullWidth
                                    />
                                )}
                            />
                        </div>

                        <div className={"study"}>
                            <Autocomplete
                                className={classes.studiesField}
                                id="studies"
                                value={
                                    project.study
                                        ? project.study
                                        : defaultOption
                                }
                                onChange={(event, value) => {
                                    onStudyChange(value);
                                }}
                                options={studies}
                                getOptionLabel={option => option.name}
                                loading={isLoading}
                                disabled={hasActivities || isLoading}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        label="Estudo"
                                        margin="normal"
                                        placeholder="Estudo"
                                        required={true}
                                        fullWidth
                                    />
                                )}
                            />
                            {hasActivities && (
                                <Tooltip title="Projeto em execução, não é possivel alterar estudo.">
                                    <div className="icon">
                                        <FontAwesomeIcon
                                            className="study-no-change-icon"
                                            icon={faExclamationCircle}
                                        />
                                    </div>
                                </Tooltip>
                            )}
                        </div>
                        {hasCampaign && (
                            <div className={"campaign"}>
                                <TextField
                                    required={false}
                                    className={classes.campaignField}
                                    variant="standard"
                                    label="Nº da Campanha"
                                    margin="normal"
                                    placeholder="Nº da Campanha"
                                    id="number"
                                    value={project.campaign || ""}
                                    onChange={onInputChange("campaign")}
                                />
                            </div>
                        )}
                        <div className={"deadline"}>
                            <TextField
                                required={true}
                                className={classes.deadlineField}
                                variant="standard"
                                label="Prazo"
                                margin="normal"
                                type="number"
                                inputProps={{
                                    min: "1",
                                }}
                                id="deadlineDays"
                                name="deadlineDays"
                                value={project.deadlineDays || ""}
                                onChange={onInputChange("deadlineDays")}
                                placeholder="Prazo"
                                readOnly={false}
                            />
                            {!isLoading && (
                                <div className={"deadline-date"}>
                                    <div className={"days-info"}>
                                        Prazo de entrega:{" "}
                                        {moment(project.deadlineDate).format(
                                            "LL",
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    {hasRole(UserRoles.CONTROLE_FINANCEIRO) && (
                        <div className={"project-payments-terms"}>
                            <h5>Condições de pagamento</h5>
                            <div className={"project-payments-terms-item"}>
                                <div className={"project-value"}>
                                    <TextField
                                        className={classes.projectValueField}
                                        variant="standard"
                                        label="Valor"
                                        id="projectValue"
                                        name="projectValue"
                                        margin="normal"
                                        value={project.projectValue}
                                        onChange={onInputChange("projectValue")}
                                        placeholder="Valor"
                                        inputComponent={InputCurrency}
                                    />
                                </div>
                                <div className={"project-payment-date"}>
                                    <LocalizationProvider
                                        dateAdapter={AdapterMoment}
                                        adapterLocale="pt-br"
                                    >
                                        <DatePicker
                                            className={
                                                classes.projectPaymentDateField
                                            }
                                            format="DD/MM/YYYY"
                                            disablePast={false}
                                            disabled={isLoading}
                                            labelFunc={date => {
                                                return project.firstPaymentDate
                                                    ? date.format("DD/MM/YYYY")
                                                    : "";
                                            }}
                                            label="Data do 1º vencimento"
                                            value={project.firstPaymentDate}
                                            onChange={onDateChange(
                                                "firstPaymentDate",
                                            )}
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    variant="standard"
                                                    margin="dense"
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </div>
                            </div>
                            <div className={"project-payments-terms-item"}>
                                <div className={"payment-condition"}>
                                    <Autocomplete
                                        className={classes.customerNameField}
                                        id="payment-condition"
                                        name="payment-condition"
                                        value={project.paymentCondition}
                                        onChange={(event, paymentCondition) => {
                                            onSelectChange("paymentCondition")(
                                                paymentCondition,
                                            );
                                        }}
                                        options={paymentConditions || []}
                                        getOptionLabel={option => option.label}
                                        isOptionEqualToValue={(option, value) =>
                                            option.value === value
                                        }
                                        loading={isLoading}
                                        disabled={isLoading}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                label="Forma de pagamento"
                                                variant="standard"
                                                margin="normal"
                                                placeholder="Forma de pagamento"
                                                required={true}
                                                fullWidth
                                            />
                                        )}
                                    />
                                </div>
                                {project.paymentCondition &&
                                    (project.paymentCondition.value === 2 ||
                                        project.paymentCondition.value ===
                                            3) && (
                                        <div className={"instalments"}>
                                            <Autocomplete
                                                className={
                                                    classes.customerNameField
                                                }
                                                id="payment-instalments"
                                                name="payment-instalments"
                                                value={
                                                    project.paymentInstalments
                                                }
                                                onChange={(
                                                    event,
                                                    paymentInstalments,
                                                ) => {
                                                    onSelectChange(
                                                        "paymentInstalments",
                                                    )(paymentInstalments);
                                                }}
                                                options={instalments || []}
                                                getOptionLabel={option =>
                                                    option.label
                                                }
                                                isOptionEqualToValue={(
                                                    option,
                                                    value,
                                                ) => option.value === value}
                                                loading={isLoading}
                                                disabled={isLoading}
                                                renderInput={params => (
                                                    <TextField
                                                        {...params}
                                                        variant="standard"
                                                        label="Parcelas"
                                                        margin="normal"
                                                        placeholder="Parcelas"
                                                        required={true}
                                                    />
                                                )}
                                            />
                                        </div>
                                    )}
                            </div>
                        </div>
                    )}
                </div>
                <div className={"footer"}>
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={isLoading}
                        className={classes.submitButton}
                    >
                        Salvar
                        {isLoading && (
                            <CircularProgress
                                size={18}
                                thickness={4}
                                className={classes.loadingIndicator}
                            />
                        )}
                    </Button>
                </div>
            </form>
        </div>
    );
}
