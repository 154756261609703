/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";

import CloseIcon from "@mui/icons-material/Close";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import { green } from "@mui/material/colors";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import { Redirect } from "react-router-dom";

import { updateDocument } from "../../services/data-service";

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        padding: "2em",
        display: "flex",
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    message: {
        paddingLeft: "1em",
        paddingRight: "1em",
    },
    avatar: {
        color: "#fff",
        backgroundColor: green[500],
    },
}));

export default function Notification(props) {
    const { id, message, user, createdAt, close, action } = props;
    const [redirect, setRedirect] = useState(null);
    const classes = useStyles();
    const createMessage = () => {
        return {
            __html: message,
        };
    };
    const handleAction = () => {
        updateDocument(`users/${user.id}/notifications/${id}`, {
            read: true,
        });
        setRedirect(true);
    };

    useEffect(() => {
        const audio = new Audio(process.env.REACT_APP_NOTIFICATION_AUDIO);
        audio.play();
        return () => audio.pause();
    }, []);

    if (redirect)
        return (
            <Redirect
                to={{
                    pathname: action,
                }}
            />
        );

    return (
        <Paper className={classes.root} elevation={3}>
            <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={close}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
            <div className={classes.icon}>
                <Avatar className={classes.avatar}>
                    <NotificationsIcon />
                </Avatar>
            </div>
            <Box className={classes.message} onClick={handleAction}>
                <Typography variant="body1" gutterBottom>
                    <div dangerouslySetInnerHTML={createMessage()} />
                </Typography>
                <Typography variant="body2" gutterBottom>
                    {moment(createdAt, moment.ISO_8601)
                        .subtract(1, "m")
                        .fromNow()}
                </Typography>
            </Box>
        </Paper>
    );
}
