/* eslint-disable react/prop-types */
import React, { useState } from "react";

import { faCalendarCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Fab from "@mui/material/Fab";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import SnackbarContent from "@mui/material/SnackbarContent";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";

import "./ProjectEarlyConclusionDialog.css";
import { callFunction } from "../../../../services/data-service";

const useStyles = makeStyles(theme => ({
    actionIcon: {
        margin: theme.spacing(1),
    },
    actionButton: {
        margin: theme.spacing(1) / 2,
        fontFamily: "Roboto",
        fontSize: "14px",
        fontWeight: "normal",
        fontStyle: "normal",
        fontStretch: "normal",
        lineHeight: "1.14",
        letterSpacing: "normal",
        textAlign: "left",
        color: "#4192d9",
        textTransform: "none",
        boxShadow: "none",
    },
    actionButtonLabel: {
        paddingRight: theme.spacing(1),
    },
    cancelButton: {
        color: "red",
    },
    submitButton: {
        color: "green",
    },
    loadingIndicator: {
        color: "red",
        marginLeft: theme.spacing(2),
    },
    error: {
        backgroundColor: theme.palette.error.dark,
        marginBottom: theme.spacing(2),
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
}));

// TODO: Convert to functional component
export default function ProjectEarlyConclusionDialog(props) {
    const { projectId, onUpdated, onClose, isLoading } = props;
    const classes = useStyles();

    const [state, setState] = useState({
        isConcluing: false,
        open: false,
        errors: [],
    });

    const handleClickOpen = () => {
        setState({ ...state, open: true });
    };

    const handleClickCancel = () => {
        setState({ ...state, isConcluing: true });

        callFunction("completeProject", {
            projectId,
        })
            .then(response => {
                if (onUpdated) onUpdated(response.entity);
                handleClose();
                setState({ ...state, isConcluing: false });
            })
            .catch(error => {
                console.error("Error removing customer", error);
                setState({
                    ...state,
                    isConcluing: false,
                    errors: [
                        "Não foi possivel comcluir o projeto antecipadamente.",
                    ],
                });
            });
    };

    const onCloseError = key => {
        const { errors } = state;
        errors.splice(key, 1);
        setState({ ...state, errors });
    };

    const handleClose = () => {
        setState({ ...state, errors: [], open: false });

        if (onClose) onClose();
    };

    const renderErrors = () => {
        const { errors } = state;

        if (!errors) return "";

        return (
            <div className="form-errors">
                {errors.map((error, key) => {
                    return (
                        <SnackbarContent
                            key={key}
                            className={classNames(classes.error)}
                            aria-describedby="error-message"
                            message={
                                <span
                                    id="error-message"
                                    className={classes.message}
                                >
                                    <Icon
                                        className={classNames(
                                            classes.icon,
                                            classes.iconVariant,
                                        )}
                                    />
                                    {error}
                                </span>
                            }
                            action={[
                                <IconButton
                                    key="close"
                                    aria-label="Close"
                                    color="inherit"
                                    className={classes.close}
                                    onClick={() => onCloseError(key)}
                                >
                                    <CloseIcon className={classes.icon} />
                                </IconButton>,
                            ]}
                        />
                    );
                })}
            </div>
        );
    };

    return (
        <div className="project-cancel-dialog">
            <Fab
                variant="extended"
                size="small"
                aria-label="Add"
                classes={{
                    root: classes.actionButton,
                    label: classes.actionButtonLabel,
                }}
                disabled={isLoading}
                onClick={handleClickOpen}
            >
                <FontAwesomeIcon
                    className={classes.actionIcon}
                    icon={faCalendarCheck}
                />
                Concluir
            </Fab>
            <Dialog
                open={state.open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Concluir antecipadamente
                </DialogTitle>
                <DialogContent>
                    {renderErrors()}
                    <DialogContentText id="alert-dialog-description">
                        Você tem certeza que deseja concluir antecipadamente
                        este projeto?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        className={classes.submitButton}
                        onClick={handleClickCancel}
                        disabled={isLoading}
                    >
                        Sim
                        {state.isConcluing && (
                            <CircularProgress
                                size={18}
                                thickness={4}
                                className={classes.loadingIndicator}
                            />
                        )}
                    </Button>
                    <Button
                        className={classes.cancelButton}
                        onClick={handleClose}
                    >
                        Não
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
