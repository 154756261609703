const projectPhases = [
    {
        name: "Logística",
        type: "LOG",
    },
    {
        name: "Conferência",
        type: "CONF",
    },
    {
        name: "Análise Crítica",
        type: "ANC",
    },
    {
        name: "Atribuição de Relatorista",
        type: "ATR",
    },
    {
        name: "Pré-Revisão",
        type: "PRV",
    },
    {
        name: "Revisão Técnica",
        type: "RVT",
    },
    {
        name: "Correção",
        type: "COR",
    },
    {
        name: "Laboratório",
        type: "LAB",
    },
    {
        name: "Desenho",
        type: "DES",
    },
    {
        name: "Finalização do Relatório",
        type: "FNR",
    },
];

export const getProjectPhaseName = type => {
    let name = "";
    if (type) {
        const projectPhase = projectPhases.find(r => r.type === type);
        if (projectPhase) name = projectPhase.name;
    }
    return name;
};

export default projectPhases;
