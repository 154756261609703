import React, { useContext } from "react";

import { faCalendar } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import moment from "moment";
import Skeleton from "react-loading-skeleton";

import "./TaskDeadline.css";

import ProjectStatus from "../../../../../constants/project.status";
import { DataContext } from "../../../../../contexts";
import { deadlineInfo } from "../../../../../lib/status.utils";

export default function TaskDeadline(props) {
    const { phase, isLoadingPhase } = useContext(DataContext);

    if (isLoadingPhase || !phase.id) return <Skeleton height={110} />;

    if (
        [ProjectStatus.CANCELLED, ProjectStatus.COMPLETED].includes(
            phase.status,
        )
    )
        return null;

    const { status, deadline } = deadlineInfo(
        phase.releasedAt,
        phase.plannedDurationDays,
    );

    const Deadline = () => {
        if (!phase.status) return "---";
        return moment(deadline, moment.ISO_8601).fromNow(true);
    };

    return (
        <div className="task-deadline">
            <div
                className={classNames({
                    "deadline-icon": true,
                    "deadline-on-time-icon": status === "onTime",
                    "deadline-less-than-twenty-icon": status === "approaching",
                    "deadline-out-of-time-icon": status === "overdue",
                    "deadline-not-released-icon": !phase.status,
                })}
            >
                <div className="deadline-icon-container">
                    <FontAwesomeIcon
                        className="deadline-calendar-icon"
                        icon={faCalendar}
                    />
                </div>
            </div>
            <div
                className={classNames({
                    "deadline-data": true,
                    "deadline-on-time": status === "onTime",
                    "deadline-less-than-twenty-time": status === "approaching",
                    "deadline-out-of-time": status === "overdue",
                    "deadline-not-released": !phase.status,
                })}
            >
                <h5 className="deadline-data-title">PRAZO DA ATIVIDADE</h5>
                <span className="deadline-data-info">
                    <Deadline />
                </span>
            </div>
        </div>
    );
}
