/* eslint-disable no-sequences */
const sortBy = (field, reverse = false, primer = clearString) => {
    const key = primer
        ? function (x) {
              return primer(x[field]);
          }
        : function (x) {
              return x[field];
          };

    reverse = !reverse ? 1 : -1;

    return function (a, b) {
        return (a = key(a)), (b = key(b)), reverse * ((a > b) - (b > a));
    };
};

export default sortBy;

export const clearString = str => {
    return str
        .toLowerCase()
        .replace(/[áãà]/g, "a")
        .replace(/é/g, "e")
        .replace(/í/g, "i")
        .replace(/[óõô]/g, "o")
        .replace(/[úü]/g, "u")
        .replace(/ç/g, "c");
};
