/* eslint-disable react-hooks/exhaustive-deps */
import "moment/locale/pt-br";
import React, { useState, useEffect, useContext } from "react";

import { faPlusSquare, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CloseIcon from "@mui/icons-material/Close";
import { LocalizationProvider, DatePicker } from "@mui/lab";
import AdapterMoment from "@mui/lab/AdapterMoment";
import { FormControlLabel } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import { Redirect } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import classNames from "classnames";
import FormControl from "@mui/material/FormControl";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import Link from "@mui/material/Link";
import SnackbarContent from "@mui/material/SnackbarContent";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import { Store as notifications } from "react-notifications-component";

import notificationOpts from "../../../../constants/notification-options";
import InputCurrency from "../../../commons/input-currency";
import {
    instalments,
    paymentConditions,
    fetchData,
    getInitialProjectData,
    initialStudyData,
    fetchStudyAreas,
} from "./data";
import NewCustomerForm from "./new-customer-form";
import { useStyles } from "./styles";

import "./ProjectCreateForm.css";

import { Promise } from "bluebird";

import UserRoles from "../../../../constants/user-roles";
import { AuthContext } from "../../../../contexts/auth.context";
import { callFunction } from "../../../../services/data-service";

export default function ProjectCreateForm(props) {
    const { hasAnyRole, hasRole } = useContext(AuthContext);
    const classes = useStyles();

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [newCustomerFormOpen, setNewCustomerFormOpen] = useState(false);
    const [errors, setErrors] = useState([]);
    const [project, setProject] = useState(getInitialProjectData(props));
    const [data, setData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [created, setCreated] = useState(false);
    const [isRaizenDeadlinesTooltipOpen, setIsRaizenDeadlinesTooltipOpen] =
        useState(false);

    const { customers, studies, studyAreas, selectedCustomer, revalidate } =
        data;

    // fetch data
    useEffect(() => {
        fetchData(project).then(response => {
            setData({ ...response });
            setIsLoading(false);
        });
    }, []);

    // fetch study areas on customer changed
    useEffect(() => {
        if (project.customerId)
            fetchStudyAreas(project.customerId).then(newStudyAreas => {
                setData({ ...data, studyAreas: [...newStudyAreas] });
                project.studyArea =
                    newStudyAreas &&
                    newStudyAreas.find(a => a.type === "default");
                project.studyAreaId = project.studyArea && project.studyArea.id;
                setProject({ ...project });
            });
    }, [project.customerId]);

    // check if project is created
    useEffect(() => {
        if (project.id) {
            setCreated(true);
        }
    }, [project.id]);

    const onInputChange = name => event => {
        if (name === "projectValue") {
            if (event.type === "currency") {
                project[name] = event.values.floatValue;
            }
        } else {
            project[name] = event.target.value;
        }
        setProject({ ...project });
    };

    const onCustomerChange = async value => {
        if (value) {
            project.customerId = value.value;
            project.customer = value;

            project.studies = [initialStudyData];

            if (project.customer.raizenDeadlines) {
                setIsRaizenDeadlinesTooltipOpen(true);
            }

            project.number = "";
            if (project.customer.latestCompletedProject) {
                project.number = project.customer.latestCompletedProject;
            }
            setProject({ ...project });
        } else {
            project.customerId = null;
            project.customer = null;
            project.number = "";
            project.studies = [initialStudyData];
            project.studyArea = null;
            project.studyAreaId = null;
            setProject({ ...project });
        }
    };

    const onStudyChange = (index, value) => {
        project.studies[index] = value ? value : initialStudyData;
        setProject({ ...project });
        setIsRaizenDeadlinesTooltipOpen(false);
    };

    const onStudyDeadlineChange = index => event => {
        project.studies[index].deadline = event.target.value;
        setProject({ ...project });
    };

    const onStudyCampaignChange = index => event => {
        project.studies[index].campaign = event.target.value;
        setProject({ ...project });
    };

    const onSubmit = async event => {
        event.preventDefault();

        setIsSubmitting(true);

        const projects = project.studies.map(study => {
            return {
                number: project.number,
                hasLetter: project.hasLetter,
                deadlineDays: study.deadline,
                study: study.data,
                customer: project.customer.data,
                studyArea: project.studyArea.data,
                value: project.projectValue ? project.projectValue : null,
                provisioningId: project.provisioningId
                    ? project.provisioningId
                    : null,
                paymentCondition: project.paymentCondition
                    ? project.paymentCondition.value
                    : null,
                paymentInstalments: project.paymentInstalments
                    ? project.paymentInstalments.value
                    : null,
                firstPaymentDate: project.firstPaymentDate
                    ? project.firstPaymentDate
                    : null,
                campaign: study.campaign ? study.campaign : null,
            };
        });
        let projectId;
        await Promise.mapSeries(projects, async newProject => {
            try {
                const projectDoc = await callFunction(
                    "createProject",
                    newProject,
                );
                notifications.addNotification({
                    ...notificationOpts,
                    type: "success",
                    message: `Projeto ${project.number} cadastrado com sucesso`,
                });
                projectId = projectDoc.data.id;
            } catch (error) {
                setErrors([
                    "Não foi possível cadastrar o projeto, tente novamente",
                ]);
                console.error("Error creating project", error);
            }
        });
        setProject({ ...project, id: projectId });
        setIsSubmitting(false);
    };

    const onAddStudy = () => {
        project.studies.push(initialStudyData);
        setProject({ ...project });
    };

    const onRemoveStudy = index => {
        project.studies.splice(index, 1);
        setProject({ ...project });
    };

    const onNewCustomerAdded = customer => {
        project.customerId = customer.id;
        project.customer = undefined;
        project.studyAreaId = customer.customerAddresses[0].id;
        project.studyArea = undefined;
        setProject({ ...project });
        revalidate();
    };

    const onDateChange = name => date => {
        project[name] = date.format();
        setProject({ ...project });
    };

    const onOpenNewCustomerForm = () => {
        setNewCustomerFormOpen(true);
    };

    const onCloseNewCustomerForm = () => {
        setNewCustomerFormOpen(false);
    };

    const onCloseError = key => {
        errors.splice(key, 1);
        setErrors([...errors]);
    };

    const handleLetterCheck = () => {
        project.hasLetter = !project.hasLetter;
        setProject({ ...project });
    };

    if (isRaizenDeadlinesTooltipOpen) {
        setTimeout(() => {
            setIsRaizenDeadlinesTooltipOpen(false);
        }, 3000);
    }

    if (created) {
        const path = "/projects/" + project.id;
        return (
            <Redirect
                to={{
                    pathname: path,
                }}
            />
        );
    }

    // set default number
    if (project.projectNumber && !project.number) {
        project.number = project.projectNumber;
        setProject({ ...project });
    }

    // set default customer
    if (project.customerId && !project.customer) {
        if (customers) {
            project.customer = customers.find(
                e => e.value === project.customerId,
            );
            if (project.customer) setProject({ ...project });
        }
    }

    // set default study Area
    if (project.studyAreaId && !project.studyArea) {
        if (studyAreas) {
            project.studyArea = studyAreas.find(
                e => e.value === project.studyAreaId,
            );
            if (project.studyArea) setProject({ ...project });
        }
    }

    // set default study Area
    if (project.studyId && project.studies[0].value === 0) {
        if (studies) {
            project.studies[0] = studies.find(e => e.value === project.studyId);
            if (project.studies[0]) setProject({ ...project });
        }
    }

    const renderErrors = () => {
        if (!errors || !errors.length) return "";

        return (
            <div className="form-errors">
                {errors.map((error, key) => {
                    return (
                        <SnackbarContent
                            key={key}
                            className={classNames(classes.error)}
                            aria-describedby="error-message"
                            message={
                                <span
                                    id="error-message"
                                    className={classes.message}
                                >
                                    <Icon
                                        className={classNames(
                                            classes.icon,
                                            classes.iconVariant,
                                        )}
                                    />
                                    {error}
                                </span>
                            }
                            action={[
                                <IconButton
                                    key="close"
                                    aria-label="Close"
                                    color="inherit"
                                    className={classes.close}
                                    onClick={() => onCloseError(key)}
                                >
                                    <CloseIcon className={classes.icon} />
                                </IconButton>,
                            ]}
                        />
                    );
                })}
            </div>
        );
    };

    return (
        <div className={"project-create-form"}>
            <form
                className={classes.container}
                autoComplete="off"
                onSubmit={onSubmit}
            >
                <div className={"header"}>
                    <div className={"header-title"}>Cadastrar Projeto</div>
                    <div className={"header-actions"} />
                </div>
                <div className={"content"}>
                    {renderErrors()}
                    <div className={"customer-data"}>
                        <h5>Dados do Cliente</h5>
                        <div className={"customer-name"}>
                            <Autocomplete
                                className={classes.customerNameField}
                                id="customerName"
                                value={project.customer}
                                onChange={(event, customer) => {
                                    onCustomerChange(customer);
                                }}
                                options={customers || []}
                                getOptionLabel={option => option.label}
                                isOptionEqualToValue={(option, value) =>
                                    option.value === value
                                }
                                loading={isLoading}
                                disabled={isSubmitting || isLoading}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        label="Razão Social do Empreendimento"
                                        margin="normal"
                                        placeholder="Razão Social do Empreendimento"
                                        required={true}
                                        fullWidth
                                    />
                                )}
                            />
                            <div className={"customer-new-form"}>
                                <Link
                                    className={classes.newCustomerButton}
                                    component="button"
                                    variant="body2"
                                    type="button"
                                    onClick={onOpenNewCustomerForm}
                                    aria-label="Cadastrar novo cliente"
                                >
                                    {hasAnyRole(["CDC"]) ? (
                                        <Tooltip title="Cadastrar novo cliente">
                                            <Icon fontSize="small">
                                                <FontAwesomeIcon
                                                    className="new-customer-form-icon"
                                                    icon={faPlusSquare}
                                                />
                                            </Icon>
                                        </Tooltip>
                                    ) : null}
                                </Link>
                            </div>
                        </div>
                        {project.customer && (
                            <div className={"study-area"}>
                                <Autocomplete
                                    className={classes.customerNameField}
                                    id="studyArea"
                                    value={project.studyArea}
                                    onChange={(event, studyArea) => {
                                        setProject({ ...project, studyArea });
                                    }}
                                    options={studyAreas || []}
                                    getOptionLabel={option => option.label}
                                    loading={isLoading}
                                    disabled={isSubmitting || isLoading}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            label="Área de Estudo"
                                            margin="normal"
                                            placeholder="Área de Estudo"
                                            required={true}
                                            fullWidth
                                        />
                                    )}
                                />
                            </div>
                        )}
                        {project.studyArea && (
                            <div className={"study-area-address"}>
                                <p>{`${project.studyArea.address} - ${project.studyArea.city} - ${project.studyArea.state}`}</p>
                            </div>
                        )}
                    </div>
                    <div className={"project-data"}>
                        <h5>Dados do Projeto</h5>
                        <div className={"number"}>
                            <TextField
                                className={classes.racpNumberField}
                                id="number"
                                value={project.number || ""}
                                label="Nº RACP"
                                variant="standard"
                                onChange={onInputChange("number")}
                                placeholder="Nº RACP"
                                disabled={isSubmitting || isLoading}
                            />
                            {selectedCustomer && (
                                <div className={"number-last"}>
                                    <p>
                                        Nº ultimo projeto:{" "}
                                        {
                                            selectedCustomer.latestCompletedProject
                                        }
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={"project-studies"}>
                        <h5>Estudos</h5>
                        <div className="project-studies-items">
                            {project.studies.map((study, index) => {
                                return (
                                    <div
                                        key={index}
                                        className={"project-study-item"}
                                    >
                                        <div className={"study"}>
                                            <Autocomplete
                                                className={classes.studiesField}
                                                id={
                                                    "project.studies[" +
                                                    index +
                                                    "].study"
                                                }
                                                value={study}
                                                onChange={(event, value) => {
                                                    onStudyChange(index, value);
                                                }}
                                                options={studies || []}
                                                getOptionLabel={option =>
                                                    option.label
                                                }
                                                loading={isLoading}
                                                disabled={
                                                    isSubmitting || isLoading
                                                }
                                                renderInput={params => (
                                                    <TextField
                                                        {...params}
                                                        variant="standard"
                                                        label="Estudo"
                                                        margin="normal"
                                                        placeholder="Estudo"
                                                        required={true}
                                                        fullWidth
                                                    />
                                                )}
                                            />
                                        </div>
                                        {study.hasCampaign && (
                                            <div className={"campaign"}>
                                                <TextField
                                                    className={
                                                        classes.campaignField
                                                    }
                                                    id="campaign"
                                                    value={study.campaign || ""}
                                                    label="Nº da Campanha"
                                                    variant="standard"
                                                    onChange={onStudyCampaignChange(
                                                        index,
                                                    )}
                                                    placeholder=" Nº da Campanha"
                                                    disabled={
                                                        isSubmitting ||
                                                        isLoading
                                                    }
                                                />
                                            </div>
                                        )}
                                        <div className={"deadline"}>
                                            <TextField
                                                className={
                                                    classes.deadlineField
                                                }
                                                label="Prazo (dias)"
                                                variant="standard"
                                                value={study.deadline || ""}
                                                id={
                                                    "project.studies[" +
                                                    index +
                                                    "].deadline"
                                                }
                                                type="number"
                                                inputProps={{
                                                    min: "1",
                                                }}
                                                onChange={onStudyDeadlineChange(
                                                    index,
                                                )}
                                                placeholder="Prazo (dias)"
                                                disabled={
                                                    isSubmitting || isLoading
                                                }
                                            />
                                        </div>
                                        {index > 0 && (
                                            <div
                                                className={"remove-study-item"}
                                            >
                                                <Tooltip title="Excluir estudo">
                                                    <Link
                                                        className={
                                                            classes.removeStudyButton
                                                        }
                                                        component="button"
                                                        variant="body2"
                                                        type="button"
                                                        onClick={() =>
                                                            onRemoveStudy(index)
                                                        }
                                                        aria-label="Remover estudo"
                                                        disabled={
                                                            isSubmitting ||
                                                            isLoading
                                                        }
                                                    >
                                                        <Icon fontSize="small">
                                                            <FontAwesomeIcon
                                                                className="remove-study-icon"
                                                                icon={
                                                                    faTrashAlt
                                                                }
                                                            />
                                                        </Icon>
                                                    </Link>
                                                </Tooltip>
                                            </div>
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                        <div className="project-studies-actions">
                            <Link
                                className={classes.addStudyButton}
                                component="button"
                                variant="body2"
                                type="button"
                                onClick={onAddStudy}
                                aria-label="Adicionar novo estudo"
                                disabled={isSubmitting || isLoading}
                            >
                                <Icon fontSize="small">
                                    <FontAwesomeIcon
                                        className="project-studies-add-icon"
                                        icon={faPlusSquare}
                                    />
                                </Icon>
                                Adicionar estudo
                            </Link>
                        </div>
                        <div className={classes.letterCheckboxContainer}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={project.hasLetter}
                                        onChange={handleLetterCheck}
                                        classes={{
                                            root: classes.letterCheckbox,
                                            checked: classes.checked,
                                        }}
                                        disabled={isSubmitting || isLoading}
                                    />
                                }
                                label={
                                    <p className={classes.letterCheckboxLabel}>
                                        Carta
                                    </p>
                                }
                            />
                        </div>
                    </div>
                    {hasRole(UserRoles.CONTROLE_FINANCEIRO) && (
                        <div className={"project-payments-terms"}>
                            <h5>Condições de pagamento</h5>
                            <div className={"project-payments-terms-item"}>
                                <div className={"project-value"}>
                                    <FormControl
                                        className={classes.projectValueField}
                                    >
                                        <InputLabel htmlFor="projectValue">
                                            Valor
                                        </InputLabel>
                                        <Input
                                            id="projectValue"
                                            onChange={onInputChange(
                                                "projectValue",
                                            )}
                                            placeholder="Valor"
                                            inputComponent={InputCurrency}
                                            disabled={isSubmitting || isLoading}
                                        />
                                    </FormControl>
                                </div>
                                <div className={"project-payment-date"}>
                                    <LocalizationProvider
                                        dateAdapter={AdapterMoment}
                                        adapterLocale="pt-br"
                                    >
                                        <DatePicker
                                            className={
                                                classes.projectPaymentDateField
                                            }
                                            format="DD/MM/YYYY"
                                            disablePast={true}
                                            disabled={isSubmitting || isLoading}
                                            labelFunc={date => {
                                                return project.firstPaymentDate
                                                    ? date.format("DD/MM/YYYY")
                                                    : "";
                                            }}
                                            label="Data do 1º vencimento"
                                            value={project.firstPaymentDate}
                                            onChange={onDateChange(
                                                "firstPaymentDate",
                                            )}
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    variant="standard"
                                                    margin="dense"
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </div>
                            </div>
                            <div className={"project-payments-terms-item"}>
                                <div className={"payment-condition"}>
                                    <Autocomplete
                                        className={
                                            classes.paymentConditionField
                                        }
                                        id="paymentCondition"
                                        value={project.paymentCondition}
                                        onChange={(event, paymentCondition) => {
                                            setProject({
                                                ...project,
                                                paymentCondition,
                                            });
                                        }}
                                        options={paymentConditions || []}
                                        getOptionLabel={option => option.label}
                                        loading={isLoading}
                                        disabled={isSubmitting || isLoading}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                variant="standard"
                                                label="Forma de pagamento"
                                                margin="normal"
                                                placeholder="Forma de pagamento"
                                                required={true}
                                                fullWidth
                                            />
                                        )}
                                    />
                                </div>
                                {project.paymentCondition &&
                                    (project.paymentCondition.value === 2 ||
                                        project.paymentCondition.value ===
                                            3) && (
                                        <div className={"instalments"}>
                                            <Autocomplete
                                                className={
                                                    classes.instalmentsField
                                                }
                                                id="paymentInstalments"
                                                value={
                                                    project.paymentInstalments
                                                }
                                                onChange={(
                                                    event,
                                                    paymentInstalments,
                                                ) => {
                                                    setProject({
                                                        ...project,
                                                        paymentInstalments,
                                                    });
                                                }}
                                                options={instalments || []}
                                                getOptionLabel={option =>
                                                    option.label
                                                }
                                                loading={isLoading}
                                                disabled={
                                                    isSubmitting || isLoading
                                                }
                                                renderInput={params => (
                                                    <TextField
                                                        {...params}
                                                        variant="standard"
                                                        label="Parcelas"
                                                        margin="normal"
                                                        placeholder="Parcelas"
                                                        required={true}
                                                        fullWidth
                                                    />
                                                )}
                                            />
                                        </div>
                                    )}
                            </div>
                        </div>
                    )}
                </div>
                <div className={"footer"}>
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={isLoading || isSubmitting}
                        className={classes.submitButton}
                    >
                        Salvar
                        {isLoading ||
                            (isSubmitting && (
                                <CircularProgress
                                    size={18}
                                    thickness={4}
                                    className={classes.loadingIndicator}
                                />
                            ))}
                    </Button>
                </div>
            </form>
            <NewCustomerForm
                open={newCustomerFormOpen}
                onClose={onCloseNewCustomerForm}
                onCreated={onNewCustomerAdded}
            />
            {/* <NewStudyAreaDialog
                dialogOpen={isNewStudyAreaDialogOpen}
                handleDialogClose={onCloseNewStudyAreaDialog}
                customer={{ id: project.customerId }}
                receivedStudyArea={{}}
                onCreated={onNewStudyAreaAdded}
            /> */}
        </div>
    );
}
