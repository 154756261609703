export default function ProjectFactory(
    {
        id,
        customer = {},
        study = {},
        phase = {},
        phases = [],
        execution = {},
        executions = [],
        checklists = [],
        infos = [],
        events = [],
        assignee = {},
        surveyor = {},
        drafter = {},
        createdAt,
        createdBy = {},
        updatedAt,
        updatedBy = {},
    } = {},
    ...opts
) {
    const project = {
        id,
        customer,
        study,
        phase,
        phases,
        execution,
        executions,
        checklists,
        infos,
        events,
        assignee,
        surveyor,
        drafter,

        createdAt,
        createdBy,
        updatedAt,
        updatedBy,

        ...opts,
    };

    // Load assigneeId
    project.assigneeId = project.assignee && project.assignee.id;

    // Load surveyorId
    project.surveyorId = project.surveyor && project.surveyor.id;

    // Load drafterId
    project.drafterId = project.drafter && project.drafter.id;

    return project;
}
