import React from "react";

import CloseIcon from "@mui/icons-material/Close";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { DialogTitle, IconButton, Stack } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

import OrderForm, { ViewMode } from "../../orders/order-form";

export default function PlanningDialog({
    onClose,
    onOpenNewWindow,
    onOrderChanged,
    open,
    edit,
    data,
    ...props
}) {
    const orderId = data.id;
    return (
        <Dialog open={open} fullWidth={true} maxWidth="xl">
            <DialogTitle sx={{ m: 1, p: 2 }}>
                {onClose && (
                    <Stack
                        direction="row"
                        sx={{
                            position: "absolute",
                            right: 12,
                            top: 8,
                            color: theme => theme.palette.grey[500],
                        }}
                    >
                        <IconButton
                            size="small"
                            aria-label="close"
                            onClick={onOpenNewWindow}
                        >
                            <OpenInNewIcon />
                        </IconButton>
                        <IconButton
                            size="small"
                            aria-label="close"
                            onClick={onClose}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                )}
            </DialogTitle>
            <DialogContent dividers>
                <OrderForm
                    dialog
                    onCancel={onClose}
                    onSave={onClose}
                    onOrderChanged={onOrderChanged}
                    orderId={orderId}
                    order={data}
                    viewMode={
                        orderId
                            ? edit
                                ? ViewMode.EDIT
                                : ViewMode.DISPLAY
                            : ViewMode.NEW
                    }
                    {...props}
                />
            </DialogContent>
        </Dialog>
    );
}
