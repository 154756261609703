import React, { Component } from "react";
import PropTypes from "prop-types";

import "./Dashboard.css";

class Dashboard extends Component {
    static propTypes = {
        classes: PropTypes.object
    };

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true
        };
    }

    render() {
        return (
            <div>
                <p>Dashboard</p>
            </div>
        );
    }
}

export default Dashboard;
