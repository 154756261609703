/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState, useContext } from "react";

import { Close as CloseIcon } from "@mui/icons-material";
import {
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    CircularProgress,
    Icon,
    IconButton,
    SnackbarContent,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { makeStyles, withStyles } from "@mui/styles";
import classNames from "classnames";
import moment from "moment";

import "./ConfirmTaskDelayDialog.css";

import { DataContext } from "../../../../contexts";

import Autocomplete from "@mui/material/Autocomplete";

import { getCollectionData } from "../../../../services/data-service";

const useStyles = makeStyles(theme => ({
    actionIcon: {
        margin: theme.spacing(1),
    },
    actionButton: {
        margin: theme.spacing(2),
        fontFamily: "Roboto",
        fontSize: "14px",
        fontWeight: "normal",
        fontStyle: "normal",
        fontStretch: "normal",
        lineHeight: "1.14",
        letterSpacing: "normal",
        textAlign: "left",
        color: "#4192d9",
        textTransform: "none",
    },
    actionButtonLabel: {
        paddingRight: theme.spacing(1),
    },
    cancelButton: {
        color: "red",
    },
    submitButton: {
        color: "green",
    },
    loadingIndicator: {
        color: "green",
        marginLeft: theme.spacing(2),
    },
    error: {
        backgroundColor: theme.palette.error.dark,
        marginBottom: theme.spacing(2),
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    messageField: {
        minWidth: 600,
    },
    reasonSelect: {
        marginTop: theme.spacing(1),
        marginButton: theme.spacing(1),
        marginRight: theme.spacing(1),
        minWidth: 400,
    },
    totalOverdueField: {
        marginTop: theme.spacing(1),
        marginButton: theme.spacing(1),
        marginLeft: theme.spacing(1),
        width: 180,
    },
    delayOverviewTable: {
        marginTop: theme.spacing(1),
        marginButton: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
}));

const HeaderTableCell = withStyles(() => ({
    head: {
        backgroundColor: "#f8f8f8",
        color: "#8b8b8b",
    },
}))(TableCell);

const theme = createTheme({
    palette: {
        primary: {
            main: "#3fb165",
        },
        secondary: {
            light: "#0066ff",
            main: "#0044ff",
            contrastText: "#ffcc00",
        },
    },
    typography: {
        useNextVariants: true,
    },
});

async function fetchDelayData(data) {
    const project = data.project;
    const projectPhases = await getCollectionData(
        `projects/${project.id}/executions/${project.executionId}/phases`,
    );
    const phases = projectPhases
        .filter(p => !p.forWellsInstallation)
        .sort((a, b) => a.order - b.order);
    const reasonsData = await getCollectionData(`reasons`);
    const reasons = reasonsData.filter(r => r.type === "delay");

    const currentProjectPhase = data;
    const currentPhase = data;

    const now = moment(); //todays date
    const phaseStart = moment(currentProjectPhase.releasedAt, moment.ISO_8601);
    const phaseElapsedDays = moment.duration(now.diff(phaseStart)).asDays();
    const totalOverdue =
        phaseElapsedDays - (currentProjectPhase.plannedDurationDays || 0);

    const projectStart = moment(project.createdAt, moment.ISO_8601);
    let projectElapsedDays = moment.duration(now.diff(projectStart)).asDays();

    let plannedProgress = phases
        .filter(p => p.order < currentPhase.order)
        .map(p => p.deadlineFactor)
        .reduce((a, b) => {
            return a + b;
        }, 0);
    let actualProgress = (projectElapsedDays / project.deadlineDays) * 100.0;
    let actualDeadline = moment(now);
    let actualDurationDays = projectElapsedDays;
    let delayOverview = [];

    // Check delay impact over remaining phases
    phases
        .filter(phase => phase.order > currentPhase.order)
        .forEach(phase => {
            const plannedDurationDays =
                (project.deadlineDays * phase.deadlineFactor) / 100;

            // calculate the progress factor between planeed and realized progress,
            const progressFactor =
                (100.0 - actualProgress) / (100.0 - plannedProgress);

            // calculate new deadline factor based on current progress
            const newDeadlineFactor = phase.deadlineFactor * progressFactor;

            // calculate new deadline date
            actualDurationDays =
                (project.deadlineDays * newDeadlineFactor) / 100;

            if (actualDurationDays < 0) {
                actualDurationDays = 0;
            }

            // calculate new deadline date
            actualDeadline = moment(actualDeadline).add(
                actualDurationDays * 24,
                "hours",
            );

            delayOverview.push({
                phaseName: phase.name,
                plannedDurationDays,
                actualDurationDays,
                actualDeadline,
                plannedProgress,
                projectElapsedDays,
                actualProgress,
            });

            plannedProgress = plannedProgress + phase.deadlineFactor;
            projectElapsedDays = projectElapsedDays + actualDurationDays;
            actualProgress =
                (projectElapsedDays / project.deadlineDays) * 100.0;
        });
    return {
        project,
        phases,
        reasons,
        projectPhases,
        currentProjectPhase,
        currentPhase,
        totalOverdue,
        delayOverview,
    };
}

export default function ConfirmTaskDelayDialog({
    open,
    message,
    onConfirm,
    onClose,
}) {
    const classes = useStyles();

    const { phase, isLoadingPhase } = useContext(DataContext);

    const [state, setState] = useState({
        isLoading: true,
        errors: [],
        delayMessage: message || "",
        phases: [],
        reasons: [],
        delayOverview: [],
        phase: {},
        reason: undefined,
        totalOverdue: 0,
    });

    const {
        delayMessage,
        reason,
        reasons,
        totalOverdue,
        delayOverview,
        isLoading,
    } = state;

    // fetch data
    useEffect(() => {
        if (isLoadingPhase) return;
        fetchDelayData(phase).then(delayData => {
            setState({
                ...state,
                project: delayData.project,
                phases: delayData.phases.map(phase => ({
                    value: phase.id,
                    label: phase.name,
                    data: phase,
                })),
                reasons: delayData.reasons,
                isLoading: false,
                totalOverdue: getDurationLabel(delayData.totalOverdue),
                delayOverview: delayData.delayOverview,
            });
        });
    }, [phase, isLoadingPhase]);

    const handleConfirmClick = () => {
        const { delayMessage, reason } = state;
        const errors = [];

        if (!reason) errors.push("Selecione um motivo");

        if (errors.length) {
            setState({
                ...state,
                errors,
            });
            return;
        }

        if (onConfirm) {
            setState({
                ...state,
                isLoading: true,
            });
            onConfirm({
                message: delayMessage,
                reason,
            });
        }
    };

    const onCloseError = key => {
        const { errors } = state;
        errors.splice(key, 1);
        setState({
            ...state,
            errors,
        });
    };

    const handleClose = () => {
        setState({
            ...state,
            errors: [],
        });

        if (onClose) onClose();
    };

    const handleInputChange = name => event => {
        setState({
            ...state,
            [name]: event.target.value,
        });
    };

    const handleSelectChange = name => value => {
        setState({
            ...state,
            [name]: value,
        });
    };

    const getDurationLabel = duration => {
        const value = Math.round(duration);
        let label = "";
        if (value > 1) label = value + " dias";
        if (value === 1.0) label = value + " dia";
        if (duration.toPrecision(2) < 1) {
            const hours = Math.round(duration * 24);
            if (hours >= 1) {
                label = hours + (hours === 1 ? " hora" : " horas");
            } else {
                label = "< 1 hora";
            }
        }
        return label;
    };

    const renderErrors = () => {
        const { errors } = state;

        if (!errors) return "";

        return (
            <div className="form-errors">
                {errors.map((error, key) => {
                    return (
                        <SnackbarContent
                            key={key}
                            className={classNames(classes.error)}
                            aria-describedby="error-message"
                            message={
                                <span
                                    id="error-message"
                                    className={classes.message}
                                >
                                    <Icon
                                        className={classNames(
                                            classes.icon,
                                            classes.iconVariant,
                                        )}
                                    />
                                    {error}
                                </span>
                            }
                            action={[
                                <IconButton
                                    key="close"
                                    aria-label="Close"
                                    color="inherit"
                                    className={classes.close}
                                    onClick={() => onCloseError(key)}
                                >
                                    <CloseIcon className={classes.icon} />
                                </IconButton>,
                            ]}
                        />
                    );
                })}
            </div>
        );
    };

    return (
        <ThemeProvider theme={theme}>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="md"
                disableBackdropClick
                disableEscapeKeyDown
            >
                <DialogTitle id="alert-dialog-title">
                    Justificar atraso
                </DialogTitle>
                <DialogContent>
                    <div className="confirm-task-delay-dialog">
                        {renderErrors()}
                        <div className="delay-fields">
                            <Autocomplete
                                className={classes.reasonSelect}
                                value={reason}
                                id="reason"
                                options={reasons}
                                getOptionLabel={option => option.name}
                                loading={isLoading}
                                disabled={isLoading}
                                isOptionEqualToValue={(option, value) =>
                                    option.id === value
                                }
                                onChange={(event, value) => {
                                    handleSelectChange("reason")(value);
                                }}
                                style={{ width: 300 }}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        placeholder="Selecione um motivo"
                                        label="Motivo do atraso"
                                        variant="standard"
                                    />
                                )}
                            />
                            <TextField
                                className={classes.totalOverdueField}
                                id="totalOverdue"
                                value={totalOverdue}
                                onChange={handleInputChange("totalOverdue")}
                                label="Tempo de atraso"
                                margin="dense"
                                disabled={isLoading}
                                InputProps={{
                                    readOnly: true,
                                }}
                                variant="standard"
                            />
                        </div>
                        {delayOverview.length ? (
                            <div className="delay-overview">
                                <Table className={classes.delayOverviewTable}>
                                    <TableHead>
                                        <TableRow>
                                            <HeaderTableCell>
                                                ETAPA
                                            </HeaderTableCell>
                                            <HeaderTableCell align="right">
                                                PLANEJADO
                                            </HeaderTableCell>
                                            <HeaderTableCell align="right">
                                                AJUSTADO
                                            </HeaderTableCell>
                                            <HeaderTableCell align="right">
                                                DATA
                                            </HeaderTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {delayOverview.map((row, index) => (
                                            <TableRow key={index}>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                >
                                                    {row.phaseName}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {getDurationLabel(
                                                        row.plannedDurationDays,
                                                    )}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {getDurationLabel(
                                                        row.actualDurationDays,
                                                    )}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {moment(
                                                        row.actualDeadline,
                                                        moment.ISO_8601,
                                                    ).format("DD/MM/YYYY")}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </div>
                        ) : null}
                        <div className="delay-message">
                            <TextField
                                id="delay-message"
                                fullWidth
                                value={delayMessage}
                                onChange={handleInputChange("delayMessage")}
                                label="Adicionar informação"
                                multiline
                                rows="4"
                                className={classes.messageField}
                                margin="normal"
                                disabled={isLoading}
                            />
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        className={classes.submitButton}
                        onClick={handleConfirmClick}
                        disabled={isLoading}
                    >
                        Confirmar
                        {state.isLoading && (
                            <CircularProgress
                                size={18}
                                thickness={4}
                                className={classes.loadingIndicator}
                            />
                        )}
                    </Button>
                    <Button
                        className={classes.cancelButton}
                        onClick={handleClose}
                        disabled={isLoading}
                    >
                        Cancelar
                    </Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    );
}
