import React from "react";

import OrderDatatable from "./order-datatable";
import ListViewDetails from "../commons/list-detail-view";

export default function Orders(props) {
    return (
        <ListViewDetails
            detailsPath={"/orders/:id"}
            datatableView={DatatableView}
            listView={ListView}
            detailView={DetailView}
            {...props}
        />
    );
}

const ListView = () => {
    return <div>ListView</div>;
};

const DetailView = () => {
    return <div>DetailView</div>;
};

const DatatableView = props => {
    return <OrderDatatable {...props} />;
};
