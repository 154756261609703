/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";

import { Close as CloseIcon } from "@mui/icons-material";
import {
    TextField,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    CircularProgress,
    Icon,
    IconButton,
    SnackbarContent,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";

import "./ProjectDrafterSelectorDialog.css";
import {
    getCollectionSnapshot,
    where,
    updateDocument,
} from "../../../../services/data-service";

import Autocomplete from "@mui/material/Autocomplete";

const useStyles = makeStyles(theme => ({
    actionIcon: {
        margin: theme.spacing(1),
    },
    actionButton: {
        margin: theme.spacing(2),
        fontFamily: "Roboto",
        fontSize: "14px",
        fontWeight: "normal",
        fontStyle: "normal",
        fontStretch: "normal",
        lineHeight: "1.14",
        letterSpacing: "normal",
        textAlign: "left",
        color: "#4192d9",
        textTransform: "none",
    },
    actionButtonLabel: {
        paddingRight: theme.spacing(1),
    },
    cancelButton: {
        color: "red",
    },
    submitButton: {
        color: "green",
    },
    loadingIndicator: {
        color: "green",
        marginLeft: theme.spacing(2),
    },
    error: {
        backgroundColor: theme.palette.error.dark,
        marginBottom: theme.spacing(2),
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        width: "480px",
    },
    drafterSelect: {
        width: "auto",
        minWidth: 450,
    },
    phaseSelect: {
        width: "auto",
        minWidth: 300,
    },
    studySelect: {
        width: "auto",
        minWidth: 200,
    },
}));

export default function ProjectDrafterSelectorDialog(props) {
    const { projectId, open } = props;
    const classes = useStyles();

    const [isLoading, setIsLoading] = useState(true);
    const [errors, setErrors] = useState([]);
    const [drafter, setDrafter] = useState(props.drafter);
    const [drafters, setDrafters] = useState([]);

    // fetch data
    useEffect(() => {
        getCollectionSnapshot("users", [
            where("roles", "array-contains", "DES"),
        ]).then(data => {
            if (!data.empty) {
                const users = data.docs.map(doc => doc.data());
                setDrafters([
                    ...users
                        .filter(user => !user.inactive)
                        .map(user => ({
                            ...user,
                            value: user.id,
                            label: user.name,
                        })),
                ]);
            }
            setIsLoading(false);
        });
    }, []);

    const onCloseError = key => {
        errors.splice(key, 1);
        setErrors([...errors]);
    };

    const handleClose = () => {
        setErrors([]);
        const { onClose } = props;
        if (onClose) onClose();
    };

    const handleConfirmClick = () => {
        if (!drafter) {
            errors.push("Escolha um desenhista.");
            setErrors([...errors]);
            return;
        }
        setIsLoading(true);
        updateDocument(`projects/${projectId}`, {
            drafter: {
                id: drafter.id,
                name: drafter.name,
                email: drafter.email,
            },
        })
            .then(() => {
                if (props.onClose) props.onClose();
            })
            .catch(error => {
                console.error("Error updating drafter ", error);
            })
            .finally(() => setIsLoading(false));
    };

    const renderErrors = () => {
        if (!errors) return "";

        return (
            <div className="form-errors">
                {errors.map((error, key) => {
                    return (
                        <SnackbarContent
                            key={key}
                            className={classNames(classes.error)}
                            aria-describedby="error-message"
                            message={
                                <span
                                    id="error-message"
                                    className={classes.message}
                                >
                                    <Icon
                                        className={classNames(
                                            classes.icon,
                                            classes.iconVariant,
                                        )}
                                    />
                                    {error}
                                </span>
                            }
                            action={[
                                <IconButton
                                    key="close"
                                    aria-label="Close"
                                    color="inherit"
                                    className={classes.close}
                                    onClick={() => onCloseError(key)}
                                >
                                    <CloseIcon className={classes.icon} />
                                </IconButton>,
                            ]}
                        />
                    );
                })}
            </div>
        );
    };

    return (
        <div className="project-dafter-selector-dialog">
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="project-dafter-selector-dialog-title"
                aria-describedby="project-dafter-selector-dialog-description"
            >
                <DialogTitle id="project-dafter-selector-dialog-title">
                    Deseja atribuir um desenhista?
                </DialogTitle>
                <DialogContent>
                    {renderErrors()}
                    <div>
                        <Autocomplete
                            className={classes.drafterSelect}
                            id="drafter"
                            name="drafterSelector"
                            value={drafter || ""}
                            onChange={(event, data) => {
                                setDrafter(data);
                            }}
                            options={drafters}
                            getOptionLabel={option => option.name || ""}
                            loading={isLoading}
                            disabled={isLoading}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    label="Desenhista"
                                    margin="normal"
                                    placeholder="Desenhista"
                                    required={true}
                                    fullWidth
                                />
                            )}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        className={classes.submitButton}
                        onClick={handleConfirmClick}
                        disabled={isLoading}
                    >
                        Atribuir
                        {isLoading && (
                            <CircularProgress
                                size={18}
                                thickness={4}
                                className={classes.loadingIndicator}
                            />
                        )}
                    </Button>
                    <Button
                        className={classes.cancelButton}
                        onClick={handleClose}
                    >
                        Cancelar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
