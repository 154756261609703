import React, { useState } from "react";

import {
    Autocomplete,
    Button,
    Checkbox,
    DialogActions,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    FormLabel,
    Grid,
    TextField,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { createTheme } from "@mui/material/styles";

import {
    SAMPLE_DEPTH,
    SAMPLE_LAB,
    SAMPLE_MATRIX,
    SAMPLE_PARAMS,
    SAMPLE_VALIDITY,
} from "../../../constants/order-sample";
import { OrderSampleModel } from "../../../models";

export default function OrderSampleDialog({
    onClose,
    onSave,
    open,
    data = [{}],
}) {
    const [sample, setSample] = useState(new OrderSampleModel({ ...data[0] }));
    const [error, setError] = useState({});
    const { parameters = [] } = sample;
    const singleSample = data.length === 1;

    const handleSaveClick = () => {
        if (
            !sample.matrix ||
            !sample.laboratory ||
            !sample.depth ||
            !sample.name ||
            !sample.validity
        ) {
            setError({
                matrix: !sample.matrix,
                laboratory: !sample.laboratory,
                depth: !sample.depth,
                name: !sample.name,
                validity: !sample.validity,
            });
        } else {
            onSave &&
                onSave(
                    data.map(s => {
                        return new OrderSampleModel({
                            ...s,
                            ...(singleSample && { name: sample.name }),
                            matrix: sample.matrix,
                            laboratory: sample.laboratory,
                            depth: sample.depth,
                            validity: sample.validity,
                            parameters: sample.parameters,
                        });
                    }),
                );
        }
    };

    const handleParameterChange = event => {
        const item = event.target.name;
        const exists = parameters.includes(item);

        if (exists) {
            setSample({
                ...sample,
                parameters: parameters.filter(c => c !== item),
            });
            return;
        } else {
            const result = parameters;
            result.push(item);
            setSample({
                ...sample,
                parameters: result,
            });
        }
    };

    return (
        <Dialog open={open} fullWidth>
            <DialogTitle sx={{ m: 1, p: 2 }}>
                {singleSample
                    ? `Amostra - ${sample.name}`
                    : `Amostras (${data.length})`}
            </DialogTitle>
            <DialogContent dividers>
                {singleSample && (
                    <TextField
                        sx={{ pb: theme.spacing(2) }}
                        fullWidth
                        id="sample-name"
                        value={sample.name}
                        onChange={event =>
                            setSample({
                                ...sample,
                                name: event.target.value,
                            })
                        }
                        label="ST ou PM"
                        variant="standard"
                        error={error.name}
                        required
                    />
                )}
                {!singleSample && (
                    <TextField
                        sx={{ pb: theme.spacing(2) }}
                        fullWidth
                        id="sample-name"
                        value={data.map(d => d.name).join(", ")}
                        multiline
                        // rows={3}
                        label="ST ou PM"
                        variant="filled"
                        error={error.name}
                        required
                    />
                )}

                <Autocomplete
                    id="sample-matrix"
                    value={sample.matrix}
                    onChange={(event, matrix) => {
                        setSample({
                            ...sample,
                            matrix: matrix,
                        });
                    }}
                    options={SAMPLE_MATRIX}
                    getOptionLabel={option => option}
                    isOptionEqualToValue={(option, value) =>
                        option.value === value
                    }
                    renderInput={params => (
                        <TextField
                            {...params}
                            variant="standard"
                            label="Matriz"
                            margin="normal"
                            placeholder="Matriz"
                            error={error.matrix}
                            required
                            fullWidth
                        />
                    )}
                />

                <Autocomplete
                    id="sample-laboratory"
                    value={sample.laboratory}
                    onChange={(event, laboratory) => {
                        setSample({
                            ...sample,
                            laboratory: laboratory,
                        });
                    }}
                    options={SAMPLE_LAB}
                    getOptionLabel={option => option}
                    isOptionEqualToValue={(option, value) =>
                        option.value === value
                    }
                    renderInput={params => (
                        <TextField
                            {...params}
                            variant="standard"
                            label="Laboratório"
                            margin="normal"
                            placeholder="Laboratório"
                            error={error.laboratory}
                            required
                            fullWidth
                        />
                    )}
                />

                <Autocomplete
                    id="sample-laboratory"
                    value={sample.depth}
                    onChange={(event, depth) => {
                        setSample({
                            ...sample,
                            depth: depth,
                        });
                    }}
                    options={SAMPLE_DEPTH}
                    getOptionLabel={option => option}
                    isOptionEqualToValue={(option, value) =>
                        option.value === value
                    }
                    renderInput={params => (
                        <TextField
                            {...params}
                            variant="standard"
                            label="Profundidade"
                            margin="normal"
                            placeholder="Profundidade"
                            error={error.depth}
                            required
                            fullWidth
                        />
                    )}
                />

                <Autocomplete
                    id="sample-laboratory"
                    value={sample.validity}
                    onChange={(event, validity) => {
                        setSample({
                            ...sample,
                            validity: validity,
                        });
                    }}
                    options={SAMPLE_VALIDITY}
                    getOptionLabel={option => option}
                    isOptionEqualToValue={(option, value) =>
                        option.value === value
                    }
                    renderInput={params => (
                        <TextField
                            {...params}
                            variant="standard"
                            label="Validade"
                            margin="normal"
                            placeholder="Validade"
                            error={error.validity}
                            required
                            fullWidth
                        />
                    )}
                />

                <FormControl
                    sx={{ mt: 2 }}
                    component="fieldset"
                    variant="standard"
                >
                    <FormLabel component="legend">Parâmetros</FormLabel>
                    <FormGroup>
                        <Grid
                            container
                            spacing={2}
                            columns={{ xs: 4, sm: 8, md: 12 }}
                        >
                            {SAMPLE_PARAMS.map((parameter, i) => {
                                return (
                                    <Grid key={i} item xs={4}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={parameters.some(
                                                        p => p === parameter,
                                                    )}
                                                    onChange={
                                                        handleParameterChange
                                                    }
                                                    name={parameter}
                                                />
                                            }
                                            label={parameter}
                                        />
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </FormGroup>
                    <FormHelperText>Selecione os parâmetros</FormHelperText>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancelar</Button>
                <Button onClick={handleSaveClick}>Salvar</Button>
            </DialogActions>
        </Dialog>
    );
}

const theme = createTheme();
