import { getAuth } from "firebase/auth";
import {
    Timestamp,
    collection,
    collectionGroup,
    deleteDoc,
    doc,
    getDoc,
    getDocs,
    onSnapshot,
    orderBy,
    query,
    runTransaction,
    setDoc,
    updateDoc,
    where,
    writeBatch,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";

import { getUser } from "./user-service";
import { app, firestore, functions } from "../configs/firebase/firebase.config";
import { toJSON } from "../lib/object-utils";

async function getCurrentUser() {
    const auth = getAuth();
    const currentAuthUser = auth.currentUser;
    const users = await getCollectionData("users", [
        where("email", "==", `${currentAuthUser.email}`),
    ]);
    const currentUser = users[0];
    return toJSON(getUser(currentUser));
}

function getDocumentReference(path) {
    return doc(firestore, ...path.split("/"));
}

function getDocumentSnapshot(path) {
    return getDoc(getDocumentReference(path));
}

async function getDocumentData(path) {
    const docSnap = await getDocumentSnapshot(path);
    return docSnap.data();
}

function onDocumentSnapshot(path, callback) {
    const docRef = getDocumentReference(path);
    return onSnapshot(docRef, callback);
}

function onCollectionSnapshot({ path, queryConstraints = [], callback }) {
    const collectionRef = getCollectionReference(path);
    const q = query(collectionRef, ...queryConstraints);
    return onSnapshot(q, callback);
}

function getCollectionReference(path) {
    return collection(firestore, ...path.split("/"));
}

function getCollectionSnapshot(path, queryConstraints = []) {
    const collectionRef = getCollectionReference(path);
    const q = query(
        collectionRef,
        ...queryConstraints.map(v => {
            if (Array.isArray(v)) {
                return query(collectionRef, ...v);
            } else {
                return v;
            }
        }),
    );
    return getDocs(q);
}

async function getCollectionData(path, queryConstraints = []) {
    const docSnap = await getCollectionSnapshot(path, queryConstraints);
    return docSnap.docs.map(doc => doc.data());
}

async function getCollectionGroupData(collectionId, queryConstraints = []) {
    const q = query(
        collectionGroup(firestore, collectionId),
        ...queryConstraints,
    );
    const docSnap = await getDocs(q);
    return docSnap.docs.map(doc => doc.data());
}

function onCollectionGroupSnapshot(
    collectionId,
    queryConstraints = [],
    callback,
) {
    const q = query(
        collectionGroup(firestore, collectionId),
        ...queryConstraints,
    );
    return onSnapshot(q, callback);
}

async function updateDocument(path, data) {
    const currentUser = await getCurrentUser();
    const docRef = getDocumentReference(path);
    return updateDoc(docRef, {
        ...data,
        updatedTs: Timestamp.now(),
        updatedAt: new Date().toISOString(),
        updatedBy: currentUser,
    });
}

async function createDocument(path, data) {
    const currentUser = await getCurrentUser();
    const collectionRef = getCollectionReference(path);
    const docRef = data.id
        ? await doc(collectionRef, data.id)
        : await doc(collectionRef);
    data.id = docRef.id;
    data.createdTs = Timestamp.now();
    data.createdAt = new Date().toISOString();
    data.createdBy = getCleanData(currentUser);
    await setDoc(docRef, data);
    return data;
}

function deleteDocument(path) {
    const docRef = getDocumentReference(path);
    return deleteDoc(docRef);
}

async function callFunction(name, request) {
    const currentUser = await getCurrentUser();
    const functionRef = httpsCallable(functions, name);
    return functionRef({
        ...request,
        user: currentUser,
        triggerUser: currentUser,
    });
}

async function calHttplFunction(name) {
    const url = `https://us-central1-${app.options.projectId}.cloudfunctions.net/${name}`;
    return fetch(url);
}

function getCleanData(data) {
    return JSON.parse(JSON.stringify(data));
}

export {
    Timestamp,
    calHttplFunction,
    callFunction,
    createDocument,
    deleteDocument,
    getCleanData,
    getCollectionData,
    getCollectionGroupData,
    getCollectionReference,
    getCollectionSnapshot,
    getCurrentUser,
    getDocumentData,
    getDocumentReference,
    getDocumentSnapshot,
    onCollectionGroupSnapshot,
    onCollectionSnapshot,
    onDocumentSnapshot,
    orderBy,
    runTransaction,
    updateDocument,
    where,
    writeBatch,
};
