import React, { useContext } from "react";

import { faCircle, faFlag } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Visibility as ShowIcon, Edit as EditIcon } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import classNames from "classnames";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import { Link, useHistory } from "react-router-dom";

import textLabels from "../../../constants/mui-datatable-labels";
import { projectFilterType } from "../../../constants/project-filter-types";
import projectPhases from "../../../constants/project-phases";
import { AuthContext } from "../../../contexts";
import { useFetchProjectData } from "../../../hooks";
import { pendencyName } from "../../../lib/pendency-utils";
import {
    deadlineStatus,
    deadlineStatusColor,
    deadlineStatusOutput,
} from "../../../lib/status.utils";
import DatatableView from "../../commons/datatable-view";

export default function ProjectDatatable(props) {
    const { filterType } = props;
    const history = useHistory();
    const { user: currentUser } = useContext(AuthContext);
    const { projects, isLoading } = useFetchProjectData({
        filterType,
    });

    const renderDatatable = () => {
        const deadlineStatusIcon = project => {
            project.deadlineStatus = deadlineStatus(
                project.createdAt,
                project.deadlineDays,
            );

            return (
                <Tooltip
                    title={deadlineStatusOutput(project.deadlineStatus) || ""}
                >
                    <FontAwesomeIcon
                        className={classNames(
                            deadlineStatusColor(project.deadlineStatus),
                            "status-icon",
                        )}
                        icon={faCircle}
                    />
                </Tooltip>
            );
        };

        const getCustomerName = project => {
            const studyArea = project.studyArea ? project.studyArea.name : "";
            const customerName = project.customer
                ? project.customer.name
                : project.customerName;
            return studyArea ? `${customerName} - ${studyArea}` : customerName;
        };

        const getDateValue = date => {
            return date ? moment(new Date(date)).format("DD/MM/YYYY") : "";
        };

        const data = projects.map(project => {
            if (!project.provisioning) project.provisioning = {};
            return {
                status: {
                    createdAt: project.createdAt,
                    deadlineDays: project.deadlineDays,
                    deadlineStatus: project.deadlineStatus,
                },
                number: project.number,
                customer: getCustomerName(project),
                study: project.study ? project.study.id : project.studyName,
                campaign: project.campaign ? project.campaign : "",
                phase: project.phase ? project.phase.name : project.phaseName,
                phases:
                    project.phases &&
                    project.phases.map(p => p.name).join(", "),
                pendency:
                    project.status === "Pendency"
                        ? pendencyName(project.pendencyType)
                        : false,
                assignee: project.assignee
                    ? project.assignee.name
                    : project.assigneeName,
                surveyor: project.surveyor
                    ? project.surveyor.name
                    : project.surveyorName,
                drafter: project.drafter
                    ? project.drafter.name
                    : project.drafterName,
                deadlineDate: project.deadlineDate,
                createdAt: project.createdAt,
                deliveryDate: project.deadlineDate
                    ? project.deadlineDate
                    : project.deadlineDateName,
                completedAt: project.completedAt,
                deliveryProtocol: project.deliveryProtocol,
                nextStudyProvisioning: project.nextStudyProvisioning,
                nextStudyStartAt: project.nextStudyStartAt,
                deliveryProtocolDate: project.deliveryProtocolDate,
                isCurrentUserAssigned:
                    project.assignee && project.assignee.id === currentUser.id,
                flag: project.status,
                id: project.id,
            };
        });

        const DEFAULT_COLUMNS = [
            {
                name: "status",
                label: "Status",
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return deadlineStatusIcon(value);
                    },
                },
            },
            {
                name: "number",
                label: "RACP",
                options: {
                    filter: true,
                    sort: true,
                },
            },
            {
                name: "customer",
                label: "Cliente",
                options: {
                    filter: true,
                    sort: true,
                },
            },
            {
                name: "study",
                label: "Estudo",
                options: {
                    filter: true,
                    sort: true,
                    filterType: "multiselect",
                },
            },
            {
                name: "phases",
                label: "Etapa",
                options: {
                    filter: true,
                    sort: true,
                    filterType: "multiselect",
                    filterOptions: {
                        names: projectPhases.map(pp => pp.name),
                        logic(phases, filterVal) {
                            let found = false;
                            if (phases) {
                                const options = phases.split(", ");
                                found = options.some(r =>
                                    filterVal.includes(r),
                                );
                            }
                            return !found;
                        },
                    },
                    // customBodyRender: (value, tableMeta, updateValue) => {
                    //     return (
                    //         value && (
                    //             <span className="label label-pendency label-margin-right">
                    //                 {value}
                    //             </span>
                    //         )
                    //     );
                    // },
                },
            },
            {
                name: "campaign",
                label: "Campanha",
                options: {
                    filter: true,
                    sort: true,
                },
            },
            {
                name: "pendency",
                label: "Pendência",
                options: {
                    filter: true,
                    sort: true,
                    filterType: "multiselect",
                    filterOptions: {
                        names: ["Com Pendência", "Sem Pendência"],
                        logic(pendency, filterVal) {
                            const show =
                                (filterVal.indexOf("Com Pendência") >= 0 &&
                                    pendency) ||
                                (filterVal.indexOf("Sem Pendência") >= 0 &&
                                    !pendency);
                            return !show;
                        },
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            value && (
                                <span className="label label-pendency label-margin-right">
                                    {value}
                                </span>
                            )
                        );
                    },
                },
            },
            {
                name: "surveyor",
                label: "Relatorista",
                options: {
                    filter: true,
                    sort: true,
                    filterType: "multiselect",
                },
            },
            {
                name: "drafter",
                label: "Desenhista",
                options: {
                    filter: true,
                    sort: true,
                    filterType: "multiselect",
                },
            },
            {
                name: "deliveryDate",
                label: "Data Entrega",
                options: {
                    filter: true,
                    sort: true,
                    customBodyRender: value => getDateValue(value),
                },
            },
            {
                name: "createdAt",
                label: "Data Criação",
                options: {
                    filter: true,
                    sort: true,
                    display: true,
                    customBodyRender: value => getDateValue(value),
                },
            },
            {
                name: "isCurrentUserAssigned",
                label: "Minha Atividade",
                options: {
                    display: false,
                    filter: true,
                    sort: true,
                    filterType: "multiselect",
                    filterOptions: {
                        names: ["Minhas Atividades", "Não sou responsável"],
                        logic(value, filterVal) {
                            const show =
                                (filterVal.indexOf("Minhas Atividades") >= 0 &&
                                    value) ||
                                (filterVal.indexOf("Não sou responsável") >=
                                    0 &&
                                    !value);
                            return !show;
                        },
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            value && (
                                <FontAwesomeIcon
                                    className={classNames(
                                        "status-icon",
                                        "assigned-flag",
                                    )}
                                    icon={faFlag}
                                />
                            )
                        );
                    },
                },
            },
        ];

        const HISTORY_COLUMNS = [
            {
                name: "id",
                label: "Id",
                options: {
                    filter: true,
                    sort: true,
                    display: false,
                },
            },
            {
                name: "number",
                label: "RACP",
                options: {
                    filter: true,
                    sort: true,
                    display: false,
                },
            },
            {
                name: "customer",
                label: "Cliente",
                options: {
                    filter: true,
                    sort: true,
                },
            },
            {
                name: "study",
                label: "Estudo",
                options: {
                    filter: true,
                    sort: true,
                    filterType: "multiselect",
                },
            },
            {
                name: "campaign",
                label: "Campanha",
                options: {
                    filter: true,
                    sort: true,
                },
            },
            {
                name: "surveyor",
                label: "Técnico responsável",
                options: {
                    filter: true,
                    sort: true,
                    filterType: "multiselect",
                },
            },
            {
                name: "deadlineDate",
                label: "Previsão de entrega",
                options: {
                    filter: true,
                    sort: true,
                    customBodyRender: value => getDateValue(value),
                },
            },
            {
                name: "completedAt",
                label: "Data finalização",
                options: {
                    filter: true,
                    sort: true,
                    customBodyRender: value => getDateValue(value),
                },
            },
            {
                name: "deliveryProtocol",
                label: "Protocolo",
                options: {
                    filter: true,
                    sort: true,
                },
            },
            {
                name: "nextStudyProvisioning",
                label: "Projeção proximo estudo",
                options: {
                    filter: true,
                    sort: true,
                },
            },
            {
                name: "nextStudyStartAt",
                label: "Data projeção proximo estudo",
                options: {
                    filter: true,
                    sort: true,
                    customBodyRender: value => getDateValue(value),
                },
            },
            {
                name: "deliveryProtocolDate",
                label: "Upload Raízen",
                options: {
                    filter: true,
                    sort: true,
                    customBodyRender: value => getDateValue(value),
                },
            },
            {
                name: "",
                options: {
                    filter: false,
                    sort: false,
                    empty: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <span className="datatable-icon-column">
                                <Link
                                    to={`/project-history/${tableMeta.rowData[0]}`}
                                >
                                    <IconButton
                                        size="small"
                                        aria-label="Visualizar"
                                    >
                                        <Tooltip title="Visualizar projeto">
                                            <ShowIcon />
                                        </Tooltip>
                                    </IconButton>
                                </Link>
                                <Link
                                    to={`/project-history/${tableMeta.rowData[0]}/edit`}
                                >
                                    <IconButton
                                        size="small"
                                        aria-label="Editar"
                                    >
                                        <Tooltip title="Editar provisionamento">
                                            <EditIcon />
                                        </Tooltip>
                                    </IconButton>
                                </Link>
                            </span>
                        );
                    },
                },
            },
        ];

        const columns =
            filterType === projectFilterType.HISTORY
                ? HISTORY_COLUMNS
                : DEFAULT_COLUMNS;

        const options = {
            selectableRows: "none",
            responsive: true,
            tableBodyHeight: "100%",
            tableBodyMaxHeight: "100%",
            filterType: "textField",
            elevation: 1,
            textLabels: textLabels,
            downloadOptions: {
                filename: "projetos.csv",
            },
            onDownload: (buildHead, buildBody, columns, data) => {
                columns = columns.map(column => {
                    column.name = column.label;
                    return column;
                });
                return "\uFEFF" + buildHead(columns) + buildBody(data);
            },
            onRowClick: (rowData, rowMeta) => {
                if (filterType !== projectFilterType.HISTORY)
                    history.push(`/projects/${projects[rowMeta.dataIndex].id}`);
            },
            setRowProps: row => {
                if (filterType !== projectFilterType.HISTORY) {
                    const isCurrentUserAssignedColIndex = 11;
                    return {
                        className: classNames({
                            "tablerow-currentuser-assigned":
                                row[isCurrentUserAssignedColIndex] !== false,
                        }),
                    };
                }
            },
        };

        let title = "Projetos";
        switch (filterType) {
            case projectFilterType.USER_GROUP:
                title = "Atividades da área";
                break;
            case projectFilterType.USER:
                title = "Minhas atividades";
                break;
            case projectFilterType.HISTORY:
                title = "Histórico de projetos";
                break;
            case projectFilterType.DRAFTER:
                title = "Meus Desenhos";
                break;
            case projectFilterType.SURVEYOR:
                title = "Meus Relatórios";
                break;
            default:
                title = "Projetos";
        }

        return (
            <DatatableView
                title={title}
                data={data}
                columns={columns}
                options={options}
            />
        );
    };

    const renderLoading = () => {
        const skeletonRows = () => {
            let rows = [];
            for (let i = 0; i < 20; i++) {
                rows.push(<Skeleton key={i} height={55} />);
            }
            return rows;
        };

        return skeletonRows();
    };

    return isLoading ? renderLoading() : renderDatatable();
}

ProjectDatatable.defaultProps = {
    filterType: projectFilterType.ALL,
};
