import React, { useEffect, useState } from "react";

import { Alert, createTheme, Skeleton } from "@mui/material";
import Stack from "@mui/material/Stack";
import { useHistory } from "react-router-dom";

import OrderFormEdit from "./OrderFormEdit";
import OrderFormView from "./OrderFormView";
import OrderStatus from "../../../constants/order-status";
import { useCancelOrder, useDeleteOrder, useSaveOrder } from "../../../hooks";
import { getNewOrder, getOrder } from "../../../services";
import { onDocumentSnapshot } from "../../../services/data-service";
import DetailView from "../../commons/detail-view";

export default function OrderForm({ orderId, dialog, ...props }) {
    const [isLoading, setLoading] = useState(props.viewMode !== ViewMode.NEW);
    const [viewMode, setViewMode] = useState(props.viewMode);
    const [order, setOrder] = useState({ ...props.order });
    const [listening, setListening] = useState({});
    const history = useHistory();

    const saveOrder = useSaveOrder();
    const deleteOrder = useDeleteOrder();
    const cancelOrder = useCancelOrder();

    const fetchOrder = () => {
        if (orderId) {
            setLoading(true);
            if (listening.unsubscribe) listening.unsubscribe();
            const unsubscribe = onDocumentSnapshot(
                `orders/${orderId}`,
                snapshot => {
                    setOrder(
                        snapshot.exists()
                            ? getOrder(snapshot.data())
                            : { notFound: true },
                    );
                    setLoading(false);
                },
            );
            setListening({ unsubscribe });
        } else {
            setOrder(getNewOrder());
        }
    };

    // fetch data
    useEffect(() => {
        if (order.id !== orderId && order.status?.id !== OrderStatus.DRAFT) {
            fetchOrder();
        } else {
            setLoading(false);
        }
    }, [orderId, order.id]);

    if (order.notFound) {
        return (
            <Stack sx={{ width: "100%" }} spacing={2} padding={4}>
                <Alert severity="error">
                    Não foi possivel encontrar a ordem de serviço{" "}
                    <b>{orderId}</b>
                </Alert>
            </Stack>
        );
    }

    if (isLoading) {
        return (
            <DetailView
                sx={{ ...(dialog && { padding: theme.spacing(2) }) }}
                headerTitle="Ordem de Serviço"
                subHeader1={<Skeleton width={280} />}
                // subHeader2={<Skeleton width={350} />}
                headerActions={<Skeleton width={300} />}
                content={<Skeleton variant="rectangular" height={600} />}
            />
        );
    }

    if (viewMode === ViewMode.DISPLAY) {
        return (
            <OrderFormView
                order={order}
                dialog={dialog}
                onEdit={() => {
                    setViewMode(ViewMode.EDIT);
                }}
                onCancel={() => {
                    setLoading(true);
                    cancelOrder(order)
                        .then(() => {
                            props.onSave && props.onSave();
                        })
                        .finally(() => setLoading(false));
                }}
            />
        );
    }

    return (
        <OrderFormEdit
            order={order}
            dialog={dialog}
            onChange={data => {
                setOrder(getOrder(data));
                props.onOrderChanged && props.onOrderChanged(data);
            }}
            onSave={() => {
                setLoading(true);
                saveOrder(order)
                    .then(data => {
                        if (viewMode === ViewMode.NEW) {
                            history.push(`/orders/${data.id}`);
                        }
                        setViewMode(ViewMode.DISPLAY);
                        props.onSave && props.onSave();
                    })
                    .finally(() => setLoading(false));
            }}
            onDelete={async () => {
                setLoading(true);
                listening.unsubscribe();
                deleteOrder(order)
                    .then(() => {
                        history.push(`/orders`);
                    })
                    .finally(() => setLoading(false));
            }}
            onCancel={() => {
                fetchOrder();
                setViewMode(ViewMode.DISPLAY);
            }}
        />
    );
}

const theme = createTheme();

export const ViewMode = {
    NEW: "NEW",
    EDIT: "EDIT",
    DISPLAY: "DISPLAY",
};
