import React, { Fragment, useContext, useEffect, useState } from "react";

import { Stack, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import { Box } from "@mui/system";

import { DataContext } from "../../../contexts";
import { fullTextFilter } from "../../projects/project-list/project-filter/ProjectFilter";

export default function ProjectRACPSelector({
    onSelected,
    label = "Nº RACP",
    variant = "standard",
    required = true,
    project,
    ...props
}) {
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState("");
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const { getFilteredProjects } = useContext(DataContext);

    useEffect(() => {
        let active = true;

        if (!value.length) {
            return undefined;
        }

        setLoading(true);
        if (active) {
            setOptions([...fullTextFilter(getFilteredProjects(), value)]);
        }
        setLoading(false);

        return () => {
            active = false;
        };
    }, [value]);

    useEffect(() => {
        if (!open) {
            setOptions([]);
            setLoading(false);
        }
    }, [open]);

    return (
        <Autocomplete
            {...props}
            value={project && project.id ? project : null}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(event, value) => onSelected && onSelected(value)}
            options={options}
            loading={loading}
            onInputChange={(event, value) => {
                setValue(value);
            }}
            getOptionLabel={option => option.number}
            renderOption={(props, option) => {
                const project = option ? option : {};
                const customer = { ...(project.customer && project.customer) };
                const study = { ...(project.study && project.study) };
                const studyArea = {
                    address: "",
                    city: "",
                    state: "",
                    ...(project.studyArea && project.studyArea),
                };
                return (
                    <Box {...props} key={option.id} component="li">
                        <Stack>
                            <Typography
                                sx={{ fontSize: 14 }}
                                // color="text.secondary"
                                gutterBottom
                            >
                                {project.number}
                            </Typography>
                            <Typography
                                variant="h5"
                                component="div"
                                gutterBottom
                            >
                                {customer.name}
                            </Typography>
                            <Typography color="text.secondary">
                                {study.name}
                            </Typography>
                            <Typography variant="body2">
                                {`${studyArea.address} - ${studyArea.city} - ${studyArea.state}`}
                            </Typography>
                        </Stack>
                    </Box>
                );
            }}
            renderInput={params => (
                <TextField
                    {...params}
                    variant={variant}
                    required={required}
                    label={label}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <Fragment>
                                {loading ? (
                                    <CircularProgress
                                        color="inherit"
                                        size={20}
                                    />
                                ) : null}
                                {params.InputProps.endAdornment}
                            </Fragment>
                        ),
                    }}
                />
            )}
        />
    );
}
