import React, { useState } from "react";

import ArrowDropIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Box, Divider, IconButton, Typography } from "@mui/material";
import { createTheme } from "@mui/material/styles";

export default function FormSection(props) {
    const [isCollapsed, setCollapsed] = useState(true);

    return (
        <Box
            sx={{
                paddingTop: theme.spacing(2),
                paddingBottom: theme.spacing(2),
                flexGrow: 1,
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    alingItems: "flex-end",
                    pb: theme.spacing(1),
                }}
            >
                <Typography
                    variant="h4"
                    component="div"
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        pr: theme.spacing(1),
                    }}
                >
                    {props.title}
                </Typography>
                <IconButton onClick={() => setCollapsed(!isCollapsed)}>
                    {isCollapsed ? <ArrowDropIcon /> : <ArrowRightIcon />}
                </IconButton>
            </Box>
            <Divider />
            <Box sx={{ pt: theme.spacing(2) }}>
                {isCollapsed && props.children}
            </Box>
        </Box>
    );
}

const theme = createTheme();
