import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { IconButton, TextField } from "@mui/material";

import ArrowDropIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

import classNames from "classnames";

const useStyles = makeStyles(theme => ({
    data: {
        marginTop: "0.5em",
        flex: 1,
        display: "flex",
        flexDirection: "column",
    },
    dataHeader: {
        flex: 1,
        display: "flex",
    },
    dataTitle: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
    dataContent: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
    },
    row: {
        display: "flex",
    },
    inputField: {
        "& > *": {
            marginLeft: 15,
            marginRight: 5,
            marginBottom: 10,
        },
    },
    zipCodeField: {
        "& > *": {
            marginLeft: 0,
        },
    },
    addressField: {
        "& > *": {
            marginLeft: 0,
        },
        width: 400,
    },
    numberField: {
        width: 100,
    },
    neighborhoodField: {
        "& > *": {
            marginLeft: 0,
        },
        width: 200,
    },
    cityField: {
        width: 184,
    },
    stateField: {
        width: 100,
    },
}));
const CustomerAddress = ({
    onInputChange,
    customer,
    fieldError,
    loadAddressByCep,
}) => {
    const classes = useStyles();
    const [isDisplayed, setIsDisplayed] = useState(true);

    const address = (
        <div className={classes.data}>
            <div className={classes.dataHeader}>
                <div className={classes.dataTitle}>
                    <h5>Endereço</h5>
                </div>
                <IconButton onClick={() => setIsDisplayed(!isDisplayed)}>
                    {isDisplayed ? <ArrowDropIcon /> : <ArrowRightIcon />}
                </IconButton>
            </div>
            <div
                className={classes.dataContent}
                style={{ display: isDisplayed ? "" : "none" }}
            >
                <div className={classes.row}>
                    <TextField
                        variant="standard"
                        className={classNames(
                            classes.zipCodeField,
                            classes.inputField
                        )}
                        label="CEP"
                        error={fieldError.zipCode ? true : false}
                        helperText={fieldError.zipCode}
                        value={customer.zipCode || ""}
                        onChange={onInputChange("zipCode")}
                        required={true}
                        onBlur={e => {
                            loadAddressByCep(e.target.value);
                        }}
                    />
                </div>
                <div className={classes.row}>
                    <TextField
                        variant="standard"
                        className={classNames(
                            classes.addressField,
                            classes.inputField
                        )}
                        label="Endereço"
                        value={customer.address || ""}
                        onChange={onInputChange("address")}
                        required={true}
                    />
                    <TextField
                        variant="standard"
                        className={classNames(
                            classes.inputField,
                            classes.numberField
                        )}
                        label="nº"
                        value={customer.number || ""}
                        onChange={onInputChange("number")}
                        required={true}
                    />
                </div>
                <div className={classes.row}>
                    <TextField
                        variant="standard"
                        className={classNames(
                            classes.neighborhoodField,
                            classes.inputField
                        )}
                        label="Bairro"
                        value={customer.neighborhood || ""}
                        onChange={onInputChange("neighborhood")}
                        required={true}
                    />
                    <TextField
                        variant="standard"
                        className={classNames(
                            classes.inputField,
                            classes.cityField
                        )}
                        label="Cidade"
                        value={customer.city || ""}
                        onChange={onInputChange("city")}
                        required={true}
                    />
                    <TextField
                        variant="standard"
                        className={classNames(
                            classes.inputField,
                            classes.stateField
                        )}
                        label="Estado"
                        value={customer.state || ""}
                        onChange={onInputChange("state")}
                        required={true}
                    />
                </div>
            </div>
        </div>
    );

    return address;
};

export default CustomerAddress;
