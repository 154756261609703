import ProjectStatus from "../../../../constants/project.status";
import sortBy from "../../../../lib/sort-by";
import { getCollectionData, where } from "../../../../services/data-service";

export const initialStudyData = {
    value: 0,
    label: "",
    deadline: 0,
    hasCampaign: false,
};
export const initialPaymentCondition = { value: 0, label: "" };

export const initialProjectData = {
    customer: null,
    study: null,
    studyArea: null,
    hasLetter: false,
    studies: [initialStudyData],
    paymentCondition: initialPaymentCondition,
};

export const paymentConditions = [
    { label: "À vista", value: 1 },
    { label: "Parcelado boleto", value: 2 },
    { label: "Parcelado depósito", value: 3 },
];

export const instalments = [
    { label: "1x", value: 1 },
    { label: "2x", value: 2 },
    { label: "3x", value: 3 },
    { label: "4x", value: 4 },
    { label: "5x", value: 5 },
    { label: "6x", value: 6 },
    { label: "7x", value: 7 },
    { label: "8x", value: 8 },
    { label: "9x", value: 9 },
    { label: "10x", value: 10 },
    { label: "11x", value: 11 },
    { label: "12x", value: 12 },
    { label: "13x", value: 13 },
    { label: "14x", value: 14 },
    { label: "15x", value: 15 },
    { label: "16x", value: 16 },
    { label: "17x", value: 17 },
    { label: "18x", value: 18 },
    { label: "19x", value: 19 },
    { label: "20x", value: 20 },
    { label: "21x", value: 21 },
    { label: "22x", value: 22 },
    { label: "23x", value: 23 },
    { label: "24x", value: 24 },
];

export async function fetchData(project) {
    // fetch studies
    const studiesData = await getCollectionData("studies");

    // fetch customers
    const customersData = await getCollectionData("customers");

    // fetch customer projects
    const customerProjects = [];
    if (project.customer) {
        customerProjects.push(
            await getCollectionData("projects", [
                where("customer.id", "==", project.customer.id),
                where("status", "==", ProjectStatus.COMPLETED),
            ]),
        );
    }

    // get current customer
    const selectedCustomer =
        customersData &&
        project.customer &&
        customersData.find(customer => customer.id === project.customer.id);

    // set customer latestCompleteProject
    if (selectedCustomer && customerProjects) {
        selectedCustomer.latestCompletedProject =
            customerProjects[0] && customerProjects[0].number;
    }

    // build list of customers
    const customers =
        customersData &&
        customersData
            .map(customer => ({
                value: customer.id,
                label: `${customer.name}`,
                latestCompletedProject:
                    customer.projects && customer.projects[0].number,
                raizenDeadlines: customer.raizenDeadlines,
                data: customer,
            }))
            .sort(sortBy("label"));

    // build list of studies
    const studies =
        studiesData &&
        studiesData
            .map(study => ({
                value: study.id,
                label: study.name,
                hasCampaign: study.hasCampaign,
                deadline:
                    selectedCustomer && selectedCustomer.raizenDeadlines
                        ? study.raizenDeadline
                            ? study.raizenDeadline
                            : study.deadline
                        : study.deadline,
                data: study,
            }))
            .sort(sortBy("label"));

    // build list of study areas
    let studyAreas = [];
    if (selectedCustomer) {
        studyAreas = await fetchStudyAreas(selectedCustomer.id);
        selectedCustomer.studyAreas = studyAreas;
    }

    return {
        customers,
        studies,
        studyAreas,
        selectedCustomer,
    };
}

export async function fetchStudyAreas(customerId) {
    const studyAreas = await getCollectionData(
        `customers/${customerId}/addresses`,
    );
    return studyAreas
        .filter(area => !area.inactive)
        .map(area => ({
            ...area,
            value: area.id,
            label:
                area.type === "default"
                    ? "Área de estudo é o próprio empreendimento"
                    : area.name,
            data: area,
        }))
        .sort(sortBy("label"));
}

export function getInitialProjectData(props) {
    const projectProperties = props.location.state || {};
    return {
        ...initialProjectData,
        ...projectProperties,
    };
}
