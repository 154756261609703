import { useContext } from "react";

import { useConfirm } from "material-ui-confirm";
import { Store as notifications } from "react-notifications-component";

import notificationOpts from "../constants/notification-options";
import notificationType from "../constants/notification-types";
import { AuthContext } from "../contexts";
import { cancelOrder } from "../services";

export default function useCancelOrder() {
    const confirm = useConfirm();
    const { user: currentUser } = useContext(AuthContext);
    return order =>
        confirm({
            title: "Confirmar o cancelamento",
            description: "Deseja cancelar este roteiro?",
            confirmationText: "Sim",
            cancellationText: "Não",
        }).then(() => {
            return cancelOrder(order, currentUser).catch(error => {
                console.error("Error canceling order", order, error);
                notifications.addNotification({
                    ...notificationOpts,
                    type: notificationType.ERROR,
                    message: `Não foi possivel cancelar, tente novamente`,
                });
            });
        });
}
