import React from "react";
import PropTypes from "prop-types";

import { withStyles } from '@mui/styles';

import NumberFormat from "react-number-format";

const styles = theme => ({});

class InputCurrency extends React.Component {
    static propTypes = {
        classes: PropTypes.object,
        inputRef: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired,
        thousandSeparator: PropTypes.string,
        decimalSeparator: PropTypes.string,
        prefix: PropTypes.string
    };

    static defaultProps = {
        thousandSeparator: ".",
        decimalSeparator: ",",
        prefix: "R$ "
    };

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true
        };
    }

    componentDidMount() {
        this.setState({
            isLoading: false
        });
    }

    render() {
        const {
            className,
            inputRef,
            onChange,
            onValueChange,
            thousandSeparator,
            decimalSeparator,
            prefix,
            ...other
        } = this.props;
        return (
            <NumberFormat
                className={className}
                {...other}
                getInputRef={inputRef}
                onValueChange={values => {
                    onChange({
                        type: "currency",
                        thousandSeparator,
                        decimalSeparator,
                        prefix,
                        values,
                        target: {
                            value: values.value
                        }
                    });
                }}
                isNumericString={false}
                decimalScale={2}
                fixedDecimalScale={true}
                thousandSeparator={thousandSeparator}
                decimalSeparator={decimalSeparator}
                prefix={prefix}
            />
        );
    }
}

export default withStyles(styles)(InputCurrency);
