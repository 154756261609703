import React, { useState } from "react";

import DownloadIcon from "@mui/icons-material/Download";
import {
    Breadcrumbs,
    Button,
    createTheme,
    IconButton,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import classNames from "classnames";
import Lightbox from "react-image-lightbox";
import { PhotoAlbum } from "react-photo-album";
import { NavLink } from "react-router-dom";

import "react-image-lightbox/style.css";
import {
    orderTaskStatusLabelStyle,
    orderTaskStatusName,
} from "../../../lib/status.utils";
import DetailView from "../../commons/detail-view";
import FormSection from "../../commons/form-section/FormSection";

const theme = createTheme();

export default function OrderTaskGeneric({ order, task, ...props }) {
    return (
        <DetailView
            headerTitle={<Header order={order} task={task} />}
            subHeader1={<SubHeader1 task={task} />}
            content={<Content order={order} task={task} />}
        />
    );
}

const Header = ({ order, task }) => {
    return (
        <Box>
            <Breadcrumbs aria-label="breadcrumb">
                <NavLink to="/orders">
                    <Typography color="text.secondary">
                        Ordens de Serviço
                    </Typography>
                </NavLink>
                <NavLink to={`/orders/${order.id}`}>
                    <Typography color="text.secondary">
                        {order.number}
                    </Typography>
                </NavLink>
                <Typography color="text.primary">{task.description}</Typography>
            </Breadcrumbs>
        </Box>
    );
};

const SubHeader1 = ({ task }) => {
    const { status } = task;
    return (
        <Box>
            <span>
                <span
                    style={{ fontSize: 12 }}
                    className={classNames(
                        "label",
                        orderTaskStatusLabelStyle(status),
                    )}
                >
                    {orderTaskStatusName(status)}
                </span>
            </span>
        </Box>
    );
};

const Content = ({ task }) => {
    return (
        <Box mt={2}>
            <TextField
                fullWidth
                multiline
                minRows={4}
                id="task-note"
                value={task.note}
                label="Observações"
                variant="standard"
            />

            <FormSection title="Imagens">
                <TaskImages task={task} />
            </FormSection>
        </Box>
    );
};

const TaskImages = ({ task }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    const images = task.getImages();
    const photos = images.map(img => {
        return {
            src: img.url,
            height: 74,
            width: 74,
        };
    });

    if (!images || !images.length) {
        return (
            <Box>
                <Typography variant="body1" gutterBottom>
                    Não há imagens para exibição
                </Typography>
            </Box>
        );
    }
    return (
        <Box>
            <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={1}
            >
                <Button variant="text">Fazer download</Button>
            </Stack>
            <PhotoAlbum
                photos={photos}
                layout="rows"
                targetRowHeight={150}
                onClick={({ index }) => {
                    setPhotoIndex(index);
                    setIsOpen(true);
                }}
            />
            {isOpen && (
                <Lightbox
                    mainSrc={images[photoIndex].url}
                    nextSrc={images[(photoIndex + 1) % images.length].url}
                    prevSrc={
                        images[(photoIndex + images.length - 1) % images.length]
                            .url
                    }
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() =>
                        setPhotoIndex(
                            (photoIndex + images.length - 1) % images.length,
                        )
                    }
                    onMoveNextRequest={() =>
                        setPhotoIndex((photoIndex + 1) % images.length)
                    }
                />
            )}
        </Box>
    );
};
