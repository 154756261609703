/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    TextField,
    DialogActions,
    Button,
    CircularProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";

import clone from "../../../../lib/clone";
import { callFunction } from "../../../../services/data-service";

const useStyles = makeStyles(() => ({
    loadingIndicator: {
        color: "green",
        marginLeft: "2px",
    },
    actionButton: {
        margin: "5px",
        fontFamily: "Roboto",
        fontSize: "14px",
        fontWeight: "normal",
        fontStyle: "normal",
        fontStretch: "normal",
        lineHeight: "1.14",
        letterSpacing: "normal",
        textAlign: "left",
        color: "#4192d9",
        textTransform: "none",
    },
    submitButton: {
        color: "green",
    },
    cancelButton: {
        color: "red",
    },
}));
const ProjectDeliveryProtocolDialog = ({
    dialogOpen,
    onCloseDialog,
    project,
    receivedDeliveryProtocol,
    receivedDeliveryProtocolDate,
    receivedCustomerType,
}) => {
    const classes = useStyles();
    const [deliveryProtocol, setDeliveryProtocol] = useState(
        receivedDeliveryProtocol,
    );
    const [deliveryProtocolDate, setDeliveryProtocolDate] = useState(
        receivedDeliveryProtocolDate,
    );
    const [open, setOpen] = useState(false);
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [customerType, setCustomerType] = useState(undefined);

    useEffect(() => {
        setOpen(dialogOpen);
    }, [dialogOpen]);

    useEffect(() => {
        setDeliveryProtocol(receivedDeliveryProtocol);
    }, [receivedDeliveryProtocol]);

    useEffect(() => {
        setDeliveryProtocolDate(receivedDeliveryProtocolDate);
    }, [receivedDeliveryProtocolDate]);

    useEffect(() => {
        setCustomerType(receivedCustomerType);
    }, [receivedCustomerType]);

    useEffect(() => {
        setError(false);
    }, [onCloseDialog]);

    const onSubmitDeliveryProtocol = async event => {
        event.preventDefault();
        setIsLoading(true);
        if (deliveryProtocolDate) {
            let updatedProject = clone(project);

            if (!(customerType === "raizen")) {
                const confirmedDeliveryProtocol = deliveryProtocol.trim();
                if (confirmedDeliveryProtocol) {
                    if (confirmedDeliveryProtocol.length <= 0) {
                        setError(true);
                        return;
                    } else {
                        updatedProject.deliveryProtocol =
                            confirmedDeliveryProtocol;
                    }
                }
            }
            setError(false);
            updatedProject.deliveryProtocolDate = deliveryProtocolDate;

            try {
                await callFunction("updateProjectDeliveryProtocol", {
                    projectId: updatedProject.id,
                    deliveryProtocol: updatedProject.deliveryProtocol,
                    deliveryProtocolDate: updatedProject.deliveryProtocolDate,
                });
            } catch (error) {
                console.error("Could not updateProjectDeliveryProtocol", error);
                setError(true);
            }
            setOpen(false);
            onCloseDialog();
        }
        setIsLoading(false);
    };
    return (
        <Dialog open={open} onClose={onCloseDialog}>
            <form onSubmit={onSubmitDeliveryProtocol}>
                <DialogTitle>
                    {customerType === "raizen"
                        ? "Upload Raízen"
                        : "Protocolo de entrega"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {customerType === "raizen"
                            ? "Digite a data de upload no portal da Raízen"
                            : "Digite o protocolo de entrega, caso o tenha"}
                    </DialogContentText>
                    {!(customerType === "raizen") ? (
                        <TextField
                            fullWidth
                            error={error}
                            required
                            label={"Nº do protocolo"}
                            onChange={event =>
                                setDeliveryProtocol(event.target.value)
                            }
                            value={deliveryProtocol}
                        />
                    ) : null}
                    <TextField
                        type="date"
                        value={deliveryProtocolDate}
                        onChange={event =>
                            setDeliveryProtocolDate(event.target.value)
                        }
                        label="Data de envio"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        required
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        type="submit"
                        disabled={isLoading}
                        className={classNames(
                            classes.actionButton,
                            classes.submitButton,
                        )}
                    >
                        Cadastrar
                        {isLoading && (
                            <CircularProgress
                                size={18}
                                thickness={4}
                                className={classes.loadingIndicator}
                            />
                        )}
                    </Button>
                    <Button
                        onClick={onCloseDialog}
                        disabled={isLoading}
                        className={classNames(
                            classes.actionButton,
                            classes.cancelButton,
                        )}
                    >
                        Cancelar
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default ProjectDeliveryProtocolDialog;
