const defaultLanguage = "pt-BR";
const messages = {
    "auth/weak-password": {
        "pt-BR": "A senha deve conter pelo menos 6 letras",
    },
    "auth/email-already-in-use": {
        "pt-BR": "O e-mail informado já esta sendo utilizado por outro usuário",
    },
    "auth/invalid-email": {
        "pt-BR": "Endereço de e-mail invalido",
    },
    "auth/requires-recent-login": {
        "pt-BR":
            "Faça login novamente antes de tentar novamente esta solicitação.",
    },
    "auth/wrong-password": {
        "pt-BR": "E-mail ou senha inválido",
    },
    "auth/user-not-found": {
        "pt-BR": "Login inválido",
    },
    "auth/user-disabled": {
        "pt-BR": "Usuário bloqueado",
    },
    "auth/network-request-failed": {
        "pt-BR": "Falha de conexão",
    },
};

export default function getErrorByCode(code, language = defaultLanguage) {
    return messages[code][language];
}

export function getErrorMessage(error, language = defaultLanguage) {
    const { code, message } = error;

    if (messages[code] && messages[code][language]) {
        return messages[code][language];
    } else {
        return message;
    }
}
