import React, { useState, useEffect } from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import {
    Box,
    Grow,
    IconButton,
    Tooltip,
    CircularProgress,
    Snackbar,
    Alert,
    styled,
    ClickAwayListener,
    Skeleton,
} from "@mui/material";

import { theme } from "../../layout";

const CustomIconButton = styled(IconButton)(({ theme }) => ({
    "&:hover": {
        backgroundColor: theme.palette.action.hover,
    },
    "&.Mui-disabled": {
        opacity: 0.5,
        color: theme.palette.action.disabled,
    },
}));

const HoverableBox = styled(props => {
    const { ...other } = props;
    return <Box {...other} />;
})(({ theme, readonly }) => ({
    ...(!readonly && {
        transition: "background-color 300ms, transform 300ms",
        "&:hover": {
            backgroundColor: theme.palette.action.hover,
            transform: "scale(1.02)",
        },
    }),
}));

export default function DynamicInlineEditor({
    displayComponent,
    renderInput,
    onSave,
    onCancel,
    onEdit,
    initialValue,
    readonly,
    loading,
    ...props
}) {
    const [isEditing, setIsEditing] = useState(false);
    const [value, setValue] = useState(initialValue);
    const [isLoading, setIsLoading] = useState(loading);
    const [error, setError] = useState("");

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    useEffect(() => {
        setIsLoading(loading ? true : false);
    }, [loading]);

    const handleEdit = () => {
        if (onEdit) {
            onEdit();
        }
        setIsEditing(true);
    };

    const handleChange = newValue => {
        setValue(newValue);
    };

    const handleSave = async () => {
        setIsLoading(true);
        setError("");
        try {
            await onSave(value);
            setIsEditing(false);
        } catch (error) {
            setError("Não foi possivel alterar o dado: " + error.message);
            console.error("Save operation failed:", error);
        }
        // setIsLoading(false);
    };

    const handleCancel = () => {
        if (onCancel) {
            onCancel();
        }
        setValue(initialValue); // Reset to initial value on cancel
        if (!isLoading) {
            setIsEditing(false);
        }
    };

    const handleClickAway = () => {
        if (isEditing && !isLoading) {
            handleCancel();
        }
    };

    const renderDisplay = () => {
        if (displayComponent) {
            return (
                <HoverableBox onClick={handleEdit} readonly={readonly}>
                    {displayComponent}
                </HoverableBox>
            );
        } else {
            return (
                <HoverableBox onClick={handleEdit} readonly={readonly}>
                    <Box
                        sx={{
                            padding: theme.spacing(1),
                        }}
                    />
                </HoverableBox>
            );
        }
    };

    if (isLoading) {
        return (
            <ClickAwayListener onClickAway={handleClickAway}>
                <Box {...props}>
                    <Skeleton />
                </Box>
            </ClickAwayListener>
        );
    }

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <Box {...props}>
                {!isEditing || readonly ? (
                    renderDisplay()
                ) : (
                    <Grow in={isEditing}>
                        <Box>
                            {renderInput(value, handleChange)}
                            <Box display="flex" justifyContent="flex-end">
                                <Tooltip title="Save">
                                    <CustomIconButton
                                        color="primary"
                                        onClick={handleSave}
                                        aria-label="save"
                                        size="small"
                                        disabled={isLoading}
                                    >
                                        {isLoading ? (
                                            <CircularProgress size={24} />
                                        ) : (
                                            <SaveIcon size="inherit" />
                                        )}
                                    </CustomIconButton>
                                </Tooltip>
                                <Tooltip title="Cancel">
                                    <CustomIconButton
                                        color="secondary"
                                        onClick={handleCancel}
                                        aria-label="cancel"
                                        size="small"
                                        disabled={isLoading}
                                    >
                                        <CancelIcon size="inherit" />
                                    </CustomIconButton>
                                </Tooltip>
                            </Box>
                        </Box>
                    </Grow>
                )}
                {error && (
                    <Snackbar
                        open={!!error}
                        autoHideDuration={6000}
                        onClose={() => setError("")}
                    >
                        <Alert
                            onClose={() => setError("")}
                            severity="error"
                            sx={{ width: "100%" }}
                        >
                            {error}
                        </Alert>
                    </Snackbar>
                )}
            </Box>
        </ClickAwayListener>
    );
}
