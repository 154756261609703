const firebaseConfig = {
    apiKey: "AIzaSyAmhNjeD8ssQi-NdX2m9KalRELgkUn-Jf0",
    authDomain: "diatech-development.firebaseapp.com",
    databaseURL: "https://diatech-development.firebaseio.com",
    projectId: "diatech-development",
    storageBucket: "diatech-development.appspot.com",
    messagingSenderId: "504516593591",
    appId: "1:504516593591:web:888623cbd676cd650f4a32",
    measurementId: "G-H4132GW660",
};

export default firebaseConfig;
