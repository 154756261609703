import React, { useState, useContext } from "react";

import ArrowDropIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { IconButton, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";

import UserRoles from "../../../../../constants/user-roles";
import { AuthContext } from "../../../../../contexts/auth.context";
import { phoneFormatter, cpfFormatter } from "../../../../../lib/form-utils";

const useStyles = makeStyles(theme => ({
    data: {
        marginTop: "0.5em",
        flex: 1,
        display: "flex",
        flexDirection: "column",
    },
    dataHeader: {
        flex: 1,
        display: "flex",
    },
    dataTitle: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
    dataContent: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
    },
    row: {
        display: "flex",
    },
    inputField: {
        "& > *": {
            marginLeft: 15,
            marginRight: 5,
            marginBottom: 10,
        },
    },
    legalResponsiblefield: {
        "& > *": {
            marginLeft: 0,
        },
        width: 300,
    },
    cpfField: {
        width: 200,
    },
    phoneField: {
        "& > *": {
            marginLeft: 0,
        },
        width: 200,
    },
    emailField: {
        width: 300,
    },
}));

const FinancialOfficerInfo = ({ customer, onInputChange }) => {
    const { hasRole } = useContext(AuthContext);
    const classes = useStyles();

    const [isDisplayed, setIsDisplayed] = useState(true);

    const info = (
        <div className={classes.data}>
            <div className={classes.dataHeader}>
                <div className={classes.dataTitle}>
                    <h5>Responsável Financeiro</h5>
                </div>
                <IconButton onClick={() => setIsDisplayed(!isDisplayed)}>
                    {isDisplayed ? <ArrowDropIcon /> : <ArrowRightIcon />}
                </IconButton>
            </div>
            <div
                className={classes.dataContent}
                style={{ display: isDisplayed ? "" : "none" }}
            >
                <div className={classes.row}>
                    <TextField
                        variant="standard"
                        className={classNames(
                            classes.inputField,
                            classes.legalResponsiblefield,
                        )}
                        label="Responsável Financeiro"
                        value={customer.financialResponsibleName || ""}
                        onChange={onInputChange("financialResponsibleName")}
                    />
                    <TextField
                        variant="standard"
                        className={classNames(
                            classes.inputField,
                            classes.cpfField,
                        )}
                        label="CPF"
                        value={
                            cpfFormatter(customer.financialResponsibleCpf) || ""
                        }
                        onChange={onInputChange("financialResponsibleCpf")}
                    />
                </div>
                <div className={classes.row}>
                    <TextField
                        variant="standard"
                        className={classNames(
                            classes.inputField,
                            classes.phoneField,
                        )}
                        label="Telefone"
                        value={
                            phoneFormatter(
                                customer.financialResponsiblePhone,
                            ) || ""
                        }
                        onChange={onInputChange("financialResponsiblePhone")}
                    />
                    <TextField
                        variant="standard"
                        className={classNames(
                            classes.inputField,
                            classes.emailField,
                        )}
                        label="E-mail"
                        type="email"
                        value={customer.financialResponsibleEmail || ""}
                        onChange={onInputChange("financialResponsibleEmail")}
                    />
                </div>
            </div>
        </div>
    );

    return hasRole(UserRoles.CONTROLE_FINANCEIRO) && info;
};

export default FinancialOfficerInfo;
