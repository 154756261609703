import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getPerformance } from "firebase/performance";

import developmentConfig from "./firebaseConfig.development";
import productionConfig from "./firebaseConfig.production";
import stagingConfig from "./firebaseConfig.staging";

const getFirebaseConfig = () => {
    switch (process.env.REACT_APP_ENV) {
        case "production":
            return productionConfig;
        case "staging":
            return stagingConfig;

        default:
            return developmentConfig;
    }
};

// Initialize Firebase
const app = initializeApp(getFirebaseConfig());
const auth = getAuth();
const analytics = getAnalytics(app);
const perf = getPerformance(app);
const firestore = getFirestore(app);
const functions = getFunctions(app);
const currentUser = auth.currentUser;

export { app, firestore, functions, currentUser, auth, perf, analytics };
