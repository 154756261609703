import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";

import "./Graphs.css";

class Graphs extends Component {
    static propTypes = {
        classes: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
        };
    }

    render() {
        return (
            <Redirect
                to={{
                    pathname: "/projects",
                }}
            />
        );
    }
}

export default Graphs;
