import React, { useEffect, useState } from "react";

import { Autocomplete, Box, Stack, TextField, Typography } from "@mui/material";

import { getCollectionData } from "../../../services/data-service";

export default function StudyAreaSelector({
    customer,
    studyArea,
    onSelected,
    label = "Área de Estudo",
    required = true,
    variant = "standard",
    ...props
}) {
    const [isLoading, setIsLoading] = useState(true);
    const [studyAreas, setStudyAreas] = useState([]);

    useEffect(() => {
        if (customer) {
            // setAddress(null);
            getCollectionData(`customers/${customer.id}/addresses`)
                .then(data => {
                    setStudyAreas([...data]);
                    onSelected(null);
                    let item;
                    if (studyArea) {
                        item = data.find(d => d.id === studyArea.id);
                    } else if (data.length === 1) {
                        item = data[0];
                    }
                    if (item) {
                        onSelected && onSelected(item);
                    }
                })
                .finally(() => setIsLoading(false));
        } else {
            setStudyAreas([]);
        }
    }, [customer]);

    return (
        <Box spacing={1}>
            <Autocomplete
                {...props}
                value={studyArea && studyArea.id ? studyArea : null}
                onChange={(event, value) => onSelected && onSelected(value)}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={option => option.name}
                renderOption={(props, option) => {
                    return (
                        <Box {...props} key={option.id} component="li">
                            <Stack>
                                <Typography
                                    variant="h5"
                                    component="div"
                                    gutterBottom
                                >
                                    {option.name}
                                </Typography>
                                <Typography
                                    sx={{ fontSize: 14 }}
                                    color="text.secondary"
                                    gutterBottom
                                >
                                    {option.address}
                                </Typography>
                                <Typography
                                    sx={{ fontSize: 14 }}
                                    color="text.secondary"
                                    gutterBottom
                                >
                                    {option.neighborhood} - {option.city} -{" "}
                                    {option.state} - {option.cep}
                                </Typography>
                            </Stack>
                        </Box>
                    );
                }}
                options={studyAreas}
                disabled={!customer}
                loading={isLoading}
                renderInput={params => (
                    <TextField
                        {...params}
                        variant={variant}
                        label={label}
                        required={required}
                    />
                )}
            />
            {studyArea && studyArea.id && (
                <p>{`${studyArea.address} - ${studyArea.city} - ${studyArea.state} - ${studyArea.cep}`}</p>
            )}
        </Box>
    );
}
