import OrderActions from "../constants/order-actions";
import OrderStatus from "../constants/order-status";
import { isObject } from "../lib/object-utils";
import OrderStatusModel from "../models/order-status-model";

export function getOrderStatus(status) {
    if (isObject(status)) return getOrderStatus(status.id);
    switch (status) {
        case OrderStatus.DRAFT:
            return new OrderStatusModel({
                id: OrderStatus.DRAFT,
                name: "Rascunho",
                color: "status.draft",
                actions: [],
            });

        case OrderStatus.NEW:
            return new OrderStatusModel({
                id: OrderStatus.NEW,
                name: "Nova",
                color: "status.new",
                actions: [OrderActions.RELEASE, OrderActions.CANCEL],
            });

        case OrderStatus.RELEASED:
            return new OrderStatusModel({
                id: OrderStatus.RELEASED,
                name: "Liberada",
                color: "status.released",
                actions: [OrderActions.START_WORK, OrderActions.CANCEL],
            });

        case OrderStatus.IN_PROGRESS:
            return new OrderStatusModel({
                id: OrderStatus.IN_PROGRESS,
                name: "Em Andamento",
                color: "status.inProgress",
                actions: [
                    OrderActions.COMPLETE_WORK,
                    OrderActions.SUSPEND_WORK,
                    OrderActions.CANCEL,
                ],
            });

        case OrderStatus.WORK_COMPLETED:
            return new OrderStatusModel({
                id: OrderStatus.WORK_COMPLETED,
                name: "Finalizada",
                color: "status.workCompleted",
                actions: [
                    OrderActions.SEND_TO_VERIFICATION,
                    OrderActions.RELEASE_BACK,
                    OrderActions.CANCEL,
                ],
            });

        case OrderStatus.ON_HOLD:
            return new OrderStatusModel({
                id: OrderStatus.ON_HOLD,
                name: "Em Espera",
                color: "status.onHold",
                actions: [OrderActions.RELEASE, OrderActions.CANCEL],
            });

        case OrderStatus.VERIFICATION:
            return new OrderStatusModel({
                id: OrderStatus.VERIFICATION,
                name: "Conferência",
                color: "status.verification",
                actions: [OrderActions.RELEASE_TO_LAUNCH, OrderActions.CANCEL],
            });

        case OrderStatus.LAUNCH:
            return new OrderStatusModel({
                id: OrderStatus.LAUNCH,
                name: "Lançamento",
                color: "status.launch",
                actions: [OrderActions.COMPLETE, OrderActions.CANCEL],
            });

        case OrderStatus.COMPLETED:
            return new OrderStatusModel({
                id: OrderStatus.COMPLETED,
                name: "Concluída",
                color: "status.completed",
                actions: [OrderActions.COPY],
            });

        case OrderStatus.CANCELLED:
            return new OrderStatusModel({
                id: OrderStatus.CANCELLED,
                name: "Cancelada",
                color: "status.cancelled",
                actions: [OrderActions.COPY],
            });

        default:
            return new OrderStatusModel({
                id: OrderStatus.UNKNOWN,
                name: "Desconhecido",
                label: "label-red",
                color: "error",
            });
    }
}

export function getAllOrderStatus() {
    return Object.values(OrderStatus).map(u => getOrderStatus(u));
}

export function getAllOrderStatuslKeys() {
    return Object.values(OrderStatus);
}
