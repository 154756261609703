import React from "react";

import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";

export default function SearchTextField(props) {
    return (
        <TextField
            {...props}
            variant="standard"
            value={props.value}
            onChange={props.onChange}
            placeholder="Search…"
            InputProps={{
                startAdornment: <SearchIcon fontSize="small" />,
                endAdornment: (
                    <IconButton
                        title="Clear"
                        aria-label="Clear"
                        size="small"
                        style={{
                            visibility: props.value ? "visible" : "hidden",
                        }}
                        onClick={props.clearSearch}
                    >
                        <ClearIcon fontSize="small" />
                    </IconButton>
                ),
            }}
            sx={{
                // width: {
                //     xs: 1,
                //     sm: "auto",
                // },
                ...props.sx,
                m: theme => theme.spacing(1, 0.5, 1.5),
                "& .MuiSvgIcon-root": {
                    mr: 0.5,
                },
                "& .MuiInput-underline:before": {
                    borderBottom: 1,
                    borderColor: "divider",
                },
            }}
        />
    );
}
