import { useEffect, useState } from "react";

import { getOrder } from "../services";
import {
    onCollectionSnapshot,
    orderBy,
    Timestamp,
    where,
} from "../services/data-service";

export default function useListenOrders(filters) {
    const [orders, setOrders] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [listener, setListener] = useState({});
    const { period = {}, status } = filters;
    const [filter, setFilter] = useState({ period, status });
    const { startDate, endDate } = period;

    useEffect(() => {
        if (filter === filters) {
            return;
        } else {
            setFilter({ filters });
        }

        if (listener.unsubscribe) listener.unsubscribe();

        const query = [orderBy("date", "asc")];
        if (startDate)
            query.push(where("date", ">=", Timestamp.fromMillis(startDate)));
        if (endDate)
            query.push(where("date", "<=", Timestamp.fromMillis(endDate)));
        if (status && status.length)
            query.push(where("status", "in", [...status]));

        const unsubscribe = onCollectionSnapshot({
            path: "orders",
            queryConstraints: query,
            callback: snapshot => {
                if (snapshot.empty) {
                    setOrders([]);
                } else {
                    setOrders([
                        ...snapshot.docs
                            .map(doc => doc.data())
                            .map(order => getOrder(order)),
                    ]);
                }
                setIsLoading(false);
            },
        });
        setListener({ unsubscribe });
        // }
    }, [startDate, endDate, status]);

    return {
        isLoading,
        orders,
        unsubscribe: listener.unsubscribe,
    };
}
