/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";

import { faPlusSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ArrowDropIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton, Link, Icon, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

import {
    onCollectionSnapshot,
    updateDocument,
} from "../../../../../services/data-service";

const useStyles = makeStyles(() => ({
    data: {
        marginTop: "1em",
        flex: 1,
        display: "flex",
        flexDirection: "column",
    },
    dataHeader: {
        flex: 1,
        display: "flex",
    },
    dataTitle: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
    dataContent: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        paddingLeft: 20,
    },
    addStudyLabel: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
    addStudyAreaButton: {
        marginLeft: "1em",
        color: "#4192d9",
        fontFamily: "Roboto",
        fontSize: "14px",
        fontWeight: "normal",
        fontStyle: "normal",
        fontStretch: "normal",
        lineHeight: "1.14",
        letterSpacing: "normal",
        textAlign: "left",
    },
    addStudyAreaIcon: {
        boxSizing: "initial",
        paddingRight: "16px",
        color: "#4192d9",
    },
    studyArea: {
        width: 380,
        display: "flex",
    },
    studyAreaName: {
        textDecoration: "underline",
        "&:hover": {
            cursor: "pointer",
        },
    },
    studyAreaInfo: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
    deleteIcon: {
        color: "var(--red)",
    },
}));

const StudyArea = ({
    handleNewStudyAreaDialogOpen,
    receivedCustomer,
    handleSelectStudyArea,
}) => {
    const classes = useStyles();

    const [isDisplayed, setIsDisplayed] = useState(true);
    const [studyAreas, setStudyAreas] = useState([]);

    useEffect(() => {
        fetchCustomerStudyAreas(receivedCustomer.id);
        setIsDisplayed(true);
    }, [receivedCustomer]);

    const fetchCustomerStudyAreas = async customerId => {
        onCollectionSnapshot({
            path: `customers/${customerId}/addresses`,
            callback: snapshot => {
                let areas = [];
                if (!snapshot.empty) {
                    areas = snapshot.docs.map(doc => doc.data());
                    areas = areas.filter(
                        area => !area.inactive && area.type !== "default",
                    );
                }
                setStudyAreas(areas);
            },
        });
    };

    const handleDeleteStudyArea = async area => {
        updateDocument(`customers/${area.customerId}/addresses/${area.id}`, {
            inactive: true,
        })
            .then(() => fetchCustomerStudyAreas(receivedCustomer.id))
            .catch(error => {
                console.error(error);
            });
    };

    const StudyArea = ({ area }) => {
        return (
            <div className={classes.studyArea}>
                <div className={classes.studyAreaInfo}>
                    <p>
                        <Box
                            component="span"
                            className={classes.studyAreaName}
                            onClick={() => {
                                handleSelectStudyArea(area);
                            }}
                        >
                            {area.name}
                        </Box>
                        {` - ${area.address}, ${area.number} - ${area.city}/${area.state}`}
                    </p>
                </div>
                <div className={classes.studyAreaIcon}>
                    <IconButton
                        onClick={handleDeleteStudyArea.bind(null, area)}
                    >
                        <DeleteIcon className={classes.deleteIcon} />
                    </IconButton>
                </div>
            </div>
        );
    };

    const info = (
        <div className={classes.data}>
            <div className={classes.dataHeader}>
                <div className={classes.dataTitle}>
                    <h5>Áreas de Estudo</h5>
                </div>
                <IconButton onClick={() => setIsDisplayed(!isDisplayed)}>
                    {isDisplayed ? <ArrowDropIcon /> : <ArrowRightIcon />}
                </IconButton>
                <div className={classes.addStudyLabel}>
                    <Link
                        className={classes.addStudyAreaButton}
                        onClick={handleNewStudyAreaDialogOpen}
                        component="button"
                        variant="body2"
                        type="button"
                        aria-label="Adicionar nova área de estudo"
                    >
                        <Icon fontSize="small">
                            <FontAwesomeIcon
                                className={classes.addStudyAreaIcon}
                                icon={faPlusSquare}
                            />
                        </Icon>
                        Acrescentar área de estudo
                    </Link>
                </div>
            </div>
            <div
                className={classes.dataContent}
                style={{ display: isDisplayed ? "" : "none" }}
            >
                {studyAreas.map((studyArea, index) => {
                    return <StudyArea key={index} area={studyArea} />;
                })}
            </div>
        </div>
    );
    return info;
};

export default StudyArea;
