/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from "react";

import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import DomainIcon from "@mui/icons-material/Domain";

import "./ReasonList.css";

import { makeStyles } from "@mui/styles";

import ListFilter from "../../commons/list-filter";
import { getReasonType, getReasonLabel } from "../../../constants/reason-types";
import { onCollectionGroupSnapshot } from "../../../services/data-service";

const useStyles = makeStyles(() => ({
    newReasonButton: {
        backgroundColor: "var(--dark-seafoam-green);",
        height: 60,
        fontFamily: "Roboto",
        fontSize: "20px",
        fontWeight: 500,
        fontStyle: "normal",
        fontStretch: "normal",
        lineHeight: "1.14",
        letterSpacing: "normal",
        textAlign: "center",
        color: "var(--white)",
        "&:hover": {
            backgroundColor: "var(--green)",
            borderColor: "#385cac",
        },
    },
}));

export default function ReasonList(props) {
    const classes = useStyles();
    const [reasons, setReasons] = useState([]);
    const [unfiltredReasons, setUnfiltredReasons] = useState([]);
    const [filter, setFilter] = useState("");
    const [isLoading, setIsLoading] = useState(true);

    const { onReasonSelected } = props;

    // fetch data
    useEffect(() => {
        onCollectionGroupSnapshot("reasons", [], snapshot => {
            if (snapshot.empty) {
                setUnfiltredReasons([]);
                setReasons([]);
            } else {
                setUnfiltredReasons([
                    ...snapshot.docs
                        .map(doc => doc.data())
                        .filter(reason => !reason.inactive)
                        .sort((a, b) => a.name.localeCompare(b.name)),
                ]);
            }
            setIsLoading(false);
        });
    }, []);

    useEffect(() => {
        filterReasons();
    }, [unfiltredReasons, filter]);

    const filterReasons = () => {
        const toUpper = value => {
            return value ? value.toString().toUpperCase() : value;
        };

        const normalize = value => {
            return value
                ? value.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                : value;
        };

        const toFilter = value => {
            value = toUpper(value);
            value = normalize(value);
            return value;
        };

        const filtredReasons = filter
            ? unfiltredReasons.filter(
                  c =>
                      toFilter(c.id).includes(toFilter(filter)) ||
                      toFilter(c.name).includes(toFilter(filter)) ||
                      c.type === getReasonType(filter),
              )
            : unfiltredReasons;

        setReasons([...filtredReasons]);
    };

    const handleFilterChange = value => {
        setFilter(value);
    };

    const onNewReason = () => {
        if (onReasonSelected) onReasonSelected({});
    };

    const onSelectReason = reason => {
        if (reason && onReasonSelected) onReasonSelected(reason);
    };

    return (
        <div className="created-reasons-form">
            <div className={"content"}>
                <ListFilter
                    placeholder="Buscar por nome, tipo"
                    onFilterChange={handleFilterChange}
                />
                <List className={classes.root}>
                    {reasons.map(reason => (
                        <ListItem
                            key={reason.id}
                            button
                            onClick={() => {
                                onSelectReason(reason);
                            }}
                        >
                            <ListItemIcon>
                                <DomainIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={reason.name}
                                secondary={getReasonLabel(reason.type)}
                            />
                        </ListItem>
                    ))}
                </List>
            </div>
            <div className="new-project">
                <Button
                    variant="contained"
                    className={classes.newReasonButton}
                    onClick={onNewReason}
                    disabled={isLoading}
                >
                    Novo Motivo
                </Button>
            </div>
        </div>
    );
}
