/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext } from "react";

import {
    faCalendarAlt,
    faUser,
    faBell,
    faQuestionCircle,
} from "@fortawesome/free-regular-svg-icons";
import {
    faBars,
    faFileAlt,
    faPlus,
    faHistory,
    faRoad,
    faTasks,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { NavLink } from "react-router-dom";

import "./SidebarMenu.css";

import config from "../../configs/app.config";
import { AuthContext, DataContext } from "../../contexts";
import { NotificationContext } from "../../contexts/notification.context";
import logo from "./logo.png";
import SidebarItem from "./sidebar-item";

const classNames = require("classnames");

export default function SidebarMenu(props) {
    const { user: currentUser, logout, hasAnyRole } = useContext(AuthContext);
    const { counter } = useContext(DataContext);
    const { unread: unreadNotifications } = useContext(NotificationContext);

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        logout();
    };

    const handleProfile = () => {
        props.history.push("/users/profile");
        handleMenuClose();
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const iconClasses = classNames({
        "button-icon": true,
    });

    const items = [
        {
            text: "Provisionamentos",
            icon: faCalendarAlt,
            navTo: "/provisionings",
            counter: counter.inProvisioning,
            authRoles: ["CDP"],
        },
        {
            text: "Projetos",
            icon: faBars,
            navTo: "/projects",
            counter: counter.inProgress,
            expanded: true,
            subitems: [
                {
                    text: "Relatórios",
                    navTo: "/projects/relatorios",
                    counter: counter.isSurveyor,
                    authRoles: ["REL"],
                },
                {
                    text: "Desenhos",
                    navTo: "/projects/desenhos",
                    counter: counter.isDrafter,
                    authRoles: ["DES"],
                },
            ],
        },
        {
            text: "Atividades",
            icon: faTasks,
            navTo: "/tasks",
            counter: counter.isReponsible || "0",
            counterType: "alert",
        },
        {
            text: "Roteiros",
            icon: faRoad,
            navTo: "/planning",
        },
        {
            text: "Ordens de Serviço",
            icon: faFileAlt,
            navTo: "/orders",
        },
        {
            text: "Histórico de projetos",
            icon: faHistory,
            navTo: "/project-history",
            counter: counter.inHistory,
        },
        {
            text: "Notificações",
            icon: faBell,
            navTo: "/notifications",
            counter: unreadNotifications.length,
            counterType: "alert",
        },
        {
            text: "Cadastros",
            icon: faPlus,
            navTo: "",
            authRoles: ["CDU", "CDC", "FIN", "CDP", "CDE", "CDJ", "CDW"],
            subitems: [
                {
                    text: "Cadastrar Projeto",
                    navTo: "/projects/new",
                    authRoles: ["CDP"],
                },
                {
                    text: "Cadastrar Poços",
                    navTo: "/wells/form",
                    authRoles: ["CDW"],
                },
                {
                    text: "Cadastrar Cliente",
                    navTo: "/customers",
                    authRoles: ["CDC"],
                },
                {
                    text: "Cadastrar Justificativa",
                    navTo: "/reasons",
                    authRoles: ["CDJ"],
                },
                {
                    text: "Cadastrar Usuário",
                    navTo: "/users",
                    authRoles: ["CDU"],
                },
            ],
        },
        {
            text: "Documentação",
            icon: faQuestionCircle,
            navTo: "/documentation",
        },
    ];

    return (
        <div className={"sidebar-container"}>
            <div className={"sidebar-header"}>
                <NavLink to="/" replace={true}>
                    <div className={"sidebar-logo"}>
                        <img src={logo} alt="logo" height="40px" width="40px" />
                        <div>
                            <h1>DTAS</h1>
                            <div className="app-version">
                                {config.appVersion}
                            </div>
                        </div>
                    </div>
                </NavLink>
            </div>
            <div className={"sidebar-content"}>
                {items.map((item, index) =>
                    item.authRoles ? (
                        hasAnyRole(item.authRoles) && (
                            <SidebarItem
                                key={index}
                                text={item.text}
                                icon={item.icon}
                                navTo={item.navTo}
                                counter={item.counter}
                                counterType={item.counterType}
                                subitems={item.subitems}
                                expanded={item.expanded}
                            />
                        )
                    ) : (
                        <SidebarItem
                            key={index}
                            text={item.text}
                            icon={item.icon}
                            navTo={item.navTo}
                            counter={item.counter}
                            counterType={item.counterType}
                            subitems={item.subitems}
                            expanded={item.expanded}
                        />
                    ),
                )}
            </div>
            <div className={"sidebar-footer"}>
                {currentUser && (
                    <div className={"button-selected"}>
                        <Button
                            fullWidth={true}
                            aria-owns={anchorEl ? "simple-menu" : undefined}
                            aria-haspopup="true"
                            onClick={handleClick}
                        >
                            <div className={"button-item"}>
                                <FontAwesomeIcon
                                    className={iconClasses}
                                    icon={faUser}
                                />
                                <span className={"button-text"}>
                                    {currentUser.name}
                                </span>
                            </div>
                        </Button>
                        <Menu
                            className={"menu-margin"}
                            id="simple-menu"
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "center",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                        >
                            <MenuItem onClick={handleProfile}>
                                <span className="menu-item">Perfil</span>
                            </MenuItem>
                            <MenuItem onClick={handleClose}>
                                <span className="menu-item">Sair</span>
                            </MenuItem>
                        </Menu>
                    </div>
                )}
            </div>
        </div>
    );
}
