/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from "react";

import CloseIcon from "@mui/icons-material/Close";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import SnackbarContent from "@mui/material/SnackbarContent";
import { makeStyles } from "@mui/styles";
import cep from "cep-promise";
import classNames from "classnames";

import "./CustomerForm.css";

import {
    CustomerInfo,
    CustomerAddress,
    LegalOfficerInfo,
    FinancialOfficerInfo,
    StudyArea,
    RaizenCheckbox,
} from "./sections";
import NewStudyAreaDialog from "./sections/study-area/new-study-area-dialog/NewStudyAreaDialog";
import clone from "../../../lib/clone";
import { cnpjParser, phoneParser, cpfParser } from "../../../lib/form-utils";
import {
    getDocumentData,
    getCollectionData,
    createDocument,
    updateDocument,
    deleteDocument,
} from "../../../services/data-service";

const newCustomer = {};

const useStyles = makeStyles(theme => ({
    customerCreateForm: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        padding: "2.188em 4.688em 2.188em 4.688em",
        overflow: "auto",
    },
    headerTitle: {
        fontFamily: "Roboto",
        fontSize: "1.625em",
        fontWeight: 500,
        fontStyle: "normal",
        fontStretch: "normal",
        lineHeight: 1.15,
        letterSpacing: "normal",
        textAlign: "left",
        color: "var(--black)",
    },
    content: {
        marginTop: theme.spacing(6),
    },
    data: {
        flex: 1,
        marginTop: theme.spacing(1),
        display: "flex",
        flexDirection: "column",
    },
    dataHeader: {
        display: "flex",
    },
    dataTitle: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
    dataContent: {
        transition: "all 1s",
    },
    inputField: {
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 300,
    },
    buttons: {
        marginTop: theme.spacing(2),
        flex: 1,
    },
    actionContainer: {
        padding: "0 24px 24px",
    },
    cancelButton: {
        color: "red",
    },
    loadingIndicator: {
        color: "green",
        marginLeft: theme.spacing(2),
    },
    error: {
        backgroundColor: theme.palette.error.dark,
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    submitButton: {
        color: "#FFF",
        backgroundColor: "var(--dark-seafoam-green)",
        "&:hover": {
            backgroundColor: "var(--green)",
            borderColor: "#385cac",
        },
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(2),
        padding: theme.spacing(1),
    },
    deleteButton: {
        color: "#FFFFFF",
        backgroundColor: "var(--red)",
        "&:hover": {
            backgroundColor: "#a00000",
            borderColor: "#385cac",
        },
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(2),
        padding: theme.spacing(1),
    },
    buttonIcon: {
        marginLeft: theme.spacing(1),
    },
    success: {
        marginTop: "1em",
        backgroundColor: "var(--dark-seafoam-green)",
    },
}));

export default function CustomerForm(props) {
    const { customerId } = props;
    const classes = useStyles();

    const [refresh, setRefresh] = useState(false);

    const [state, setState] = useState({
        customer: {},
        isCreatedCustomer: false,
        isLoading: true,
        errors: [],
        success: [],
        selectedStudyArea: {},
        cnpjExists: false,
        isStudyAreaDialogOpen: false,
        isDeleteCustomerDialogOpen: false,
        fieldError: {},
    });

    const fetchData = customerId => {
        getDocumentData(`customers/${customerId}`).then(async customer => {
            const addresses = await getCollectionData(
                `customers/${customerId}/addresses`,
            );
            const address = addresses.find(a => a.type === "default");
            if (address) delete address.customer;
            setState({
                ...state,
                customer: {
                    ...address,
                    ...customer,
                    addressId: address && address.id,
                },
                isStudyAreaDialogOpen: false,
                isCreatedCustomer: true,
                isLoading: false,
            });
        });
    };

    // fetch data
    useEffect(() => {
        setState({ ...state, isLoading: true, customer: newCustomer });
        if (customerId) {
            fetchData(customerId);
        } else {
            setState({
                ...state,
                customer: clone(newCustomer),
                isCreatedCustomer: false,
                isStudyAreaDialogOpen: false,
                isLoading: false,
            });
        }
    }, [customerId]);

    useEffect(() => {
        if (refresh) {
            fetchData(customerId);
            setRefresh(false);
        }
    }, [refresh, customerId]);

    const onInputChange = name => event => {
        if (name === "cnpj") {
            setState({
                ...state,
                isCreatedCustomer: false,
            });
        }
        let { customer } = state;
        customer[name] = event.target.value;

        setState({
            ...state,
            customer,
        });
    };

    const handleRaizenCheck = () => {
        let { customer } = state;
        customer.raizenDeadlines = !customer.raizenDeadlines;

        setState({
            ...state,
            customer,
        });
    };

    const onCloseSuccess = key => {
        const { success } = state;

        success.splice(key, 1);
        setState({
            ...state,
            created: true,
        });
    };

    const onCloseError = key => {
        const { errors } = state;
        errors.splice(key, 1);
        setState({
            ...state,
            errors,
        });
    };

    const handleDialogClose = () => {
        setState({
            ...state,
            isDeleteCustomerDialogOpen: false,
            isStudyAreaDialogOpen: false,
            selectedStudyArea: {},
        });
    };

    const handleDeleteCustomerDialogOpen = () => {
        setState({
            ...state,
            isDeleteCustomerDialogOpen: true,
        });
    };

    const handleNewStudyAreaDialogOpen = () => {
        setState({
            ...state,
            isStudyAreaDialogOpen: true,
        });
    };

    const checkCnpj = async () => {
        const { customer } = state;
        const customers = await getCollectionData("customers");
        return customers.some(
            c => c.cnpj === cnpjParser(customer.cnpj) && !c.inactive,
        );
    };
    const loadAddressByCep = value => {
        const { customer, fieldError } = state;
        fieldError.cep = "";
        if (value) {
            setState({
                ...state,
                isLoading: true,
            });
            cep(value)
                .then(response => {
                    customer.cep = response.cep;
                    customer.address = response.street;
                    customer.neighborhood = response.neighborhood;
                    customer.city = response.city;
                    customer.state = response.state;
                    setState({
                        ...state,
                        customer,
                        fieldError,
                        isLoading: false,
                    });
                })
                .catch(error => {
                    fieldError.cep = error.message;
                    console.error(error);
                    setState({
                        ...state,
                        fieldError,
                        isLoading: false,
                    });
                });
        } else {
            setState({
                ...state,
                fieldError,
            });
        }
    };
    const fetchCustomerByCnpj = async cnpj => {
        const customers = await (
            await getCollectionData("customers")
        ).filter(c => c.cnpj === cnpjParser(cnpj));
        const customer = customers[0];
        if (customer) {
            setState({
                ...state,
                isCreatedCustomer: true,
                customer,
            });
        }
    };

    const handleSubmit = async event => {
        event.preventDefault();

        try {
            setState({
                ...state,
                isLoading: true,
                cnpjExists: false,
            });

            const { customer, isCreatedCustomer } = state;

            customer.cnpj = cnpjParser(customer.cnpj);
            if (customer.legalResponsibleCpf) {
                customer.legalResponsibleCpf = cpfParser(
                    customer.legalResponsibleCpf,
                );
            }
            if (customer.financialResponsibleCpf) {
                customer.financialResponsibleCpf = cpfParser(
                    customer.financialResponsibleCpf,
                );
            }
            if (customer.legalResponsiblePhone) {
                customer.legalResponsiblePhone = phoneParser(
                    customer.legalResponsiblePhone,
                );
            }
            if (customer.financialResponsiblePhone) {
                customer.financialResponsiblePhone = phoneParser(
                    customer.financialResponsiblePhone,
                );
            }
            // if (customer.name) {
            //     customer.name = customer.name.toUpperCase();
            // }
            // create customer
            if (!isCreatedCustomer) {
                const existingCnpj = await checkCnpj();
                if (!existingCnpj) {
                    createDocument("customers", customer)
                        .then(customer => {
                            createDocument(
                                `customers/${customer.id}/addresses`,
                                {
                                    cep: customer.cep,
                                    address: customer.address,
                                    city: customer.city,
                                    neighborhood: customer.neighborhood,
                                    number: customer.number,
                                    state: customer.state,
                                    type: "default",
                                    name: "Área de estudo é o próprio empreendimento",
                                },
                            )
                                .then(address => {
                                    setState({
                                        ...state,
                                        customer: {
                                            ...address,
                                            ...customer,
                                            addressId: address && address.id,
                                        },
                                        isLoading: false,
                                        success: [
                                            "Cliente cadastrado com sucesso",
                                        ],
                                        isCreatedCustomer: true,
                                    });
                                })
                                .catch(error => {
                                    console.error(
                                        "Error creating customer address",
                                        error,
                                    );
                                    setState({
                                        ...state,
                                        isLoading: false,
                                    });
                                });
                        })
                        .catch(error => {
                            console.error("Error creating customer", error);
                            setState({
                                ...state,
                                isLoading: false,
                                errors: [
                                    "Não foi possivel cadastrar este cliente, tente novamente",
                                ],
                            });
                        });
                } else {
                    setState({
                        ...state,
                        isLoading: false,
                        errors: ["CNPJ já cadastrado"],
                        cnpjExists: true,
                    });
                }
                // update customer
            } else {
                updateDocument(`customers/${customer.id}`, customer)
                    .then(async () => {
                        await updateDocument(
                            `customers/${customer.id}/addresses/${customer.addressId}`,
                            {
                                cep: customer.cep,
                                address: customer.address,
                                city: customer.city,
                                neighborhood: customer.neighborhood,
                                number: customer.number,
                                state: customer.state,
                            },
                        );
                        setState({
                            ...state,
                            isLoading: false,
                            success: ["Cliente atualizado com sucesso"],
                        });
                    })
                    .catch(error => {
                        console.error("Error updating customer", error);
                        setState({
                            ...state,
                            isLoading: false,
                            errors: [
                                "Não foi possivel atualizar este cliente, tente novamente",
                            ],
                        });
                    });
            }
        } catch (error) {
            console.error("Error submitting customer", error);
            setState({
                ...state,
                isLoading: false,
                errors: ["Não foi possivel concluir operação, tente novamente"],
            });
        }
    };

    const handleDeleteCustomer = async () => {
        const { customer, isCreatedCustomer } = state;
        setState({
            ...state,
            isLoading: true,
        });
        if (isCreatedCustomer) {
            try {
                deleteDocument(`customers/${customer.id}`)
                    .then(() => {
                        setState({
                            ...state,
                            isLoading: false,
                            isDeleteCustomerDialogOpen: false,
                            success: ["Cliente removido com sucesso"],
                            customer: {},
                            isCreatedCustomer: false,
                        });
                    })
                    .catch(error => {
                        console.error("Error removing customer", error);
                        setState({
                            ...state,
                            isLoading: false,
                            isDeleteCustomerDialogOpen: false,
                            errors: [
                                "Não foi possivel remover este cliente, tente novamente",
                            ],
                        });
                    });
            } catch (error) {
                console.error("Error removing customer", error);
                setState({
                    ...state,
                    isLoading: false,
                    isDeleteCustomerDialogOpen: false,
                    errors: [
                        "Não foi possivel concluir operação, tente novamente",
                    ],
                });
            }
        } else {
            setState({
                ...state,
                customer: {},
                isLoading: false,
                isDeleteCustomerDialogOpen: false,
            });
        }
    };

    const handleSelectStudyArea = studyArea => {
        setState({
            ...state,
            isStudyAreaDialogOpen: true,
            selectedStudyArea: studyArea,
        });
    };

    const {
        isCreatedCustomer,
        customer,
        isLoading,
        cnpjExists,
        isStudyAreaDialogOpen,
        isDeleteCustomerDialogOpen,
        selectedStudyArea,
        fieldError,
    } = state;

    const renderSuccess = () => {
        const { success } = state;

        if (!success || !success.length) return "";
        return (
            <div className="form-errors">
                {success.map((message, key) => {
                    return (
                        <SnackbarContent
                            key={key}
                            className={classNames(classes.success)}
                            aria-describedby="success-message"
                            message={
                                <span
                                    id="success-message"
                                    className={classes.message}
                                >
                                    <Icon
                                        className={classNames(
                                            classes.icon,
                                            classes.iconVariant,
                                        )}
                                    />
                                    {success}
                                </span>
                            }
                            action={[
                                <IconButton
                                    key="close"
                                    aria-label="Close"
                                    color="inherit"
                                    className={classes.close}
                                    onClick={() => onCloseSuccess(key)}
                                >
                                    <CloseIcon className={classes.icon} />
                                </IconButton>,
                            ]}
                        />
                    );
                })}
            </div>
        );
    };
    const renderErrors = () => {
        const { errors } = state;

        if (!errors || !errors.length) return "";

        return (
            <div className="form-errors">
                {errors.map((error, key) => {
                    return (
                        <SnackbarContent
                            key={key}
                            className={classNames(classes.error)}
                            aria-describedby="error-message"
                            message={
                                <span
                                    id="error-message"
                                    className={classes.message}
                                >
                                    <Icon
                                        className={classNames(
                                            classes.icon,
                                            classes.iconVariant,
                                        )}
                                    />
                                    {error}
                                </span>
                            }
                            action={[
                                <IconButton
                                    key="close"
                                    aria-label="Close"
                                    color="inherit"
                                    className={classes.close}
                                    onClick={() => onCloseError(key)}
                                >
                                    <CloseIcon className={classes.icon} />
                                </IconButton>,
                            ]}
                        />
                    );
                })}
            </div>
        );
    };

    const handleCheckCnpj = ({ isValidating, cnpjInUse }) => {
        setState({
            ...state,
            isLoading: isValidating,
            cnpjInUse,
        });
    };

    return (
        <div className={classes.customerCreateForm}>
            <form
                className={classes.container}
                autoComplete="off"
                onSubmit={handleSubmit}
            >
                <div className={classes.header}>
                    <h1 className={classes.headerTitle}>
                        {isCreatedCustomer ? "Editar" : "Cadastrar"} Cliente
                    </h1>
                </div>
                <div className={classes.contents}>
                    {renderErrors()}
                    {renderSuccess()}
                    <CustomerInfo
                        customer={customer}
                        onInputChange={onInputChange}
                        cnpjExists={cnpjExists}
                        fetchCustomer={fetchCustomerByCnpj}
                        isCreatedCustomer={isCreatedCustomer}
                        onCheckCnpj={handleCheckCnpj}
                    />
                    <CustomerAddress
                        customer={customer}
                        onInputChange={onInputChange}
                        fieldError={fieldError}
                        loadAddressByCep={loadAddressByCep}
                        isCreatedCustomer={isCreatedCustomer}
                    />
                    <LegalOfficerInfo
                        customer={customer}
                        onInputChange={onInputChange}
                    />
                    <FinancialOfficerInfo
                        customer={customer}
                        onInputChange={onInputChange}
                    />

                    <RaizenCheckbox
                        customer={customer}
                        handleRaizenCheck={handleRaizenCheck}
                    />
                    {isCreatedCustomer ? (
                        <StudyArea
                            handleNewStudyAreaDialogOpen={
                                handleNewStudyAreaDialogOpen
                            }
                            receivedCustomer={customer}
                            handleSelectStudyArea={handleSelectStudyArea}
                        />
                    ) : null}

                    <div className={classes.buttons}>
                        <Button
                            className={classNames(
                                classes.submitButton,
                                classes.addUser,
                            )}
                            variant="contained"
                            type="submit"
                            disabled={isLoading}
                        >
                            {isCreatedCustomer ? "Atualizar" : "Cadastrar"}
                            {isLoading && (
                                <CircularProgress
                                    size={18}
                                    thickness={4}
                                    className={classes.loadingIndicator}
                                />
                            )}
                        </Button>
                        {isCreatedCustomer && (
                            <Button
                                className={classNames(
                                    classes.deleteButton,
                                    classes.deleteUser,
                                )}
                                variant="contained"
                                onClick={handleDeleteCustomerDialogOpen}
                                disabled={isLoading}
                            >
                                Apagar
                            </Button>
                        )}
                    </div>
                </div>
            </form>
            <NewStudyAreaDialog
                dialogOpen={isStudyAreaDialogOpen}
                handleDialogClose={handleDialogClose}
                customer={customer}
                receivedStudyArea={selectedStudyArea}
                onCreated={() => {
                    setRefresh(true);
                }}
            />
            <Dialog
                open={isDeleteCustomerDialogOpen}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Excluir cliente?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Deseja realmente excluir este cliente?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose}>Não</Button>
                    <Button onClick={handleDeleteCustomer}>Sim</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
