const firebaseConfig = {
    apiKey: "AIzaSyAKwazKdQlSyheYcXGZQzswPzMBYX7q3V8",
    authDomain: "diatech-production.firebaseapp.com",
    databaseURL: "https://diatech-production.firebaseio.com",
    projectId: "diatech-production",
    storageBucket: "diatech-production.appspot.com",
    messagingSenderId: "690052400293",
    appId: "1:690052400293:web:0e901bd1ff1c82fbd3794c",
    measurementId: "G-WLMJHLLTK7",
};

export default firebaseConfig;
