import { parsePhoneNumberFromString } from "libphonenumber-js";
import { encodeURIPathParam, decodeURIPathParam } from "./uri-utils";
import * as cpf from "./cpf-utils";
import * as cnpj from "./cnpj-utils";

const DEFAULT_COUNTRY_CODE = "BR";

export const emailDecoder = value =>
    value != null ? decodeURIPathParam(value) : "";

export const emailEncoder = value =>
    value != null ? encodeURIPathParam(value) : "";

export const emailValidator = value => {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(value).toLowerCase());
};

export const cpfFormatter = value => (value !== "" ? cpf.format(value) : "");

export const cpfParser = value => (value !== "" ? cpf.strip(value) : "");

export const cpfValidator = value => {
    if (!value) {
        return "The cpf is required";
    }
    if (!cpf.isValid(value)) {
        return "The cpf is invalid";
    }
    return undefined;
};

export const cnpjFormatter = value => (value !== "" ? cnpj.format(value) : "");

export const cnpjParser = value => (value !== "" ? cnpj.strip(value) : "");

export const cnpjValidator = value => {
    if (!value) {
        return "The cnpj is required";
    }
    if (!cnpj.isValid(value)) {
        return "The cnpj is invalid";
    }
    return undefined;
};

export const phoneFormatter = value => {
    if (value === "" || value === undefined) return "";
    const phoneNumber = parsePhoneNumberFromString(value, DEFAULT_COUNTRY_CODE);
    if (phoneNumber === undefined) return value;
    if (phoneNumber.isValid()) return phoneNumber.formatNational();
    return phoneNumber.nationalNumber;
};

export const phoneParser = value => {
    if (value === "") return "";
    const phoneNumber = parsePhoneNumberFromString(value, DEFAULT_COUNTRY_CODE);
    if (phoneNumber === undefined) return value;
    if (phoneNumber.isValid()) return phoneNumber.number;
    return phoneNumber.nationalNumber;
};

export const phoneValidator = value => {
    if (value === "" || value === undefined) return undefined;
    const phoneNumber = parsePhoneNumberFromString(value, DEFAULT_COUNTRY_CODE);
    const notValid = "The telephone is invalid";
    if (phoneNumber === undefined) return notValid;
    if (!phoneNumber.isValid()) return notValid;
    return undefined;
};
