import moment from "moment";

import OrderStatus from "../constants/order-status";
import OrderTaskSampleStatus from "../constants/order-task-sample-status";
import OrderTaskStatus from "../constants/order-task-status";
import ProjectStatus from "../constants/project.status";
import WellStatus from "../constants/well-status";

export const statusLabelStyle = status => {
    switch (status) {
        case ProjectStatus.IN_PROGRESS:
            return "label-in-progress";
        case ProjectStatus.CANCELLED:
            return "label-cancelled";
        case ProjectStatus.COMPLETED:
            return "label-completed";
        case ProjectStatus.RELEASED:
            return "label-released";
        case ProjectStatus.PENDENCY:
            return "label-pendency";
        case ProjectStatus.TASK_CANCELLED:
            return "label-task-cancelled";
        case ProjectStatus.TASK_INCOMPLETED:
            return "label-task-incompleted";
        default:
            return "label-open";
    }
};

export const statusName = status => {
    switch (status) {
        case ProjectStatus.IN_PROGRESS:
            return "Em Andamento";
        case ProjectStatus.CANCELLED:
            return "Cancelado";
        case ProjectStatus.COMPLETED:
            return "Concluído";
        case ProjectStatus.RELEASED:
            return "Liberado";
        case ProjectStatus.PENDENCY:
            return "Pendência";
        case ProjectStatus.TASK_INCOMPLETED:
            return "Não Concluído";
        case ProjectStatus.TASK_CANCELLED:
            return "Cancelado";
        default:
            return "Não Iniciado";
    }
};

const deadlineStatusTypes = {
    onTime: "Dentro da data de entrega prevista",
    approaching: "Próximo da data de entrega",
    overdue: "Passou a data de entrega",
};
export const deadlineStatusOutput = statusId =>
    deadlineStatusTypes[statusId] || "";

const deadlineStatusColors = {
    onTime: "status-green",
    approaching: "status-orange",
    overdue: "status-red",
};
export const deadlineStatusColor = statusId =>
    deadlineStatusColors[statusId] || "";

const deadlineLabelStyles = {
    onTime: "label-green",
    approaching: "label-orange",
    overdue: "label-red",
};
export const deadlineLabelStyle = statusId =>
    deadlineLabelStyles[statusId] || "";

export const deadlineStatus = (startDate, daysToDelivery) => {
    const { status } = deadlineInfo(startDate, daysToDelivery);
    return status;
};

export const deadlineInfo = (startDate, daysToDelivery) => {
    const now = moment(); //todays date
    const start = moment(startDate, moment.ISO_8601); // deadline date
    const duration = moment.duration(now.diff(start));
    const elapsedDays = duration.asDays();
    const progress = (elapsedDays / daysToDelivery) * 100.0;
    const deadline = start.add(daysToDelivery, "days");

    let status = "";
    switch (true) {
        case progress > 100.0:
            status = "overdue";
            break;
        case progress > 80.0:
            status = "approaching";
            break;
        default:
            status = "onTime";
            break;
    }

    return {
        startDate,
        deadline,
        elapsedDays,
        daysToDelivery,
        progress,
        status,
    };
};

const accountingStatusTypes = {
    invoiced: "Fatura enviada para o cliente",
    notInvoiced: "Fatura não foi enviada para o cliente",
    notInvoicedApproaching:
        "Fatura não foi enviada para o cliente, porém está dentro do prazo",
    notInvoicedOverdue: "Fatura não foi enviada e passou do prazo",
};
export const accountingStatusOutput = statusId =>
    accountingStatusTypes[statusId] || "";

const accountingStatusColors = {
    invoiced: "status-green",
    notInvoiced: "status-dusk",
    notInvoicedApproaching: "status-orange",
    notInvoicedOverdue: "status-red",
};
export const accountingStatusColor = statusId =>
    accountingStatusColors[statusId] || "";

export const accountingStatus = (
    creationDate,
    invoiceDate,
    invoiceSent = false,
) => {
    if (invoiceSent) return "invoiced";
    if (!invoiceDate) return "notInvoiced";

    const dateOfCreation = moment(creationDate, moment.ISO_8601);
    const dateToInvoice = moment(invoiceDate, moment.ISO_8601);
    const now = moment();

    const currentDaysToInvoice = moment
        .duration(dateToInvoice.diff(now))
        .asDays();
    const totalDaysToInvoice = moment
        .duration(dateToInvoice.diff(dateOfCreation))
        .asDays();

    if (totalDaysToInvoice < 0) return "notInvoicedOverdue";

    const progress =
        ((totalDaysToInvoice - currentDaysToInvoice) * 100.0) /
        totalDaysToInvoice;

    if (progress > 100.0) return "notInvoicedOverdue";
    if (progress < 80.0) return "notInvoiced";
    if (progress > 80.0) return "notInvoicedApproaching";
};

//======================================================
//      WELL STATUS
//======================================================

export const wellStatusName = status => {
    switch (status) {
        case WellStatus.INACTIVE:
            return "Inativo";
        default:
            return "Ativo";
    }
};

export const wellStatusColor = status => {
    switch (status) {
        case WellStatus.INACTIVE:
            return "default";
        default:
            return "success";
    }
};

export const wellStatusItem = status => {
    if (!status) return;
    return {
        id: status,
        name: wellStatusName(status),
    };
};

export const wellStatusList = Object.values(WellStatus).map(wellStatusItem);

//======================================================
//      ORDER TASK STATUS
//======================================================

export const orderTaskStatusLabelStyle = status => {
    switch (status) {
        case OrderTaskStatus.COMPLETED:
            return "label-green";
        case OrderTaskStatus.IN_PROGRESS:
            return "label-orange";
        default:
            return "label-grey";
    }
};

export const orderTaskStatusName = status => {
    switch (status) {
        case OrderTaskStatus.COMPLETED:
            return "Concluído";
        case OrderTaskStatus.IN_PROGRESS:
            return "Em Andamento";
        default:
            return "Pendente";
    }
};

export const orderTaskStatusColor = status => {
    switch (status) {
        case OrderTaskStatus.COMPLETED:
            return "success";
        case OrderTaskStatus.IN_PROGRESS:
            return "warning";
        default:
            return "default";
    }
};

export const orderTaskStatusItem = status => {
    if (!status) return;
    return {
        id: status,
        name: orderTaskStatusName(status),
    };
};

export const orderTaskStatusList =
    Object.values(OrderTaskStatus).map(orderTaskStatusItem);

//======================================================
//      ORDER TASK SMAPLE STATUS
//======================================================

export const orderTaskSampleStatusName = status => {
    switch (status) {
        case OrderTaskSampleStatus.COMPLETED:
            return "Concluído";
        case OrderTaskSampleStatus.IN_PROGRESS:
            return "Em Andamento";
        default:
            return "Pendente";
    }
};

export const orderTaskSampleStatusColor = status => {
    switch (status) {
        case OrderTaskSampleStatus.COMPLETED:
            return "success";
        case OrderTaskSampleStatus.IN_PROGRESS:
            return "warning";
        default:
            return "default";
    }
};

export const orderTaskSampleStatusItem = status => {
    if (!status) return;
    return {
        id: status,
        name: orderTaskSampleStatusName(status),
    };
};

export const orderTaskSampleStatusList = Object.values(
    OrderTaskSampleStatus,
).map(orderTaskSampleStatusItem);
