/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState } from "react";

import {
    faCircle,
    faDollarSign,
    faFlag,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddIcon from "@mui/icons-material/Add";
import { Button, Table, TableBody, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";

import "./ProjectList.css";

import classNames from "classnames";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import { Link, NavLink } from "react-router-dom";

import ProjectFilter from "./project-filter/ProjectFilter";
import { projectFilterType } from "../../../constants/project-filter-types";
import ProjectStatus from "../../../constants/project.status";
import UserRoles from "../../../constants/user-roles";
import { AuthContext } from "../../../contexts";
import useFetchProjectData from "../../../hooks/useFetchProjectData";
import { pendencyName } from "../../../lib/pendency-utils";
import {
    accountingStatus,
    accountingStatusColor,
    accountingStatusOutput,
    deadlineStatus,
    deadlineStatusColor,
    deadlineStatusOutput,
} from "../../../lib/status.utils";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    newProjectButton: {
        //backgroundColor: 'var(--dark-seafoam-green);',
        width: "100%",
        /* height: 60, */
        fontFamily: "Roboto",
        fontSize: "20px",
        fontWeight: 500,
        fontStyle: "normal",
        fontStretch: "normal",
        lineHeight: "1.14",
        letterSpacing: "normal",
        textAlign: "center",
        color: "var(--dark-seafoam-green)",
        marginTop: theme.spacing(1),
        "&:hover": {
            backgroundColor: "transparent",
            //backgroundColor: 'var(--green)',
            //borderColor: '#385cac',
        },
        flex: 1,
    },
    tableRow: {
        "&:hover": {
            cursor: "pointer",
        },
    },
    projectFilter: {
        display: "flex",
    },
}));

function checkLocation() {
    return (
        window.location.pathname === "/projects" ||
        window.location.pathname === "/department-activities" ||
        window.location.pathname === "/user-activities" ||
        window.location.pathname === "/project-history"
    );
}

export default function ProjectList(props) {
    const { filterType } = props;
    const classes = useStyles();
    const { user: currentUser, hasAnyRole, hasRole } = useContext(AuthContext);
    const [listCounter, setListCounter] = useState(0);
    const [isSelectedProject, setIsSelectedProject] = useState(
        !checkLocation(),
    );
    const { isLoading, projects, projectCounter } = useFetchProjectData({
        filterType,
    });
    const [filtredProjects, setFiltredProjects] = useState(projects);

    const nextLocation = !checkLocation();
    if (nextLocation !== isSelectedProject) {
        setIsSelectedProject(nextLocation);
    }

    const handleFilterChange = filtredProjects => {
        setFiltredProjects(filtredProjects);
        setListCounter(filtredProjects.length);
    };

    const getCustomerName = project => {
        const studyArea = project.studyArea ? project.studyArea.name : "";
        const customerName = project.customer
            ? project.customer.name
            : project.customerName;
        return studyArea ? `${customerName} - ${studyArea}` : customerName;
    };

    const renderLoading = () => {
        const skeletonRows = () => {
            let rows = [];
            for (let i = 0; i < 20; i++) {
                rows.push(
                    <Skeleton key={i} height={isSelectedProject ? 124 : 55} />,
                );
            }
            return rows;
        };

        return skeletonRows();
    };

    const renderProjectList = () => {
        return (
            <Table>
                <TableBody>
                    {filtredProjects.map((project, index) =>
                        renderProjectItem(project, index),
                    )}
                </TableBody>
            </Table>
        );
    };

    const renderProjectItem = (project, index) => {
        const deadlineStatusIcon = () => {
            project.deadlineStatus = deadlineStatus(
                project.createdAt,
                project.deadlineDays,
            );

            return (
                <Tooltip
                    title={deadlineStatusOutput(project.deadlineStatus) || ""}
                >
                    <FontAwesomeIcon
                        className={classNames(
                            deadlineStatusColor(project.deadlineStatus),
                            "status-icon",
                        )}
                        icon={faCircle}
                    />
                </Tooltip>
            );
        };

        const deadlineDaysLabel = () => {
            const deadlineStatus = project.deadlineLabelStatus;
            if (project.deadlineLabelDate)
                return (
                    <span
                        className={classNames({
                            label: true,
                            "bg-default": true,
                            "label-deadline": true,
                            "label-deadline-ontime":
                                deadlineStatus === "onTime",
                            "label-deadline-approaching":
                                deadlineStatus === "approaching",
                            "label-deadline-overdue":
                                deadlineStatus === "overdue",
                        })}
                    >
                        {moment(
                            project.deadlineLabelDate,
                            moment.ISO_8601,
                        ).fromNow(true)}
                    </span>
                );
            return <span />;
        };

        const accountStatusIcon = () => {
            if (hasRole(UserRoles.CONTROLE_FINANCEIRO)) {
                project.accountingStatus = accountingStatus(
                    project.createdAt,
                    project.firstPaymentDate,
                    project.invoiceSent,
                );
                return (
                    <Tooltip
                        title={
                            accountingStatusOutput(project.accountingStatus) ||
                            ""
                        }
                    >
                        <FontAwesomeIcon
                            className={classNames(
                                accountingStatusColor(project.accountingStatus),
                                "status-icon",
                            )}
                            icon={faDollarSign}
                        />
                    </Tooltip>
                );
            } else {
                return <span className="project-icon" />;
            }
        };

        const assignedFlag = (
            <FontAwesomeIcon
                className={classNames("status-icon", "assigned-flag")}
                icon={faFlag}
            />
        );
        const isAssigned = () => {
            return project.assignee && project.assignee.id === currentUser.id;
        };

        const isAssignedFlag = isAssigned() ? assignedFlag : "";

        const defaultView = (
            <div key={index} className="list-project-item">
                <NavLink
                    to={"/projects/" + project.id}
                    replace={true}
                    activeClassName="list-project-item-selected"
                >
                    {[
                        ProjectStatus.COMPLETED,
                        ProjectStatus.CANCELLED,
                    ].includes(project.status) ? (
                        <div className="list-project-item-row">
                            <span className="project-number">
                                {project.number}
                            </span>
                        </div>
                    ) : (
                        <div className="list-project-item-row">
                            {deadlineStatusIcon()}
                            <span className="project-number">
                                {project.number}
                            </span>
                            <Tooltip
                                title={
                                    filterType === projectFilterType.ALL
                                        ? "Prazo final do projeto"
                                        : "Prazo final da etapa"
                                }
                            >
                                {deadlineDaysLabel()}
                            </Tooltip>
                        </div>
                    )}
                    <div className="list-project-item-row">
                        {accountStatusIcon()}
                        <span className="project-customer">
                            {getCustomerName(project)}
                        </span>
                    </div>
                    <div className="list-project-item-row">
                        <span className="project-icon" />
                        <span className="project-study-type">
                            {project.study
                                ? project.study.name
                                : project.studyName}
                        </span>
                    </div>
                    <div className="list-project-item-row">
                        <div className="list-project-item-label">
                            {project.status === "Pendency" && (
                                <span className="label label-pendency label-margin-right">
                                    {pendencyName(project.pendencyType)}
                                </span>
                            )}
                            <span className="label bg-default label-margin-right">
                                {project.phase
                                    ? project.phase.name
                                    : project.phaseName}
                            </span>
                            <span className="label bg-default">
                                {project.assignee
                                    ? project.assignee.name
                                    : project.assigneeName}
                            </span>
                            {isAssignedFlag}
                        </div>
                    </div>
                </NavLink>
            </div>
        );

        return defaultView;
    };

    return (
        <div className="project-list">
            <div className={classes.projectFilter}>
                <ProjectFilter
                    projects={projects}
                    filterType={filterType}
                    onChange={handleFilterChange}
                />
                {filterType === projectFilterType.ALL &&
                hasAnyRole(["CDP"]) &&
                !isSelectedProject ? (
                    <div className="new-project">
                        <Link to={"/projects/new"}>
                            <Button
                                //variant="contained"
                                className={classes.newProjectButton}
                            >
                                <AddIcon />
                                Novo Projeto
                            </Button>
                        </Link>
                    </div>
                ) : (
                    <div className="fix" />
                )}
            </div>
            <div className="list-project">
                <div className="listCounter">
                    {listCounter} de {projectCounter}
                </div>
                {isLoading ? renderLoading() : renderProjectList()}
            </div>
        </div>
    );
}

ProjectList.defaultProps = {
    filterType: projectFilterType.ALL,
};
