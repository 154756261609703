/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

import classNames from "classnames";
import CloseIcon from "@mui/icons-material/Close";

import "./ReasonForm.css";

import { TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import SnackbarContent from "@mui/material/SnackbarContent";
import { makeStyles } from "@mui/styles";
import { Store as notifications } from "react-notifications-component";

import notificationOpts from "../../../constants/notification-options";
import notificationType from "../../../constants/notification-types";
import reasonsTypes from "../../../constants/reason-types";
import clone from "../../../lib/clone";
import {
    getDocumentData,
    createDocument,
    updateDocument,
    deleteDocument,
} from "../../../services/data-service";

const initialReasonType = {
    label: "",
    value: "",
};

const initialReason = {
    name: "",
    reasonType: initialReasonType,
    type: "",
};

const useStyles = makeStyles(theme => ({
    reasonCreateForm: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        padding: "2.188em 4.688em 2.188em 4.688em",
        overflow: "auto",
    },
    header: {
        marginBottom: theme.spacing(1),
    },
    headerTitle: {
        fontFamily: "Roboto",
        fontSize: "1.625em",
        fontWeight: 500,
        fontStyle: "normal",
        fontStretch: "normal",
        lineHeight: 1.15,
        letterSpacing: "normal",
        textAlign: "left",
        color: "var(--black)",
    },
    content: {
        marginTop: theme.spacing(6),
    },
    data: {
        flex: 1,
        marginTop: theme.spacing(1),
        display: "flex",
        flexDirection: "column",
    },
    inputField: {
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 300,
    },
    nameField: {
        width: 450,
    },
    buttons: {
        marginTop: theme.spacing(2),
        flex: 1,
    },
    loadingIndicator: {
        color: "green",
        marginLeft: theme.spacing(2),
    },
    error: {
        backgroundColor: theme.palette.error.dark,
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    submitButton: {
        color: "#FFF",
        backgroundColor: "var(--dark-seafoam-green)",
        "&:hover": {
            backgroundColor: "var(--green)",
            borderColor: "#385cac",
        },
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(2),
        padding: theme.spacing(1),
    },
    deleteButton: {
        color: "#FFFFFF",
        backgroundColor: "var(--red)",
        "&:hover": {
            backgroundColor: "#a00000",
            borderColor: "#385cac",
        },
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(2),
        padding: theme.spacing(1),
    },
    success: {
        marginTop: "1em",
        backgroundColor: "var(--dark-seafoam-green)",
    },
}));

export default function ReasonForm(props) {
    const { reasonId, onCreated } = props;
    const classes = useStyles();

    const [refresh, setRefresh] = useState(false);

    const [state, setState] = useState({
        reason: { ...initialReason },
        isCreatedReason: false,
        isLoading: true,
        errors: [],
    });

    const fetchData = reasonId => {
        getDocumentData(`reasons/${reasonId}`).then(async reason => {
            setState({
                ...state,
                reason: {
                    ...reason,
                    reasonType: reasonsTypes.find(r => r.value === reason.type),
                },
                isCreatedReason: true,
                isLoading: false,
            });
        });
    };

    // fetch data
    useEffect(() => {
        setState({ ...state, isLoading: true, reason: initialReason });
        if (reasonId) {
            fetchData(reasonId);
        } else {
            setState({
                ...state,
                reason: clone(initialReason),
                isCreatedReason: false,
                isLoading: false,
            });
        }
    }, [reasonId]);

    useEffect(() => {
        if (refresh) {
            fetchData(reasonId);
            setRefresh(false);
        }
    }, [refresh, reasonId]);

    const onInputChange = name => event => {
        let { reason } = state;
        reason[name] = event.target.value;

        setState({
            ...state,
            reason,
        });
    };

    const onTypeChange = async value => {
        let { reason } = state;
        if (value) {
            reason.type = value.value;
            reason.reasonType = value;
            setState({
                ...state,
                reason,
            });
        } else {
            reason.type = "";
        }
        setState({
            ...state,
            reason,
        });
    };

    const onCloseError = key => {
        const { errors } = state;
        errors.splice(key, 1);
        setState({
            ...state,
            errors,
        });
    };

    const handleSubmit = async event => {
        event.preventDefault();
        try {
            setState({
                ...state,
                isLoading: true,
                cnpjExists: false,
            });

            const { reason, isCreatedReason } = state;
            // create reason
            if (!isCreatedReason) {
                createDocument("reasons", reason)
                    .then(async reason => {
                        setState({
                            ...state,
                            reason,
                            isLoading: false,
                            isCreatedReason: true,
                        });
                        notifications.addNotification({
                            ...notificationOpts,
                            type: notificationType.SUCCESS,
                            message: "Justificativa cadastrada com sucesso",
                        });
                        onCreated && onCreated(reason);
                    })
                    .catch(error => {
                        console.error("Error creating reason", error);
                        setState({
                            ...state,
                            isLoading: false,
                            errors: [
                                "Não foi possivel cadastrar esta justificativa, tente novamente",
                            ],
                        });
                    });
                // update reason
            } else {
                updateDocument(`reasons/${reason.id}`, reason)
                    .then(async () => {
                        setState({
                            ...state,
                            reason,
                            isLoading: false,
                            isCreatedReason: true,
                        });
                        notifications.addNotification({
                            ...notificationOpts,
                            type: notificationType.SUCCESS,
                            message: "Justificativa atualizada com sucesso",
                        });
                    })
                    .catch(error => {
                        console.error("Error updating reason", error);
                        setState({
                            ...state,
                            isLoading: false,
                            errors: [
                                "Não foi possivel atualizar esta justificativa, tente novamente",
                            ],
                        });
                    });
            }
        } catch (error) {
            console.error("Error submitting reason", error);
            setState({
                ...state,
                isLoading: false,
                errors: ["Não foi possivel concluir operação, tente novamente"],
            });
        }
    };

    const handleDelete = async () => {
        const { reason, isCreatedReason } = state;
        setState({
            ...state,
            isLoading: true,
        });
        if (isCreatedReason) {
            try {
                deleteDocument(`reasons/${reason.id}`)
                    .then(() => {
                        setState({
                            ...state,
                            isLoading: false,
                            reason: { ...initialReason },
                            isCreatedReason: false,
                        });
                        notifications.addNotification({
                            ...notificationOpts,
                            type: notificationType.SUCCESS,
                            message: "Justificativa removida com sucesso",
                        });
                    })
                    .catch(error => {
                        console.error("Error removing reason", error);
                        setState({
                            ...state,
                            isLoading: false,
                            errors: [
                                "Não foi possivel remover esta justificativa, tente novamente",
                            ],
                        });
                    });
            } catch (error) {
                console.error("Error removing reason", error);
                setState({
                    ...state,
                    isLoading: false,
                    errors: [
                        "Não foi possivel concluir operação, tente novamente",
                    ],
                });
            }
        } else {
            setState({
                ...state,
                reason: { ...initialReason },
                isLoading: false,
            });
        }
    };

    const { isCreatedReason, reason, isLoading } = state;

    const renderErrors = () => {
        const { errors } = state;

        if (!errors || !errors.length) return "";

        return (
            <div className="form-errors">
                {errors.map((error, key) => {
                    return (
                        <SnackbarContent
                            key={key}
                            className={classNames(classes.error)}
                            aria-describedby="error-message"
                            message={
                                <span
                                    id="error-message"
                                    className={classes.message}
                                >
                                    <Icon
                                        className={classNames(
                                            classes.icon,
                                            classes.iconVariant,
                                        )}
                                    />
                                    {error}
                                </span>
                            }
                            action={[
                                <IconButton
                                    key="close"
                                    aria-label="Close"
                                    color="inherit"
                                    className={classes.close}
                                    onClick={() => onCloseError(key)}
                                >
                                    <CloseIcon className={classes.icon} />
                                </IconButton>,
                            ]}
                        />
                    );
                })}
            </div>
        );
    };

    return (
        <div className={classes.reasonCreateForm}>
            <form
                className={classes.container}
                autoComplete="off"
                onSubmit={handleSubmit}
            >
                <div className={classes.header}>
                    <h1 className={classes.headerTitle}>
                        {isCreatedReason ? "Editar" : "Cadastrar"}
                        {" Justificativa"}
                    </h1>
                </div>
                <div className={classes.contents}>
                    {renderErrors()}
                    <TextField
                        variant="standard"
                        className={classNames(
                            classes.inputField,
                            classes.nameField,
                        )}
                        multiline={true}
                        rows="3"
                        id={"name"}
                        label={"Nome da justificativa"}
                        onChange={onInputChange("name")}
                        required={true}
                        value={reason.name}
                        disabled={isLoading}
                    />

                    <Autocomplete
                        className={classNames(
                            classes.inputField,
                            classes.typeField,
                        )}
                        id="type"
                        value={reason.reasonType}
                        onChange={(event, type) => {
                            onTypeChange(type);
                        }}
                        options={reasonsTypes}
                        getOptionLabel={option => option.label}
                        isOptionEqualToValue={(option, value) =>
                            option.value === value
                        }
                        loading={isLoading}
                        disabled={isLoading}
                        renderInput={params => (
                            <TextField
                                variant="standard"
                                {...params}
                                label="Tipo de justificativa"
                                margin="normal"
                                placeholder="Tipo de justificativa"
                                required={true}
                                fullWidth
                            />
                        )}
                    />

                    <div className={classes.buttons}>
                        <Button
                            className={classNames(
                                classes.submitButton,
                                classes.addUser,
                            )}
                            type="submit"
                            variant="contained"
                            disabled={isLoading}
                        >
                            {isCreatedReason ? "Atualizar" : "Cadastrar"}
                            {isLoading && (
                                <CircularProgress
                                    size={18}
                                    thickness={4}
                                    className={classes.loadingIndicator}
                                />
                            )}
                        </Button>
                        {isCreatedReason && (
                            <Button
                                className={classes.deleteButton}
                                type="submit"
                                variant="contained"
                                onClick={handleDelete}
                                disabled={isLoading}
                            >
                                Apagar
                            </Button>
                        )}
                    </div>
                </div>
            </form>
        </div>
    );
}
