import React from "react";

import { BrowserRouter as Router } from "react-router-dom";

import { AppProvider } from "../contexts/app.context";

import App from "./app";

function Root(props) {
    return (
        <Router>
            <AppProvider>
                <App {...props} />
            </AppProvider>
        </Router>
    );
}

export default Root;
