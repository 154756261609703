const reasonsTypes = [
    {
        label: "Pendência",
        value: "pendency",
    },
    {
        label: "Atraso",
        value: "delay",
    },
    {
        label: "Cancelamento",
        value: "cancelation",
    },
    {
        label: "Status (Poço)",
        value: "wells-status",
    },
];

export const getReasonLabel = value => {
    let label = "";
    if (value) {
        const reasonType = reasonsTypes.find(r => r.value === value);
        if (reasonType) label = reasonType.label;
    }
    return label;
};

export const getReasonType = label => {
    const normalize = value => {
        return value
            ? value.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
            : value;
    };
    const toUpper = value => {
        return value ? value.toString().toUpperCase() : value;
    };

    let value = "";
    if (label) {
        const reasonType = reasonsTypes.find(r =>
            normalize(toUpper(r.label)).includes(normalize(toUpper(label))),
        );
        if (reasonType) value = reasonType.value;
    }

    return value;
};

export default reasonsTypes;
