import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(theme => ({
    addStudyButton: {
        color: "#4192d9",
        fontFamily: "Roboto",
        fontSize: "14px",
        fontWeight: "normal",
        fontStyle: "normal",
        fontStretch: "normal",
        lineHeight: "1.14",
        letterSpacing: "normal",
        textAlign: "left",
    },
    newStudyButton: {
        color: "#4192d9",
        fontFamily: "Roboto",
        fontSize: "14px",
        fontWeight: "normal",
        fontStyle: "normal",
        fontStretch: "normal",
        lineHeight: "1.14",
        letterSpacing: "normal",
        textAlign: "left",
    },
    newCustomerButton: {
        color: "#4192d9",
        "text-transform": "none",
    },
    racpNumberField: {
        marginTop: theme.spacing(3),
        marginButton: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    customerNameField: {
        marginTop: theme.spacing(1),
        marginButton: theme.spacing(1),
        marginRight: theme.spacing(2),
        width: 480,
    },
    formControl: {
        marginTop: theme.spacing(1),
        marginButton: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    studiesField: {
        marginTop: theme.spacing(1),
        marginButton: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 285,
    },
    campaignField: {
        width: 140,
        marginLeft: theme.spacing(1),
        marginTop: theme.spacing(3),
        marginButton: theme.spacing(1),
        marginRight: theme.spacing(2),
    },
    deadlineField: {
        width: 100,
        marginLeft: theme.spacing(1),
        marginTop: theme.spacing(3),
        marginButton: theme.spacing(1),
        marginRight: theme.spacing(2),
    },
    projectValueField: {
        width: 300,
        marginTop: theme.spacing(1),
        marginButton: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    projectPaymentDateField: {
        width: 200,
        marginLeft: theme.spacing(1),
        marginTop: theme.spacing(1),
        marginButton: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    paymentConditionField: {
        width: 260,
        marginTop: theme.spacing(1),
        marginButton: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    instalmentsField: {
        width: 100,
        marginLeft: theme.spacing(1),
        marginTop: theme.spacing(1),
        marginButton: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    invoiceResponsibleField: {
        width: 300,
        marginTop: theme.spacing(1),
        marginButton: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    invoiceDateField: {
        width: 100,
        marginTop: theme.spacing(1),
        marginButton: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    submitButton: {
        backgroundColor: "var(--dark-seafoam-green)",
        "&:hover": {
            backgroundColor: "var(--green)",
            borderColor: "#385cac",
        },
    },
    loadingIndicator: {
        color: "green",
        marginLeft: theme.spacing(2),
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    letterCheckboxContainer: {
        flex: 1,
        marginTop: "2em",
    },
    letterCheckboxLabel: {
        fontFamily: "Roboto",
        paddingTop: "1px",
        fontSize: 16,
        color: "#4e4e4e;",
    },
    letterCheckbox: {
        "&$checked": {
            color: "red",
        },
    },
    checked: {},
    projectStudyArea: {
        marginTop: theme.spacing(1),
        display: "flex",
    },
    newStudyArea: {
        marginTop: theme.spacing(2),
    },
}));
