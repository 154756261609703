/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

import { Link, Redirect } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { LocalizationProvider, DatePicker } from "@mui/lab";
import AdapterMoment from "@mui/lab/AdapterMoment";
import {
    TextField,
    Button,
    SnackbarContent,
    IconButton,
    Icon,
    CircularProgress,
    Typography,
} from "@mui/material";
import classNames from "classnames";
import moment from "moment";

import "./ProjectHistoryForm.css";
import { makeStyles } from "@mui/styles";

import {
    getDocumentData,
    callFunction,
} from "../../../../services/data-service";

const useStyles = makeStyles(theme => ({
    inputField: {
        marginTop: theme.spacing(2),
        minWidth: 380,
    },
    passwordField: {
        width: 144,
    },
    button: {
        marginRight: theme.spacing(2),
    },
    submitButton: {
        color: "var(--green)",
    },
    cancelButton: {
        color: "var(--red)",
    },
    buttonIcon: {
        marginLeft: theme.spacing(1),
    },
    formControl: {
        marginTop: theme.spacing(1),
        marginButton: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    projectPermissions: {
        marginTop: theme.spacing(1),
    },
    loadingIndicator: {
        color: "green",
        marginLeft: theme.spacing(2),
    },

    error: {
        backgroundColor: theme.palette.error.dark,
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
}));

export default function ProjectHistoryForm(props) {
    const classes = useStyles();

    const [state, setState] = useState({
        isLoading: true,
        project: {},
        errors: [],
    });
    const { project, updated, isLoading, customerType, errors } = state;

    useEffect(() => {
        const projectId = props.match.params.id;
        if (projectId) fetchData(projectId);
    }, [props.match.params]);

    const fetchData = async projectId => {
        setState({
            ...state,
            isLoading: true,
        });

        const project = await getDocumentData(`projects/${projectId}`);
        if (project.id) {
            const customerType = project.customer.name
                .toLowerCase()
                .match(/ra.zen/)
                ? "raizen"
                : "default";
            setState({
                ...state,
                project,
                isLoading: false,
                customerType,
            });
        } else {
            const errors = [];
            errors.push("Projeto não encontrado.");
            setState({
                ...state,
                errors,
                isLoading: false,
            });
        }
    };

    if (updated) {
        const path = "/project-history";
        return (
            <Redirect
                to={{
                    pathname: path,
                }}
            />
        );
    }

    const renderForm = () => {
        const getCustomerName = project => {
            const studyArea = project.studyArea ? project.studyArea.name : "";
            const customerName = project.customer
                ? project.customer.name
                : project.customerName;
            return studyArea ? `${customerName} - ${studyArea}` : customerName;
        };

        return (
            <form
                className={classes.container}
                autoComplete="off"
                onSubmit={onSubmit}
            >
                <div className={"section"}>
                    <TextField
                        className={classes.inputField}
                        variant="filled"
                        type="text"
                        id="customerName"
                        label="Cliente"
                        onChange={onInputChange("customerName")}
                        required={false}
                        InputProps={{
                            readOnly: true,
                            disabled: true,
                        }}
                        value={getCustomerName(project) || project.customerName}
                    />
                </div>
                <div className={"section"}>
                    <TextField
                        className={classes.inputField}
                        type="text"
                        variant="filled"
                        id="lastProjectNumber"
                        label="Último projeto"
                        onChange={onInputChange("lastProjectNumber")}
                        required={false}
                        InputProps={{
                            readOnly: true,
                            disabled: true,
                        }}
                        value={project.number || ""}
                    />
                </div>
                <div className={"section"}>
                    <TextField
                        className={classes.inputField}
                        type="text"
                        variant="filled"
                        id="lastStudyName"
                        label="Último estudo"
                        onChange={onInputChange("lastStudyName")}
                        required={false}
                        InputProps={{
                            readOnly: true,
                            disabled: true,
                        }}
                        value={project.study.name || ""}
                    />
                </div>
                <div className={"section"}>
                    <TextField
                        className={classes.inputField}
                        type="text"
                        variant="filled"
                        id="lastProjectCompletedAt"
                        label="Data do último estudo"
                        onChange={onInputChange("lastProjectCompletedAt")}
                        required={false}
                        InputProps={{
                            readOnly: true,
                            disabled: true,
                        }}
                        value={
                            moment(project.completedAt).format("DD/MM/YYYY") ||
                            ""
                        }
                    />
                </div>
                <div className={"line"} />
                <div className={"section"}>
                    <TextField
                        className={classes.inputField}
                        type="text"
                        // variant="outlined"
                        id="deliveryProtocol"
                        label="Protocolo de entrega"
                        onChange={onInputChange("deliveryProtocol")}
                        required={false}
                        value={project.deliveryProtocol || ""}
                    />
                </div>
                {customerType === "raizen" ? (
                    <div className={"section"}>
                        <LocalizationProvider
                            dateAdapter={AdapterMoment}
                            adapterLocale="pt-br"
                        >
                            <DatePicker
                                className={classes.inputField}
                                format="DD/MM/YYYY"
                                disablePast={false}
                                disabled={isLoading}
                                labelFunc={date => {
                                    return project.deliveryProtocolDate
                                        ? date.format("DD/MM/YYYY")
                                        : "";
                                }}
                                label="Upload Raízen (data)"
                                value={project.deliveryProtocolDate}
                                onChange={onDateChange("deliveryProtocolDate")}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        margin="dense"
                                    />
                                )}
                            />
                        </LocalizationProvider>
                    </div>
                ) : null}
                <div className={"section"}>
                    <TextField
                        className={classes.inputField}
                        type="number"
                        // variant="outlined"
                        id="nextStudyProvisioning"
                        label="Projeção proximo estudo (dias)"
                        inputProps={{
                            min: "1",
                        }}
                        onChange={onInputChange("nextStudyProvisioning")}
                        required={false}
                        value={project.nextStudyProvisioning || ""}
                    />
                </div>
                <div className={"section"}>
                    {project.nextStudyProvisioning ? (
                        <div className={"days-info"}>
                            <Typography variant="subtitle2" gutterBottom>
                                Data projeção proximo estudo:{" "}
                                {moment(project.nextStudyStartAt).format(
                                    "DD/MM/YYYY",
                                ) || ""}
                            </Typography>
                        </div>
                    ) : null}
                </div>
                <div className={"footer"}>
                    <Link to={"/project-history"}>
                        <Button
                            className={classNames(
                                classes.button,
                                classes.cancelButton,
                            )}
                        >
                            Cancelar
                        </Button>
                    </Link>
                    <Button
                        className={classNames(
                            classes.button,
                            classes.submitButton,
                        )}
                        type="submit"
                        disabled={isLoading}
                    >
                        Salvar
                        {isLoading && (
                            <CircularProgress
                                size={18}
                                thickness={4}
                                className={classes.loadingIndicator}
                            />
                        )}
                    </Button>
                </div>
            </form>
        );
    };

    const renderErrors = () => {
        if (!errors || !errors.length) return "";

        return (
            <div className="form-errors">
                {errors.map((error, key) => {
                    return (
                        <SnackbarContent
                            key={key}
                            className={classNames(classes.error)}
                            aria-describedby="error-message"
                            message={
                                <span
                                    id="error-message"
                                    className={classes.message}
                                >
                                    <Icon
                                        className={classNames(
                                            classes.icon,
                                            classes.iconVariant,
                                        )}
                                    />
                                    {error}
                                </span>
                            }
                            action={[
                                <IconButton
                                    key="close"
                                    aria-label="Close"
                                    color="inherit"
                                    className={classes.close}
                                    onClick={() => onCloseError(key)}
                                >
                                    <CloseIcon className={classes.icon} />
                                </IconButton>,
                            ]}
                        />
                    );
                })}
            </div>
        );
    };

    const onCloseError = key => {
        errors.splice(key, 1);
        setState({
            ...state,
            errors,
        });
    };

    const onInputChange = name => event => {
        project[name] = event.target.value;

        if (name === "nextStudyProvisioning") {
            project.nextStudyStartAt = moment().add(
                project.nextStudyProvisioning,
                "days",
            );
        }

        setState({
            ...state,
            project,
        });
    };

    const onDateChange = name => date => {
        project[name] = date.format();
        setState({
            ...state,
            project,
        });
    };

    const onSubmit = async event => {
        event.preventDefault();

        setState({
            ...state,
            isLoading: true,
        });

        try {
            await callFunction("updateProjectHistoryData", {
                projectId: project.id,
                deliveryProtocol: project.deliveryProtocol,
                deliveryProtocolDate: project.deliveryProtocolDate,
                nextStudyProvisioning: project.nextStudyProvisioning,
                nextStudyStartAt: moment(
                    project.nextStudyStartAt,
                ).toISOString(),
            });
            setState({
                ...state,
                isLoading: false,
                updated: true,
            });
        } catch (error) {
            console.error("Could not save project-history", error);
            errors.push("Não foi possivel gerar um provisionamento");
            setState({
                ...state,
                isLoading: false,
                errors,
            });
        }
    };

    return (
        <div className="project-history-form">
            <div className={"header"}>
                <h1 className={"header-title"}>Histório de projeto</h1>
            </div>
            <div className={"content"}>
                {renderErrors()}
                {project.id ? renderForm() : null}
            </div>
        </div>
    );
}
