import React from "react";

import ReactDOM from "react-dom";

import Root from "./components/root";
import config from "./configs/app.config";
import * as serviceWorker from "./serviceWorker";

config.loadEnvironment();

ReactDOM.render(
    <React.StrictMode>
        <Root />
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
