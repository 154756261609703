import moment from "moment";
import { Store as notifications } from "react-notifications-component";

import notificationOpts from "../constants/notification-options";
import notificationType from "../constants/notification-types";
import OrderStatus from "../constants/order-status";
import {
    createDocument,
    getCleanData,
    Timestamp,
    updateDocument,
} from "../services/data-service";

export default function useSaveOrder() {
    return order => {
        const date =
            order.date instanceof Timestamp
                ? order.date
                : Timestamp.fromDate(moment(order.date).toDate());
        if (order.id) {
            return updateDocument(`orders/${order.id}`, {
                ...getCleanData(order),
                status: order.status.id,
                date,
            }).catch(error => {
                console.error("Error updating order", order, error);
                notifications.addNotification({
                    ...notificationOpts,
                    type: notificationType.ERROR,
                    message: `Não foi possivel atualizar, tente novamente`,
                });
            });
        } else {
            return createDocument(`orders`, {
                ...getCleanData(order),
                status: OrderStatus.NEW,
                date: Timestamp.fromDate(moment(order.date).toDate()),
            })
                .then(data => {
                    notifications.addNotification({
                        ...notificationOpts,
                        type: notificationType.SUCCESS,
                        message: `Ordem de Serviço criada com sucesso`,
                    });
                    return data;
                })
                .catch(error => {
                    console.error("Error creating order", order, error);
                    notifications.addNotification({
                        ...notificationOpts,
                        type: notificationType.ERROR,
                        message: `Não foi possivel cadastrar, tente novamente`,
                    });
                });
        }
    };
}
