import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import textLabels from "../../../constants/mui-datatable-labels";
import { DataContext } from "../../../contexts";
import DatatableView from "../../commons/datatable-view";
import DeadlineStatusIcon from "../../commons/deadline-status-icon/DeadlineStatusIcon";

import Skeleton from "react-loading-skeleton";
import { pendencyName } from "../../../lib/pendency-utils";
import moment from "moment";

export default function TaskDatatable() {
    const { phases, isLoadingPhases: isLoading } = useContext(DataContext);
    const history = useHistory();

    const renderDatatable = () => {
        const data = phases.map(phase => {
            return {
                status: {
                    releasedAt: phase.releasedAt,
                    plannedDurationDays: phase.plannedDurationDays,
                },
                projectNumber: phase.project.number,
                customer: phase.customer.name,
                study: phase.study.name,
                name: phase.name,
                campaign: phase.campaign,
                pendency:
                    phase.status === "Pendency"
                        ? pendencyName(phase.pendencyType)
                        : false,
                assignee: phase.assignee.name,
                plannedDeliveryDate: moment(phase.plannedDeliveryDate).format(
                    "DD/MM/YYYY"
                ),
            };
        });

        const columns = [
            {
                name: "status",
                label: "Status",
                options: {
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <DeadlineStatusIcon
                                size={16}
                                startDate={value.releasedAt}
                                daysToDelivery={value.plannedDurationDays}
                            />
                        );
                    },
                },
            },
            {
                name: "projectNumber",
                label: "RACP",
                options: {
                    filter: true,
                    sort: true,
                },
            },
            {
                name: "customer",
                label: "Cliente",
                options: {
                    filter: true,
                    sort: true,
                },
            },
            {
                name: "study",
                label: "Estudo",
                options: {
                    filter: true,
                    sort: true,
                    filterType: "multiselect",
                },
            },
            {
                name: "name",
                label: "Etapa",
                options: {
                    filter: true,
                    sort: true,
                },
            },
            {
                name: "campaign",
                label: "Campanha",
                options: {
                    filter: true,
                    sort: true,
                },
            },
            {
                name: "pendency",
                label: "Pendência",
                options: {
                    filter: true,
                    sort: true,
                    filterType: "multiselect",
                    filterOptions: {
                        names: ["Com Pendência", "Sem Pendência"],
                        logic(pendency, filterVal) {
                            const show =
                                (filterVal.indexOf("Com Pendência") >= 0 &&
                                    pendency) ||
                                (filterVal.indexOf("Sem Pendência") >= 0 &&
                                    !pendency);
                            return !show;
                        },
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            value && (
                                <span className="label label-pendency label-margin-right">
                                    {value}
                                </span>
                            )
                        );
                    },
                },
            },
            {
                name: "assignee",
                label: "Atribuição",
                options: {
                    filter: true,
                    sort: true,
                    filterType: "multiselect",
                },
            },
            {
                name: "plannedDeliveryDate",
                label: "Prazo",
                options: {
                    filter: true,
                    sort: true,
                },
            },
        ];

        const options = {
            selectableRows: "none",
            filterType: "textField",
            elevation: 1,
            textLabels: textLabels,
            downloadOptions: {
                filename: "atividades.csv",
            },
            onDownload: (buildHead, buildBody, columns, data) => {
                columns = columns.map(column => {
                    column.name = column.label;
                    return column;
                });
                return "\uFEFF" + buildHead(columns) + buildBody(data);
            },
            onRowClick: (rowData, rowMeta) => {
                const documentId = phases[rowMeta.dataIndex].documentId;
                history.push(`/tasks/${documentId}`);
            },
        };

        const title = "Atividades";

        return (
            <DatatableView
                title={title}
                data={data}
                columns={columns}
                options={options}
            />
        );
    };

    const renderLoading = () => {
        const skeletonRows = () => {
            let rows = [];
            for (let i = 0; i < 20; i++) {
                rows.push(<Skeleton key={i} height={55} />);
            }
            return rows;
        };

        return skeletonRows();
    };

    return isLoading ? renderLoading() : renderDatatable();
}
