/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useEffect, useState } from "react";

import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ExpandMore from "@mui/icons-material/ChevronRight";
import DehaseIcon from "@mui/icons-material/Dehaze";
import ExpandLess from "@mui/icons-material/ExpandMore";
import Checkbox from "@mui/material/Checkbox";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Popover from "@mui/material/Popover";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import moment from "moment";
import Skeleton from "react-loading-skeleton";

import "./TaskChecklist.css";

import { Store as notifications } from "react-notifications-component";

import notificationOpts from "../../../../constants/notification-options";
import notificationType from "../../../../constants/notification-types";
import { DataContext } from "../../../../contexts";
import { callFunction } from "../../../../services/data-service";

const useStyles = makeStyles(theme => ({
    phase: {
        fontFamily: "Roboto",
        fontSize: "14px",
        fontWeight: "500",
        fontStyle: "normal",
        fontStretch: "normal",
        lineHeight: "1.14",
        letterSpacing: "normal",
        textAlign: "left",
    },
    currentPhase: {
        color: "#4192d9",
    },
    defaultPhase: {
        color: "var(--brownish-grey)",
    },
    task: {
        borderBottom: "2px solid #e6ecf2",
    },
    taskRoot: {
        loat: "right",
        padding: 0,
        color: "#4192d9",
        "&$checked": {
            color: "#4192d9",
        },
    },
    checked: {},
    popover: {
        pointerEvents: "none",
    },
    paper: {
        padding: theme.spacing(1),
    },
    notRelevantButton: {
        "&:hover": {
            backgroundColor: "#ececec",
        },
        padding: 0,
    },
    executionsTabs: {
        flexGrow: 1,
        width: "100%",
    },
}));

const theme = createTheme({
    palette: {
        primary: {
            main: "#4192d9",
        },
        secondary: {
            light: "#0066ff",
            main: "#4192d9",
            contrastText: "#ffcc00",
        },
    },
    typography: {
        useNextVariants: true,
    },
});

export default function TaskChecklist(props) {
    const classes = useStyles();
    const { phase, isLoadingPhase } = useContext(DataContext);

    const [isLoading, setIsLoading] = useState(true);
    const [checklist, setChecklist] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorElId, setAnchorElId] = useState(null);

    // build checklist
    useEffect(() => {
        if (!isLoadingPhase) {
            const newCheckList = {
                ...phase,
                open: true,
            };
            // if (checklist.id) return;
            setChecklist({ ...newCheckList });
        }
    }, [phase.id, isLoadingPhase]);

    // set is loading
    useEffect(() => {
        if (isLoadingPhase) {
            setIsLoading(true);
        } else {
            if (checklist.id) setIsLoading(false);
        }
    }, [isLoadingPhase, checklist]);

    const handleItemUpdateClick = (activity, notRelevant = false) => {
        updateChecklistItem(activity, notRelevant);

        // DTA-214 - ART, Somente um pode estar selecionado.
        // Caso risque a solicitada tickar a conforme ou caso ticar a solicitada
        // riscar a conforme automaticamente.
        if (checklist.name.match(/Atribuição.de.Relatorista/g)) {
            checklist.activities.forEach((element, elementIndex) => {
                if (element.activityId === activity.activityId) return;
                updateChecklistItem(element, !notRelevant);
            });
        }
    };

    const updateChecklistItem = async (activity, notRelevant = false) => {
        const oldValue = { ...activity };
        if (notRelevant) {
            activity.completed = false;
            activity.notRelevant = !activity.notRelevant;
        } else {
            activity.completed = !activity.completed;
            activity.notRelevant = false;
        }
        try {
            setActivityLoading(activity, true);
            handleUpdatedByClose();
            callFunction("tooglePhaseStatus", {
                projectId: phase.projectId,
                executionId: phase.executionId,
                phaseId: phase.id,
                activityId: activity.id,
                completed: activity.completed,
                notRelevant: activity.notRelevant,
            }).then(() => {
                setTimeout(() => {
                    setActivityLoading(activity, false);
                }, 100);
            });
        } catch (error) {
            activity.completed = oldValue.completed;
            activity.notRelevant = oldValue.notRelevant;
            notifications.addNotification({
                ...notificationOpts,
                type: notificationType.ERROR,
                message:
                    "Não foi possível atualizar a atividade, tente novamente",
            });
            console.error("Error updating phase: ", error);
        }
    };

    const handleUpdatedByOpen = event => {
        setAnchorEl(event.currentTarget);
        setAnchorElId(event.currentTarget.id);
    };

    const handleUpdatedByClose = () => {
        setAnchorEl(null);
        setAnchorElId(null);
    };

    const toogleExpandAll = () => {
        if (checklist.activities) {
            checklist.activities = checklist.activities.map(activity => {
                activity.open = !activity.open;
                return activity;
            });
            setChecklist({ ...checklist });
        }
    };

    const renderIcon = (open = null) => {
        return open ? <ExpandLess /> : <ExpandMore />;
    };

    const renderChecklistItem = (activity, index) => {
        const open = anchorElId === "activity[" + activity.id + "]";
        return (
            <div key={index}>
                <List component="div" disablePadding>
                    {activity.loading ? (
                        <div className={"task-item-loading"}>
                            <Skeleton height={24} />
                        </div>
                    ) : (
                        <ListItem className={classes.task}>
                            <div className={"task-item"}>
                                <div
                                    className={"task-completed"}
                                    id={"activity[" + activity.id + "]"}
                                    aria-owns={
                                        open
                                            ? "activity[" +
                                              activity.id +
                                              "]-popover"
                                            : undefined
                                    }
                                    aria-haspopup="true"
                                    onMouseEnter={handleUpdatedByOpen}
                                    onMouseLeave={handleUpdatedByClose}
                                >
                                    <Checkbox
                                        color="primary"
                                        classes={{
                                            root: classes.taskRoot,
                                            checked: classes.checked,
                                        }}
                                        checked={activity.completed || false}
                                        onClick={() =>
                                            handleItemUpdateClick(activity)
                                        }
                                        disabled={
                                            !phase.active || activity.loading
                                        }
                                        value="completed"
                                    />
                                </div>
                                <div className={"task-name"}>
                                    <span
                                        className={classNames({
                                            "task-not-relevant-item":
                                                activity.notRelevant === true,
                                            "task-loading-item":
                                                activity.loading === true,
                                        })}
                                    >
                                        {activity.name}
                                    </span>
                                </div>
                                <div
                                    className={"task-not-relevant"}
                                    id={"activity[" + activity.id + "]"}
                                    aria-owns={
                                        open
                                            ? "activity[" +
                                              activity.id +
                                              "]-popover"
                                            : undefined
                                    }
                                    aria-haspopup="true"
                                    onMouseEnter={handleUpdatedByOpen}
                                    onMouseLeave={handleUpdatedByClose}
                                >
                                    <IconButton
                                        size="small"
                                        className={classes.notRelevantButton}
                                        component="span"
                                        onClick={() =>
                                            handleItemUpdateClick(
                                                activity,
                                                true,
                                            )
                                        }
                                        disabled={
                                            !phase.active || activity.loading
                                        }
                                    >
                                        <FontAwesomeIcon
                                            className="task-not-relevant-icon"
                                            icon={faTimesCircle}
                                        />
                                    </IconButton>
                                </div>
                            </div>
                        </ListItem>
                    )}
                </List>
                {(activity.completed || activity.notRelevant) && (
                    <Popover
                        id={"activity[" + activity.id + "]-popover"}
                        className={classes.popover}
                        classes={{
                            paper: classes.paper,
                        }}
                        open={open}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                        }}
                        onClose={handleUpdatedByClose}
                        disableRestoreFocus
                    >
                        <Typography>
                            {activity.updatedBy ? (
                                activity.updatedBy.name
                            ) : (
                                <Skeleton width={120} />
                            )}
                        </Typography>
                        <Typography variant="caption" gutterBottom>
                            {activity.updatedAt ? (
                                moment(
                                    activity.updatedAt,
                                    moment.ISO_8601,
                                ).calendar()
                            ) : (
                                <Skeleton width={100} />
                            )}
                        </Typography>
                    </Popover>
                )}
            </div>
        );
    };

    const handleCheckClick = () => {
        checklist.open = !checklist.open;
        setChecklist({ ...checklist });
    };

    const setActivityLoading = (activity, loading) => {
        if (checklist.activities) {
            checklist.activities = checklist.activities.map(a => {
                if (a.id === activity.id) {
                    a.loading = loading;
                }
                return a;
            });
            setChecklist({ ...checklist });
        }
    };

    const renderChecklist = () => {
        const progressIndicator = () => {
            const total = checklist.activities.length;
            const done = checklist.activities.filter(
                activity => activity.completed || activity.notRelevant,
            ).length;
            return done + "/" + total;
        };

        return (
            <React.Fragment>
                <ListItem
                    button
                    className={classNames(classes.phase, classes.currentPhase)}
                    onClick={() => handleCheckClick()}
                >
                    {renderIcon(checklist.open)}
                    <span className={"phase-name"}>{checklist.name}</span>
                    <span className={"phase-progress-indicator"}>
                        {progressIndicator()}
                    </span>
                </ListItem>
                <Collapse in={checklist.open} timeout="auto" unmountOnExit>
                    {checklist.activities.map((activitiy, activitiyIndex) => {
                        return renderChecklistItem(activitiy, activitiyIndex);
                    })}
                </Collapse>
            </React.Fragment>
        );
    };

    return (
        <div className="task-checklist">
            <ThemeProvider theme={theme}>
                <div className="row">
                    <h5>Checklist de atividades</h5>
                    <div className="icon">
                        <DehaseIcon onClick={toogleExpandAll} />
                    </div>
                </div>
                <div className="checklist-phases">
                    {isLoading ? <Skeleton height={200} /> : renderChecklist()}
                </div>
            </ThemeProvider>
        </div>
    );
}
