/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useEffect, useState } from "react";

import {
    faTimesCircle,
    faSeedling,
    faTint,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DehaseIcon from "@mui/icons-material/Dehaze";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import LaunchIcon from "@mui/icons-material/Launch";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Popover from "@mui/material/Popover";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import moment from "moment";
import Skeleton from "react-loading-skeleton";

import "./ProjectChecklist.css";

import { Store as notifications } from "react-notifications-component";

import notificationOpts from "../../../../constants/notification-options";
import notificationType from "../../../../constants/notification-types";
import { projectFilterType } from "../../../../constants/project-filter-types";
import { DataContext, AuthContext } from "../../../../contexts";

import {} from "@mui/material";

import { Link } from "react-router-dom";

import { updateDocument } from "../../../../services/data-service";
import ProjectStatusLabel from "../../../commons/project-status-label";

const CHECKLIST_WATER = 0;
const CHECKLIST_SOIL = 1;

const useStyles = makeStyles(theme => ({
    phase: {
        fontFamily: "Roboto",
        fontSize: "14px",
        fontWeight: "500",
        fontStyle: "normal",
        fontStretch: "normal",
        lineHeight: "1.14",
        letterSpacing: "normal",
        textAlign: "left",
    },
    currentPhase: {
        color: "#4192d9",
    },
    defaultPhase: {
        color: "var(--brownish-grey)",
    },
    task: {
        borderBottom: "2px solid #e6ecf2",
    },
    taskRoot: {
        loat: "right",
        padding: 0,
        color: "#4192d9",
        "&$checked": {
            color: "#4192d9",
        },
    },
    checked: {},
    popover: {
        pointerEvents: "none",
    },
    paper: {
        padding: theme.spacing(1),
    },
    notRelevantButton: {
        "&:hover": {
            backgroundColor: "#ececec",
        },
        padding: 0,
    },
    executionsTabs: {
        flexGrow: 1,
        width: "100%",
    },
    table: {
        width: "100%",
    },
}));

const theme = createTheme({
    palette: {
        primary: {
            main: "#4192d9",
        },
        secondary: {
            light: "#0066ff",
            main: "#4192d9",
            contrastText: "#ffcc00",
        },
    },
    typography: {
        useNextVariants: true,
    },
});

export default function ProjectChecklist(props) {
    const classes = useStyles();

    const { filterType, readOnly } = props;
    const { user: currentUser } = useContext(AuthContext);
    const {
        project,
        isLoadingProject,
        isLoadingUsers,
        isLoadingInfos,
        isLoadingEvents,
        setPhaseCompleted,
    } = useContext(DataContext);

    const [isLoading, setIsLoading] = useState(true);
    const [checklist, setChecklist] = useState({});
    const [executions, setExecutions] = useState([]);
    const [execution, setExecution] = useState({});
    const [executionTab, setExecutionTab] = useState(0);
    const [selectedChecklist, setSelectedChecklist] = useState(CHECKLIST_WATER);
    const [openAll, setOpenAll] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorElId, setAnchorElId] = useState(null);
    const [isUserProjectDepartment, setIsUserProjectDepartment] =
        useState(false);

    const isLoadingData =
        isLoadingUsers || isLoadingProject || isLoadingInfos || isLoadingEvents;

    // load executions
    useEffect(() => {
        if (!isLoadingProject) {
            setExecutions([...project.executions]);
            setExecution({ ...project.executions[executionTab] });
        }
    }, [project, isLoadingProject]);

    // load checklist
    useEffect(() => {
        if (execution.id && project.checklists) {
            setIsLoading(true);
            const newChecklist = project.checklists.find(
                c => c.execution.id === execution.id,
            );
            // keep current open state
            if (
                checklist &&
                checklist.phases &&
                newChecklist &&
                newChecklist.phases
            ) {
                newChecklist.phases = newChecklist.phases.map(newPhase => {
                    const phase = checklist.phases.find(
                        p => p.id === newPhase.id,
                    );
                    newPhase.open = phase ? phase.open : false;
                    return newPhase;
                });
                // Remove phases for wells instalation if not needed
                if (!project.hasWellsInstallation) {
                    newChecklist.phases = newChecklist.phases.filter(
                        p => !p.forWellsInstallation,
                    );
                }
            }
            setChecklist({
                ...newChecklist,
            });
            setIsLoading(false);
        }
    }, [execution, project.checklists]);

    // check current user role
    useEffect(() => {
        if (currentUser) {
            setIsUserProjectDepartment(
                currentUser.roles.some(role => {
                    return role === "PRJ";
                }),
            );
        }
    }, [currentUser]);

    // set is loading
    useEffect(() => {
        setIsLoading(props.isLoading, isLoadingData);
    }, [props.isLoading, isLoadingData]);

    const handlePhaseClick = phase => {
        phase.open = !phase.open;
        checklist.phases[checklist.phases.findIndex(p => p.id === phase.id)] =
            phase;
        setChecklist({ ...checklist });
    };

    const handleItemUpdateClick = (
        task,
        taskIndex,
        phaseIndex,
        notRelevant = false,
    ) => {
        updateChecklistItem(task, taskIndex, phaseIndex, notRelevant);

        // DTA-214 - ART, Somente um pode estar selecionado.
        // Caso risque a solicitada tickar a conforme ou caso ticar a solicitada
        // riscar a conforme automaticamente.
        const phase = checklist.phases[phaseIndex];
        if (phase.name.match(/Atribuição.de.Relatorista/g)) {
            phase.tasks.forEach((element, elementIndex) => {
                if (element.activityId === task.activityId) return;
                updateChecklistItem(
                    element,
                    elementIndex,
                    phaseIndex,
                    !notRelevant,
                );
            });
        }
    };

    const updateChecklistItem = (
        task,
        taskIndex,
        phaseIndex,
        notRelevant = false,
    ) => {
        if (notRelevant) {
            task.completed = false;
            task.notRelevant = !task.notRelevant;
        } else {
            task.completed = !task.completed;
            task.notRelevant = false;
        }

        updateDocument(
            `projects/${project.id}/executions/${execution.id}/phases/${task.phase.id}/activities/${task.id}`,
            {
                completed: task.completed,
                notRelevant: task.notRelevant,
            },
        )
            .then(() => {
                // notifications.addNotification({
                //     ...notificationOpts,
                //     type: notificationType.SUCCESS,
                //     message: "Atividade atualizada com sucesso",
                // });
                const activities = checklist.phases[phaseIndex].activities;
                const completed = activities.length
                    ? !activities.some(
                          activity =>
                              !activity.completed && !activity.notRelevant,
                      )
                    : false;
                setPhaseCompleted(completed);
            })
            .catch(error => {
                notifications.addNotification({
                    ...notificationOpts,
                    type: notificationType.ERROR,
                    message:
                        "Não foi possível atualizar a atividade, tente novamente",
                });
                console.error("Error updating task: ", error);
            });
    };

    const handleUpdatedByOpen = event => {
        setAnchorEl(event.currentTarget);
        setAnchorElId(event.currentTarget.id);
    };

    const handleUpdatedByClose = () => {
        setAnchorEl(null);
        setAnchorElId(null);
    };

    const handleTabChange = (event, value) => {
        setExecutionTab(value);
        setExecution({ ...executions[value] });
    };

    const openAllPhases = () => {
        if (checklist.phases) {
            checklist.phases = checklist.phases.map(phase => {
                phase.open = !openAll;
                return phase;
            });
            setChecklist({ ...checklist });
            setOpenAll(!openAll);
        }
    };

    const renderExecutionTabs = () => {
        if (isLoading) return "";
        if (executions.length <= 1) return "";
        if (!filterType === projectFilterType.ALL) return "";

        return (
            <div className="checklist-tabs">
                <div className={classes.executionsTabs}>
                    <Tabs
                        value={executionTab}
                        onChange={handleTabChange}
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        {executions.map((e, index) => {
                            return (
                                <Tab
                                    key={index}
                                    label={
                                        "Checklist " +
                                        (executions.length - index)
                                    }
                                />
                            );
                        })}
                    </Tabs>
                </div>
                {executions[executionTab].reasonId && (
                    <div className="execution-info">
                        <dl>
                            <dt>Responsável:</dt>
                            <dd>
                                {executions[executionTab].createdBy
                                    ? executions[executionTab].createdBy.name
                                    : "-"}
                            </dd>

                            {executions[executionTab].reason &&
                                executions[executionTab].reason.id !==
                                    "R-P-6" && (
                                    <div>
                                        <dt>Etapa Origem:</dt>
                                        <dd>
                                            {executions[executionTab].rootPhase
                                                ? executions[executionTab]
                                                      .rootPhase.name
                                                : "-"}
                                        </dd>
                                    </div>
                                )}

                            <dt>Motivo:</dt>
                            <dd>
                                {executions[executionTab].reason
                                    ? executions[executionTab].reason.name
                                    : "-"}
                            </dd>

                            <dt>Criado em:</dt>
                            <dd>
                                {executions[executionTab].createdAt
                                    ? moment(
                                          executions[executionTab].createdAt,
                                          moment.ISO_8601,
                                      ).format("LLL")
                                    : "-"}
                            </dd>
                        </dl>
                    </div>
                )}
            </div>
        );
    };

    const renderPhase = (phase, phaseIndex) => {
        const progressIndicator = () => {
            const total = phase.tasks.length;
            const done = phase.tasks.filter(
                task => task.completed || task.notRelevant,
            ).length;
            return done + "/" + total;
        };

        return (
            <React.Fragment key={phaseIndex}>
                <TableRow className={classes.table}>
                    <TableCell>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => handlePhaseClick(phase, phaseIndex)}
                        >
                            {phase.open ? (
                                <KeyboardArrowUpIcon />
                            ) : (
                                <KeyboardArrowDownIcon />
                            )}
                        </IconButton>
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {phase.name}
                    </TableCell>
                    <TableCell align="right">
                        {phase.assignee && phase.assignee.name}
                    </TableCell>
                    <TableCell align="right">
                        <ProjectStatusLabel
                            size={12}
                            status={phase.status}
                            pendencyType={phase.pendencyType}
                        />
                    </TableCell>
                    <TableCell align="right">{progressIndicator()}</TableCell>
                    <TableCell align="right">
                        <Link to={`/tasks/${phase.documentId}`}>
                            <IconButton aria-label="expand row" size="small">
                                <LaunchIcon />
                            </IconButton>
                        </Link>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={6}
                    >
                        <Collapse in={phase.open} timeout="auto" unmountOnExit>
                            <Box margin={1}>
                                {phase.tasks.map((task, taskIndex) =>
                                    renderChecklist(
                                        task,
                                        taskIndex,
                                        phaseIndex,
                                    ),
                                )}
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        );
    };

    const renderChecklist = (task, index, phaseIndex) => {
        const open = anchorElId === "task[" + task.id + "]";
        const phase = checklist.phases[phaseIndex];
        const isSurvayorAdd = project.surveyorId !== undefined;
        const advancedTickSurvayorTicket = () => {
            return (
                phase.selectSurveyor &&
                isSurvayorAdd &&
                isUserProjectDepartment &&
                project.executionId === execution.id
            );
        };

        const disabled = () => {
            if (advancedTickSurvayorTicket()) {
                return false;
            } else {
                return readOnly || !phase.active;
            }
        };
        return (
            <div key={index}>
                <List component="div" disablePadding>
                    <ListItem className={classes.task} disabled={execution > 0}>
                        <div className={"task-item"}>
                            <div
                                className={"task-completed"}
                                id={"task[" + task.id + "]"}
                                aria-owns={
                                    open
                                        ? "task[" + task.id + "]-popover"
                                        : undefined
                                }
                                aria-haspopup="true"
                                onMouseEnter={handleUpdatedByOpen}
                                onMouseLeave={handleUpdatedByClose}
                            >
                                <Checkbox
                                    color="primary"
                                    classes={{
                                        root: classes.taskRoot,
                                        checked: classes.checked,
                                    }}
                                    checked={task.completed || false}
                                    onClick={() =>
                                        handleItemUpdateClick(
                                            task,
                                            index,
                                            phaseIndex,
                                        )
                                    }
                                    disabled={disabled()}
                                    value="completed"
                                />
                            </div>
                            <div className={"task-name"}>
                                <span
                                    className={classNames({
                                        "task-not-relevant-item":
                                            task.notRelevant === true,
                                    })}
                                >
                                    {task.name}
                                </span>
                            </div>
                            <div
                                className={"task-not-relevant"}
                                id={"task[" + task.id + "]"}
                                aria-owns={
                                    open
                                        ? "task[" + task.id + "]-popover"
                                        : undefined
                                }
                                aria-haspopup="true"
                                onMouseEnter={handleUpdatedByOpen}
                                onMouseLeave={handleUpdatedByClose}
                            >
                                {!readOnly && (
                                    <IconButton
                                        size="small"
                                        className={classes.notRelevantButton}
                                        component="span"
                                        onClick={() =>
                                            handleItemUpdateClick(
                                                task,
                                                index,
                                                phaseIndex,
                                                true,
                                            )
                                        }
                                        disabled={!phase.active}
                                    >
                                        <FontAwesomeIcon
                                            className="task-not-relevant-icon"
                                            icon={faTimesCircle}
                                        />
                                    </IconButton>
                                )}
                            </div>
                        </div>
                    </ListItem>
                </List>
                {(task.completed || task.notRelevant) && (
                    <Popover
                        id={"task[" + task.id + "]-popover"}
                        className={classes.popover}
                        classes={{
                            paper: classes.paper,
                        }}
                        open={open}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                        }}
                        onClose={handleUpdatedByClose}
                        disableRestoreFocus
                    >
                        <Typography>
                            {task.updatedBy ? (
                                task.updatedBy.name
                            ) : (
                                <Skeleton width={120} />
                            )}
                        </Typography>
                        <Typography variant="caption" gutterBottom>
                            {task.updatedAt ? (
                                moment(
                                    task.updatedAt,
                                    moment.ISO_8601,
                                ).calendar()
                            ) : (
                                <Skeleton width={100} />
                            )}
                        </Typography>
                    </Popover>
                )}
            </div>
        );
    };

    const renderExecutionChecklist = () => {
        if (checklist.phases) {
            return (
                <TableContainer component={"div"}>
                    <Table aria-label="collapsible table">
                        <TableHead>
                            <TableRow>
                                <TableCell />
                                <TableCell>Etapa</TableCell>
                                <TableCell align="right">Responsável</TableCell>
                                <TableCell align="right">Status</TableCell>
                                <TableCell align="right">Tarefas</TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {checklist.phases
                                .filter(
                                    phase =>
                                        (selectedChecklist ===
                                            CHECKLIST_WATER &&
                                            !phase.forWellsInstallation) ||
                                        (selectedChecklist === CHECKLIST_SOIL &&
                                            phase.forWellsInstallation),
                                )
                                .map((phase, index) => {
                                    return renderPhase(phase, index);
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
            );
        }
    };

    const handleChecklistTabChange = (event, value) => {
        setSelectedChecklist(value);
    };

    const renderChecklists = () => {
        const renderChecklistTabs = () => {
            if (project.hasWellsInstallation) {
                return (
                    <Tabs
                        value={selectedChecklist}
                        onChange={handleChecklistTabChange}
                        variant="scrollable"
                        scrollButtons="auto"
                        indicatorColor="primary"
                        textColor="primary"
                    >
                        <Tab
                            label="Checklist Água"
                            icon={<FontAwesomeIcon icon={faTint} />}
                        />
                        <Tab
                            label="Checklist Solo"
                            icon={<FontAwesomeIcon icon={faSeedling} />}
                        />
                    </Tabs>
                );
            }
        };

        return (
            <div>
                {renderExecutionTabs()}
                {renderChecklistTabs()}
                <div className="checklist-phases">
                    {isLoading ? (
                        <Skeleton height={200} />
                    ) : (
                        renderExecutionChecklist()
                    )}
                </div>
            </div>
        );
    };

    return (
        <div className="project-checklist">
            <ThemeProvider theme={theme}>
                <div className="row">
                    <h5>Checklist de atividades</h5>
                    <div className="icon">
                        <DehaseIcon onClick={openAllPhases} />
                    </div>
                </div>
                {renderChecklists()}
            </ThemeProvider>
        </div>
    );
}

ProjectChecklist.defaultProps = {
    readOnly: true,
    filterType: projectFilterType.ALL,
};
