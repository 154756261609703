import React, { useEffect, useState } from "react";

import { Autocomplete, TextField } from "@mui/material";

import { getCollectionData } from "../../../services/data-service";

export default function CustomerSelector({
    customer,
    label = "Razão Social do Empreendimento",
    variant = "standard",
    required = true,
    onSelected,
    ...props
}) {
    const [isLoading, setIsLoading] = useState(true);
    const [customers, setCustomers] = useState([]);

    useEffect(() => {
        getCollectionData("customers")
            .then(data =>
                setCustomers([
                    ...data.map(value => {
                        return {
                            id: value.id,
                            name: value.name,
                        };
                    }),
                ]),
            )
            .finally(() => setIsLoading(false));
    }, []);

    return (
        <Autocomplete
            {...props}
            value={customer && customer.id ? customer : null}
            onChange={(event, value) => onSelected && onSelected(value)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={option => option.name}
            options={customers}
            loading={isLoading}
            renderInput={params => (
                <TextField
                    {...params}
                    label={label}
                    required={required}
                    variant={variant}
                />
            )}
        />
    );
}
