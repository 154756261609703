/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, useContext } from "react";

import {
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    Button,
    CircularProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import cep from "cep-promise";
import classNames from "classnames";

import { AuthContext } from "../../../../../../contexts/auth.context";
import clone from "../../../../../../lib/clone";
import { phoneFormatter, cpfFormatter } from "../../../../../../lib/form-utils";
import {
    updateDocument,
    createDocument,
} from "../../../../../../services/data-service";

const useStyles = makeStyles(() => ({
    content: {
        display: "flex",
        justifyContent: "center",
    },
    inputField: {
        marginLeft: 10,
        marginRight: 10,
        marginBottom: 10,
        margin: 5,
    },
    nameField: {
        width: 500,
    },
    addressField: {
        width: 430,
    },
    numberField: {
        width: 50,
    },
    neighborhoodField: {
        width: 190,
    },
    cityField: {
        width: 190,
    },
    stateField: {
        width: 80,
    },
    responsibleNameField: {
        width: 240,
    },
    responsibleCpfField: {
        width: 240,
    },
    phoneField: {
        width: 240,
    },
    emailField: {
        width: 240,
    },
    cancelButton: {
        color: "red",
    },
    submitButton: {
        color: "green",
    },
    loadingIndicator: {
        color: "green",
        marginLeft: 10,
    },
}));

export default function NewStudyAreaDialog({
    dialogOpen,
    handleDialogClose,
    customer,
    receivedStudyArea,
    onCreated,
}) {
    const classes = useStyles();

    const { hasAnyRole } = useContext(AuthContext);

    const [studyArea, setStudyArea] = useState({});
    const [fieldError, setFieldError] = useState({});
    const [isCreatedStudyArea, setIsCreatedStudyArea] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const onInputChange = key => event => {
        setStudyArea({
            ...studyArea,
            [key]: event.target.value,
        });
    };

    const loadAddressByCep = value => {
        fieldError.cep = "";
        if (value) {
            setIsLoading(true);
            cep(value)
                .then(response => {
                    setStudyArea({
                        ...studyArea,
                        cep: response.cep,
                        address: response.street,
                        neighborhood: response.neighborhood,
                        city: response.city,
                        state: response.state,
                    });
                    setIsLoading(false);
                })
                .catch(error => {
                    fieldError.cep = error.message;
                    console.error(error);
                    setFieldError(fieldError);
                    setIsLoading(false);
                });
        } else {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (customer) {
            if (customer.id) {
                setStudyArea({
                    ...studyArea,
                    customer: { id: customer.id },
                    customerId: customer.id,
                });
            }
        }
    }, [customer]);

    useEffect(() => {
        if (receivedStudyArea) {
            if (receivedStudyArea.id) {
                setStudyArea(clone(receivedStudyArea));
                setIsCreatedStudyArea(true);
            } else {
                setStudyArea({});
                setIsCreatedStudyArea(false);
            }
        } else {
            setStudyArea({});
            setIsCreatedStudyArea(false);
        }
    }, [receivedStudyArea]);

    const onSubmitDialog = async event => {
        event.preventDefault();
        setIsLoading(true);

        try {
            // Edit study area
            if (isCreatedStudyArea) {
                updateDocument(
                    `customers/${studyArea.customer.id}/addresses/${studyArea.id}`,
                    studyArea,
                )
                    .then(() => {
                        setIsLoading(false);
                        handleDialogClose();
                    })
                    .catch(error => {
                        console.error("Error updating study area", error);
                    });
            }
            // create study area
            else {
                studyArea.customerId = customer.id;
                studyArea.customer = { id: customer.id };
                createDocument(
                    `customers/${studyArea.customer.id}/addresses`,
                    studyArea,
                )
                    .then(() => {
                        setIsLoading(false);
                        onCreated(studyArea);
                        handleDialogClose();
                    })
                    .catch(error => {
                        console.error("Error creating study area", error);
                    });
            }
        } catch (error) {
            setIsLoading(false);
            console.error("Error submitting study area", error);
        }
    };
    return (
        <Dialog open={dialogOpen} onClose={handleDialogClose}>
            <form onSubmit={onSubmitDialog} autoComplete="off">
                <DialogTitle>
                    {isCreatedStudyArea
                        ? studyArea.inactive
                            ? "Área de Estudo (desativada)"
                            : "Editar Área de Estudo"
                        : "Cadastrar Área de Estudo"}
                </DialogTitle>
                <DialogContent className={classes.content}>
                    <div className={classes.studyAreaForm}>
                        <div className={classes.row}>
                            <TextField
                                variant="standard"
                                label="Nome"
                                required={true}
                                className={classNames(
                                    classes.inputField,
                                    classes.nameField,
                                )}
                                value={studyArea.name || ""}
                                onChange={onInputChange("name")}
                                disabled={studyArea.inactive}
                            />
                        </div>
                        <div className={classes.row}>
                            <TextField
                                variant="standard"
                                label="CEP"
                                required={true}
                                className={classNames(
                                    classes.inputField,
                                    classes.nameField,
                                )}
                                error={fieldError.cep ? true : false}
                                helperText={fieldError.cep}
                                value={studyArea.cep || ""}
                                onChange={onInputChange("cep")}
                                disabled={studyArea.inactive}
                                onBlur={e => {
                                    loadAddressByCep(e.target.value);
                                }}
                            />
                        </div>
                        <div className={classes.row}>
                            <TextField
                                variant="standard"
                                label="Endereço"
                                required={true}
                                className={classNames(
                                    classes.inputField,
                                    classes.addressField,
                                )}
                                value={studyArea.address || ""}
                                onChange={onInputChange("address")}
                                disabled={studyArea.inactive}
                            />
                            <TextField
                                variant="standard"
                                type="number"
                                label="nº"
                                required={true}
                                className={classNames(
                                    classes.inputField,
                                    classes.numberField,
                                )}
                                value={studyArea.number || ""}
                                onChange={onInputChange("number")}
                                disabled={studyArea.inactive}
                            />
                        </div>
                        <div className={classes.row}>
                            <TextField
                                variant="standard"
                                label="Bairro"
                                required={true}
                                className={classNames(
                                    classes.inputField,
                                    classes.neighborhoodField,
                                )}
                                value={studyArea.neighborhood || ""}
                                onChange={onInputChange("neighborhood")}
                                disabled={studyArea.inactive}
                            />
                            <TextField
                                variant="standard"
                                label="Cidade"
                                required={true}
                                className={classNames(
                                    classes.inputField,
                                    classes.cityField,
                                )}
                                value={studyArea.city || ""}
                                onChange={onInputChange("city")}
                                disabled={studyArea.inactive}
                            />
                            <TextField
                                variant="standard"
                                label="Estado"
                                required={true}
                                className={classNames(
                                    classes.inputField,
                                    classes.stateField,
                                )}
                                value={studyArea.state || ""}
                                onChange={onInputChange("state")}
                                disabled={studyArea.inactive}
                            />
                        </div>
                        <div className={classes.row}>
                            <TextField
                                variant="standard"
                                label="Nome do responsável"
                                className={classNames(
                                    classes.inputField,
                                    classes.responsibleNameField,
                                )}
                                value={studyArea.responsibleName || ""}
                                onChange={onInputChange("responsibleName")}
                                disabled={studyArea.inactive}
                            />
                            <TextField
                                variant="standard"
                                label="CPF do responsável"
                                className={classNames(
                                    classes.inputField,
                                    classes.responsibleCpfField,
                                )}
                                value={cpfFormatter(
                                    studyArea.responsibleCPF || "",
                                )}
                                onChange={onInputChange("responsibleCPF")}
                                disabled={studyArea.inactive}
                            />
                        </div>
                        <div className={classes.row}>
                            <TextField
                                variant="standard"
                                label="Telefone"
                                className={classNames(
                                    classes.inputField,
                                    classes.phoneField,
                                )}
                                value={phoneFormatter(studyArea.phone || "")}
                                onChange={onInputChange("phone")}
                                disabled={studyArea.inactive}
                            />
                            <TextField
                                variant="standard"
                                type="email"
                                label="E-mail"
                                className={classNames(
                                    classes.inputField,
                                    classes.emailField,
                                )}
                                value={studyArea.email || ""}
                                onChange={onInputChange("email")}
                                disabled={studyArea.inactive}
                            />
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleDialogClose}
                        className={classNames(
                            classes.button,
                            classes.cancelButton,
                        )}
                        disabled={isLoading}
                    >
                        Cancelar
                    </Button>
                    {studyArea.inactive ? null : (
                        <Button
                            type="submit"
                            className={classNames(
                                classes.button,
                                classes.submitButton,
                            )}
                            disabled={isLoading || !hasAnyRole(["CDC"])}
                        >
                            {isCreatedStudyArea ? "Atualizar" : "Cadastrar"}
                            {isLoading && (
                                <CircularProgress
                                    size={18}
                                    thickness={4}
                                    className={classes.loadingIndicator}
                                />
                            )}
                        </Button>
                    )}
                </DialogActions>
            </form>
        </Dialog>
    );
}
