/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import { Alert, Skeleton } from "@mui/material";
import Stack from "@mui/material/Stack";

import OrderTaskF037 from "./OrderTaskF037";
import OrderTaskGeneric from "./OrderTaskGeneric";
import { OrderTaskModel } from "../../../models";
import { getNewOrder, getOrder, getTaskList } from "../../../services";
import { onDocumentSnapshot } from "../../../services/data-service";

export default function OrderTaskView({ ...props }) {
    const { match = {} } = props;
    const { orderId, taskId } = match.params;
    const [isLoading, setLoading] = useState(true);
    const [order, setOrder] = useState(getNewOrder());
    const [listening, setListening] = useState({});

    const task = new OrderTaskModel({
        ...getTaskList(order).find(t => `${t.id}` === `${taskId}`),
    });

    const fetchOrder = () => {
        if (orderId) {
            setLoading(true);
            if (listening.unsubscribe) listening.unsubscribe();
            const unsubscribe = onDocumentSnapshot(
                `orders/${orderId}`,
                snapshot => {
                    setOrder(
                        snapshot.exists()
                            ? getOrder(snapshot.data())
                            : { notFound: true },
                    );
                    setLoading(false);
                },
            );
            setListening({ unsubscribe });
        } else {
            setOrder(getNewOrder());
        }
    };

    // fetch data
    useEffect(() => {
        if (order.id !== orderId) fetchOrder();
    }, [orderId, order.id]);

    if (order.notFound || !task) {
        return (
            <Stack sx={{ width: "100%" }} spacing={2} padding={4}>
                <Alert severity="error">
                    Não foi possivel encontrar detalhes desta tarefa
                </Alert>
            </Stack>
        );
    }

    if (isLoading) {
        return <Skeleton variant="rectangular" height={250} />;
    }

    if (task.form && (task.form.key === "F037" || task.form.id === "F037")) {
        return <OrderTaskF037 order={order} task={task} />;
    }

    return <OrderTaskGeneric order={order} task={task} />;
}
