/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useState } from "react";

import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import MuiDialogActions from "@mui/material/DialogActions";
import MuiDialogContent from "@mui/material/DialogContent";
import MuiDialogTitle from "@mui/material/DialogTitle";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { makeStyles, withStyles } from "@mui/styles";
import moment from "moment";

import { AuthContext, DataContext } from "../../../../contexts";
import { callFunction } from "../../../../services/data-service";
import ConfirmTaskDelayDialog from "../confirm-task-delay-dialog/ConfirmTaskDelayDialog";
import "./CompleteTaskDialog.css";

const useStyles = makeStyles(theme => ({
    cancelButton: {
        color: "red",
    },
    submitButton: {
        color: "green",
    },
    loadingIndicator: {
        color: "green",
        marginLeft: theme.spacing(2),
    },
    messageField: {
        minWidth: 600,
    },
    nextPhaseTableContainer: {
        paddingTop: theme.spacing(2),
        overflowX: "inherit",
    },
}));

const SelectAutocomplete = withStyles(theme => ({
    root: {
        lineHeight: "1.43",
        textAlign: "left",
        color: "#6a6a6a",
        borderStyle: "none",
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        "& label": {
            fontFamily: "Roboto",
            fontSize: 14,
            fontWeight: 500,
            fontStyle: "normal",
            fontStretch: "normal",
            letterSpacing: "normal",
            textAlign: "center",
            margin: 0,
        },
    },
    inputRoot: {
        fontFamily: "Roboto",
        fontSize: 14,
        fontWeight: 500,
        fontStyle: "normal",
        fontStretch: "normal",
        lineHeight: "1.43",
        letterSpacing: "normal",
        textAlign: "left",
        color: "#6a6a6a",
        "& fieldset": {
            border: "none",
        },
        backgroundColor: "rgba(153, 153, 153, 0.2)",
    },
}))(Autocomplete);

const DialogTitle = withStyles(theme => ({
    root: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}))(props => {
    const { children, classes } = props;
    return <MuiDialogTitle className={classes.root}>{children}</MuiDialogTitle>;
});

const DialogContent = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const theme = createTheme({
    palette: {
        primary: {
            main: "#3fb165",
        },
        secondary: {
            light: "#0066ff",
            main: "#0044ff",
            contrastText: "#ffcc00",
        },
    },
    typography: {
        useNextVariants: true,
    },
});

const wellsInstallationOptions = [
    { label: "Sim", value: true },
    { label: "Não", value: false },
];

export default function CompleteTaskDialog({
    open,
    onClose,
    onComplete,
    onError,
}) {
    const classes = useStyles();

    const [openConfirmTaskDelayDialog, setOpenConfirmTaskDelayDialog] =
        useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [isCompleting, setIsCompleting] = useState(false);
    const [hasWellsInstallation, setHasWellsInstallation] = useState(false);
    const [assignees, setAssignees] = useState([]);
    const [message, setMessage] = useState(undefined);
    const [nextPhasesInfo, setNextPhasesInfo] = useState([]);
    const [surveyor, setSurveyor] = useState(undefined);
    const [data, setData] = useState({ nextPhaseInfo: {}, loading: true });
    const [required, setRequired] = useState([]);

    const { user: currentUser } = useContext(AuthContext);

    const { project, projectId, phase, users, isLoadingPhase, isLoadingUsers } =
        useContext(DataContext);

    // fetch assignes
    useEffect(() => {
        setAssignees([
            ...users
                .filter(user => !user.inactive)
                .map(user => ({
                    id: user.id,
                    name: user.name,
                    email: user.email,
                })),
        ]);
    }, [users]);

    // fetch data
    useEffect(() => {
        if (isLoadingPhase || isLoadingUsers) {
            setIsLoading(true);
            return;
        }

        const actualDurationDays = () => {
            const now = moment();
            const releasedAt = moment(phase.releasedAt, moment.ISO_8601);
            return moment.duration(now.diff(releasedAt)).asDays();
        };

        const isPhaseOverdue = () => {
            return moment().isAfter(
                moment(phase.plannedDeadline, moment.ISO_8601),
            );
        };

        const requestData = {};
        requestData.actualStartDate = phase.releasedAt;
        requestData.plannedStartDate = phase.plannedStartDate;
        requestData.plannedDeadline = phase.plannedDeadline;
        requestData.plannedDurationDays = phase.plannedDurationDays;
        requestData.actualDurationDays = actualDurationDays();
        requestData.currentPhaseName = phase.name;
        requestData.currentPhase = phase;
        requestData.nextPhases = phase.nextPhases;
        requestData.isPhaseOverdue = isPhaseOverdue();
        requestData.isLastPhase = phase.isLastPhase;
        requestData.surveyors = users
            .filter(user => !user.inactive)
            .map(user => ({
                id: user.id,
                name: user.name,
                email: user.email,
            }));
        requestData.project = project;

        requestData.project.hasWellsInstallation = requestData.project
            .hasWellsInstallation
            ? true
            : false;

        setData({ ...requestData, loading: false });

        if (!phase.isLastPhase) {
            setNextPhasesInfo(
                phase.nextPhases.map(p => {
                    return {
                        ...p,
                        assignee: assignees.find(a => a.id === p.defaultUser),
                    };
                }),
            );
        }
    }, [project, phase, isLoadingPhase, users, isLoadingUsers, assignees]);

    useEffect(() => {
        if (data.loading && !assignees.length) {
            setIsLoading(true);
        } else {
            setIsLoading(false);
        }
    }, [data, assignees]);

    const completeTask = (delayInfo = null) => {
        setIsCompleting(true);
        try {
            const request = {
                projectId: project.id || projectId,
                executionId: phase.executionId,
                phaseId: phase.id,
                completeMessage: delayInfo ? delayInfo.message : message,
                delayInfo: delayInfo,
                hasWellsInstallation: hasWellsInstallation.value || false,
                completedBy: {
                    id: currentUser.id,
                    name: currentUser.name,
                    email: currentUser.email,
                },
                nextSurveyor: surveyor,
                nextPhasesInfo: nextPhasesInfo,
            };
            callFunction("completePhase", request).then(() => {
                if (onComplete) onComplete();
            });
        } catch (e) {
            console.error(`Error completing phase: ${e}`);
            if (onError) onError(e);
        }
    };

    const handleConfirmClick = async () => {
        if (data.currentPhase && data.currentPhase.selectSurveyor) {
            if (!surveyor) {
                if (!required.find(r => r === "surveyor")) {
                    required.push("surveyor");
                }
                setRequired([...required]);
                return;
            }
        }
        if (data.currentPhase && data.currentPhase.checkWellsInstallation) {
            if (!hasWellsInstallation) {
                if (!required.find(r => r === "hasWellsInstallation")) {
                    required.push("hasWellsInstallation");
                }
                setRequired([...required]);
                return;
            }
        }

        if (data.isPhaseOverdue) {
            setOpenConfirmTaskDelayDialog(true);
        } else {
            completeTask();
        }
    };

    const handleDialogClose = () => () => {
        if (onClose) onClose();
    };

    const handleMessageChange = event => {
        setMessage(event.target.value);
    };

    const onDelayDialogClose = () => {
        setOpenConfirmTaskDelayDialog(false);
        if (onClose) onClose();
    };

    const onDelayDialogConfirm = delayInfo => {
        completeTask(delayInfo);
    };

    const onSurveyorChange = option => {
        if (option) {
            if (required.find(r => r === "surveyor")) {
                const newRequired = required.filter(r => r !== "surveyor");
                setRequired([...newRequired]);
            }
        }
        setSurveyor(option);
    };

    const onWellsInstalationChange = async option => {
        if (option) {
            setIsLoading(true);
            data.project.hasWellsInstallation = option.value;
            if (required.find(r => r === "hasWellsInstallation")) {
                const newRequired = required.filter(
                    r => r !== "hasWellsInstallation",
                );
                setRequired(newRequired);
            }

            setHasWellsInstallation(option);
            setIsLoading(false);
        } else {
            data.project.hasWellsInstallation = false;
            setHasWellsInstallation(undefined);
        }
        setData({ ...data });
    };

    const onNextPhaseAssigneChange = (nextPhaseId, assignee) => {
        if (!assignee) return;
        setNextPhasesInfo([
            ...nextPhasesInfo.map(e => {
                if (e.phaseId === nextPhaseId) {
                    return {
                        ...e,
                        assignee: assignee,
                        defaultUser: assignee.id,
                    };
                }
                return e;
            }),
        ]);
    };

    const getDurationLabel = (duration = 0) => {
        const value = Math.round(duration);
        let label = "";
        if (value > 1) label = value + " dias";
        if (value === 1.0) label = value + " dia";
        if (duration.toPrecision(2) < 1) {
            const hours = Math.round(duration * 24);
            if (hours >= 1) {
                label = hours + (hours === 1 ? " hora" : " horas");
            } else {
                label = "< 1 hora";
            }
        }
        return label;
    };

    const showSurveyor = data.currentPhase && data.currentPhase.selectSurveyor;

    const showSurveyorRequired =
        showSurveyor &&
        !surveyor &&
        required.find(r => r === "surveyor") !== null;

    const showSurveyorHelperText = showSurveyorRequired ? "Obrigatório" : "";

    const showWellsQuestion =
        data.currentPhase && data.currentPhase.checkWellsInstallation;

    const showWellsQuestionRequired =
        showWellsQuestion &&
        !hasWellsInstallation &&
        required.find(r => r === "hasWellsInstallation") !== null;

    const showWellsQuestionHelperText = showWellsQuestionRequired
        ? "Obrigatório"
        : "";

    return (
        <ThemeProvider theme={theme}>
            <Dialog
                open={open && !openConfirmTaskDelayDialog}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="md"
                disableBackdropClick
                disableEscapeKeyDown
            >
                <DialogTitle id="alert-dialog-title">
                    Concluir Etapa
                </DialogTitle>
                <DialogContent>
                    <div className="complete-task-dialog">
                        <div className="complete-task-details">
                            <dl>
                                <dt>Inicio planejado:</dt>
                                <dd>
                                    {moment(data.plannedStartDate).format(
                                        "DD/MM/YYYY HH:mm",
                                    )}
                                </dd>

                                <dt>Etapa iniciada em:</dt>
                                <dd>
                                    {moment(data.actualStartDate).format(
                                        "DD/MM/YYYY HH:mm",
                                    )}
                                </dd>

                                <dt>Prazo planejado</dt>
                                <dd>
                                    {moment(data.plannedDeadline).format(
                                        "DD/MM/YYYY HH:mm",
                                    )}
                                </dd>

                                <dt>Tempo planejado</dt>
                                <dd>
                                    {getDurationLabel(data.plannedDurationDays)}
                                </dd>

                                <dt>Tempo decorrido:</dt>
                                <dd>
                                    {getDurationLabel(data.actualDurationDays)}
                                </dd>

                                <dt>Etapa atual:</dt>
                                <dd>{data.currentPhaseName}</dd>
                            </dl>
                        </div>
                        <Box display="flex">
                            <Box paddingRight={1}>
                                <WellsInstallationContainer
                                    showWellsQuestion={showWellsQuestion}
                                    hasWellsInstallation={hasWellsInstallation}
                                    isLoading={isLoading || isCompleting}
                                    onWellsInstalationChange={
                                        onWellsInstalationChange
                                    }
                                    showWellsQuestionRequired={
                                        showWellsQuestionRequired
                                    }
                                    showWellsQuestionHelperText={
                                        showWellsQuestionHelperText
                                    }
                                />
                            </Box>
                            <Box paddingLeft={1}>
                                <SelectSurveyorContainer
                                    showSurveyor={showSurveyor}
                                    surveyor={surveyor}
                                    data={data}
                                    isLoading={isLoading || isCompleting}
                                    onSurveyorChange={onSurveyorChange}
                                    showSurveyorRequired={showSurveyorRequired}
                                    showSurveyorHelperText={
                                        showSurveyorHelperText
                                    }
                                />
                            </Box>
                        </Box>
                        <NextPhaseInfoContainer
                            data={data}
                            classes={classes}
                            nextPhasesInfo={nextPhasesInfo}
                            onNextPhaseAssigneChange={onNextPhaseAssigneChange}
                            assignees={assignees}
                            isLoading={isLoading || isCompleting}
                        />
                        <div className="complete-task-message">
                            <TextField
                                id="complete-task-message"
                                fullWidth
                                value={message}
                                onChange={handleMessageChange}
                                label="Adicionar informação"
                                multiline
                                rows="4"
                                className={classes.messageField}
                                margin="normal"
                                disabled={isLoading || isCompleting}
                            />
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        className={classes.submitButton}
                        onClick={handleConfirmClick}
                        disabled={isLoading || isCompleting}
                    >
                        Concluir
                        {(isLoading || isCompleting) && (
                            <CircularProgress
                                size={18}
                                thickness={4}
                                className={classes.loadingIndicator}
                            />
                        )}
                    </Button>
                    <Button
                        className={classes.cancelButton}
                        onClick={handleDialogClose("cancel")}
                        disabled={isLoading || isCompleting}
                    >
                        Cancelar
                    </Button>
                </DialogActions>
            </Dialog>
            {openConfirmTaskDelayDialog && (
                <ConfirmTaskDelayDialog
                    open={openConfirmTaskDelayDialog}
                    onClose={onDelayDialogClose}
                    onConfirm={onDelayDialogConfirm}
                    message={message}
                />
            )}
        </ThemeProvider>
    );
}

function NextPhaseAssignee({
    nextPhaseInfo,
    id,
    onChange,
    assignees,
    isLoading,
}) {
    return (
        <SelectAutocomplete
            id={id}
            value={nextPhaseInfo.assignee}
            options={assignees}
            getOptionLabel={option => option.name}
            loading={isLoading}
            disabled={isLoading}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(event, value) => {
                onChange(nextPhaseInfo.phaseId, value);
            }}
            style={{
                width: 300,
            }}
            renderInput={params => (
                <TextField
                    {...params}
                    label="Concluir e atribuir para"
                    variant="outlined"
                    size="small"
                />
            )}
        />
    );
}

function NextPhaseInfoContainer({
    data,
    classes,
    nextPhasesInfo,
    onNextPhaseAssigneChange,
    assignees,
    isLoading,
}) {
    if (data.isLastPhase || !data.nextPhases) return null;
    return (
        <TableContainer
            component="div"
            className={classes.nextPhaseTableContainer}
        >
            <Table
                className={classes.table}
                size="small"
                aria-label="next-phase-info-table"
            >
                <TableHead>
                    <TableRow>
                        <TableCell>Proxima Etapa</TableCell>
                        <TableCell align="left">Atribuição</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {nextPhasesInfo
                        .filter(
                            p =>
                                !p.forWellsInstallation ||
                                p.forWellsInstallation ===
                                    data.project.hasWellsInstallation,
                        )
                        .map((nextPhaseInfo, index) => (
                            <TableRow key={index}>
                                <TableCell component="th" scope="row">
                                    {nextPhaseInfo.name}
                                </TableCell>
                                <TableCell align="left">
                                    <NextPhaseAssignee
                                        id={`nextPhaseAssignee-${index}`}
                                        nextPhaseInfo={nextPhaseInfo}
                                        onChange={onNextPhaseAssigneChange}
                                        assignees={assignees}
                                        isLoading={isLoading}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

function WellsInstallationContainer({
    showWellsQuestion,
    hasWellsInstallation,
    isLoading,
    onWellsInstalationChange,
    showWellsQuestionRequired,
    showWellsQuestionHelperText,
}) {
    return (
        showWellsQuestion && (
            <SelectAutocomplete
                value={hasWellsInstallation}
                id="checkWellsInstallation"
                options={wellsInstallationOptions}
                getOptionLabel={option => {
                    return option ? option.label : "";
                }}
                loading={isLoading}
                isOptionEqualToValue={(option, value) => {
                    return option.value === value;
                }}
                onChange={(event, value) => {
                    onWellsInstalationChange(value);
                }}
                style={{
                    width: 300,
                }}
                renderInput={params => (
                    <TextField
                        {...params}
                        label="Haverá instalação de poços? *"
                        variant="outlined"
                        error={showWellsQuestionRequired}
                        helperText={
                            showWellsQuestionRequired &&
                            showWellsQuestionHelperText
                        }
                        size="small"
                    />
                )}
            />
        )
    );
}

function SelectSurveyorContainer({
    showSurveyor,
    surveyor,
    data,
    isLoading,
    onSurveyorChange,
    showSurveyorRequired,
    showSurveyorHelperText,
}) {
    return (
        showSurveyor && (
            <SelectAutocomplete
                value={surveyor}
                id="project-surveyor"
                options={data.surveyors}
                getOptionLabel={option => option.name}
                loading={isLoading}
                disabled={isLoading}
                isOptionEqualToValue={(option, value) => option === value.id}
                onChange={(event, value) => {
                    onSurveyorChange(value);
                }}
                style={{
                    width: 300,
                }}
                renderInput={params => (
                    <TextField
                        {...params}
                        label="Atribuir relatorista *"
                        variant="outlined"
                        error={showSurveyorRequired}
                        helperText={
                            showSurveyorRequired && showSurveyorHelperText
                        }
                        size="small"
                    />
                )}
            />
        )
    );
}
