/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useEffect, useState } from "react";

import { faDollarSign, faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Fab from "@mui/material/Fab";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import { Link, Redirect } from "react-router-dom";

import ProjectCancelDialog from "./project-cancel-dialog/ProjectCancelDialog";
import ProjectChecklist from "./project-checklist";
import ProjectCustomerDetailsDialog from "./project-customer-details-dialog";
import ProjectEarlyConclusionDialog from "./project-early-conclusion-dialog/ProjectEarlyConclusionDialog";
import ProjectInfo from "./project-info";
import ProjectReopenDialog from "./project-reopen-dialog/ProjectReopenDialog";
import ProjectSidebar from "./project-sidebar";
import { projectFilterType } from "../../../constants/project-filter-types";
import ProjectStatus from "../../../constants/project.status";
import { AuthContext, DataContext } from "../../../contexts";
import { pendencyName } from "../../../lib/pendency-utils";
import { getCollectionData } from "../../../services/data-service";
import ProjectInvoicingForm from "../../forms/project/project-invoicing-form/ProjectInvoicingForm";
import "./ProjectData.css";
import UserRoles from "../../../constants/user-roles";

const useStyles = makeStyles(theme => ({
    actionIcon: {
        margin: theme.spacing(1),
    },
    completePhaseButton: {
        fontFamily: "Roboto",
        fontSize: "14px",
        fontWeight: "500",
        fontStyle: "normal",
        fontStretch: "normal",
        lineHeight: "1.43",
        letterSpacing: "normal",
        textAlign: "center",
        color: "#4192d9",
        textTransform: "none",
        padding: "12px 16px",
        backgroundColor: "rgba(153, 153, 153, 0.2)",
        border: "none",
    },
    actionButton: {
        margin: theme.spacing(1) / 2,
        fontFamily: "Roboto",
        fontSize: "14px",
        fontWeight: "normal",
        fontStyle: "normal",
        fontStretch: "normal",
        lineHeight: "1.14",
        letterSpacing: "normal",
        textAlign: "left",
        color: "#4192d9",
        textTransform: "none",
        boxShadow: "none",
    },
    actionButtonLabel: {
        paddingRight: theme.spacing(1),
    },
    newProjectLabel: {
        backgroundColor: "var(--pumpkin-orange)",
        color: "var(--white)",
        height: 16,
        fontFamily: "Roboto",
        fontSize: "10px",
        fontWeight: "500",
        fontStyle: "normal",
        fontStretch: "normal",
        lineHeight: " 1.5",
        letterSpacing: "normal",
        textAlign: "center",
        marginLeft: theme.spacing(1),
    },
    actionSelect: {
        margin: theme.spacing(1),
        minWidth: 200,
    },
}));

const initialCompletePhase = {
    confirmed: false,
    cancelled: false,
    nextAssigneeId: undefined,
    completeMessage: "",
};

// Fetch all needed data
function useFetchData(props) {
    const { match, location = {} } = props;
    const currentProjectId = match.params.id;
    const [users, setUsers] = useState([]);

    const {
        project,
        fetchProject,
        isLoadingUsers,
        isLoadingProject,
        isLoadingInfos,
        isLoadingEvents,
        notFound,
        unfetchProject,
    } = useContext(DataContext);

    const isLoading =
        isLoadingUsers || isLoadingProject || isLoadingInfos || isLoadingEvents;

    // fetch project data
    useEffect(() => {
        fetchProject(currentProjectId);
        return function cleanup() {
            unfetchProject();
        };
    }, [currentProjectId, location.key]);

    // set project  not found
    useEffect(() => {
        props.setProjectNotFound(notFound);
    }, [notFound]);

    // load data
    useEffect(() => {
        if (!isLoading) {
            if (project) {
                props.setProject(project);
            }
        }

        if (users) {
            const assignees = users.map(user => ({
                ...user,
                value: user.id,
                label: user.name,
            }));
            if (assignees !== props.assignees) {
                props.setAssignees(assignees);
            }

            if (users !== props.users) {
                props.setUsers([
                    ...users.map(user => ({
                        id: user.id,
                        name: user.name,
                        email: user.email,
                    })),
                ]);
            }
        }
        const loading = !project || !users || isLoading;
        if (props.isLoading !== loading) props.setIsLoading(loading);
    }, [project, users, isLoading]);

    // fetch users
    useEffect(() => {
        getCollectionData("users").then(users => setUsers(users));
    }, []);
}
export default function ProjectData(props) {
    const classes = useStyles();

    const { match, filterType } = props;
    const currentProjectId = match.params.id;
    const { user: currentUser, hasRole, hasAnyRole } = useContext(AuthContext);

    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingActions, setIsLoadingActions] = useState(false);
    const [isPhaseCompleted, setIsPhaseCompleted] = useState(false);
    const [isCustomerDialogOpen, setIsCustomerDialogOpen] = useState(false);

    const [projectNotFound, setProjectNotFound] = useState(false);

    const [invoicingFormOpen, setInvoicingFormOpen] = useState(false);
    const [project, setProject] = useState({});
    const [completePhase, setCompletePhase] = useState(initialCompletePhase);
    const [assignees, setAssignees] = useState([]);
    const [users, setUsers] = useState([]);

    useFetchData({
        ...props,
        isLoading,
        project,
        assignees,
        users,
        setIsLoading,
        setProject,
        setProjectNotFound,
        setAssignees,
        setUsers,
    });

    const { unfetchProject } = useContext(DataContext);

    const checkPhaseProgress = () => {
        if (isLoading) return;
        setIsLoadingActions(true);
        const currentPhase = project.phase;
        const phaseCompleted = project.phaseCompleted;

        const phaseOverdue = moment().isAfter(
            moment(currentPhase.plannedDeadline, moment.ISO_8601),
        );
        if (!isPhaseCompleted) {
            completePhase.confirmed = false;
            completePhase.nextAssigneeId = undefined;
            completePhase.completeMessage = "";
        } else {
            if (currentPhase && currentPhase.setSurveyorOnFinish) {
                completePhase.nextAssigneeId = completePhase.nextAssigneeId
                    ? completePhase.nextAssigneeId
                    : project.surveyorId;
            }
        }

        setIsPhaseCompleted(phaseCompleted);
        setCompletePhase(completePhase);
        setIsLoadingActions(false);
        return { phaseCompleted, phaseOverdue };
    };

    const onOpenInvoicingForm = () => {
        setInvoicingFormOpen(true);
    };

    const onCloseInvoicingForm = () => {
        setInvoicingFormOpen(false);
    };

    const onInvoicingUpdated = invoicingProject => {
        setProject(invoicingProject);
    };

    const onCompletePhaseClick = () => {
        const { phaseCompleted } = checkPhaseProgress();
        if (phaseCompleted) {
            completePhase.cancelled = false;
            setCompletePhase(completePhase);
        }
    };

    const handleCustomerDialogOpen = () => {
        setIsCustomerDialogOpen(true);
    };

    const handleCustomerDialogClose = () => {
        setIsCustomerDialogOpen(false);
    };

    useEffect(() => {
        if (project.id !== currentProjectId) {
            setCompletePhase({ ...initialCompletePhase });
        }
    }, [project, currentProjectId]);

    useEffect(() => {
        checkPhaseProgress();
    }, [project, isLoading, completePhase]);

    if (projectNotFound) {
        return (
            <div className="project-data">
                <div className="data-content">
                    <h2>Projeto não encontrado.</h2>
                    <small>{"Id: " + match.params.id}</small>
                </div>
            </div>
        );
    }

    if (
        !isLoading &&
        project.id === currentProjectId &&
        filterType === projectFilterType.USER
    ) {
        if (currentUser.id !== project.assigneeId) {
            unfetchProject();
            const path = `/projects/${project.id}`;
            return (
                <Redirect
                    to={{
                        pathname: path,
                    }}
                />
            );
        }
    }

    if (
        !isLoading &&
        project.id === currentProjectId &&
        [ProjectStatus.COMPLETED, ProjectStatus.CANCELLED].includes(
            project.status,
        ) &&
        filterType !== projectFilterType.ALL &&
        filterType !== projectFilterType.HISTORY
    ) {
        const path = "/projects/" + project.id;
        return (
            <Redirect
                to={{
                    pathname: path,
                }}
            />
        );
    }

    const headerTitle = () => {
        const title =
            filterType === projectFilterType.USER
                ? "Minhas Atividades"
                : filterType === projectFilterType.USER_GROUP
                ? "Atividades da Área"
                : "Projetos";
        return <div className="header-title">{title}</div>;
    };

    const headerActions = () => {
        return filterType === projectFilterType.ALL &&
            ![ProjectStatus.COMPLETED, ProjectStatus.CANCELLED].includes(
                project.status,
            ) &&
            project.id ? (
            <div className={"header-actions"}>
                {earlyProjectConclusionBotton()}
                {cancelProjectButton()}
                {updateInvoiceButton()}
                {editProjectButton()}
            </div>
        ) : (
            filterType === projectFilterType.HISTORY &&
                [ProjectStatus.COMPLETED, ProjectStatus.CANCELLED].includes(
                    project.status,
                ) &&
                project.id && (
                    <div className={"header-actions"}>
                        {reopenProjectButton()}
                    </div>
                )
        );
    };

    const updateInvoiceButton = () => {
        return (
            hasRole(UserRoles.CONTROLE_FINANCEIRO) && (
                <div className="project-invoicing-button">
                    <ProjectInvoicingForm
                        project={project}
                        open={invoicingFormOpen}
                        onClose={onCloseInvoicingForm}
                        onUpdated={onInvoicingUpdated}
                    />
                    <Fab
                        variant="extended"
                        size="small"
                        aria-label="Add"
                        classes={{
                            root: classes.actionButton,
                            label: classes.actionButtonLabel,
                        }}
                        disabled={isLoading}
                        onClick={onOpenInvoicingForm}
                    >
                        <FontAwesomeIcon
                            className={classes.actionIcon}
                            icon={faDollarSign}
                        />
                        Atualizar Fatura
                    </Fab>
                </div>
            )
        );
    };

    const editProjectButton = () => {
        return (
            hasAnyRole(["CDC"]) && (
                <div className="project-edit-button">
                    <Link to={"/projects/" + project.id + "/edit"}>
                        <Fab
                            variant="extended"
                            size="small"
                            aria-label="Add"
                            classes={{
                                root: classes.actionButton,
                                label: classes.actionButtonLabel,
                            }}
                            disabled={isLoading}
                        >
                            <FontAwesomeIcon
                                className={classes.actionIcon}
                                icon={faPen}
                            />
                            Editar Projeto
                        </Fab>
                    </Link>
                </div>
            )
        );
    };

    const cancelProjectButton = () => {
        return (
            hasAnyRole(["CDC"]) && (
                <div className="project-edit-button">
                    <ProjectCancelDialog
                        projectId={project.id}
                        isLoading={isLoading}
                    />
                </div>
            )
        );
    };

    const earlyProjectConclusionBotton = () => {
        return (
            hasAnyRole(["CDC"]) && (
                <div className="project-edit-button">
                    <ProjectEarlyConclusionDialog
                        projectId={project.id}
                        isLoading={isLoading}
                    />
                </div>
            )
        );
    };

    const reopenProjectButton = () => {
        return (
            hasAnyRole(["CDC"]) && (
                <div className="project-edit-button">
                    <ProjectReopenDialog
                        projectId={project.id}
                        isLoading={isLoading}
                    />
                </div>
            )
        );
    };

    const projectNumber = () => {
        const number = isLoading ? <Skeleton width={120} /> : project.number;
        return (
            <div className="project-number">
                {number} {cancelledFlag()} {completedFlag()} {pendencyFlag()}{" "}
                {letterFlag()}
            </div>
        );
    };

    const cancelledFlag = () => {
        return (
            project.status === ProjectStatus.CANCELLED && (
                <span className="label bg-red">Cancelado</span>
            )
        );
    };

    const completedFlag = () => {
        return (
            project.status === ProjectStatus.COMPLETED && (
                <span className="label bg-green">Concluído</span>
            )
        );
    };

    const pendencyFlag = () => {
        return project.status === "Pendency" ? (
            <span className="label bg-orange">
                {pendencyName(project.pendencyType)}
            </span>
        ) : (
            ""
        );
    };

    const letterFlag = () => {
        return project.hasLetter && <span className="label bg-red">Carta</span>;
    };

    const projectCustomerName = () => {
        const customer = project.customer || {};
        const studyArea = project.studyArea;
        if (studyArea && studyArea.type !== "default")
            return (
                <span>
                    {customer.name}
                    <Typography
                        variant="subtitle1"
                        gutterBottom
                        component="div"
                    >
                        {studyArea.name}
                    </Typography>
                </span>
            );
        return customer.name;
    };

    const projectCustomer = () => {
        return (
            <Box
                className="project-customer"
                onClick={handleCustomerDialogOpen}
            >
                {isLoading ? <Skeleton width={200} /> : projectCustomerName()}
            </Box>
        );
    };

    const projectActions = () => {
        if (isLoading) return <Skeleton height={55} />;

        if (
            project.status === ProjectStatus.COMPLETED ||
            project.status === ProjectStatus.CANCELLED ||
            !project.id
        )
            return <div className="project-actions" />;

        if (isLoadingActions) return <div className="project-actions" />;

        if (isPhaseCompleted && filterType === projectFilterType.USER)
            return (
                <div className="project-actions">
                    <Button
                        variant="outlined"
                        component="span"
                        className={classes.completePhaseButton}
                        onClick={onCompletePhaseClick}
                    >
                        Concluir Etapa
                    </Button>
                </div>
            );

        return <div className="project-actions" />;
    };

    return (
        <div className="project-data">
            <div className="data-content">
                <div className="project-header">
                    {headerTitle()}
                    {headerActions()}
                </div>
                <div className="project-subheader">
                    {projectNumber()}
                    {projectCustomer()}
                    <hr className="line" />
                    {projectActions()}
                </div>
                <div className="project-content">
                    <ProjectChecklist
                        filterType={filterType}
                        isLoading={isLoading}
                        readOnly={
                            filterType === projectFilterType.USER ? false : true
                        }
                    />
                </div>
                <div className="project-footer">
                    <ProjectInfo project={project} />
                </div>
            </div>
            <div className="data-sidebar">
                <ProjectSidebar />
            </div>
            {isCustomerDialogOpen && (
                <ProjectCustomerDetailsDialog
                    open={isCustomerDialogOpen}
                    onClose={handleCustomerDialogClose}
                />
            )}
        </div>
    );
}

ProjectData.defaultProps = {
    filterType: projectFilterType.ALL,
};
