const UserRoles = {
    CADASTRAR_USUARIO: "CDU",
    CADASTRAR_CLIENTE: "CDC",
    CONTROLE_FINANCEIRO: "FIN",
    CADASTRAR_PROJETO: "CDP",
    CADASTRAR_POCO: "CDW",
    CADASTRAR_ESTUDO: "CDE",
    CADASTRAR_JUSTIFICATIVAS: "CDJ",
    ATRIBUICAO_RELATORISTA: "ATR",
    PRE_REVISAO: "PRV",
    REVISAO_TECNICA: "RVT",
    FINALIZACAO_DO_RELATORIO: "FNR",
    CREATE_ROADMAP: "CRM",
    UPDATE_ROADMAP: "URM",
    DELETE_ROADMAP: "DRM",
    CREATE_ORDER: "CREATE_ORDER",
    UPDATE_ORDER: "UPDATE_ORDER",
    DELETE_ORDER: "DELETE_ORDER",
    CANCEL_ORDER: "CANCEL_ORDER",
    RELEASE_ORDER: "RELEASE_ORDER",
    START_ORDER_WORK: "START_ORDER_WORK",
    COMPLETE_ORDER_WORK: "COMPLETE_ORDER_WORK",
    SUSPEND_ORDER_WORK: "SUSPEND_ORDER_WORK",
    SEND_ORDER_TO_VERIFICATION: "SEND_ORDER_TO_VERIFICATION",
    PERFORM_ORDER_VERIFICATION: "PERFORM_ORDER_VERIFICATION",
    RELEASE_ORDER_TO_LAUNCH: "RELEASE_ORDER_TO_LAUNCH",
    COMPLETE_ORDER: "COMPLETE_ORDER",
    COPY_ORDER: "COPY_ORDER",
    DOWNLOAD_REPORTS: "DOWNLOAD_REPORTS",
    RELEASE_TASK: "RLP",
    LOGIN_FIELD_APP: "LFA",
    CAMPO: "CAM",
    COMERCIAL: "COM",
    DESENHISTA: "DES",
    RELATORISTA: "REL",
    DIRETORIA: "DIR",
    PROJETOS: "PRJ",
    QUALIDADE: "QUA",
    LOGISTICA: "LOG",
    CONFERENCIA: "CONF",
    LABORATORIO: "LAB",
    CORRECAO: "COR",
    ANALISE_CRITICA: "ANC",
};

export default UserRoles;
