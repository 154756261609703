import React from "react";

import "./PageNotFound.css";

export default function PageNotFound() {
    return (
        <div className="page-not-found">
            <h1>Pagina não encontrada!</h1>
            <br />
            <a className="back-button" href="/">
                Voltar
            </a>
        </div>
    );
}
