import React, { createContext, useReducer } from "react";

import {
    getAuth,
    onAuthStateChanged,
    signInWithEmailAndPassword,
    signOut,
} from "firebase/auth";

import { getCollectionSnapshot, where } from "../services/data-service";

const auth = getAuth();

const initialState = {
    user: null,
    users: null,
    error: null,
    isAuthenticated: false,
    isAuthenticating: false,
    isLoadingUsers: true,
};

const AuthContext = createContext({
    user: {},
    users: [],
    isAuthenticated: false,
    isAuthenticating: false,
    isLoadingUsers: true,
    error: {},
    login: credentials => {},
    logout: () => {},
    hasRole: () => {},
    hasAnyRole: () => {},
});

function authReducer(state, action) {
    switch (action.type) {
        case "AUTHENTICATING":
            return {
                ...state,
                isAuthenticating: true,
            };
        case "AUTHENTICATED":
            return {
                ...state,
                isAuthenticated: true,
            };
        case "UNAUTHENTICATED":
            return {
                ...state,
                isAuthenticated: false,
            };
        case "LOGIN":
            return {
                ...state,
                isAuthenticated: true,
                isAuthenticating: false,
                user: action.payload,
            };
        case "LOGOUT":
            return {
                ...state,
                isAuthenticated: false,
                isAuthenticating: false,
                user: null,
            };
        case "ERROR":
            return {
                ...state,
                isAuthenticated: false,
                isAuthenticating: false,
                error: action.payload,
            };
        case "SET_USERS":
            return {
                ...state,
                users: action.payload,
                isLoadingUsers: false,
            };
        default:
            return state;
    }
}

function AuthProvider(props) {
    const [state, dispatch] = useReducer(authReducer, initialState);

    async function login(credentials) {
        dispatch({ type: "AUTHENTICATING" });
        return signInWithEmailAndPassword(
            auth,
            credentials.email,
            credentials.password,
        );
    }

    function logout() {
        signOut(auth).then(() => {
            dispatch({ type: "LOGOUT" });
        });
    }

    function fetchUser(userEmail) {
        const currentUser = state.user || {};
        if (userEmail !== currentUser.email)
            getCollectionSnapshot("users", [
                where("email", "==", `${userEmail}`),
            ])
                .then(docSnap => {
                    if (!docSnap.empty) {
                        dispatch({
                            type: "LOGIN",
                            payload: docSnap.docs[0].data(),
                        });
                    } else {
                        logout();
                    }
                })
                .then(() => {
                    getCollectionSnapshot("users").then(docSnap => {
                        const users = docSnap.docs.map(doc => doc.data());
                        dispatch({
                            type: "SET_USERS",
                            payload: users,
                        });
                    });
                })
                .catch(function (error) {
                    logout();
                    dispatch({
                        type: "ERROR",
                        payload: error,
                    });
                    console.error("Error fetching user:", error);
                });
    }

    function hasRole(role) {
        const currentUser = state.user;
        if (currentUser) {
            return currentUser.roles.some(r => r === role);
        }
        return false;
    }

    function hasAnyRole(roles) {
        const currentUser = state.user;
        if (currentUser) return currentUser.roles.some(r => roles.includes(r));
        return false;
    }

    // track auth changes
    onAuthStateChanged(auth, function (user) {
        if (user) {
            if (!state.isAuthenticated) {
                dispatch({ type: "AUTHENTICATED" });
            }
            fetchUser(user.email);
        } else {
            if (state.isAuthenticated) {
                dispatch({ type: "UNAUTHENTICATED" });
            }
        }
    });

    function isLoading() {
        const currentUser = state.user || {};
        const authUser = auth.currentUser || {};

        if (authUser.email !== currentUser.email) return true;

        return false;
    }

    function currentUser() {
        const user = state.user ? state.user : {};
        return {
            id: user.id,
            name: user.name,
            email: user.email,
        };
    }

    return (
        <AuthContext.Provider
            value={{
                user: state.user,
                currentUser: currentUser(),
                users: state.users,
                error: state.error,
                isAuthenticated: state.isAuthenticated && state.user !== null,
                isAuthenticating: state.isAuthenticating,
                isLoading: isLoading(),
                isLoadingUsers: state.isLoadingUsers,
                login,
                logout,
                hasRole,
                hasAnyRole,
            }}
            {...props}
        />
    );
}

export { AuthContext, AuthProvider };
