import React from "react";

import "./ProjectSidebar.css";

import ProjectDetails from "./project-details";
import ProjectInfos from "./project-infos";
import ProjectDeadline from "./project-deadline";

export default function ProjectSidebar(props) {
    return (
        <div className="project-sidebar">
            <div className="project-sidebar-container">
                <div className="project-sidebar-details">
                    <ProjectDetails />
                </div>
                <div className="project-sidebar-deadline">
                    <ProjectDeadline />
                </div>
                <div className="project-sidebar-infos">
                    <ProjectInfos />
                </div>
            </div>
        </div>
    );
}
