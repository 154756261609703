import React from "react";

import moment from "moment";

import { isEmpty } from "../../../lib/object-utils";
import { getNewOrder } from "../../../services";
import OrderForm, { ViewMode } from "../order-form";

export default function OrderDetails(props) {
    const { match = {}, location = {} } = props;
    const { path, params } = match;
    const orderId = params.id;
    const { state = {} } = location;

    let { order = {} } = state || {};

    if (path === "/orders/new" && isEmpty(order)) {
        order = getNewOrder();
    }

    return (
        <OrderForm
            orderId={orderId}
            order={{ ...order, date: moment(order.date) }}
            viewMode={path === "/orders/new" ? ViewMode.NEW : ViewMode.DISPLAY}
        />
    );
}
