/* eslint-disable react/prop-types */
import React, { useState } from "react";

import {
    Delete as DeleteIcon,
    Edit as EditIcon,
    OpenInNew as NewIcon,
} from "@mui/icons-material";
import {
    IconButton,
    Tooltip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    CircularProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import { Store as notifications } from "react-notifications-component";
import { Link } from "react-router-dom";


import "./Provisionings.css";
import MUIDataTable from "mui-datatables";

import textLabels from "../../constants/mui-datatable-labels";


import { useEffect } from "react";


import notificationOpts from "../../constants/notification-options";
import notificationType from "../../constants/notification-types";
import {
    onCollectionSnapshot,
    deleteDocument,
} from "../../services/data-service";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    tableRow: {
        "&:hover": {
            cursor: "pointer",
        },
    },
    projectFilter: {
        display: "flex",
    },
    actionsCell: {
        minWidth: "145px",
    },
    cancelButton: {
        color: "red",
    },
    submitButton: {
        color: "green",
    },
    loadingIndicator: {
        color: "green",
        marginLeft: theme.spacing(2),
    },
}));

export default function Provisionings(props) {
    const classes = useStyles();
    const [provisionings, setProvisionings] = useState(undefined);
    const [dialogProps, setDialogProps] = useState({});

    // fetch data
    useEffect(() => {
        onCollectionSnapshot({
            path: "provisionings",
            callback: snapshot => {
                if (snapshot.empty) {
                    setProvisionings([]);
                } else {
                    setProvisionings(snapshot.docs.map(doc => doc.data()));
                }
            },
        });
    }, []);

    const renderLoading = () => {
        const skeletonRows = () => {
            let rows = [];
            for (let i = 0; i < 5; i++) {
                rows.push(<Skeleton key={i} height={55} />);
            }
            return rows;
        };

        return skeletonRows();
    };

    const onProvisioningSelected = provisioningId => {
        const provisioning = provisionings.find(p => p.id === provisioningId);
        props.history.push({
            pathname: "/projects/new",
            state: {
                provisioningId: provisioning.id,
                customerId: provisioning.customer && provisioning.customer.id,
                studyAreaId:
                    provisioning.studyArea && provisioning.studyArea.id,
                studyId: provisioning.study && provisioning.study.id,
                projectNumber:
                    provisioning.lastProject && provisioning.lastProject.number,
            },
        });
    };

    const onDialogUpdate = () => {
        setDialogProps({
            ...dialogProps,
            open: false,
        });
    };

    const renderList = () => {
        const columns = [
            {
                name: "id",
                label: "Id",
                options: {
                    filter: false,
                    sort: false,
                    display: false,
                },
            },
            {
                name: "customerName",
                label: "Cliente",
                options: {
                    filter: true,
                    sort: true,
                },
            },
            {
                name: "lastProjectNumber",
                label: "Último projeto",
                options: {
                    filter: true,
                    sort: true,
                },
            },
            {
                name: "lastStudyName",
                label: "Último estudo",
                options: {
                    filter: true,
                    sort: true,
                },
            },
            {
                name: "lastProjectCompletedAt",
                label: "Data último estudo",
                options: {
                    filter: true,
                    sort: true,
                },
            },
            {
                name: "nextStudyStartAt",
                label: "Data prevista para o próximo estudo",
                options: {
                    filter: true,
                    sort: true,
                },
            },
            {
                name: "nextStudyStartIn",
                label: " Dias para o próximo estudo",
                options: {
                    filter: true,
                    sort: true,
                },
            },
            {
                name: "",
                options: {
                    filter: false,
                    sort: false,
                    empty: true,
                    customBodyRender: (value, tableMeta) => {
                        const provisioningId = tableMeta.rowData[0];
                        return (
                            <span className="datatable-icon-column">
                                <IconButton
                                    size="small"
                                    onClick={() =>
                                        onProvisioningSelected(provisioningId)
                                    }
                                    className={classes.button}
                                    aria-label="Gerar novo estudo"
                                >
                                    <Tooltip title="Gerar novo estudo">
                                        <NewIcon />
                                    </Tooltip>
                                </IconButton>
                                <Link
                                    to={`/provisionings/${provisioningId}/edit`}
                                >
                                    <IconButton
                                        size="small"
                                        className={classes.button}
                                        aria-label="Editar"
                                    >
                                        <Tooltip title="Editar provisionamento">
                                            <EditIcon />
                                        </Tooltip>
                                    </IconButton>
                                </Link>
                                <IconButton
                                    size="small"
                                    onClick={() =>
                                        setDialogProps({
                                            ...dialogProps,
                                            provisioningId: provisioningId,
                                            open: true,
                                            onDeleted: onDialogUpdate,
                                        })
                                    }
                                    className={classes.button}
                                    aria-label="Excluir"
                                >
                                    <Tooltip title="Excluir provisionamento">
                                        <DeleteIcon />
                                    </Tooltip>
                                </IconButton>
                            </span>
                        );
                    },
                },
            },
        ];

        const data =
            provisionings &&
            provisionings.map(provisioning => {
                return {
                    customerName:
                        provisioning.customer && provisioning.customer.name,
                    lastProjectNumber:
                        provisioning.lastProject &&
                        provisioning.lastProject.number,
                    lastStudyName:
                        provisioning.lastStudy && provisioning.lastStudy.name,
                    lastProjectCompletedAt: moment(
                        provisioning.lastProjectCompletedAt,
                    ).format("DD/MM/YYYY"),
                    nextStudyStartAt: moment(
                        provisioning.nextStudyStartAt,
                    ).format("DD/MM/YYYY"),
                    nextStudyStartIn: moment(provisioning.nextStudyStartAt)
                        .endOf("day")
                        .diff(moment().startOf("day"), "days"),
                    id: provisioning.id,
                };
            });

        const options = {
            selectableRows: "none",
            filterType: "textField",
            elevation: 1,
            textLabels: textLabels,
            downloadOptions: {
                filename: "provisionamentos.csv",
            },
            onDownload: (buildHead, buildBody, columns, data) => {
                columns = columns.map(column => {
                    column.name = column.label;
                    return column;
                });
                return "\uFEFF" + buildHead(columns) + buildBody(data);
            },
        };

        return (
            <MUIDataTable
                title={"Provisionamentos"}
                data={data}
                columns={columns}
                options={options}
            />
        );
    };

    return (
        <div className="provisioning-list">
            <div className="list-provisioning">
                {provisionings ? renderList() : renderLoading()}
            </div>
            <DeleteConfirmationDialog
                {...dialogProps}
             />
        </div>
    );
}

function DeleteConfirmationDialog(props) {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const [open, setOpen] = useState(props.open);

    useEffect(() => {
        setOpen(props.open);
    }, [props]);

    const onConfirm = async () => {
        try {
            setIsLoading(true);
            const resource = `provisionings/${props.provisioningId}`;
            deleteDocument(resource)
                .then(() => {
                    notifications.addNotification({
                        ...notificationOpts,
                        type: notificationType.SUCCESS,
                        message: `Provisionamento excluido com sucesso`,
                    });
                })
                .catch(error => {
                    console.error("Error deleting provisioning", error);
                    notifications.addNotification({
                        ...notificationOpts,
                        type: notificationType.ERROR,
                        message: `Erro ao excluir o provisionamento`,
                    });
                });
        } finally {
            if (props.onDeleted) props.onDeleted();
            setIsLoading(false);
            setOpen(false);
        }
    };

    const onCancel = () => {
        // cancel
        setIsLoading(false);
        setOpen(false);
        if (props.onCancel) props.onCancel();
    };

    return (
        <Dialog open={open ? open : false} aria-labelledby="dialog-title">
            <DialogTitle id="dialog-title">Excluir Provisionamento</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Deseja excluir este provisionamento?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button className={classes.cancelButton} onClick={onCancel}>
                    Cancelar
                </Button>
                <Button
                    className={classes.submitButton}
                    onClick={onConfirm}
                    disabled={isLoading}
                >
                    Concluir
                    {isLoading && (
                        <CircularProgress
                            size={18}
                            thickness={4}
                            className={classes.loadingIndicator}
                        />
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
