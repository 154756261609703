export default function PhaseFactory({
    id,
    name,
    status,
    project = {},
    customer = {},
    study = {},
    phase = {},
    assigneeId = {},
    assignee = {},
    activities = [],
    nextPhases = [],
    active,
    completed,
    startedAt,
    daysToDelivery = 0,
    plannedDeliveryDate,
    completedAt,
    completedBy = {},
    createdAt,
    createdBy = {},
    updatedAt,
    updatedBy = {},
    ...props
} = {}) {
    const task = {
        id,
        name,
        status,
        project,
        study,
        phase,
        active,
        completed,
        customer,
        assigneeId,
        assignee,
        activities,
        nextPhases,
        startedAt,
        daysToDelivery,
        plannedDeliveryDate,
        completedAt,
        completedBy,
        createdAt,
        createdBy,
        updatedAt,
        updatedBy,
        ...props,
    };

    if (task.project.id) {
        task.projectId = project.id;
    }

    if (task.project.number) {
        task.projectNumber = project.number;
    }

    task.documentId = `${task.projectId}__${task.executionId}__${task.id}`;
    task.documentName = `projects/${task.projectId}/executions/${task.executionId}/phases/${task.id}`;

    return task;
}
