import React, { useState } from "react";

import UserList from "./user-list";
import UserForm from "./user-form";

import "./Users.css";

export default function Users() {
    const [state, setState] = useState({
        isLoading: true,
        selectedUser: {
            name: "",
            email: "",
            pass: "",
        },
        errors: [],
    });

    const onUserSelected = user => {
        setState({
            ...state,
            selectedUser: user,
        });
    };

    return (
        <div className="users-form">
            <div className="created-users-form">
                <UserList onUserSelected={onUserSelected} />
            </div>
            <div className="new-user-form">
                <UserForm
                    userId={state.selectedUser.id}
                    currentUser={state.selectedUser}
                    onCreated={onUserSelected}
                />
            </div>
        </div>
    );
}
