import React from "react";

import { Box, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import DeadlineLabel from "../../../commons/deadline-label";
import DeadlineStatusIcon from "../../../commons/deadline-status-icon";
import ProjectStatusLabel from "../../../commons/project-status-label";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        margin: "auto",
        maxWidth: 500,
        backgroundColor: "inherit",
    },
    statusIcon: {
        height: 16,
        width: 16,
    },
}));

export default function TaskItem({
    name,
    releasedAt,
    plannedDurationDays,
    project = {},
    customer = {},
    study = {},
    status,
    pendencyType,
}) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Paper className={classes.paper} elevation={0}>
                <Box display="flex" paddingBottom={1}>
                    <Box>
                        <DeadlineStatusIcon
                            size={16}
                            startDate={releasedAt}
                            daysToDelivery={plannedDurationDays}
                        />
                    </Box>
                    <Box
                        flexGrow={1}
                        paddingLeft={2}
                        paddingRight={2}
                        paddingTop={0.5}
                    >
                        <Typography variant="caption" color="secondary">
                            {project.number}
                        </Typography>
                    </Box>
                    <Box justifyContent="flex-end">
                        <DeadlineLabel
                            title="Prazo final da atividade"
                            startDate={releasedAt}
                            daysToDelivery={plannedDurationDays}
                        />
                    </Box>
                </Box>
                <Box display="flex" paddingBottom={1}>
                    <Box paddingLeft={1} paddingRight={1} />
                    <Box flexGrow={1} paddingLeft={2} paddingRight={2}>
                        <Typography variant="h5" color="secondary">
                            {customer.name}
                        </Typography>
                    </Box>
                </Box>
                <Box display="flex" paddingBottom={1}>
                    <Box paddingLeft={1} paddingRight={1} />
                    <Box flexGrow={1} paddingLeft={2} paddingRight={2}>
                        <Typography variant="button" color="secondary">
                            {study.name}
                        </Typography>
                    </Box>
                </Box>
                <Box display="flex" paddingBottom={1}>
                    <Box paddingLeft={1} paddingRight={1} />
                    <Box display="flex" flexGrow={1} paddingLeft={2}>
                        <Box flexGrow={1}>
                            <span className="label bg-default">{name}</span>
                        </Box>
                        <Box justifyContent="flex-end">
                            <ProjectStatusLabel
                                status={status}
                                pendencyType={pendencyType}
                            />
                        </Box>
                    </Box>
                </Box>
            </Paper>
        </div>
    );
}
