import React from "react";

import TaskList from "./task-list";
import TaskDetail from "./task-detail";
import ListViewDetails from "../commons/list-detail-view";
import TaskDatatable from "./task-datatable";

export default function Tasks(props) {
    const ListView = props => {
        return <TaskList {...props} />;
    };

    const DetailView = props => {
        return <TaskDetail {...props} />;
    };

    const DatatableView = props => {
        return <TaskDatatable {...props} />;
    };

    return (
        <ListViewDetails
            detailsPath="/tasks/:id"
            datatableView={DatatableView}
            listView={ListView}
            detailView={DetailView}
            {...props}
        />
    );
}
