export default function OrderActionModel({
    id,
    name,
    tooltip,
    icon,
    execute,
    requiredRoles,
}) {
    this.id = id;
    this.name = name;
    this.tooltip = tooltip;
    this.icon = icon;
    this.execute = execute;
    this.requiredRoles = requiredRoles;
}
