import React, { Suspense, useContext, useState } from "react";

import MenuIcon from "@mui/icons-material/Menu";
import { Box } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import Toolbar from "@mui/material/Toolbar";
import { useTheme, styled } from "@mui/styles";
import { Redirect, Route } from "react-router-dom";

import { AuthContext } from "../../contexts";
import SidebarMenu from "../sidebar-menu";

const drawerWidth = 240;

function ProtectedRoute({ component: Component, ...rest }) {
    const { isAuthenticated } = useContext(AuthContext);
    return (
        <Route
            {...rest}
            render={props =>
                isAuthenticated ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: props.location },
                        }}
                    />
                )
            }
        />
    );
}

const DrawerContainer = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
}));

const LoadingIndicator = styled(LinearProgress)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
}));

const Nav = styled("nav")(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
    },
}));

const MenuDesktop = styled(Drawer)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    width: drawerWidth,
    [theme.breakpoints.down("sm")]: {
        display: "none",
    },
}));

const MenuMobile = styled(Drawer)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    width: drawerWidth,
    [theme.breakpoints.up("sm")]: {
        display: "none",
    },
}));

const Main = styled("main")(({ theme }) => ({
    flexGrow: 1,
    [theme.breakpoints.up("md")]: {
        width: "100vh",
    },
}));

const ApplBar = styled(AppBar)(({ theme }) => ({
    [theme.breakpoints.up("sm")]: {
        display: "none",
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
    },
}));

const ToolBar = styled("div")(({ theme }) => ({
    [theme.breakpoints.down("sm")]: {
        ...theme.mixins.toolbar,
    },
}));

const MenuButton = styled(IconButton)(({ theme }) => ({
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
        display: "none",
    },
}));

export default function Layout({ component: Component, ...props }) {
    const { container } = props;
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <DrawerContainer name="DrawerContainer">
            <ProtectedRoute component={SidebarMenu} {...props} />
        </DrawerContainer>
    );

    return (
        <Suspense fallback={<LoadingIndicator />}>
            <Box sx={{ display: "flex" }}>
                <CssBaseline />
                <ApplBar position="fixed">
                    <Toolbar>
                        <MenuButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                        >
                            <MenuIcon />
                        </MenuButton>
                    </Toolbar>
                </ApplBar>
                <Nav aria-label="mailbox folders">
                    <MenuMobile
                        sx={{
                            width: drawerWidth,
                            flexShrink: 0,
                            "& .MuiDrawer-paper": {
                                width: drawerWidth,
                                boxSizing: "border-box",
                            },
                        }}
                        container={container}
                        variant="temporary"
                        anchor={theme.direction === "rtl" ? "right" : "left"}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true,
                        }}
                    >
                        {drawer}
                    </MenuMobile>
                    <MenuDesktop
                        sx={{
                            width: drawerWidth,
                            flexShrink: 0,
                            "& .MuiDrawer-paper": {
                                width: drawerWidth,
                                boxSizing: "border-box",
                            },
                        }}
                        variant="permanent"
                        anchor="left"
                        open
                    >
                        {drawer}
                    </MenuDesktop>
                </Nav>
                <Main>
                    <ToolBar />
                    <ProtectedRoute component={Component} {...props} />
                </Main>
            </Box>
        </Suspense>
    );
}
