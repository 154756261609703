import moment from "moment";

import OrderStatus from "../constants/order-status";
import OrderTaskStatus from "../constants/order-task-status";
import { toJSON } from "../lib/object-utils";
import { OrderModel, OrderSampleModel, OrderTaskModel } from "../models";
import {
    Timestamp,
    createDocument,
    getCleanData,
    getOrderStatus,
    getUser,
    updateDocument,
} from "../services";

export function getNewOrder() {
    const order = new OrderModel({});
    order.number = getOrderNumber();
    order.date = moment();
    order.status = getOrderStatus(OrderStatus.DRAFT);
    return order;
}

export function getOrder(order) {
    const model = new OrderModel({ ...order });
    model.date = order.date && moment(order.date.toDate());
    model.status = getOrderStatus(model.status);
    model.createdBy = order.createdBy && getUser(order.createdBy);
    model.updatedBy = order.updatedBy && getUser(order.updatedBy);
    model.deletedBy = order.deletedBy && getUser(order.deletedBy);
    model.cancelledBy = order.cancelledBy && getUser(order.cancelledBy);
    model.releasedBy = order.releasedBy && getUser(order.releasedBy);
    model.cancelledBy = order.cancelledBy && getUser(order.cancelledBy);
    model.completedBy = order.completedBy && getUser(order.completedBy);
    model.startedBy = order.startedBy && getUser(order.startedBy);
    model.workCompletedBy =
        order.workCompletedBy && getUser(order.workCompletedBy);
    model.onHoldBy = order.onHoldBy && getUser(order.onHoldBy);
    model.sentToVerificationBy =
        order.sentToVerificationBy && getUser(order.sentToVerificationBy);
    model.releasedToLunchBy =
        order.releasedToLunchBy && getUser(order.releasedToLunchBy);
    // calc amount of task items
    getTaskList(model).forEach(task => {
        if (task.form && task.form.perWell) {
            model.tasks[task.id].amount = getSampleList(model).length;
        }
    });
    return model;
}

export function releaseOrder(order, user) {
    return updateDocument(`orders/${order.id}`, {
        status: OrderStatus.RELEASED,
        releasedBy: toJSON(getUser(user)),
        releasedAt: new Date().toISOString(),
    });
}

export function cancelOrder(order, user) {
    return updateDocument(`orders/${order.id}`, {
        status: OrderStatus.CANCELLED,
        cancelledBy: toJSON(getUser(user)),
        cancelledAt: new Date().toISOString(),
    });
}

export function deleteOrder(order, user) {
    return updateDocument(`orders/${order.id}`, {
        status: OrderStatus.CANCELLED,
        cancelledBy: toJSON(getUser(user)),
        cancelledAt: new Date().toISOString(),
    });
}

export function completeOrder(order, user) {
    return updateDocument(`orders/${order.id}`, {
        status: OrderStatus.COMPLETED,
        completedBy: toJSON(getUser(user)),
        completedAt: new Date().toISOString(),
    });
}

export function startWorkOnOrder(order, user) {
    return updateDocument(`orders/${order.id}`, {
        status: OrderStatus.IN_PROGRESS,
        startedBy: toJSON(getUser(user)),
        startedAt: new Date().toISOString(),
    });
}

export function completeWorkOnOrder(order, user) {
    return updateDocument(`orders/${order.id}`, {
        status: OrderStatus.WORK_COMPLETED,
        workCompletedBy: toJSON(getUser(user)),
        workCompletedAt: new Date().toISOString(),
    });
}

export function putOrderOnHold(order, user) {
    return updateDocument(`orders/${order.id}`, {
        status: OrderStatus.ON_HOLD,
        onHoldBy: toJSON(getUser(user)),
        onHoldAt: new Date().toISOString(),
    });
}

export function sendOrderToVerification(order, user) {
    return updateDocument(`orders/${order.id}`, {
        status: OrderStatus.VERIFICATION,
        sentToVerificationBy: toJSON(getUser(user)),
        sentToVerificationAt: new Date().toISOString(),
    });
}

export function releaseOrderToLaunch(order, user) {
    return updateDocument(`orders/${order.id}`, {
        status: OrderStatus.LAUNCH,
        releasedToLunchBy: toJSON(getUser(user)),
        releasedToLunchAt: new Date().toISOString(),
    });
}

export function copyToNewOrder(order) {
    const newOrder = {};
    newOrder.date = order.date;
    newOrder.notes = order.notes;
    newOrder.address = order.address;
    newOrder.description = order.description;
    newOrder.project = order.project;
    newOrder.pacGoal = order.pacGoal;
    newOrder.pacNumber = order.pacNumber;
    newOrder.pacRequirements = order.pacRequirements;
    newOrder.status = order.status;
    newOrder.customer = order.customer;
    newOrder.assignees = order.assignees;
    newOrder.assigneeIds = order.assigneeIds;
    newOrder.tasks = { ...order.tasks };
    newOrder.samples = { ...order.samples };

    Object.keys(newOrder.tasks).forEach(key => {
        const task = newOrder.tasks[key];
        newOrder.tasks[key] = {
            id: task.id,
            status: OrderTaskStatus.PENDING,
            description: task.description,
            note: task.note,
            amount: task.amount,
            amountCompleted: 0,
            amountInProgress: 0,
            form: task.form,
            images: {},
            data: {},
        };
    });

    const date =
        newOrder.date instanceof Timestamp
            ? newOrder.date
            : Timestamp.fromDate(moment(newOrder.date).toDate());

    return createDocument(`orders`, {
        ...getCleanData(new OrderModel(newOrder)),
        status: OrderStatus.NEW,
        date,
    });
}

export function addAssignee(order, assignee) {
    order.assignees.push(getUser(assignee));
    return order;
}

export function addTask(order, task) {
    order.tasks[task.id] = new OrderTaskModel({ ...task });
    return order;
}

export function addSample(order, sample) {
    order.samples[sample.id] = new OrderSampleModel({ ...sample });
    return order;
}

export function removeSample(order, sampleId) {
    let tempSamples = order.samples;
    delete tempSamples[sampleId];
    order.samples = tempSamples;
    return order;
}

export function getTaskList(order) {
    return Object.values(order.tasks).map(v => new OrderTaskModel(v));
}

export function getSampleList(order) {
    return Object.values(order.samples).map(v => new OrderSampleModel(v));
}

export function getOrderNumber(number) {
    if (number) return number;
    const date = new Date();
    const day = date.getDay();
    const month = date.getMonth();
    const minute = date.getMonth();
    const millis = date.getMilliseconds();
    const year = date.getFullYear();
    return `${month}${day}${minute}${millis}-${year}`;
}
