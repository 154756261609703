import React from "react";

import { useTheme } from "@emotion/react";
import { Box } from "@mui/material";

import { getPropByString } from "../../../lib/object-utils";

export default function StatusLabel({ status = "", color = "", ...props }) {
    const { palette } = useTheme();
    const paletteColor = { ...getPropByString(palette, color) };
    const { main, contrastText } = paletteColor;
    delete props.color;
    return (
        <Box>
            <Box
                component="span"
                sx={{
                    fontSize: 12,
                    borderRadius: "4px",
                    display: "inline",
                    lineHeight: "2",
                    fontWeight: "bold",
                    padding: "3px 7px",
                    textAlign: "center",
                    verticalAlign: "baseline",
                    whiteSpace: "nowrap",
                    textTransform: "uppercase",
                    color: contrastText,
                    bgcolor: main,
                    ...props,
                }}
            >
                {status}
            </Box>
        </Box>
    );
}
