import { Timestamp } from "firebase/firestore";

import WellStatus from "../constants/well-status";

export default function WellModel({
    id,
    name,
    status = WellStatus.ACTIVE,
    statusReason,
    statusNote,
    customer,
    address,
    createdAt,
    updatedAt,
}) {
    this.id = id;
    this.name = name;
    this.status = status;
    this.statusReason = statusReason;
    this.statusNote = statusNote;
    this.customer = customer;
    this.address = address;
    this.createdAt =
        createdAt instanceof Timestamp ? createdAt.toDate() : createdAt;
    this.updatedAt =
        updatedAt instanceof Timestamp ? updatedAt.toDate() : updatedAt;
}
