import React, { Component } from "react";

import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import CircularProgress from "@mui/material/CircularProgress";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import SnackbarContent from "@mui/material/SnackbarContent";
import TextField from "@mui/material/TextField";

import AdapterMoment from "@mui/lab/AdapterMoment";
import "moment/locale/pt-br";
import { LocalizationProvider, DatePicker } from "@mui/lab";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import classNames from "classnames";

import "./ProjectInvoicingForm.css";
import { updateDocument } from "../../../../services/data-service";

const styles = theme => ({
    invoiceResponsibleField: {
        width: 300,
        marginTop: theme.spacing(1),
        marginButton: theme.spacing(1),
        marginRight: theme.spacing(3),
    },
    invoiceDateField: {
        width: 100,
        marginTop: theme.spacing(1),
        marginButton: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    cancelButton: {
        color: "red",
    },
    submitButton: {
        color: "green",
    },
    loadingIndicator: {
        color: "green",
        marginLeft: theme.spacing(2),
    },
    error: {
        backgroundColor: theme.palette.error.dark,
        marginBottom: theme.spacing(2),
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
});

// TODO: Convert to functional component
class ProjectInvoicingForm extends Component {
    static propTypes = {
        classes: PropTypes.object,
        onUpdated: PropTypes.func,
        onClose: PropTypes.func,
        project: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            newProjectOpen: false,
            project: {},
            errors: [],
        };
    }

    componentDidMount() {
        const { project } = this.props;
        if (project) {
            this.setState({
                project,
                isLoading: false,
            });
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { project } = nextProps;
        this.setState({
            project,
            isLoading: false,
        });
    }

    handleSubmit = event => {
        event.preventDefault();

        const { project } = this.state;

        this.setState({
            isLoading: true,
        });

        updateDocument(`projects/${project.id}`, project)
            .then(() => {
                if (this.props.onUpdated) this.props.onUpdated(project);
                this.handleClose();
                this.setState({
                    isLoading: false,
                });
            })
            .catch(error => {
                this.setState({
                    isLoading: false,
                    errors: [error.message],
                });
            });
    };

    onInputChange = name => event => {
        let { project } = this.state;
        project[name] = event.target.value;
        this.setState({
            project,
        });
    };

    onDateChange = name => date => {
        let project = this.state.project;
        project[name] = date.format();
        this.setState({
            project,
        });
    };

    onCheckBoxChange = name => event => {
        let project = this.state.project;
        project[name] = event.target.checked;
        this.setState({
            project,
        });
    };

    onCloseError = key => {
        const { errors } = this.state;
        errors.splice(key, 1);
        this.setState({
            errors,
        });
    };

    handleClose = () => {
        this.setState({
            project: {},
            errors: [],
        });

        if (this.props.onClose) this.props.onClose();
    };

    render() {
        const { classes, open } = this.props;
        const { isLoading, project } = this.state;
        return (
            <div className="project-invoicing-form">
                <Dialog
                    disableBackdropClick
                    disableEscapeKeyDown
                    open={open}
                    onClose={this.handleClose}
                    aria-labelledby="invoicing-form"
                >
                    <form autoComplete="off" onSubmit={this.handleSubmit}>
                        <DialogTitle id="invoicing-form">
                            Dados da Fatura
                        </DialogTitle>
                        <DialogContent>
                            {this.renderErrors()}
                            <div className={"invoicing"}>
                                <FormControlLabel
                                    className={classes.invoiceSentField}
                                    control={
                                        <Checkbox
                                            checked={project.invoiceSent}
                                            onChange={this.onCheckBoxChange(
                                                "invoiceSent",
                                            )}
                                            value="invoiceSent"
                                            color="primary"
                                        />
                                    }
                                    label="Fatura emitida"
                                />
                                <div className={"invoicing-data"}>
                                    <FormControl
                                        className={
                                            classes.invoiceResponsibleField
                                        }
                                    >
                                        <InputLabel htmlFor="invoiceResponsible">
                                            Enviado para
                                        </InputLabel>
                                        <Input
                                            value={project.invoiceResponsible}
                                            id="invoiceResponsible"
                                            onChange={this.onInputChange(
                                                "invoiceResponsible",
                                            )}
                                            placeholder="Enviado para"
                                        />
                                    </FormControl>
                                    <LocalizationProvider
                                        dateAdapter={AdapterMoment}
                                        adapterLocale="pt-br"
                                    >
                                        <DatePicker
                                            className={classes.invoiceDateField}
                                            format="DD/MM/YYYY"
                                            disablePast={false}
                                            disabled={isLoading}
                                            labelFunc={date => {
                                                return project.invoiceDate
                                                    ? date.format("DD/MM/YYYY")
                                                    : "";
                                            }}
                                            label="Data"
                                            value={project.invoiceDate}
                                            onChange={this.onDateChange(
                                                "invoiceDate",
                                            )}
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    variant="standard"
                                                    margin="dense"
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                className={classes.cancelButton}
                                onClick={this.handleClose}
                            >
                                Cancelar
                            </Button>
                            <Button
                                className={classes.submitButton}
                                type="submit"
                                disabled={isLoading}
                            >
                                Salvar
                                {isLoading && (
                                    <CircularProgress
                                        size={18}
                                        thickness={4}
                                        className={classes.loadingIndicator}
                                    />
                                )}
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </div>
        );
    }

    renderErrors = () => {
        const { classes } = this.props;
        const { errors } = this.state;

        if (!errors) return "";

        return (
            <div className="form-errors">
                {errors.map((error, key) => {
                    return (
                        <SnackbarContent
                            key={key}
                            className={classNames(classes.error)}
                            aria-describedby="error-message"
                            message={
                                <span
                                    id="error-message"
                                    className={classes.message}
                                >
                                    <Icon
                                        className={classNames(
                                            classes.icon,
                                            classes.iconVariant,
                                        )}
                                    />
                                    {error}
                                </span>
                            }
                            action={[
                                <IconButton
                                    key="close"
                                    aria-label="Close"
                                    color="inherit"
                                    className={classes.close}
                                    onClick={() => this.onCloseError(key)}
                                >
                                    <CloseIcon className={classes.icon} />
                                </IconButton>,
                            ]}
                        />
                    );
                })}
            </div>
        );
    };
}

export default withStyles(styles)(ProjectInvoicingForm);
