/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from "react";

import { ArrowDropDownCircle, ArrowDropDown, Clear } from "@mui/icons-material";
import { FormControlLabel, Checkbox } from "@mui/material";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import InputBase from "@mui/material/InputBase";
import InputLabel from "@mui/material/InputLabel";
import Link from "@mui/material/Link";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import { makeStyles } from "@mui/styles";
import moment from "moment";

import { projectFilterType } from "../../../../constants/project-filter-types";
import {
    deadlineStatusOutput,
    accountingStatusOutput,
} from "../../../../lib/status.utils";

import "./ProjectFilter.css";

import { AuthContext } from "../../../../contexts/auth.context";
import { getCollectionData } from "../../../../services/data-service";

import Skeleton from "react-loading-skeleton";

// TODO: find a better way to load list of phases without using collectionsGroup,
// because it return hundreds phases, one per each project phase
const phasesNames = [
    { name: "Logística" },
    { name: "Conferência" },
    { name: "Laboratório" },
    { name: "Revisão Técnica" },
    { name: "Conferencia de instalação" },
    { name: "Análise Crítica" },
    { name: "Desenho" },
    { name: "Finalização do Relatório" },
    { name: "Atribuição de Relatorista" },
    { name: "Logística pós instalação" },
    { name: "Pré-Revisão" },
    { name: "Correção" },
    { name: "Laboratório pós instalação" },
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const useStyles = makeStyles(theme => ({
    root: {
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
        backgroundColor: "#e6ecf2",
    },
    formControl: {
        margin: theme.spacing(1),
        /* minWidth: 120,
    maxWidth: 300, */
    },
    chips: {
        display: "flex",
        flexWrap: "wrap",
    },
    chip: {
        margin: theme.spacing.unit / 8,
    },
    letterCheckboxContainer: {
        margin: theme.spacing(1),
    },
    input: {
        // width: '100%',
        marginLeft: 15,
        flex: 1,
        backgroundColor: "#e6ecf2",
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        width: 1,
        height: 28,
        margin: 4,
    },
}));

const WITH_PENDENCY = "Com pendência";
const WITHOUT_PENDENCY = "Sem pendência";
const WITHOUT_ASSIGNEE = "Sem atibuição";
const WITHOUT_SURVEYOR = "Sem relatorista";

const ONTIME = "No Prazo";
const OVERDUE = "Atrasados";
const LESS_THAN_FIVE = "< 5";
const LESS_THAN_TEN = "< 10";
const LESS_THAN_FIFTEEN = "< 15";
const LESS_THAN_TWENTY = "< 20";
const LESS_THAN_THIRTEEN = "< 30";

const pendencyTypes = [WITH_PENDENCY, WITHOUT_PENDENCY];
const deadlineTypes = [
    ONTIME,
    OVERDUE,
    LESS_THAN_FIVE,
    LESS_THAN_TEN,
    LESS_THAN_FIFTEEN,
    LESS_THAN_TWENTY,
    LESS_THAN_THIRTEEN,
];

const initialFilter = "";
const initialAdvancedFilter = {
    phases: [],
    pendency: [],
    assignees: [],
    deadlines: [],
    surveyors: [],
    ProjectStatus: [],
    hasLetter: null,
};
const initialState = {
    phaseNames: [],
    assigneeNames: [],
    surveyorNames: [],
    statusNames: [],
    isLoading: true,
};

async function fetchData() {
    const phasesStorage = JSON.parse(localStorage.getItem("phases"));
    const phasesData = phasesStorage ? phasesStorage : phasesNames;

    const usersStorage = JSON.parse(localStorage.getItem("users"));
    const usersData = usersStorage
        ? usersStorage
        : await getCollectionData("users");

    const phases = phasesData ? phasesData : [];
    const users = usersData ? usersData : [];

    const phaseNames = [...new Set(phases.map(phase => phase.name))];
    const userNames = [...new Set(users.map(phase => phase.name))];
    const assigneeNames = [...new Set(users.map(user => user.name))];
    const surveyorNames = [...new Set(users.map(user => user.name))];
    const statusNames = ["Cancelados", "Concluídos"];

    assigneeNames.push(WITHOUT_ASSIGNEE);
    surveyorNames.push(WITHOUT_SURVEYOR);

    if (!phasesStorage) {
        localStorage.setItem(
            "phases",
            JSON.stringify(
                phaseNames.map(e => {
                    return {
                        name: e,
                    };
                }),
            ),
        );
    }

    if (!usersStorage) {
        localStorage.setItem(
            "users",
            JSON.stringify(
                userNames.map(e => {
                    return {
                        name: e,
                    };
                }),
            ),
        );
    }

    return {
        phaseNames,
        assigneeNames,
        surveyorNames,
        statusNames,
        isLoading: !phasesData || !usersData,
    };
}

export default function ProjectFilter(props) {
    const { user: currentUser } = useContext(AuthContext);
    const classes = useStyles();

    const [advancedFilter, setAdvancedFilter] = useState(initialAdvancedFilter);
    const [filter, setFilter] = useState(initialFilter);
    const [state, setState] = useState(initialState);
    const [showAdvancedFilter, setShowAdvancedFilter] = useState(false);

    const { phaseNames, assigneeNames, surveyorNames, statusNames, isLoading } =
        state;

    const hasAdvancedFilters = af => {
        return JSON.stringify(af) !== JSON.stringify(initialAdvancedFilter);
    };

    const updateUserFilter = filter => {
        const { projects } = props;

        let filtredProjects = projects;

        if (hasAdvancedFilters(advancedFilter)) {
            filtredProjects = handleAdvancedFilter(projects);
        }

        filtredProjects = fullTextFilter(filtredProjects, filter);

        filterUpdate(filtredProjects);
    };

    const toggleAdvancedFilter = () => {
        setShowAdvancedFilter(!showAdvancedFilter);
    };

    const handleAdvancedFilter = projects => {
        let filtredProjects = projects;

        const hasFilter = filter => {
            if (!filter) return false;
            if (filter.length) {
                return true;
            } else {
                return false;
            }
        };

        // filter by phases
        if (hasFilter(advancedFilter.phases)) {
            filtredProjects = filtredProjects.filter(p => {
                return advancedFilter.phases.includes(
                    p.phase ? p.phase.name : p.phaseName || "",
                );
            });
        }

        // filter by pendency
        if (hasFilter(advancedFilter.pendency)) {
            const withPendency = () => {
                return advancedFilter.pendency.includes(WITH_PENDENCY);
            };
            const withoutPendency = () => {
                return advancedFilter.pendency.includes(WITHOUT_PENDENCY);
            };
            const filterPendency = status => {
                if (withPendency() && withoutPendency()) return true;

                if (withPendency()) {
                    return status === "Pendency";
                }

                if (withoutPendency()) {
                    return status !== "Pendency";
                }
            };

            filtredProjects = filtredProjects.filter(p => {
                return filterPendency(p.status);
            });
        }

        // filter by assignee
        if (hasFilter(advancedFilter.assignees)) {
            const withoutAssignee = () => {
                return advancedFilter.assignees.includes(WITHOUT_ASSIGNEE);
            };
            const filterAssignee = assignee => {
                if (withoutAssignee()) {
                    return (
                        assignee === "" ||
                        advancedFilter.assignees.includes(assignee)
                    );
                } else {
                    return advancedFilter.assignees.includes(assignee);
                }
            };
            filtredProjects = filtredProjects.filter(p => {
                return filterAssignee(
                    p.assignee ? p.assignee.name : p.assigneeName || "",
                );
            });
        }

        // filter by surveyor
        if (hasFilter(advancedFilter.surveyors)) {
            const whithoutSurveyor = () => {
                return advancedFilter.surveyors.includes(WITHOUT_SURVEYOR);
            };

            const filterSurveyor = surveyor => {
                if (whithoutSurveyor()) {
                    return (
                        surveyor === "" ||
                        advancedFilter.surveyors.includes(surveyor)
                    );
                } else {
                    return advancedFilter.surveyors.includes(surveyor);
                }
            };

            filtredProjects = filtredProjects.filter(p => {
                return filterSurveyor(
                    p.surveyor ? p.surveyor.name : p.surveyorName || "",
                );
            });
        }

        // filter by status
        if (hasFilter(advancedFilter.ProjectStatus)) {
            let translatedStatus = [];
            advancedFilter.ProjectStatus.forEach(status => {
                switch (status) {
                    case "Cancelados":
                        translatedStatus.push("Cancelled");
                        break;
                    case "Concluídos":
                        translatedStatus.push("Completed");
                        break;
                    default:
                        break;
                }
            });

            const filterStatus = status => {
                return translatedStatus.includes(status);
            };

            filtredProjects = filtredProjects.filter(p => {
                return filterStatus(p.status);
            });
        }

        // filter by deadline
        if (hasFilter(advancedFilter.deadlines)) {
            const isLessThanFive = deadline => {
                return deadline.isBefore(moment().add(5, "days"));
            };
            const isLessThanTen = deadline => {
                return deadline.isBefore(moment().add(10, "days"));
            };
            const isLessThanFifteen = deadline => {
                return deadline.isBefore(moment().add(15, "days"));
            };
            const isLessThanTwenty = deadline => {
                return deadline.isBefore(moment().add(20, "days"));
            };
            const isLessThanThirteen = deadline => {
                return deadline.isBefore(moment().add(30, "days"));
            };

            const filterOntime = advancedFilter.deadlines.includes(ONTIME);
            const filterOverdue = advancedFilter.deadlines.includes(OVERDUE);
            const filterLt5 = advancedFilter.deadlines.includes(LESS_THAN_FIVE);
            const filterLt10 = advancedFilter.deadlines.includes(LESS_THAN_TEN);
            const filterLt15 =
                advancedFilter.deadlines.includes(LESS_THAN_FIFTEEN);
            const filterLt20 =
                advancedFilter.deadlines.includes(LESS_THAN_TWENTY);
            const filterLt30 =
                advancedFilter.deadlines.includes(LESS_THAN_THIRTEEN);

            filtredProjects = filtredProjects
                .filter(p => {
                    if (!filterOntime && !filterOverdue) return true;
                    const deadlineStatus = [];
                    if (filterOntime) deadlineStatus.push("onTime");
                    if (filterOntime) deadlineStatus.push("approaching");
                    if (filterOverdue) deadlineStatus.push("overdue");
                    return deadlineStatus.includes(p.deadlineLabelStatus);
                })
                .filter(p => {
                    const deadlineDate = moment(p.deadlineLabelDate);
                    return (
                        (filterLt5 ? isLessThanFive(deadlineDate) : true) &&
                        (filterLt10 ? isLessThanTen(deadlineDate) : true) &&
                        (filterLt15 ? isLessThanFifteen(deadlineDate) : true) &&
                        (filterLt20 ? isLessThanTwenty(deadlineDate) : true) &&
                        (filterLt30 ? isLessThanThirteen(deadlineDate) : true)
                    );
                });
        }

        // filter by letter
        if (advancedFilter.hasLetter) {
            filtredProjects = filtredProjects.filter(project => {
                return project.hasLetter;
            });
        }

        return filtredProjects;
    };

    const handleFilterChange = event => {
        setFilter(event.target.value);
    };

    const handleAdvancedFilterChange = () => {
        const { projects } = props;

        let filtredProjects = handleAdvancedFilter(projects);

        if (filter) {
            filtredProjects = fullTextFilter(filtredProjects, filter);
        }

        if (JSON.stringify(projects) !== JSON.stringify(filtredProjects))
            filterUpdate(filtredProjects);

        if (showAdvancedFilter) {
            toggleAdvancedFilter();
        }
    };

    const filterUpdate = filtredProjects => {
        if (currentUser) {
            const usersFilters =
                JSON.parse(localStorage.getItem("usersFilters")) || [];

            const index = usersFilters.findIndex(
                uf =>
                    uf.userId === currentUser.id &&
                    uf.type === props.filterType,
            );

            const userFilter = {
                userId: currentUser.id,
                type: props.filterType,
                filter: filter,
                advancedFilter: advancedFilter,
            };

            if (index === -1) {
                usersFilters.push(userFilter);
            } else {
                usersFilters[index] = userFilter;
            }
            localStorage.setItem("usersFilters", JSON.stringify(usersFilters));
        }
        if (props.onChange) props.onChange(filtredProjects);
    };

    const handleAdvancedFilterClear = () => {
        handleClear();
    };

    const handleSelectChange = name => event => {
        advancedFilter[name] = event.target.value;
        setAdvancedFilter({ ...advancedFilter });
    };

    const handleLetterCheck = () => {
        advancedFilter.hasLetter = !advancedFilter.hasLetter;
        setAdvancedFilter({ ...advancedFilter });
    };

    const handleClear = () => {
        setFilter("");
        setAdvancedFilter({ ...initialAdvancedFilter });
        filterUpdate(props.projects);
    };

    const renderAdvancedFilter = () => {
        const { filterType } = props;
        const selectMultipleFilter = (id, name, values, selectedValues) => {
            return (
                <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="select-multiple-chip">
                        {name}
                    </InputLabel>
                    <Select
                        multiple
                        value={selectedValues}
                        onChange={handleSelectChange(id)}
                        input={<Input id="select-multiple-chip" />}
                        renderValue={selected => (
                            <div className={classes.chips}>
                                {selected.map(value => (
                                    <Chip
                                        key={value}
                                        label={value}
                                        className={classes.chip}
                                    />
                                ))}
                            </div>
                        )}
                        MenuProps={MenuProps}
                    >
                        {values.map((value, index) => (
                            <MenuItem key={index} value={value}>
                                {value}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            );
        };

        if (!showAdvancedFilter) return null;

        if (isLoading) {
            return <Skeleton height={380} />;
        }

        return (
            <div className="advanced-container">
                <div className="advanced-filters">
                    {selectMultipleFilter(
                        "phases",
                        "Etapas",
                        phaseNames,
                        advancedFilter.phases || [],
                    )}
                    {selectMultipleFilter(
                        "pendency",
                        "Pendências",
                        pendencyTypes,
                        advancedFilter.pendency || [],
                    )}
                    {selectMultipleFilter(
                        "assignees",
                        "Atribuição",
                        assigneeNames,
                        advancedFilter.assignees || [],
                    )}
                    {filterType === projectFilterType.HISTORY
                        ? selectMultipleFilter(
                              "ProjectStatus",
                              "Status",
                              statusNames,
                              advancedFilter.ProjectStatus || [],
                          )
                        : selectMultipleFilter(
                              "deadlines",
                              "Prazo",
                              deadlineTypes,
                              advancedFilter.deadlines || [],
                          )}
                    {selectMultipleFilter(
                        "surveyors",
                        "Relatorista",
                        surveyorNames,
                        advancedFilter.surveyors || [],
                    )}
                    <div className={classes.letterCheckboxContainer}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={advancedFilter.hasLetter}
                                    onChange={handleLetterCheck}
                                    color="default"
                                />
                            }
                            label={
                                <p className={classes.letterCheckboxLabel}>
                                    Carta
                                </p>
                            }
                        />
                    </div>
                </div>
                <div className="advanced-actions">
                    <Link
                        className="advanced-action"
                        component="button"
                        onClick={handleAdvancedFilterClear}
                    >
                        LIMPAR
                    </Link>
                    <Link
                        className="advanced-action"
                        component="button"
                        onClick={handleAdvancedFilterChange}
                    >
                        FILTRAR
                    </Link>
                </div>
            </div>
        );
    };

    const loadCurrentUserFilters = () => {
        if (currentUser) {
            const usersFilters =
                JSON.parse(localStorage.getItem("usersFilters")) || [];

            const index = usersFilters.findIndex(
                uf =>
                    uf.userId === currentUser.id &&
                    uf.type === props.filterType,
            );

            return usersFilters[index];
        }
    };

    const loadCurrentUserAdvancedFilter = () => {
        const currentUserFilter = loadCurrentUserFilters();
        if (currentUserFilter && currentUserFilter.advancedFilter) {
            setAdvancedFilter(currentUserFilter.advancedFilter);
        }
    };

    const loadCurrentUserFilter = () => {
        const currentUserFilter = loadCurrentUserFilters();
        if (currentUserFilter && currentUserFilter.filter) {
            setFilter(currentUserFilter.filter);
        }
    };

    useEffect(() => {
        fetchData().then(data => {
            setState({
                ...state,
                phaseNames: data.phaseNames,
                assigneeNames: data.assigneeNames,
                surveyorNames: data.surveyorNames,
                statusNames: data.statusNames,
                isLoading: false,
            });
        });
        loadCurrentUserFilter();
        loadCurrentUserAdvancedFilter();
    }, []);

    useEffect(() => {
        if (!isLoading) updateUserFilter(filter);
    }, [isLoading]);

    useEffect(() => {
        updateUserFilter(filter);
    }, [filter, advancedFilter]);

    return (
        <div className="project-filter">
            <div className="select-container">
                <Paper className={classes.root} elevation={0}>
                    <InputBase
                        className={classes.input}
                        fullWidth
                        value={filter}
                        onChange={handleFilterChange}
                        placeholder="Buscar por código, área de estudo, razão social ou status"
                    />
                    <IconButton
                        className={classes.iconButton}
                        aria-label="Filtro"
                        onClick={handleClear}
                    >
                        <Clear />
                    </IconButton>
                    <Divider className={classes.divider} />
                    <IconButton
                        color="primary"
                        className={classes.iconButton}
                        aria-label="Filtros avançados"
                        onClick={toggleAdvancedFilter}
                    >
                        {hasAdvancedFilters(advancedFilter) ? (
                            <ArrowDropDownCircle />
                        ) : (
                            <ArrowDropDown />
                        )}
                    </IconButton>
                </Paper>
            </div>
            {renderAdvancedFilter()}
        </div>
    );
}

export const fullTextFilter = (projects, filter) => {
    const ProjectStatus = status => {
        switch (status) {
            case "Completed":
                return "Concluído";
            case "Cancelled":
                return "Cancelado";
            case "Pendency":
                return "Pendência";
            default:
                return "";
        }
    };

    const toFilterString = s => {
        return s
            ? s
                  .toString()
                  .toUpperCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
            : "";
    };

    const compareFilters = (a, b) => {
        return toFilterString(a).includes(toFilterString(b));
    };

    const filtredProjects = filter
        ? projects.filter(p => {
              return (
                  compareFilters(p.id, filter) ||
                  compareFilters(p.number, filter) ||
                  compareFilters(ProjectStatus(p.status), filter) ||
                  compareFilters(
                      p.assignee ? p.assignee.name : p.assigneeName || "",
                      filter,
                  ) ||
                  compareFilters(
                      p.phase ? p.phase.name : p.phaseName || "",
                      filter,
                  ) ||
                  compareFilters(
                      moment(p.deadlineDate, moment.ISO_8601).format("LL"),
                      filter,
                  ) ||
                  compareFilters(
                      p.customer ? p.customer.name : p.customerName || "",
                      filter,
                  ) ||
                  compareFilters(
                      p.studyArea
                          ? p.studyArea.name || p.studyArea.description
                          : p.studyAreaName || "",
                      filter,
                  ) ||
                  compareFilters(
                      p.study ? p.study.name : p.studyName || "",
                      filter,
                  ) ||
                  compareFilters(
                      deadlineStatusOutput(p.deadlineStatus),
                      filter,
                  ) ||
                  compareFilters(
                      accountingStatusOutput(p.accountingStatus),
                      filter,
                  )
              );
          })
        : projects;

    return filtredProjects;
};
