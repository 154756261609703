const OrderActions = {
    RELEASE: "RELEASE",
    RELEASE_BACK: "RELEASE_BACK",
    CANCEL: "CANCEL",
    START_WORK: "START_WORK",
    COMPLETE_WORK: "COMPLETE_WORK",
    SUSPEND_WORK: "SUSPEND_WORK",
    SEND_TO_VERIFICATION: "SEND_TO_VERIFICATION",
    RELEASE_TO_LAUNCH: "RELEASE_TO_LAUNCH",
    COMPLETE: "COMPLETE",
    COPY: "COPY",
};

export default OrderActions;
