/* eslint-disable react/prop-types */
import React, { useContext, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import SnackbarContent from "@mui/material/SnackbarContent";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";

import {
    cnpjParser,
    phoneParser,
    cpfParser,
} from "../../../../../lib/form-utils";
import CustomerInfo from "../../../../forms/customer-form/new-customer-form/sections/customer-info";

import "./NewCustomerForm.css";
import cep from "cep-promise";

import { AuthContext } from "../../../../../contexts/auth.context";
import { createDocument } from "../../../../../services/data-service";
import CustomerAddress from "../../../customer-form/new-customer-form/sections/customer-address/CustomerAddress";
import RaizenCheckbox from "../../../customer-form/new-customer-form/sections/raizen-checkbox";

const useStyles = makeStyles(theme => ({
    inputField: {
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    nameField: {
        width: 300,
    },
    cnpjField: {
        width: 200,
    },
    addressField: {
        width: 400,
    },
    numberField: {
        width: 100,
    },
    neighborhoodField: {
        width: 200,
    },
    cityField: {
        width: 184,
    },
    stateField: {
        width: 100,
    },
    legalResponsibleField: {
        width: 300,
    },
    cpfField: {
        width: 200,
    },
    phoneField: {
        width: 200,
    },
    emailField: {
        width: 300,
    },
    actionContainer: {
        padding: "0 24px 24px",
    },
    cancelButton: {
        color: "red",
    },
    submitButton: {
        color: "green",
    },
    loadingIndicator: {
        color: "green",
        marginLeft: theme.spacing(2),
    },
    error: {
        backgroundColor: theme.palette.error.dark,
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    dialogContent: {
        minWidth: "520px",
        minHeight: "505px",
    },
}));

const newCustomer = {
    name: "",
    cnpj: "",
    zipCode: "",
    address: "",
    number: "",
    neighborhood: "",
    city: "",
    state: "",
    legalResponsibleName: "",
    legalResponsibleCpf: "",
    legalResponsiblePhone: "",
    legalResponsibleEmail: "",
    financialResponsibleName: "",
    financialResponsibleCpf: "",
    financialResponsiblePhone: "",
    financialResponsibleEmail: "",
    raizenDeadlines: false,
};

export default function NewCustomerForm(props) {
    const { hasAnyRole } = useContext(AuthContext);
    const classes = useStyles();

    const [isLoading, setIsLoading] = useState(false);
    const [customer, setCustomer] = useState({
        ...newCustomer,
        ...props.customer,
    });
    const [errors, setErrors] = useState([]);
    const [cnpjExists, setCnpjExists] = useState(false);
    const [fieldError, setFieldError] = useState({});

    const handleClose = () => {
        setCustomer(newCustomer);
        setErrors([]);
        if (props.onClose) props.onClose();
    };

    const handleCheckCnpj = ({ isValidating, cnpjInUse }) => {
        setIsLoading(isValidating);
        setCnpjExists(cnpjInUse);
    };

    const loadAddressByCep = value => {
        fieldError.zipCode = "";
        if (value) {
            setIsLoading(true);
            cep(value)
                .then(response => {
                    customer.zipCode = response.cep;
                    customer.address = response.street;
                    customer.neighborhood = response.neighborhood;
                    customer.city = response.city;
                    customer.state = response.state;
                    setCustomer({ ...customer });
                    setFieldError({ ...fieldError });
                    setIsLoading(false);
                })
                .catch(error => {
                    fieldError.zipCode = error.message;
                    console.error(error);
                    setFieldError({ ...fieldError });
                    setIsLoading(false);
                });
        } else {
            setFieldError({ ...fieldError });
        }
    };

    const handleSubmit = async event => {
        event.preventDefault();

        setIsLoading(true);
        setCnpjExists(false);

        customer.cnpj = cnpjParser(customer.cnpj);

        if (customer.legalResponsibleCpf) {
            customer.legalResponsibleCpf = cpfParser(
                customer.legalResponsibleCpf,
            );
        }
        if (customer.financialResponsibleCpf) {
            customer.financialResponsibleCpf = cpfParser(
                customer.financialResponsibleCpf,
            );
        }
        if (customer.legalResponsiblePhone) {
            customer.legalResponsiblePhone = phoneParser(
                customer.legalResponsiblePhone,
            );
        }
        if (customer.financialResponsiblePhone) {
            customer.financialResponsiblePhone = phoneParser(
                customer.financialResponsiblePhone,
            );
        }

        if (!cnpjExists) {
            createDocument("customers", customer)
                .then(result => {
                    if (props.onCreated) props.onCreated(result);
                    handleClose();
                    setIsLoading(false);
                })
                .catch(error => {
                    setIsLoading(false);
                    setErrors(
                        error.messages || [
                            "Não foi possível cadastro o cliente, tente novamente",
                        ],
                    );
                });
        } else {
            setIsLoading(false);
            setErrors(["CNPJ já cadastrado"]);
            setCnpjExists(true);
        }
    };

    const handleInputChange = name => event => {
        customer[name] = event.target.value;
        setCustomer({ ...customer });
    };

    const handleRaizenCheck = () => {
        customer.raizenDeadlines = !customer.raizenDeadlines;
        setCustomer({ ...customer });
    };

    const onCloseError = key => {
        errors.splice(key, 1);
        setErrors([...errors]);
    };

    const renderErrors = () => {
        if (errors)
            return (
                <div className="form-errors">
                    {errors.map((error, key) => {
                        return (
                            <SnackbarContent
                                key={key}
                                className={classNames(classes.error)}
                                aria-describedby="error-message"
                                message={
                                    <span
                                        id="error-message"
                                        className={classes.message}
                                    >
                                        <Icon
                                            className={classNames(
                                                classes.icon,
                                                classes.iconVariant,
                                            )}
                                        />
                                        {error}
                                    </span>
                                }
                                action={[
                                    <IconButton
                                        key="close"
                                        aria-label="Close"
                                        color="inherit"
                                        className={classes.close}
                                        onClick={() => onCloseError(key)}
                                    >
                                        <CloseIcon className={classes.icon} />
                                    </IconButton>,
                                ]}
                            />
                        );
                    })}
                </div>
            );
    };

    return (
        <div className="project-new-customer-form">
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                open={props.open}
                onClose={handleClose}
                aria-labelledby="new-customer-form"
                className={classes.newCustomerDialog}
            >
                <form autoComplete="off" onSubmit={handleSubmit}>
                    <DialogTitle id="new-customer-form">
                        Dados do Cliente
                    </DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        {renderErrors()}
                        <CustomerInfo
                            customer={customer}
                            onInputChange={handleInputChange}
                            onCheckCnpj={handleCheckCnpj}
                        />
                        <RaizenCheckbox
                            customer={customer}
                            handleRaizenCheck={handleRaizenCheck}
                        />
                        <CustomerAddress
                            customer={customer}
                            onInputChange={handleInputChange}
                            fieldError={fieldError}
                            loadAddressByCep={loadAddressByCep}
                        />
                    </DialogContent>
                    <DialogActions className={classes.actionContainer}>
                        <Button
                            className={classes.cancelButton}
                            onClick={handleClose}
                        >
                            Cancelar
                        </Button>
                        <Button
                            className={classes.submitButton}
                            type="submit"
                            disabled={
                                isLoading || cnpjExists || !hasAnyRole(["CDC"])
                            }
                        >
                            Cadastrar
                            {isLoading && (
                                <CircularProgress
                                    size={18}
                                    thickness={4}
                                    className={classes.loadingIndicator}
                                />
                            )}
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
}
