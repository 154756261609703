import React, { useContext } from "react";

import { faCalendar } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import moment from "moment";
import Skeleton from "react-loading-skeleton";

import "./ProjectDeadline.css";

import ProjectStatus from "../../../../../constants/project.status";
import { DataContext } from "../../../../../contexts";
import { deadlineInfo } from "../../../../../lib/status.utils";

export default function ProjectDeadline(props) {
    const {
        project,
        isLoadingProject,
        isLoadingUsers,
        isLoadingInfos,
        isLoadingEvents,
    } = useContext(DataContext);

    const isLoading =
        isLoadingUsers || isLoadingProject || isLoadingInfos || isLoadingEvents;

    if (isLoading || !project.id) return <Skeleton height={110} />;

    if (
        [ProjectStatus.CANCELLED, ProjectStatus.COMPLETED].includes(
            project.status,
        )
    )
        return null;

    const { status, deadline } = deadlineInfo(
        project.createdAt,
        project.deadlineDays,
    );

    return isLoading ? (
        <Skeleton height={110} />
    ) : (
        <div className="project-deadline">
            <div
                className={classNames({
                    "deadline-icon": true,
                    "deadline-on-time-icon": status === "onTime",
                    "deadline-less-than-twenty-icon": status === "approaching",
                    "deadline-out-of-time-icon": status === "overdue",
                })}
            >
                <div className="deadline-icon-container">
                    <FontAwesomeIcon
                        className="deadline-calendar-icon"
                        icon={faCalendar}
                    />
                </div>
            </div>
            <div
                className={classNames({
                    "deadline-data": true,
                    "deadline-on-time": status === "onTime",
                    "deadline-less-than-twenty-time": status === "approaching",
                    "deadline-out-of-time": status === "overdue",
                })}
            >
                <h5 className="deadline-data-title">PRAZO DO PROJETO</h5>
                <span className="deadline-data-info">
                    {moment(deadline, moment.ISO_8601).fromNow(true)}
                </span>
            </div>
        </div>
    );
}
