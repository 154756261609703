import React from "react";
import classNames from "classnames";

import { deadlineInfo, deadlineLabelStyle } from "../../../lib/status.utils";
import moment from "moment";
import { Tooltip } from "@mui/material";

export default function DeadlineLabel({
    startDate,
    daysToDelivery,
    title,
    size = 9,
}) {
    const { deadline, status } = deadlineInfo(startDate, daysToDelivery);

    return (
        <Tooltip title={title}>
            <span
                style={{ fontSize: size }}
                className={classNames("label", deadlineLabelStyle(status))}
            >
                {moment(deadline, moment.ISO_8601).fromNow(true)}
            </span>
        </Tooltip>
    );
}
