/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";

import styled from "@emotion/styled";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import DateAdapter from "@mui/lab/AdapterMoment";
import DatePicker from "@mui/lab/DatePicker";
import LoadingButton from "@mui/lab/LoadingButton";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import {
    Autocomplete,
    Button,
    Chip,
    Grid,
    TextField,
    Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Stack from "@mui/material/Stack";
import { createTheme } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import {
    DataGrid,
    GridActionsCellItem,
    GridToolbarContainer,
} from "@mui/x-data-grid";
import { useConfirm } from "material-ui-confirm";
import moment from "moment";

import OrderSampleDialog from "./OrderSampleDialog";
import OrderTaskDialog from "./OrderTaskDialog";
import {
    SAMPLE_DEPTH,
    SAMPLE_LAB,
    SAMPLE_MATRIX,
    SAMPLE_VALIDITY,
} from "../../../constants/order-sample";
import OrderStatus from "../../../constants/order-status";
import UserRoles from "../../../constants/user-roles";
import { AuthContext } from "../../../contexts";
import {
    orderTaskStatusColor,
    orderTaskStatusName,
} from "../../../lib/status.utils";
import {
    addSample,
    addTask,
    getCollectionData,
    getSampleList,
    getTaskList,
    getUser,
    where,
} from "../../../services";
import { removeSample } from "../../../services/order-service";
import CustomerSelector from "../../commons/customer-selector";
import DetailView from "../../commons/detail-view";
import FormSection from "../../commons/form-section/FormSection";
import HtmlTooltip from "../../commons/html-tooltip";
import ProjectRACPSelector from "../../commons/project-racp-selector";
import StatusLabel from "../../commons/status-label";
import StudyAreaSelector from "../../commons/study-area-selector";

const StackItem = styled(Box)(() => ({
    textAlign: "center",
}));

export default function OrderFormEdit({
    order,
    dialog,
    onSave,
    onCancel,
    onChange,
    onDelete,
}) {
    return (
        <DetailView
            sx={{ ...(dialog && { padding: theme.spacing(2) }) }}
            headerTitle="Ordem de Serviço"
            subHeader1={<SubHeader1 order={order} />}
            headerActions={
                <HeaderActions
                    order={order}
                    onSave={onSave}
                    onCancel={onCancel}
                    onDelete={onDelete}
                />
            }
            content={<Content order={order} onChange={onChange} />}
        />
    );
}

const theme = createTheme();

const HeaderActions = ({ order, onSave, onCancel, onDelete }) => {
    const { hasRole } = useContext(AuthContext);
    const isNew = !order.id;
    return (
        <Stack direction="row" spacing={1}>
            <LoadingButton
                size="small"
                variant="contained"
                startIcon={<SaveIcon />}
                onClick={onSave}
            >
                Salvar
            </LoadingButton>

            {!isNew && hasRole(UserRoles.DELETE_ORDER) && (
                <LoadingButton
                    size="small"
                    variant="contained"
                    startIcon={<DeleteIcon />}
                    onClick={onDelete}
                >
                    Excluir
                </LoadingButton>
            )}

            {!isNew && (
                <LoadingButton
                    size="small"
                    variant="contained"
                    startIcon={<CancelIcon />}
                    onClick={onCancel}
                >
                    Cancelar
                </LoadingButton>
            )}
        </Stack>
    );
};

const SubHeader1 = ({ order }) => {
    const { status } = order;
    return (
        <Box sx={{ pt: theme.spacing(1), pb: theme.spacing(1) }}>
            <Stack
                direction="row"
                // divider={<Divider orientation="vertical" flexItem />}
                spacing={2}
            >
                <StackItem>{order.number}</StackItem>
                <StackItem>
                    <StatusLabel status={status.name} color={status.color} />
                </StackItem>
            </Stack>
        </Box>
    );
};

const Content = ({ order, onChange }) => {
    return (
        <Box>
            <FormSection title="Dados do Serviço">
                <ServiceContent order={order} onChange={onChange} />
            </FormSection>

            <FormSection title="Dados do Cliente">
                <CustomerContent order={order} onChange={onChange} />
            </FormSection>

            <FormSection title="Plano de Amostragem">
                <SamplingPlan order={order} onChange={onChange} />
            </FormSection>

            <FormSection title="Atividades a serem desenvolvidas">
                <TaskContent order={order} onChange={onChange} />
            </FormSection>
        </Box>
    );
};

const ServiceContent = ({ order, onChange }) => {
    const [isLoading, setLoading] = useState(true);
    const [assignees, setAssignees] = useState([]);
    const { currentUser } = useContext(AuthContext);

    // load assignees
    useEffect(() => {
        getCollectionData("users", [
            where("roles", "array-contains", UserRoles.CAMPO),
        ]).then(users => {
            setAssignees([...users.map(user => getUser(user))]);
            setLoading(false);
        });
    }, []);

    return (
        <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
            <Grid item xs={12}>
                <TextField
                    id="number"
                    value={order.number || ""}
                    // onChange={event =>
                    //     onChange &&
                    //     onChange({
                    //         ...order,
                    //         number: event.target.value,
                    //     })
                    // }
                    label="Número da ordem"
                    // disabled={isLoading}
                    variant="filled"
                />
            </Grid>
            <Grid item xs={10} md={10} sm={12}>
                <TextField
                    fullWidth
                    id="description"
                    value={order.description || ""}
                    onChange={event =>
                        onChange &&
                        onChange({
                            ...order,
                            description: event.target.value,
                        })
                    }
                    label="Descrição"
                    variant="standard"
                    // disabled={isLoading}
                />
            </Grid>
            <Grid item xs={2} md={2} sm={12}>
                <LocalizationProvider dateAdapter={DateAdapter}>
                    <DatePicker
                        label="Data"
                        value={moment(order.date.toDate()) || moment()}
                        onChange={date => {
                            onChange && onChange({ ...order, date });
                        }}
                        disabled={isLoading}
                        renderInput={params => (
                            <TextField
                                fullWidth
                                {...params}
                                variant="standard"
                            />
                        )}
                    />
                </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    id="notes"
                    value={order.notes || ""}
                    onChange={event =>
                        onChange &&
                        onChange({
                            ...order,
                            notes: event.target.value,
                        })
                    }
                    multiline
                    rows={4}
                    label="Observaçoes"
                    variant="standard"
                    // disabled={isLoading}
                />
            </Grid>
            <Grid item xs={12}>
                <Autocomplete
                    multiple
                    id="assignees"
                    sx={{ mb: theme.spacing(3) }}
                    options={assignees}
                    getOptionLabel={option => option.name}
                    isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                    }
                    value={order.assignees || []}
                    onChange={(event, assignees) =>
                        onChange && onChange({ ...order, assignees })
                    }
                    disabled={isLoading}
                    renderInput={params => (
                        <TextField
                            {...params}
                            variant="standard"
                            label="Responsáveis"
                        />
                    )}
                />
            </Grid>
        </Grid>
    );
};

const CustomerContent = ({ order, onChange }) => {
    const { project, customer, address } = order;
    const customerId = customer ? customer.id : "";
    const addressId = address ? address.id : "";

    const updateProject = value => {
        if (value) {
            if (value.customer && value.customer.id !== customerId) {
                order.customer = value.customer;
            }

            if (value.studyArea && value.studyArea.id !== addressId) {
                order.address = value.studyArea;
            }
        }
        onChange({ ...order, project: value });
    };

    const updateCustomer = value => {
        if (value) {
            if (value.id !== customerId) {
                order.customer = value.customer;
                order.address = null;
            }
        } else {
            order.address = null;
        }
        onChange({ ...order, customer: value });
    };

    const updateAddress = value => {
        onChange({ ...order, address: value });
    };

    return (
        <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
            <Grid item xs={12}>
                <Grid item xs={4} md={4} sm={12}>
                    <ProjectRACPSelector
                        project={project}
                        label="Nº RACP"
                        onSelected={updateProject}
                    />
                </Grid>
            </Grid>
            <Grid item xs={7} md={7} sm={12}>
                <CustomerSelector
                    customer={customer}
                    label="Cliente"
                    onSelected={updateCustomer}
                />
            </Grid>
            <Grid item xs={10} md={10} sm={12}>
                <StudyAreaSelector
                    customer={customer}
                    studyArea={address}
                    onSelected={updateAddress}
                />
            </Grid>
        </Grid>
    );
};

const SamplingPlan = ({ order, onChange }) => {
    const { customer, address } = order;
    const samples = getSampleList(order);
    const customerId = customer ? customer.id : "";
    const addressId = address ? address.id : "";
    const [isLoading, setIsLoading] = useState(false);
    const [openOrderSampleDialog, setOpenOrderSampleDialog] = useState(false);
    const [selectedSamples, setSelectedSamples] = useState([]);
    const confirm = useConfirm();

    useEffect(() => {
        if (customerId && addressId && !samples.length) {
            setIsLoading(true);
            getCollectionData(
                `customers/${customerId}/addresses/${addressId}/wells`,
            )
                .then(data => {
                    data.forEach(well =>
                        addSample(order, {
                            id: well.id,
                            name: well.name,
                            matrix: "ABS",
                            laboratory: "Acqualab",
                            depth: "VOC",
                            validity: "15 dias",
                            parameters: ["TPH (Total)", "BTEX"],
                            well: well,
                        }),
                    );
                    onChange({
                        ...order,
                    });
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [customerId, addressId]);

    const columns = [
        {
            field: "name",
            headerName: "ST ou PM",
            width: 140,
            editable: true,
        },
        {
            field: "matrix",
            headerName: "Matriz",
            width: 120,
            editable: true,
            type: "singleSelect",
            valueOptions: SAMPLE_MATRIX,
        },
        {
            field: "laboratory",
            headerName: "Laboratório",
            width: 200,
            editable: true,
            type: "singleSelect",
            valueOptions: SAMPLE_LAB,
        },
        {
            field: "depth",
            headerName: "Profundidade",
            width: 120,
            editable: true,
            type: "singleSelect",
            valueOptions: SAMPLE_DEPTH,
        },
        {
            field: "validity",
            headerName: "Validade",
            width: 100,
            editable: true,
            type: "singleSelect",
            valueOptions: SAMPLE_VALIDITY,
        },
        {
            field: "parameters",
            headerName: "Parâmetros de Análise",
            // type: "singleSelect",
            editable: false,
            width: 250,
            valueGetter: params => `${params?.join(", ")}`,
            renderCell: ({ row }) => (
                <HtmlTooltip
                    title={
                        <Typography color="inherit">
                            {row.parameters.join(", ")}
                        </Typography>
                    }
                >
                    <Typography variant="body2" gutterBottom>
                        {row.parameters &&
                            row.parameters
                                .join(", ")
                                .replace(/(.{30})..+/gms, "$1…")}
                    </Typography>
                </HtmlTooltip>
            ),
        },
    ];

    const onEditSampleCommit = ({ field, value, id }) => {
        order.samples[id][field] = value;
        onChange({ ...order });
    };

    const SamplingToolbar = () => {
        return (
            <GridToolbarContainer>
                <Button
                    variant="text"
                    startIcon={<AddIcon />}
                    size="small"
                    onClick={() => {
                        setSelectedSamples([{}]);
                        setOpenOrderSampleDialog(true);
                    }}
                >
                    Novo
                </Button>
                <Button
                    variant="text"
                    startIcon={<EditIcon />}
                    size="small"
                    onClick={() => {
                        setOpenOrderSampleDialog(true);
                    }}
                    disabled={selectedSamples.length === 0}
                >
                    Editar
                </Button>
                <Button
                    variant="text"
                    startIcon={<DeleteIcon />}
                    size="small"
                    onClick={() => {
                        confirm({
                            title: "Confirmar exclusão",
                            description: `Deseja remover poços selecionados' ?`,
                            confirmationText: "Sim",
                            cancellationText: "Não",
                        }).then(() => {
                            selectedSamples.forEach(s =>
                                removeSample(order, s.id),
                            );
                            onChange({
                                ...order,
                            });
                        });
                    }}
                    disabled={selectedSamples.length === 0}
                >
                    Excluir
                </Button>
            </GridToolbarContainer>
        );
    };

    return (
        <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
            <Grid container item xs={12}>
                <Grid item xs={12} md={4} sm={6}>
                    <TextField
                        fullWidth
                        id="pacNumber"
                        value={order.pacNumber || ""}
                        onChange={event =>
                            onChange({
                                ...order,
                                pacNumber: event.target.value,
                            })
                        }
                        label="PAC Nº"
                        variant="standard"
                        disabled={isLoading}
                    />
                </Grid>
            </Grid>

            <Grid container item xs={12}>
                <Grid item xs={12} md={8} sm={10}>
                    <TextField
                        fullWidth
                        id="pacGoal"
                        value={order.pacGoal || ""}
                        onChange={event =>
                            onChange({
                                ...order,
                                pacGoal: event.target.value,
                            })
                        }
                        label="Objetivo"
                        variant="standard"
                        disabled={isLoading}
                    />
                </Grid>
            </Grid>

            <Grid container item xs={12}>
                <Grid item xs={12} md={8} sm={10}>
                    <TextField
                        fullWidth
                        id="pacRequirements"
                        value={order.pacRequirements || ""}
                        onChange={event =>
                            onChange({
                                ...order,
                                pacRequirements: event.target.value,
                            })
                        }
                        label="Requisitos do Cliente"
                        variant="standard"
                        disabled={isLoading}
                    />
                </Grid>
            </Grid>

            <Grid container item xs={12} md={12} sm={12}>
                <Grid item xs={12} md={12} sm={12}>
                    <Box
                        sx={{
                            height: 500,
                            pt: theme.spacing(1),
                            pb: theme.spacing(5),
                        }}
                    >
                        <DataGrid
                            rows={samples}
                            columns={columns}
                            loading={isLoading}
                            onCellEditCommit={params =>
                                onEditSampleCommit(params)
                            }
                            onRowSelectionModelChange={ids =>
                                setSelectedSamples([
                                    ...samples.filter(s => ids.includes(s.id)),
                                ])
                            }
                            checkboxSelection
                            slots={{ toolbar: SamplingToolbar }}
                        />

                        {openOrderSampleDialog && (
                            <OrderSampleDialog
                                open={openOrderSampleDialog}
                                data={selectedSamples}
                                onClose={() => setOpenOrderSampleDialog(false)}
                                onSave={values => {
                                    values.forEach(value => {
                                        order.samples[value.id] = value;
                                    });
                                    onChange({
                                        ...order,
                                    });
                                    setOpenOrderSampleDialog(false);
                                }}
                            />
                        )}
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    );
};

const TaskContent = ({ order, onChange }) => {
    const [openOrderTaskDialog, setOpenOrderTaskDialog] = useState(false);

    const tasks = getTaskList(order);

    const columns = [
        {
            field: "actions",
            type: "actions",
            width: 80,
            getActions: params => [
                <GridActionsCellItem
                    key={1}
                    icon={<DeleteIcon />}
                    label="Delete"
                    onClick={() => onDeleteTask(params)}
                />,
            ],
        },
        {
            field: "description",
            headerName: "Descrição",
            width: 400,
            editable: true,
        },
        {
            field: "amount",
            type: "number",
            headerName: "Quantidade",
            width: 100,
            editable: true,
            valueGetter: (value, row) => {
                const { amount = 0, amountCompleted = 0 } = row;
                return `${amountCompleted}/${amount}`;
            },
        },
        {
            field: "form",
            valueGetter: form => {
                if (!form) {
                    return "";
                }
                return form.shortName;
            },
            headerName: "Tipo",
            width: 100,
            editable: false,
        },
        {
            field: "status",
            headerName: "Status",
            width: 120,
            valueFormatter: ({ value }) => orderTaskStatusName(value),
            valueGetter: value => orderTaskStatusName(value),
            renderCell: ({ row }) => (
                <Chip
                    size="small"
                    label={orderTaskStatusName(row.status)}
                    color={orderTaskStatusColor(row.status)}
                />
            ),
        },
        {
            field: "note",
            headerName: "Observação",
            width: 250,
            editable: true,
            flex: 1,
            renderCell: ({ row }) => (
                <HtmlTooltip title={row.note}>
                    <Typography variant="body2" gutterBottom>
                        {row.note && row.note.replace(/(.{30})..+/gms, "$1…")}
                    </Typography>
                </HtmlTooltip>
            ),
        },
    ];

    const onDeleteTask = ({ row }) => {
        delete order.tasks[row.id];
        onChange({ ...order });
    };

    const onEditWellCommit = ({ field, value, id }) => {
        order.tasks[id][field] = value;
        onChange({ ...order });
    };

    const TasksToolbar = () => {
        return (
            <GridToolbarContainer>
                <Button
                    variant="text"
                    sx={{ mb: theme.spacing(1) }}
                    size="small"
                    startIcon={<AddIcon />}
                    onClick={() => setOpenOrderTaskDialog(true)}
                >
                    Novo
                </Button>
            </GridToolbarContainer>
        );
    };

    return (
        <Grid container item xs={12} md={12} sm={12}>
            <Grid item xs={12} md={12} sm={12}>
                <Box sx={{ height: 500, pt: theme.spacing(1) }}>
                    <DataGrid
                        rows={tasks}
                        columns={columns}
                        // loading={isLoading}
                        onCellEditCommit={params => onEditWellCommit(params)}
                        slots={{ toolbar: TasksToolbar }}
                    />
                    {openOrderTaskDialog && (
                        <OrderTaskDialog
                            open={openOrderTaskDialog}
                            onClose={() => setOpenOrderTaskDialog(false)}
                            onSave={task => {
                                addTask(order, task);
                                onChange({
                                    ...order,
                                });
                                setOpenOrderTaskDialog(false);
                            }}
                        />
                    )}
                </Box>
            </Grid>
        </Grid>
    );
};
