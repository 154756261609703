
export const projectFilterType = {
    USER: "USER",
    USER_GROUP: "USER_GROUP",
    ALL: "ALL",
    HISTORY: "HISTORY",
    PROVISIONING: "PROVISIONING",
    DRAFTER: "DRAFTER",
    SURVEYOR: "SURVEYOR",
};

export const projectPath = filterType => {
    switch (filterType) {
        case projectFilterType.USER:
            return "user-activities";
        case projectFilterType.USER_GROUP:
            return "department-activities";
        case projectFilterType.ALL:
            return "projects";
        case projectFilterType.HISTORY:
            return "project-history";
        case projectFilterType.DRAFTER:
            return "project/desenhos";
        case projectFilterType.SURVEYOR:
            return "project/relatorios";
        default:
            return "projects";
    }
};
