import React, { useContext, useState } from "react";

import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import { NavLink } from "react-router-dom";

import { AuthContext } from "../../../contexts";
import "./SidebarItem.css";

const classNames = require("classnames");

const useStyles = makeStyles(theme => ({
    badgePrimary: { backgroundColor: "var(--red)" },
    badgeSecondary: { backgroundColor: "var(--dusk)" },
    badge: {
        top: "50%",
        right: -20,
    },
}));

export default function SidebarItem(props) {
    const { hasAnyRole } = useContext(AuthContext);
    const [isExpanded, setIsExpanded] = useState(false);
    const classes = useStyles();
    const { text, icon, navTo, counter, counterType, subitems, expanded } =
        props;

    const getIsExpanded = () => {
        return isExpanded || expanded;
    };

    const subItemNav = (subitem, index) => {
        return (
            <NavLink
                key={index}
                to={subitem.navTo}
                replace={true}
                exact={true}
                activeClassName="sidebar-item-selected"
            >
                <div className={"sidebar-subitem"}>
                    <ItemIcon itemIcon={subitem.icon} />
                    <span className={"sidebar-item-text"}>
                        {counter
                            ? itemWithCounter(subitem.counter, subitem.text)
                            : subitem.text}
                    </span>
                </div>
            </NavLink>
        );
    };

    const subItems = () => {
        if (subitems) {
            const subitemSet = subitems
                .map((subitem, index) => {
                    if (subitem.authRoles) {
                        if (hasAnyRole(subitem.authRoles)) {
                            return subItemNav(subitem, index);
                        } else {
                            return null;
                        }
                    } else {
                        return subItemNav(subitem, index);
                    }
                })
                .filter(subitem => subitem != null);

            if (subitemSet !== undefined && subitemSet.length > 0) {
                return subitemSet;
            }
        }
    };

    const onItemClick = () => {
        if (subItems()) {
            setIsExpanded(!getIsExpanded());
        }
    };

    const iconClasses = classNames({
        "sidebar-item-icon": true,
    });

    const subitemClasses = classNames({
        "sidebar-subitem-container": true,
        expand: getIsExpanded(),
        retract: !getIsExpanded(),
    });

    const ItemIcon = ({ itemIcon }) => {
        if (itemIcon) {
            return <FontAwesomeIcon className={iconClasses} icon={itemIcon} />;
        } else {
            return null;
        }
    };

    const CaretIcon = () => {
        if (subItems()) {
            return (
                <FontAwesomeIcon
                    icon={getIsExpanded() ? faCaretUp : faCaretDown}
                />
            );
        } else {
            return null;
        }
    };

    const subItem = () => {
        if (getIsExpanded() && subItems()) {
            return <div className={subitemClasses}>{subItems()}</div>;
        }
    };

    const itemWithCounter = (counter, item) => {
        return (
            <Badge
                badgeContent={counter}
                max={9999}
                color={counterType === "alert" ? "primary" : "secondary"}
                classes={{
                    colorPrimary: classes.badgePrimary,
                    colorSecondary: classes.badgeSecondary,
                    badge: classes.badge,
                }}
            >
                {item}
            </Badge>
        );
    };

    const item = () => {
        return (
            <Box className={"sidebar-item"} onClick={onItemClick}>
                <ItemIcon itemIcon={icon} />
                <span className={"sidebar-item-text"}>
                    {counter ? itemWithCounter(counter, text) : text}
                </span>
                <CaretIcon />
            </Box>
        );
    };

    const navItem = () => {
        return (
            <NavLink
                to={navTo}
                replace={true}
                exact={true}
                activeClassName="sidebar-item-selected"
            >
                <Box className={"sidebar-item"} onClick={onItemClick}>
                    <FontAwesomeIcon className={iconClasses} icon={icon} />
                    <span className={"sidebar-item-text"}>
                        {counter ? itemWithCounter(counter, text) : text}
                    </span>
                    <CaretIcon />
                </Box>
            </NavLink>
        );
    };

    return (
        <div className={"sidebar-item-container"}>
            {navTo ? navItem() : item()}
            {subItem()}
        </div>
    );
}
