/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useReducer, createContext, useEffect, useContext } from "react";

import { Promise } from "bluebird";

import { AuthContext } from "./auth.context";
import { projectFilterType } from "../constants/project-filter-types";
import ProjectStatus from "../constants/project.status";
import { PhaseFactory, ProjectFactory } from "../factories";
import sortBy from "../lib/sort-by";
import {
    getCollectionData,
    onDocumentSnapshot,
    onCollectionSnapshot,
    onCollectionGroupSnapshot,
    where,
} from "../services/data-service";

const initialProject = ProjectFactory();
const initialPhase = PhaseFactory();

const initialCounter = {
    inProgress: 0,
    inHistory: 0,
    withPendency: 0,
    inProvisioning: 0,
    inUserGroup: 0,
    isReponsible: 0,
    notStarted: 0,
    completed: 0,
};

const initialState = {
    projects: [],
    phases: [],
    infos: [],
    events: [],
    projectId: undefined,
    executionId: undefined,
    phaseId: undefined,
    project: initialProject,
    phase: initialPhase,
    isLoadingProject: true,
    isLoadingProjects: true,
    isLoadingPhase: true,
    isLoadingPhases: true,
    isLoadingEvents: true,
    isLoadingInfos: true,
    counter: initialCounter,
    notFound: false,
};

const DataContext = createContext({
    projects: [],
    phases: [],
    infos: [],
    events: [],
    projectId: undefined,
    executionId: undefined,
    phaseId: undefined,
    project: initialProject,
    phase: initialPhase,
    isLoadingProject: true,
    isLoadingProjects: true,
    isLoadingPhase: true,
    isLoadingPhases: true,
    isLoadingEvents: true,
    isLoadingInfos: true,
    counter: initialCounter,
    notFound: false,
    fetchProject: projectId => {},
    unfetchProject: () => {},
    fetchPhase: (projectId, executionId, phaseId) => {},
    unfetchPhase: () => {},
    getFilteredProjects: filterType => {},
    setIsLoadingProject: loading => {},
    setPhaseCompleted: completed => {},
});

function DataReducer(state, action) {
    switch (action.type) {
        case "SET_PROJECT_ID":
            return {
                ...state,
                project: {
                    ...initialProject,
                },
                ...action.payload,
                isLoadingProject: true,
                isLoadingEvents: true,
                isLoadingInfos: true,
            };
        case "SET_PROJECT":
            return {
                ...state,
                project: {
                    ...initialProject,
                    ...action.payload,
                },
                lastProjectId: undefined,
                isLoadingProject: false,
            };
        case "UPDATE_PROJECT":
            return {
                ...state,
                project: {
                    ...initialProject,
                    ...state.project,
                    ...action.payload,
                },
            };
        case "SET_TASK_ID":
            return {
                ...state,
                phase: {
                    ...initialPhase,
                },
                ...action.payload,
                isLoadingPhase: true,
                isLoadingEvents: true,
                isLoadingInfos: true,
                notFound: false,
            };
        case "SET_TASK":
            return {
                ...state,
                phase: {
                    ...initialPhase,
                    ...action.payload,
                },
                isLoadingPhase: false,
            };
        case "UPDATE_TASK":
            return {
                ...state,
                phase: {
                    ...initialPhase,
                    ...state.phase,
                    ...action.payload,
                },
            };
        case "SET_TASKS":
            return {
                ...state,
                phases: action.payload.map(payload => ({
                    ...initialPhase,
                    ...payload,
                })),
                isLoadingPhases: false,
            };
        case "SET_EVENTS":
            return {
                ...state,
                events: [...action.payload],
                isLoadingEvents: false,
            };
        case "SET_INFOS":
            return {
                ...state,
                infos: [...action.payload],
                isLoadingInfos: false,
            };
        case "SET_PROJECTS":
            return {
                ...state,
                projects: action.payload.map(payload => ({
                    ...initialProject,
                    ...payload,
                })),
                isLoadingProjects: false,
            };
        case "UPDATE_COUNTER":
            return {
                ...state,
                counter: action.payload,
            };
        case "NOT_FOUND":
            return {
                ...state,
                notFound: action.payload,
            };
        default:
            return state;
    }
}

function DataProvider(props) {
    const [state, dispatch] = useReducer(DataReducer, initialState);

    const {
        user: currentUser,
        isAuthenticated,
        users,
        isLoadingUsers,
    } = useContext(AuthContext);

    // fetch data
    useEffect(() => {
        try {
            if (currentUser && isAuthenticated)
                onCollectionSnapshot({
                    path: "projects",
                    callback: snapshot => {
                        if (snapshot.empty) {
                            dispatch({
                                type: "SET_PROJECTS",
                                payload: [],
                            });
                            dispatch({
                                type: "SET_PROJECT",
                                payload: initialProject,
                            });
                        } else {
                            const projects = snapshot.docs.map(doc =>
                                doc.data(),
                            );
                            dispatch({
                                type: "SET_PROJECTS",
                                payload: projects,
                            });
                            fetchProject(state.projectId);
                        }
                    },
                });
        } catch (error) {
            console.error("Error loading projects", error);
        }
        try {
            if (currentUser && isAuthenticated)
                onCollectionGroupSnapshot(
                    "phases",
                    [
                        where("active", "==", true),
                        where("assigneeId", "==", currentUser.id),
                    ],
                    snapshot => {
                        if (snapshot.empty) {
                            dispatch({
                                type: "SET_TASKS",
                                payload: [],
                            });
                        } else {
                            const phases = snapshot.docs.map(doc =>
                                PhaseFactory({ ...doc.data() }),
                            );

                            dispatch({
                                type: "SET_TASKS",
                                payload: phases,
                            });
                        }
                    },
                );
        } catch (error) {
            console.error("Error loading phases", error);
        }
    }, [currentUser, isAuthenticated]);

    // update counter
    useEffect(() => {
        const counter = initialCounter;
        const { projects, phases } = state;

        if (projects && currentUser) {
            counter.inProgress = getFilteredProjects(
                projectFilterType.ALL,
            ).length;
            counter.inHistory = getFilteredProjects(
                projectFilterType.HISTORY,
            ).length;
            counter.inProvisioning = getFilteredProjects(
                projectFilterType.PROVISIONING,
            ).length;
            counter.isDrafter = getFilteredProjects(
                projectFilterType.DRAFTER,
            ).length;
            counter.isSurveyor = getFilteredProjects(
                projectFilterType.SURVEYOR,
            ).length;
        }

        if (phases) {
            counter.isReponsible = phases.length;
        }

        dispatch({
            type: "UPDATE_COUNTER",
            payload: counter,
        });
    }, [state.projects, currentUser, state.phases]);

    //update current execution
    useEffect(() => {
        const project = state.project;
        // Load current execution
        project.execution = project.executions.find(
            e => e.id === project.executionId,
        );
        dispatch({
            type: "UPDATE_PROJECT",
            payload: {
                execution: project.execution,
            },
        });
    }, [state.project.executionId, state.project.executions]);

    //load project data
    useEffect(() => {
        if (
            state.projectId ||
            (state.projectId && state.projectId === state.lastProjectId)
        ) {
            loadProjectData();
        }
    }, [state.projectId, state.lastProjectId]);

    //load phase data
    useEffect(() => {
        if (state.phaseId && !state.isLoadingProjects) {
            loadPhaseData();
        }
    }, [state.phaseId, state.isLoadingProjects]);

    // fetch project
    function fetchProject(projectId) {
        dispatch({
            type: "SET_PROJECT_ID",
            payload: { projectId },
        });
    }

    function unfetchProject() {
        dispatch({
            type: "SET_PROJECT_ID",
            payload: { projectId: undefined, lastProjectId: state.projectId },
        });
    }

    function unfetchPhase() {
        unfetchProject();
        dispatch({
            type: "SET_TASK_ID",
            payload: {
                projectId: undefined,
                executionId: undefined,
                phaseId: undefined,
            },
        });
    }

    // fetch phase
    function fetchPhase(projectId, executionId, phaseId) {
        dispatch({
            type: "SET_TASK_ID",
            payload: {
                projectId,
                executionId,
                phaseId,
            },
        });
    }

    async function loadProjectData() {
        try {
            onDocumentSnapshot(
                `projects/${state.projectId}`,
                async snapshot => {
                    if (snapshot.exists) {
                        const project = snapshot.data();

                        // Load assigneeId
                        project.assigneeId =
                            project.assignee && project.assignee.id;

                        // Load surveyorId
                        project.surveyorId =
                            project.surveyor && project.surveyor.id;

                        // Load drafterId
                        project.drafterId =
                            project.drafter && project.drafter.id;

                        if (project.status) {
                            // load executions
                            project.executions = await loadExecutions(
                                project.id,
                            );

                            // set current execution
                            project.execution = project.executions
                                ? project.executions.find(
                                      e => e.id === project.executionId,
                                  )
                                : {};

                            // load checklist
                            project.checklists = loadChecklists(project);

                            project.loading = false;

                            fetchInfosAndEvents(state.projectId);

                            dispatch({
                                type: "SET_PROJECT",
                                payload: project,
                            });
                            dispatch({
                                type: "NOT_FOUND",
                                payload: false,
                            });
                        }
                    } else {
                        dispatch({
                            type: "SET_PROJECT",
                            payload: initialProject,
                        });
                        dispatch({
                            type: "NOT_FOUND",
                            payload: true,
                        });
                    }
                },
            );
        } catch (error) {
            console.error("Error loading project", error);
        }
    }

    function loadPhaseData() {
        try {
            onDocumentSnapshot(
                `projects/${state.projectId}/executions/${state.executionId}/phases/${state.phaseId}`,
                async snapshot => {
                    if (snapshot.exists) {
                        const phase = PhaseFactory({
                            ...snapshot.data(),
                        });
                        phase.activities = await loadActivities(state.phaseId);
                        phase.project = state.projects.find(
                            p => p.id === phase.projectId,
                        );

                        fetchInfosAndEvents(state.projectId);

                        dispatch({
                            type: "SET_TASK",
                            payload: phase,
                        });
                        dispatch({
                            type: "SET_PROJECT",
                            payload: phase.project,
                        });
                        dispatch({
                            type: "NOT_FOUND",
                            payload: false,
                        });
                    } else {
                        dispatch({
                            type: "NOT_FOUND",
                            payload: true,
                        });
                    }
                },
            );
        } catch (error) {
            console.error("Error loading phase", error);
        }
    }

    async function loadActivities() {
        try {
            return getCollectionData(
                `projects/${state.projectId}/executions/${state.executionId}/phases/${state.phaseId}/activities`,
            );
        } catch (error) {
            console.error("Error loading activities", error);
        }
    }

    function setPhaseCompleted(completed) {
        dispatch({
            type: "UPDATE_PROJECT",
            payload: {
                completed,
            },
        });
    }

    function loadChecklists(project) {
        const checklists = project.executions
            ? project.executions
                  .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                  .map(execution => {
                      const phases = execution.phases || [];
                      return {
                          execution,
                          phases: phases
                              .sort(sortBy("order", false, a => a))
                              .map(phase => {
                                  phase.tasks = phase.activities.sort(
                                      sortBy("order", false, a => a),
                                  );
                                  return phase;
                              }),
                      };
                  })
            : [];
        return checklists;
    }

    function loadEvents(projectId) {
        try {
            onCollectionSnapshot({
                path: `projects/${projectId}/events`,
                callback: snapshot => {
                    if (!snapshot.empty) {
                        const events = snapshot.docs
                            .map(doc => doc.data())
                            .sort(sortBy("createdAt"));
                        dispatch({
                            type: "SET_EVENTS",
                            payload: events,
                        });
                    } else {
                        dispatch({
                            type: "SET_EVENTS",
                            payload: [],
                        });
                    }
                },
            });
        } catch (error) {
            console.error("Error loading events", error);
        }
    }

    function loadInfos(projectId) {
        try {
            onCollectionSnapshot({
                path: `projects/${projectId}/infos`,
                callback: snapshot => {
                    if (!snapshot.empty) {
                        const infos = snapshot.docs
                            .map(doc => doc.data())
                            .sort(sortBy("createdAt"));
                        dispatch({
                            type: "SET_INFOS",
                            payload: infos,
                        });
                    } else {
                        dispatch({
                            type: "SET_INFOS",
                            payload: [],
                        });
                    }
                },
            });
        } catch (error) {
            console.error("Error loading infos", error);
        }
    }

    async function loadExecutions(projectId) {
        try {
            const executions = [];
            await Promise.map(
                getCollectionData(`projects/${projectId}/executions`),
                async execution => {
                    execution.phases = await loadExecutionPhases(
                        projectId,
                        execution.id,
                    );
                    executions.push(execution);
                },
            );
            return executions;
        } catch (error) {
            console.error("Error loading executions", error);
        }
    }

    async function loadExecutionPhases(projectId, executionId) {
        try {
            const phases = [];
            await Promise.map(
                getCollectionData(
                    `projects/${projectId}/executions/${executionId}/phases`,
                ),
                async phase => {
                    phase.activities = await loadExecutionPhaseActivities(
                        projectId,
                        executionId,
                        phase.id,
                    );
                    phases.push(PhaseFactory({ ...phase }));
                },
            );
            return phases;
        } catch (error) {
            console.error("Error loading executions phases", error);
        }
    }

    async function loadExecutionPhaseActivities(
        projectId,
        executionId,
        phaseId,
    ) {
        try {
            return getCollectionData(
                `projects/${projectId}/executions/${executionId}/phases/${phaseId}/activities`,
            );
        } catch (error) {
            console.error("Error loading executions phases activities", error);
        }
    }

    function getFilteredProjects(filterType) {
        const projects = state.projects || [];
        switch (filterType) {
            case projectFilterType.PROVISIONING:
                return projects.filter(project => project.provisioned);
            case projectFilterType.HISTORY:
                return projects.filter(project =>
                    [ProjectStatus.COMPLETED, ProjectStatus.CANCELLED].includes(
                        project.status,
                    ),
                );
            case projectFilterType.DRAFTER:
                return projects
                    .filter(
                        project =>
                            ![
                                ProjectStatus.COMPLETED,
                                ProjectStatus.CANCELLED,
                            ].includes(project.status),
                    )
                    .filter(project => project.drafter != null)
                    .filter(project => project.drafter.id === currentUser.id);
            case projectFilterType.SURVEYOR:
                return projects
                    .filter(
                        project =>
                            ![
                                ProjectStatus.COMPLETED,
                                ProjectStatus.CANCELLED,
                            ].includes(project.status),
                    )
                    .filter(project => project.surveyor != null)
                    .filter(project => project.surveyor.id === currentUser.id);
            default:
                return projects.filter(
                    project =>
                        ![
                            ProjectStatus.COMPLETED,
                            ProjectStatus.CANCELLED,
                        ].includes(project.status),
                );
        }
    }

    function setIsLoadingProject(loading) {
        dispatch({
            type: "UPDATE_PROJECT",
            payload: { loading },
        });
    }

    function fetchInfosAndEvents(projectId) {
        loadEvents(projectId);
        loadInfos(projectId);
    }

    return (
        <DataContext.Provider
            value={{
                projectId: state.projectId,
                project: state.project,
                projects: state.projects,
                events: state.events,
                infos: state.infos,
                isLoadingProject: state.isLoadingProject,
                isLoadingProjects: state.isLoadingProjects,
                isLoadingEvents: state.isLoadingEvents,
                isLoadingInfos: state.isLoadingInfos,
                isLoadingPhases: state.isLoadingPhases,
                isLoadingPhase: state.isLoadingPhase,
                isLoadingUsers: isLoadingUsers,
                notFound: state.notFound,
                counter: state.counter,
                phaseId: state.phaseId,
                phase: state.phase,
                phases: state.phases,
                users: users,
                fetchPhase,
                unfetchPhase,
                getFilteredProjects,
                fetchProject,
                fetchInfosAndEvents,
                unfetchProject,
                setPhaseCompleted,
                setIsLoadingProject,
            }}
            {...props}
        />
    );
}

export { DataContext, DataProvider };
