export const SAMPLE_MATRIX = ["ASB", "SL"];
export const SAMPLE_LAB = ["Acqualab", "Econsulting", "Promatec", "Outros"];
export const SAMPLE_DEPTH = ["VOC", "Superf."];
export const SAMPLE_VALIDITY = ["24h", "48h", "7 dias", "15 dias"];
export const SAMPLE_PARAMS = [
    "BTEX",
    "Chumbo Tetraetila",
    "Chumbo Total",
    "DBO",
    "DQO",
    "Estireno",
    "Etanol ",
    "Metais (Dissolvidos)",
    "Metais (Totais)",
    "Nitrato",
    "Nitrogênio Amoniacal",
    "Óleos e Graxas",
    "Óleos Minerais",
    "Óleos vegetais e gorduras animais",
    "PAH",
    "PCBs (Bifenilas Policloradas)",
    "Sólidos sedimentáveis",
    "Sólidos suspensos totais",
    "Surfactantes (substâncias tensoativas)",
    "SVOC (Varredura)",
    "SVOC (Target)",
    "VOC (Varredura)",
    "VOC (Target)",
    "TPH (Total)",
    "TPH (Finger print)",
    "TPH (Faixas)",
    "TPH (Fracionados)",
    "Geotecnia",
    "Pesticidas Organoclorados",
    "Pesticidas Organofosforados",
    "ABNT NBR",
];
export const SAMPLING_TYPES = [
    { id: "BV", name: "Baixa vazão" },
    { id: "AD", name: "Amostrador descartável" },
    { id: "OT", name: "Outros" },
];
export const CLIMATIC_CONDITIONS = [
    { id: "RAIN_24", name: "Chuva nas últimas 24h" },
    { id: "SUN", name: "Sol" },
    { id: "CLOUDY", name: "Nublado" },
];
export const WELL_DIAMETER = [
    { id: "2", name: '2"' },
    { id: "4", name: '4"' },
];
