import {
    loadEnvBool,
    loadEnvFloat,
    loadEnvInt,
    loadEnvStr,
} from "../lib/load-env";
import Singleton from "../lib/singleton";

class Config extends Singleton {
    // static REACT_APP_VERSION = "";
    // static REACT_APP_DISABLE_BACKEND = true;
    // static REACT_APP_BACKEND_URL =
    //     "https://diatech-json-server-dev.herokuapp.com";
    // static REACT_APP_CACHE_INTERVAL = 60 * 1000;
    // static REACT_APP_READ_ONLY = false;

    constructor() {
        super();
        this.loadEnvironment();
    }

    loadEnvIntWithDefault(key) {
        return loadEnvInt(key, Config[key]);
    }

    loadEnvFloatWithDefault(key) {
        return loadEnvFloat(key, Config[key]);
    }

    loadEnvStrWithDefault(key) {
        return loadEnvStr(key, Config[key]);
    }

    loadEnvBoolWithDefault(key) {
        return loadEnvBool(key, Config[key]);
    }

    loadEnvironment() {
        this.disableBackend = this.loadEnvBoolWithDefault(
            "REACT_APP_DISABLE_BACKEND"
        );
        this.appVersion = this.loadEnvStrWithDefault("REACT_APP_VERSION");
        this.backendUrl = this.loadEnvStrWithDefault("REACT_APP_BACKEND_URL");
        this.cacheInterval = this.loadEnvStrWithDefault(
            "REACT_APP_CACHE_INTERVAL"
        );
        this.readOnly = this.loadEnvBoolWithDefault("REACT_APP_READ_ONLY");
    }
}

export default new Config();
