import React, { useContext, useEffect, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import {
    TextField,
    Button,
    CircularProgress,
    FormHelperText,
    SnackbarContent,
    Icon,
    IconButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import { getAuth, updatePassword } from "firebase/auth";

import { AuthContext } from "../../../contexts";
import { getErrorMessage } from "../../../lib/firebase-messages-utils";
import userIcon from "../user.png";

const useStyles = makeStyles(theme => ({
    profile: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
    },
    title: {
        flex: 1,
        padding: "35px 75px 35px 75px",
    },
    titleText: {
        fontFamily: "Roboto",
        fontSize: "26px",
        fontWeight: 500,
        fontStyle: "normal",
        lineHeight: 1.15,
        color: "color: var(--black);",
        letterSpacing: "normal",
    },
    userProfile: {
        width: 500,
        margin: "1em",
    },
    profileForm: {
        display: "flex",
        flexDirection: "column",
    },
    profileImage: {
        flex: 1,
        display: "flex",
        justifyContent: "center",
    },
    infos: {
        marginTop: "1em",
        flex: 1,
        display: "flex",
        flexDirection: "column",
    },
    info: {
        margintop: "0.5em",
        display: "flex",
        justifyContent: "center",
    },
    passwordForm: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        marginTop: "2em",
    },
    submitButton: {
        marginTop: "1em",
        color: "#FFF",
        backgroundColor: "var(--dark-seafoam-green)",
        "&:hover": {
            backgroundColor: "var(--green)",
            borderColor: "#385cac",
        },
    },
    loadingIndicator: {
        color: "green",
        marginLeft: 10,
    },
    message: {
        marginTop: "1em",
    },
    messageSuccess: {
        backgroundColor: "var(--dark-seafoam-green)",
    },
    messageError: {
        backgroundColor: theme.palette.error.dark,
    },
}));

export default function UserProfile() {
    const classes = useStyles();
    const [user, setUser] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [passwordError, setPasswordError] = useState(false);
    const [message, setMessage] = useState([]);
    const { currentUser } = useContext(AuthContext);

    useEffect(() => {
        setUser({});
        setIsLoading(false);
    }, []);

    const onInputChange = key => event => {
        if (key === "password") {
            setPasswordError(false);
        }
        setUser({
            ...user,
            [key]: event.target.value,
        });
    };

    const checkPasswords = (password, confirmPassword) => {
        return password === confirmPassword;
    };

    const handleSubmit = async event => {
        event.preventDefault();
        if (!checkPasswords(user.password, user.confirmPassword)) {
            setPasswordError(true);
            return;
        }
        setPasswordError(false);
        setIsLoading(true);

        delete user.confirmPassword;

        updatePassword(getAuth().currentUser, user.password)
            .then(function () {
                setMessage([
                    {
                        type: "success",
                        message: "Senha atualizada com successo",
                    },
                ]);
                setUser({
                    ...user,
                    password: undefined,
                    confirmPassword: undefined,
                });
                setIsLoading(false);
            })
            .catch(function (error) {
                let message =
                    "Não foi possivel alterar sua senha, tente novamente";

                if (error && error.code) {
                    message = getErrorMessage(error);
                }

                setMessage([
                    {
                        type: "error",
                        message,
                    },
                ]);
                console.error("Error updating user password", error);
                setIsLoading(false);
            });
    };

    const onCloseMessage = key => {
        setMessage(succes => {
            return succes.splice(1, key);
        });
    };

    const renderMessages = () => {
        if (!message || !message.length) return "";

        const getClassName = type => {
            if (type === "error") {
                return classes.messageError;
            } else {
                return classes.messageSuccess;
            }
        };

        return (
            <div className={classes.messageContainer}>
                {message.map((message, key) => {
                    return (
                        <SnackbarContent
                            key={key}
                            className={classNames(
                                classes.message,
                                getClassName(message.type),
                            )}
                            aria-describedby="success-message"
                            message={
                                <span
                                    id="success-message"
                                    className={classes.message}
                                >
                                    <Icon
                                        className={classNames(
                                            classes.icon,
                                            classes.iconVariant,
                                        )}
                                    />
                                    {message.message}
                                </span>
                            }
                            action={[
                                <IconButton
                                    key="close"
                                    aria-label="Close"
                                    color="inherit"
                                    className={classes.close}
                                    onClick={() => onCloseMessage(key)}
                                >
                                    <CloseIcon className={classes.icon} />
                                </IconButton>,
                            ]}
                        />
                    );
                })}
            </div>
        );
    };

    const profile = (
        <div className={classes.profile}>
            <div className={classes.title}>
                <h1 className={classes.titleText}>Perfil</h1>
                {renderMessages()}
            </div>
            <div className={classes.userProfile}>
                <div className={classes.profileForm}>
                    <div className={classes.profileImage}>
                        <img src={userIcon} alt={"User profile"} />
                    </div>
                    <div className={classes.infos}>
                        <div className={classes.info}>
                            <TextField
                                margin="normal"
                                variant="standard"
                                label="Nome"
                                fullWidth
                                value={currentUser.name || ""}
                            />
                        </div>
                        <div className={classes.info}>
                            <TextField
                                margin="normal"
                                variant="standard"
                                label="E-mail"
                                fullWidth
                                value={currentUser.email || ""}
                            />
                        </div>
                    </div>
                    <div className={classes.password}>
                        <form
                            className={classes.passwordForm}
                            onSubmit={handleSubmit}
                        >
                            <TextField
                                variant="standard"
                                type="password"
                                label="Nova senha"
                                inputProps={{
                                    autoComplete: "new-password",
                                }}
                                fullWidth
                                margin="normal"
                                value={user.password || ""}
                                onChange={onInputChange("password")}
                                required
                                error={passwordError}
                            />
                            <TextField
                                variant="standard"
                                type="password"
                                label="Cofirmação da senha"
                                inputProps={{
                                    autoComplete: "new-password",
                                }}
                                fullWidth
                                margin="normal"
                                value={user.confirmPassword || ""}
                                onChange={onInputChange("confirmPassword")}
                                required
                                error={passwordError}
                            />
                            <FormHelperText id="component-error-text">
                                {passwordError ? "As senhas não coincidem" : ""}
                            </FormHelperText>
                            <Button
                                className={classes.submitButton}
                                disabled={isLoading}
                                type="submit"
                                variant="contained"
                            >
                                Atualizar
                                {isLoading && (
                                    <CircularProgress
                                        size={18}
                                        thickness={4}
                                        className={classes.loadingIndicator}
                                    />
                                )}
                            </Button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );

    return profile;
}
