import { useState, useEffect, useContext } from "react";

import moment from "moment";

import { projectFilterType } from "../constants/project-filter-types";
import { DataContext } from "../contexts";
import { deadlineStatus } from "../lib/status.utils";

export default function useFetchProjectData(props) {
    const [projects, setProjects] = useState([]);
    const [projectCounter, setProjectCounter] = useState(0);
    const { filterType } = props;
    const {
        projects: data,
        isLoading,
        getFilteredProjects,
    } = useContext(DataContext);

    const getDeadline = project => {
        if (!project.phase) return 0;
        return project.deadlineDays * (project.phase.deadlineFactor / 100) || 0;
    };

    const getPhaseDeadline = project => {
        // Planned duration will be negative whether project deadline is reached,
        // return project deadline in this case.
        const plannedDurationDays = getDeadline(project);
        if (plannedDurationDays < 0) {
            return moment(project.deadlineDate, moment.ISO_8601);
        }

        // return project deadline if no projectPhase
        if (!project.phase)
            return moment(project.deadlineDate, moment.ISO_8601);

        return moment(project.phase.releasedAt, moment.ISO_8601).add(
            plannedDurationDays * 24,
            "hours",
        );
    };

    const isApproaching = project => {
        const deadlineDate = getPhaseDeadline(project);
        const currentDate = moment();
        const duration = moment.duration(deadlineDate.diff(currentDate));
        const remainingDays = duration.asDays();

        if (remainingDays <= getDeadline(project) * 0.2 && remainingDays > 0) {
            return true;
        } else {
            return false;
        }
    };

    const getLabelDeadLine = project => {
        const projectDeadLine = project.deadlineDate;
        const phaseDeadline = getPhaseDeadline(project);
        const deadline =
            filterType === projectFilterType.ALL
                ? projectDeadLine
                : phaseDeadline;
        return deadline;
    };

    const getLabelDeadlineStatus = project => {
        if (filterType === projectFilterType.ALL) {
            return deadlineStatus(project.createdAt, project.deadlineDays);
        } else {
            if (!project.phase) {
                return deadlineStatus(project.createdAt, project.deadlineDays);
            } else {
                const onTime = () =>
                    moment().isBefore(
                        moment(getPhaseDeadline(project), moment.ISO_8601),
                    );

                const approaching = () => {
                    if (onTime()) {
                        return isApproaching(project);
                    } else {
                        return false;
                    }
                };

                if (onTime() && !approaching()) return "onTime";
                if (onTime() && approaching()) return "approaching";
                if (!onTime() && !approaching()) return "overdue";
            }
        }
    };

    useEffect(() => {
        if (data) {
            let projects = getFilteredProjects(filterType) || [];
            projects = projects.map(project => {
                project.deadlineLabelStatus = getLabelDeadlineStatus(project);
                project.deadlineLabelDate = getLabelDeadLine(project);
                return project;
            });

            if (filterType === projectFilterType.ALL) {
                projects.sort((p1, p2) =>
                    p1.deadlineDate > p2.deadlineDate ? 1 : -1,
                );
            } else {
                projects.sort((p1, p2) =>
                    getPhaseDeadline(p1) > getPhaseDeadline(p2) ? 1 : -1,
                );
            }
            setProjects(projects);
            setProjectCounter(projects.length);
        }
    }, [data]);

    return {
        isLoading,
        projects,
        projectCounter,
    };
}
