import React from "react";

import classNames from "classnames";

import ProjectStatus from "../../../constants/project.status";
import { pendencyName } from "../../../lib/pendency-utils";
import { statusName, statusLabelStyle } from "../../../lib/status.utils";

export default function ProjectStatusLabel({
    status,
    pendencyOnly = false,
    pendencyType = "",
    size = 9,
}) {
    if (pendencyOnly && status !== ProjectStatus.PENDENCY) return;
    return (
        <span
            style={{ fontSize: size }}
            className={classNames("label", statusLabelStyle(status))}
        >
            {status === ProjectStatus.PENDENCY
                ? pendencyName(pendencyType)
                : statusName(status)}
        </span>
    );
}
