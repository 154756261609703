import React from "react";

import "./TaskSidebar.css";

import ProjectInfos from "../../../projects/project-data/project-sidebar/project-infos";
import TaskDeadline from "./task-deadline";
import TaskDetails from "./task-details";

export default function TaskSidebar(props) {
    return (
        <div className="task-sidebar">
            <div className="task-sidebar-container">
                <div className="task-sidebar-details">
                    <TaskDetails />
                </div>
                <div className="task-sidebar-deadline">
                    <TaskDeadline />
                </div>
                <div className="project-sidebar-infos">
                    <ProjectInfos />
                </div>
            </div>
        </div>
    );
}
