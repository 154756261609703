import React from "react";

import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

const styles = {
    root: {
        display: "flex",
        width: "100%",
        marginTop: "0px",
        boxSizing: "border-box",
    },
    container: {
        padding: "32px 48px",
        minWidth: "0px",
        minHeight: "100%",
        flex: "1 1 0px",
    },
    header: {
        display: "flex",
        flexDirection: "row",
        // justifyContent: "flex-end",
        flexWrap: "wrap",
        alignItems: "center",
    },
    headerTitle: {
        flexGrow: 12,
        fontFamily: "Roboto",
        fontSize: "26px",
        fontWeight: "500",
        fontStyle: "normal",
        fontStretch: "normal",
        lineHeight: "1.15",
        letterSpacing: "normal",
        textAlign: "left",
        color: "var(--black)",
        paddingTop: "8px",
        paddingBottom: "8px",
    },
    headerActions: {
        display: "flex",
        // justifyContent: "flex-end",
        flexWrap: "wrap",
        // flexGrow: "1",
        paddingTop: "8px",
        paddingBottom: "8px",
    },
    actions: {
        display: "flex",
        justifyContent: "flex-end",
        WebkitAlignItems: "center",
        alignItems: "center",
        minHeight: "60px",
    },
    content: {},
    footer: {
        paddingTop: "35px",
        paddingBottom: "35px",
    },
    sideBar: {
        display: "flex",
        minHeight: "100vh",
        WebkitBoxPack: "end",
        justifyContent: "flex-end",
        position: "sticky",
        top: "0px",
        zIndex: "500",
        maxWidth: "380px",
        backgroundColor: "var(--white-two)",
    },
};
const useStyles = makeStyles(theme => styles);

export default function DetailView({
    headerTitle: HeaderTitle,
    headerActions: HeaderActions,
    subHeader1: SubHeader1,
    subHeader2: SubHeader2,
    actions: Actions,
    content: Content,
    sideBar: SideBar,
    footer: Footer,
    ...props
}) {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Box sx={{ ...styles.container, ...props.sx }}>
                {(HeaderTitle || HeaderActions) && (
                    <Box className={classes.header}>
                        {HeaderTitle && (
                            <Box className={classes.headerTitle}>
                                {HeaderTitle && render(HeaderTitle)}
                            </Box>
                        )}
                        {HeaderActions && (
                            <Box className={classes.headerActions}>
                                {HeaderActions && render(HeaderActions)}
                            </Box>
                        )}
                    </Box>
                )}
                {SubHeader1 && (
                    <Box component="h5" className={classes.subHeader1}>
                        {render(SubHeader1)}
                    </Box>
                )}
                {SubHeader2 && (
                    <Box component="h3" className={classes.subHeader2}>
                        {render(SubHeader2)}
                    </Box>
                )}
                {(HeaderTitle || HeaderActions || SubHeader1 || SubHeader2) && (
                    <hr className="line" />
                )}
                {Actions && (
                    <Box className={classes.actions}>{render(Actions)}</Box>
                )}
                {Content && (
                    <Box className={classes.content}>{render(Content)}</Box>
                )}
                {Footer && (
                    <Box className={classes.footer}>{render(Footer)}</Box>
                )}
            </Box>
            {SideBar && (
                <Box className={classes.sideBar}>{render(SideBar)}</Box>
            )}
        </Box>
    );
}

const render = elementOrCompoment => {
    return typeof elementOrCompoment === "function"
        ? elementOrCompoment()
        : elementOrCompoment;
};
