import React from "react";
import { makeStyles } from '@mui/styles';
import { FormControlLabel, Checkbox } from "@mui/material";

const useStyles = makeStyles(theme => ({
    raizenCheckboxArea: {
        flex: 1,
        marginTop: "0.5em",
    },
    raizenCheckboxLabel: {
        paddingTop: "1px",
    },
}));
const RaizenCheckbox = ({ customer, handleRaizenCheck }) => {
    const classes = useStyles();

    const checkbox = (
        <div className={classes.raizenCheckboxArea}>
            <FormControlLabel
                control={
                    <Checkbox
                        value="secondary"
                        color="primary"
                        checked={customer.raizenDeadlines || false}
                        onChange={handleRaizenCheck}
                    />
                }
                label={
                    <h5 className={classes.raizenCheckboxLabel}>
                        Prazos Raízen
                    </h5>
                }
            />
        </div>
    );
    return checkbox;
};

export default RaizenCheckbox;
