import React, { useState, useContext, useEffect } from "react";

import { Link } from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import Skeleton from "react-loading-skeleton";

import "./ProjectDetails.css";

import ProjectStatus from "../../../../../constants/project.status";
import { DataContext } from "../../../../../contexts";
import { deadlineInfo } from "../../../../../lib/status.utils";
import ProjectDeliveryProtocolDialog from "../../project-delivery-protocol-dialog";
import ProjectDrafterSelectorDialog from "../../project-drafter-selector-dialog";
import ProjectSurveyorSelectorDialog from "../../project-surveyor-selector-dialog";

const useStyles = makeStyles(theme => ({
    deliveryProtocol: {
        color: "#004b8d",
        "&:hover": {
            color: "#00315c",
        },
    },
}));

export default function ProjectDetails(props) {
    const classes = useStyles();
    const [surveyorDialogOpen, setSurveyorDialogOpen] = useState(false);
    const [drafterDialogOpen, setDrafterDialogOpen] = useState(false);
    const [
        isProjectDeliveryProtocolDialogOpen,
        setIsProjectDeliveryProtocolDialogOpen,
    ] = useState(false);

    const {
        project,
        isLoadingProject,
        isLoadingUsers,
        isLoadingInfos,
        isLoadingEvents,
    } = useContext(DataContext);

    const isLoading =
        isLoadingUsers || isLoadingProject || isLoadingInfos || isLoadingEvents;

    useEffect(() => {
        if (!isLoading) {
            if (project) {
                setIsProjectDeliveryProtocolDialogOpen(
                    project.status === ProjectStatus.COMPLETED &&
                        project.customer.raizenDeadlines
                        ? !project.deliveryProtocolDate
                        : !project.deliveryProtocol,
                );
            }
        }
    }, [isLoading, project]);

    const { elapsedDays } = deadlineInfo(
        project.createdAt,
        project.deadlineDays,
    );

    const updateDeliveryProtocol = () => {
        setIsProjectDeliveryProtocolDialogOpen(true);
    };

    const onCloseProjectDeliveryProtocolDialog = () => {
        setIsProjectDeliveryProtocolDialogOpen(false);
    };

    return (
        <div className="project-details">
            <div className="details-title">DETALHE DO PROJETO</div>
            <table className="details-items">
                <tbody>
                    <tr>
                        <td className="details-item">Data de criação</td>
                        <td className="details-value">
                            {project && !isLoading ? (
                                moment(
                                    project.createdAt,
                                    moment.ISO_8601,
                                ).format("LL")
                            ) : (
                                <Skeleton width={200} />
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td className="details-item">Prazo de entrega</td>
                        <td className="details-value">
                            {project && !isLoading ? (
                                moment(project.deadlineDate).format("LL")
                            ) : (
                                <Skeleton />
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td className="details-item">Prazo definido</td>
                        <td className="details-value">
                            {project && !isLoading ? (
                                project.deadlineDays + " dias"
                            ) : (
                                <Skeleton />
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td className="details-item">Tempo decorrido</td>
                        <td className="details-value">
                            {project && !isLoading ? (
                                `${Math.round(elapsedDays)} 
                               ${
                                   Math.round(elapsedDays) > 1
                                       ? "  dias"
                                       : " dia"
                               }`
                            ) : (
                                <Skeleton width={200} />
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td className="details-item">Tipo do estudo</td>
                        <td className="details-value">
                            {project && !isLoading ? (
                                project.study && project.study.name
                            ) : (
                                <Skeleton />
                            )}
                        </td>
                    </tr>
                    {project && project.campaign && (
                        <tr>
                            <td className="details-item">Campanha</td>

                            <td className="details-value">
                                {project && !isLoading ? (
                                    project.campaign
                                ) : (
                                    <Skeleton />
                                )}
                            </td>
                        </tr>
                    )}
                    <tr>
                        <td className="details-item">Relatorista</td>
                        <td className="details-value">
                            {project && !isLoading ? (
                                project.surveyor && project.surveyor.name ? (
                                    <Link
                                        component="button"
                                        variant="body2"
                                        onClick={() => {
                                            setSurveyorDialogOpen(true);
                                        }}
                                    >
                                        {project.surveyor.name}
                                    </Link>
                                ) : (
                                    <Link
                                        component="button"
                                        variant="body2"
                                        onClick={() => {
                                            setSurveyorDialogOpen(true);
                                        }}
                                    >
                                        Atribuir relatorista
                                    </Link>
                                )
                            ) : (
                                <Skeleton />
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td className="details-item">Desenhista</td>
                        <td className="details-value">
                            {project && !isLoading ? (
                                project.drafter && project.drafter.name ? (
                                    <Link
                                        component="button"
                                        variant="body2"
                                        onClick={() => {
                                            setDrafterDialogOpen(true);
                                        }}
                                    >
                                        {project.drafter.name}
                                    </Link>
                                ) : (
                                    <Link
                                        component="button"
                                        variant="body2"
                                        onClick={() => {
                                            setDrafterDialogOpen(true);
                                        }}
                                    >
                                        Atribuir desenhista
                                    </Link>
                                )
                            ) : (
                                <Skeleton />
                            )}
                        </td>
                    </tr>
                    {project &&
                        !isLoading &&
                        project.status === "Completed" && (
                            <tr>
                                <td className="details-item">
                                    {project.customer.raizenDeadlines
                                        ? "Upload Raízen"
                                        : "Protocolo de entrega"}
                                </td>
                                <td className="details-value">
                                    <Link
                                        onClick={updateDeliveryProtocol}
                                        className={classes.deliveryProtocol}
                                    >
                                        {project.deliveryProtocol
                                            ? project.deliveryProtocolDate
                                                ? `${
                                                      project.deliveryProtocol
                                                  } | ${moment(
                                                      project.deliveryProtocolDate,
                                                  ).format("LL")}`
                                                : project.deliveryProtocol
                                            : project.deliveryProtocolDate
                                            ? moment(
                                                  project.deliveryProtocolDate,
                                              ).format("LL")
                                            : "Indefinido"}
                                    </Link>
                                </td>
                            </tr>
                        )}
                </tbody>
            </table>
            {project && !isLoading && surveyorDialogOpen && (
                <ProjectSurveyorSelectorDialog
                    projectId={project.id}
                    surveyor={project.surveyor}
                    open={surveyorDialogOpen}
                    onClose={() => {
                        setSurveyorDialogOpen(false);
                    }}
                />
            )}
            {project && !isLoading && drafterDialogOpen && (
                <ProjectDrafterSelectorDialog
                    projectId={project.id}
                    drafter={project.drafter}
                    open={drafterDialogOpen}
                    onClose={() => {
                        setDrafterDialogOpen(false);
                    }}
                />
            )}
            {project.status === ProjectStatus.COMPLETED && (
                <ProjectDeliveryProtocolDialog
                    dialogOpen={isProjectDeliveryProtocolDialogOpen}
                    project={project}
                    onCloseDialog={onCloseProjectDeliveryProtocolDialog}
                    receivedDeliveryProtocol={project.deliveryProtocol}
                    receivedDeliveryProtocolDate={project.deliveryProtocolDate}
                    receivedCustomerType={
                        project.customer.raizenDeadlines ? "raizen" : "default"
                    }
                />
            )}
        </div>
    );
}
