import React, { useState, useEffect } from "react";

import ArrowDropIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { IconButton, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";

import { cnpjFormatter, cnpjParser } from "../../../../../lib/form-utils";
import { getCollectionData } from "../../../../../services/data-service";

const useStyles = makeStyles(() => ({
    data: {
        marginTop: "0.5em",
        flex: 1,
        display: "flex",
        flexDirection: "column",
    },
    dataHeader: {
        flex: 1,
        display: "flex",
    },
    dataTitle: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
    dataContent: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
    },
    row: {
        display: "flex",
    },
    inputField: {
        "& > *": {
            marginLeft: 15,
            marginRight: 5,
            marginBottom: 10,
        },
    },
    companyNameField: {
        width: 500,
        "& > *": {
            marginLeft: 0,
        },
    },
    nameField: {
        width: 500,
        "& > *": {
            marginLeft: 0,
        },
    },
    cnpjField: {
        "& > *": {
            marginLeft: 0,
        },
        width: 200,
    },
    addressField: {
        width: 400,
    },
    numberField: {
        width: 100,
    },
    neighborhoodField: {
        width: 200,
    },
    cityField: {
        width: 184,
    },
    stateField: {
        width: 100,
    },
}));

export default function CustomerInfo({ customer, onInputChange, onCheckCnpj }) {
    const classes = useStyles();
    const [isDisplayed, setIsDisplayed] = useState(true);
    const [checkCnpj, setCheckCnpj] = useState(false);
    const [cnpjExists, setCnpjExists] = useState(false);

    //check if cnpj exists
    useEffect(() => {
        if (checkCnpj) {
            getCollectionData("customers").then(customers => {
                const customerData = customers.find(
                    c => c.cnpj === cnpjParser(customer.cnpj) && !c.inactive,
                );
                if (customerData) {
                    if (onCheckCnpj)
                        onCheckCnpj({ isValidating: false, cnpjInUse: true });
                    setCnpjExists(true);
                } else {
                    if (onCheckCnpj)
                        onCheckCnpj({ isValidating: false, cnpjInUse: false });
                    setCnpjExists(false);
                }
                setCheckCnpj(false);
            });
        }
    }, [checkCnpj, customer.cnpj, onCheckCnpj]);

    const info = (
        <div className={classes.data}>
            <div className={classes.dataHeader}>
                <div className={classes.dataTitle}>
                    <h5>Dados do Empreendimento</h5>
                </div>
                <IconButton onClick={() => setIsDisplayed(!isDisplayed)}>
                    {isDisplayed ? <ArrowDropIcon /> : <ArrowRightIcon />}
                </IconButton>
            </div>
            <div
                className={classes.dataContent}
                style={{ display: isDisplayed ? "" : "none" }}
            >
                <div className={classes.row}>
                    <TextField
                        variant="standard"
                        className={classNames(
                            classes.inputField,
                            classes.cnpjField,
                        )}
                        label="CNPJ"
                        value={cnpjFormatter(customer.cnpj) || ""}
                        onChange={onInputChange("cnpj")}
                        required={true}
                        error={cnpjExists}
                        helperText={cnpjExists && "Cnpj já cadastrado"}
                        disabled={checkCnpj || customer.id !== undefined}
                        onBlur={e => {
                            if (e.target.value) {
                                onCheckCnpj({
                                    isValidating: false,
                                    cnpjInUse: false,
                                });
                                setCheckCnpj(true);
                            }
                        }}
                    />
                </div>
                <div className={classes.row}>
                    <TextField
                        variant="standard"
                        className={classNames(
                            classes.inputField,
                            classes.companyNameField,
                        )}
                        label="Razão Social do Empreendimento"
                        onChange={onInputChange("companyName")}
                        required={true}
                        value={customer.companyName || ""}
                    />
                </div>
                <div className={classes.row}>
                    <TextField
                        variant="standard"
                        className={classNames(
                            classes.inputField,
                            classes.nameField,
                        )}
                        label="Nome Fantasia"
                        onChange={onInputChange("name")}
                        required={true}
                        value={customer.name || ""}
                    />
                </div>
            </div>
        </div>
    );

    return info;
}
