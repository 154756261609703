/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
} from "@mui/material";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import SnackbarContent from "@mui/material/SnackbarContent";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import moment from "moment";
import DualListBox from "react-dual-listbox";

import UserRoles from "../../../constants/user-roles";
import clone from "../../../lib/clone";
import { getErrorMessage } from "../../../lib/firebase-messages-utils";
import sortBy from "../../../lib/sort-by";
import {
    callFunction,
    deleteDocument,
    getDocumentData,
    updateDocument,
} from "../../../services/data-service";
import "./react-dual-listbox.css";
import "./UserForm.css";
import { getUserRoles } from "../../../services";

const useStyles = makeStyles(theme => ({
    inputField: {
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 300,
    },
    passwordField: {
        width: 144,
    },
    loginField: {
        marginTop: theme.spacing(1),
    },
    error: {
        backgroundColor: theme.palette.error.dark,
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    nameField: {},
    submitButton: {
        color: "#FFFFFF",
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(2),
        padding: theme.spacing(1),
        textAlign: "left",
        backgroundColor: "var(--dark-seafoam-green)",
        "&:hover": {
            backgroundColor: "var(--green)",
            borderColor: "#385cac",
        },
    },
    success: {
        marginTop: "1em",
        backgroundColor: "var(--dark-seafoam-green)",
    },
    deleteButton: {
        color: "#FFFFFF",
        backgroundColor: "var(--red)",
        "&:hover": {
            backgroundColor: "#a00000",
            borderColor: "#385cac",
        },
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(2),
        padding: theme.spacing(1),
    },
    buttonIcon: {
        marginLeft: theme.spacing(1),
    },
    formControl: {
        marginTop: theme.spacing(1),
        marginButton: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    projectPermissions: {
        marginTop: theme.spacing(1),
    },
    loadingIndicator: {
        color: "green",
        marginLeft: theme.spacing(2),
    },
}));

const newUser = {
    name: "",
    email: "",
    password: "",
    roles: [],
};

export default function UserForm(props) {
    const { userId, onCreated } = props;
    const classes = useStyles();

    const [refresh, setRefresh] = useState(false);

    const [state, setState] = useState({
        isLoading: false,
        roles: [],
        user: clone(newUser),
        isNew: true,
        pass: "",
        confirmPass: "",
        isDeleteUserDialogOpen: false,
    });

    const fetchData = userId => {
        setState({ ...state, isLoading: true });
        getDocumentData(`users/${userId}`).then(async user => {
            setState({
                ...state,
                user,
                pass: "",
                confirmPass: "",
                isNew: false,
                isLoading: false,
            });
        });
    };

    // fetch data
    useEffect(() => {
        setState({ ...state, isLoading: true });
        if (userId) {
            fetchData(userId);
        } else {
            setState({
                ...state,
                user: clone(newUser),
                isNew: true,
                pass: "",
                confirmPass: "",
                isLoading: false,
            });
        }
    }, [userId]);

    useEffect(() => {
        if (refresh) {
            fetchData(userId);
            setRefresh(false);
        }
    }, [refresh, userId]);

    useEffect(() => {
        setState({
            ...state,
            roles: getUserRoles()
                .map(role => ({
                    value: role.id,
                    label: role.name,
                }))
                .sort(sortBy("label")),
        });
    }, []);

    const validPassword = (pass, confirmPass) => {
        return pass === confirmPass;
    };

    const onCloseError = key => {
        const { errors } = state;
        errors.splice(key, 1);
        setState({ ...state, errors });
    };

    const onCloseSuccess = key => {
        const { success } = state;

        success.splice(key, 1);
        setState({ ...state, created: true });
    };

    const onInputChange = name => event => {
        if (name === "pass") {
            setState({ ...state, pass: event.target.value });
            return;
        }
        if (name === "confirmPass") {
            setState({ ...state, confirmPass: event.target.value });
            return;
        }
        let user = state.user;

        if (name === "email") {
            setState({ ...state, isNew: true });
        }

        user[name] = event.target.value;

        setState({ ...state, user });
    };

    const onUserRolesChange = selected => {
        const { user } = state;
        user.roles = selected;
        setState({ ...state, user });
    };

    const onSubmit = async event => {
        event.preventDefault();

        setState({ ...state, isLoading: true });
        try {
            if (isNew) {
                // create user;
                if (!validPassword(pass, confirmPass)) {
                    setState({
                        ...state,
                        isLoading: false,
                        errors: ["As senhas não coincidem"],
                    });
                } else {
                    callFunction("createUser", {
                        userRequest: user,
                        password: pass,
                    })
                        .then(createdUser => {
                            setState({
                                ...state,
                                user: createdUser,
                                isLoading: false,
                                isNew: false,
                                success: ["Usuário cadastrado corretamente"],
                                pass: "",
                                confirmPass: "",
                            });
                            onCreated(createdUser);
                        })
                        .catch(error => {
                            console.error("Error creating user", error);
                            setState({
                                ...state,
                                isLoading: false,
                                errors: [getErrorMessage(error)],
                            });
                        });
                }
            } else {
                // update new user;
                if (pass.length > 0 || confirmPass.length > 0) {
                    if (!validPassword(pass, confirmPass)) {
                        setState({
                            ...state,
                            isLoading: false,
                            errors: ["As senhas não coincidem"],
                        });
                        return;
                    } else {
                        user.password = pass;
                    }
                }
                updateDocument(`users/${user.id}`, user)
                    .then(async () => {
                        setState({
                            ...state,
                            isLoading: false,
                            success: ["Usuário atualizado com sucesso"],
                        });
                    })
                    .catch(error => {
                        console.error("Error updating user", error);
                        setState({
                            ...state,
                            isLoading: false,
                            errors: [
                                "Não foi possivel atualizar este usuário, tente novamente",
                            ],
                        });
                    });
            }
        } catch (error) {
            console.error("Error submitting user", error);
            setState({
                ...state,
                isLoading: false,
                errors: ["Não foi possivel concluir operação, tente novamente"],
            });
        }
    };

    const handleDeleteDialogClose = () => {
        setState({
            ...state,
            isDeleteUserDialogOpen: false,
        });
    };

    const onOpenDeleteDialog = () => {
        setState({
            ...state,
            isDeleteUserDialogOpen: true,
        });
    };

    const onDelete = async () => {
        const { user, isNew } = state;
        setState({
            ...state,
            isLoading: true,
        });
        if (!isNew) {
            try {
                deleteDocument(`users/${user.id}`)
                    .then(() => {
                        setState({
                            ...state,
                            isLoading: false,
                            success: ["Usuário removido com sucesso"],
                            user: clone(newUser),
                            isNew: true,
                            pass: "",
                            confirmPass: "",
                            isDeleteUserDialogOpen: false,
                        });
                    })
                    .catch(error => {
                        console.error("Error removing user", error);
                        setState({
                            ...state,
                            isLoading: false,
                            isDeleteUserDialogOpen: false,
                            errors: [
                                "Não foi possivel remover este usuário, tente novamente",
                            ],
                        });
                    });
            } catch (error) {
                console.error("Error removing user", error);
                setState({
                    ...state,
                    isLoading: false,
                    isDeleteUserDialogOpen: false,
                    errors: [
                        "Não foi possivel concluir operação, tente novamente",
                    ],
                });
            }
        } else {
            setState({
                ...state,
                user: newUser,
                isLoading: false,
                isDeleteUserDialogOpen: false,
            });
        }
    };

    const renderSuccess = () => {
        const { success } = state;

        if (!success || !success.length) return "";
        return (
            <div className="form-errors">
                {success.map((message, key) => {
                    return (
                        <SnackbarContent
                            key={key}
                            className={classNames(classes.success)}
                            aria-describedby="success-message"
                            message={
                                <span
                                    id="success-message"
                                    className={classes.message}
                                >
                                    <Icon
                                        className={classNames(
                                            classes.icon,
                                            classes.iconVariant,
                                        )}
                                    />
                                    {success}
                                </span>
                            }
                            action={[
                                <IconButton
                                    key="close"
                                    aria-label="Close"
                                    color="inherit"
                                    className={classes.close}
                                    onClick={() => onCloseSuccess(key)}
                                >
                                    <CloseIcon className={classes.icon} />
                                </IconButton>,
                            ]}
                        />
                    );
                })}
            </div>
        );
    };

    const renderErrors = () => {
        const { errors } = state;

        if (!errors || !errors.length) return "";

        return (
            <div className="form-errors">
                {errors.map((error, key) => {
                    return (
                        <SnackbarContent
                            key={key}
                            className={classes.error}
                            aria-describedby="error-message"
                            message={
                                <span
                                    id="error-message"
                                    className={classes.message}
                                >
                                    <Icon
                                        className={classNames(
                                            classes.icon,
                                            classes.iconVariant,
                                        )}
                                    />
                                    {error}
                                </span>
                            }
                            action={[
                                <IconButton
                                    key="close"
                                    aria-label="Close"
                                    color="inherit"
                                    className={classes.close}
                                    onClick={() => onCloseError(key)}
                                >
                                    <CloseIcon className={classes.icon} />
                                </IconButton>,
                            ]}
                        />
                    );
                })}
            </div>
        );
    };

    const {
        isLoading,
        user,
        isNew,
        pass,
        confirmPass,
        roles,
        isDeleteUserDialogOpen,
    } = state;

    return (
        <div className={"user-create-form"}>
            <div className={"header"}>
                <h1 className={"header-title"}>
                    {isNew ? "Cadastrar" : "Editar"} Usuário
                </h1>
                <div className={"header-actions"} />
            </div>
            <div className={"content"}>
                {renderErrors()}
                {renderSuccess()}
                <div className={"user-data"}>
                    <div className={"title"}>
                        <h5>Dados do usuário</h5>
                    </div>

                    <div className={"name-data"}>
                        <TextField
                            variant="standard"
                            className={classNames(
                                classes.inputField,
                                classes.nameField,
                            )}
                            autoComplete="off"
                            id="name"
                            label="Nome"
                            onChange={onInputChange("name")}
                            required={true}
                            value={user.name || ""}
                        />
                    </div>
                    <div className={"email-data"}>
                        <TextField
                            variant="standard"
                            className={classNames(
                                classes.inputField,
                                classes.emailField,
                            )}
                            autocomplete="off"
                            type="email"
                            id="email"
                            label="E-mail"
                            onChange={onInputChange("email")}
                            required={true}
                            value={user.email || ""}
                        />
                    </div>
                    <div className={"password-data"}>
                        <TextField
                            variant="standard"
                            className={classNames(
                                classes.inputField,
                                classes.passwordField,
                            )}
                            autocomplete="off"
                            onChange={onInputChange("pass")}
                            type="password"
                            id="password"
                            label="Senha"
                            required={isNew}
                            value={pass}
                        />
                        <TextField
                            variant="standard"
                            className={classNames(
                                classes.inputField,
                                classes.passwordField,
                            )}
                            autocomplete="off"
                            onChange={onInputChange("confirmPass")}
                            type="password"
                            id="confirm-password"
                            label="Confirme a senha"
                            required={isNew}
                            value={confirmPass}
                        />
                        {!isNew && (
                            <div className={"login-data"}>
                                <TextField
                                    variant="standard"
                                    className={classNames(
                                        classes.inputField,
                                        classes.loginField,
                                    )}
                                    id="lastLoginDate"
                                    label="Data do último login"
                                    readOnly={true}
                                    required={false}
                                    disabled={true}
                                    value={
                                        user.lastLoginDate
                                            ? moment(user.lastLoginDate).format(
                                                  "DD/MM/YYYY HH:mm",
                                              ) || ""
                                            : ""
                                    }
                                />
                            </div>
                        )}
                    </div>
                    <div className={"projects-permissions-data"}>
                        <div className={"title"}>
                            <h5>Papéis do usuário:</h5>
                        </div>
                        <div className={"projects-permissions-content"}>
                            <div className={"duallist-titles"}>
                                <p className={"available-roles-title"}>
                                    Selecionar papéis
                                </p>
                                <p className={"selected-roles-title"}>
                                    Papéis selecionados
                                </p>
                            </div>
                            <DualListBox
                                options={roles}
                                selected={user.roles}
                                onChange={onUserRolesChange}
                                required={true}
                            />
                        </div>
                    </div>
                    <div className={"submit-data"}>
                        <Button
                            className={classNames(
                                classes.submitButton,
                                classes.addUser,
                            )}
                            type="submit"
                            onClick={onSubmit}
                            variant="contained"
                            disabled={isLoading}
                        >
                            {isNew ? "Cadastrar" : "Atualizar"}
                            {isLoading && (
                                <CircularProgress
                                    size={18}
                                    thickness={4}
                                    className={classes.loadingIndicator}
                                />
                            )}
                        </Button>
                        {!isNew && (
                            <Button
                                className={classNames(classes.deleteButton)}
                                type="submit"
                                variant="contained"
                                onClick={onOpenDeleteDialog}
                                disabled={isLoading}
                            >
                                Apagar
                            </Button>
                        )}
                    </div>
                </div>
            </div>
            <Dialog
                open={isDeleteUserDialogOpen}
                onClose={handleDeleteDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Excluir usuário?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Deseja realmente excluir este usuário?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteDialogClose}>Não</Button>
                    <Button onClick={onDelete}>Sim</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
