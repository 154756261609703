import ApprovalIcon from "@mui/icons-material/Approval";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ChecklistIcon from "@mui/icons-material/Checklist";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DoneIcon from "@mui/icons-material/Done";
import PauseIcon from "@mui/icons-material/Pause";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SendIcon from "@mui/icons-material/Send";
import StartIcon from "@mui/icons-material/Start";

import {
    cancelOrder,
    completeOrder,
    completeWorkOnOrder,
    copyToNewOrder,
    putOrderOnHold,
    releaseOrder,
    releaseOrderToLaunch,
    sendOrderToVerification,
    startWorkOnOrder,
} from ".";
import { theme } from "../components/layout";
import OrderActions from "../constants/order-actions";
import UserRoles from "../constants/user-roles";
import OrderActionModel from "../models/order-action-model";

export function getOrderAction(action) {
    switch (action) {
        case OrderActions.RELEASE:
            return new OrderActionModel({
                id: "RELEASE",
                name: "Liberar ordem para Campo",
                icon: (
                    <SendIcon
                        sx={{ color: theme.palette.status.released.main }}
                    />
                ),
                execute: releaseOrder,
                requiredRoles: [UserRoles.RELEASE_ORDER],
            });

        case OrderActions.RELEASE_BACK:
            return new OrderActionModel({
                id: "RELEASE_BACK",
                name: "Retornar ordem para Campo",
                icon: (
                    <RestartAltIcon
                        sx={{ color: theme.palette.status.released.main }}
                    />
                ),
                execute: releaseOrder,
                requiredRoles: [UserRoles.RELEASE_ORDER],
            });

        case OrderActions.CANCEL:
            return new OrderActionModel({
                id: "CANCEL",
                name: "Cancelar Ordem",
                icon: (
                    <CancelIcon
                        sx={{ color: theme.palette.status.cancelled.main }}
                    />
                ),
                execute: cancelOrder,
                requiredRoles: [UserRoles.CANCEL_ORDER],
            });

        case OrderActions.START_WORK:
            return new OrderActionModel({
                id: "START_WORK",
                name: "Iniciar Trabalho",
                icon: (
                    <StartIcon
                        sx={{ color: theme.palette.status.inProgress.main }}
                    />
                ),
                execute: startWorkOnOrder,
                requiredRoles: [UserRoles.START_ORDER_WORK],
            });

        case OrderActions.SUSPEND_WORK:
            return new OrderActionModel({
                id: "SUSPEND_WORK",
                name: "Colocar em Espera",
                icon: (
                    <PauseIcon
                        sx={{ color: theme.palette.status.onHold.main }}
                    />
                ),
                execute: putOrderOnHold,
                requiredRoles: [UserRoles.SUSPEND_ORDER_WORK],
            });

        case OrderActions.COMPLETE_WORK:
            return new OrderActionModel({
                id: "COMPLETE_WORK",
                name: "Concluir Trabalho",
                icon: (
                    <DoneIcon
                        sx={{
                            color: theme.palette.status.workCompleted.main,
                        }}
                    />
                ),
                execute: completeWorkOnOrder,
                requiredRoles: [UserRoles.COMPLETE_ORDER_WORK],
            });

        case OrderActions.SEND_TO_VERIFICATION:
            return new OrderActionModel({
                id: "SEND_TO_VERIFICATION",
                name: "Enviar para Conferência",
                icon: (
                    <ChecklistIcon
                        sx={{
                            color: theme.palette.status.verification.main,
                        }}
                    />
                ),
                execute: sendOrderToVerification,
                requiredRoles: [UserRoles.SEND_ORDER_TO_VERIFICATION],
            });

        case OrderActions.RELEASE_TO_LAUNCH:
            return new OrderActionModel({
                id: "RELEASE_TO_LAUNCH",
                name: "Liberar para Lançamento",
                icon: (
                    <ApprovalIcon
                        sx={{ color: theme.palette.status.launch.main }}
                    />
                ),
                execute: releaseOrderToLaunch,
                requiredRoles: [UserRoles.RELEASE_ORDER_TO_LAUNCH],
            });

        case OrderActions.COMPLETE:
            return new OrderActionModel({
                id: "COMPLETE",
                name: "Finalizar",
                icon: (
                    <CheckCircleIcon
                        sx={{ color: theme.palette.status.completed.main }}
                    />
                ),
                execute: completeOrder,
                requiredRoles: [UserRoles.COMPLETE_ORDER],
            });

        case OrderActions.COPY:
            return new OrderActionModel({
                id: "COPY",
                name: "Copiar para uma nova Ordem",
                icon: <ContentCopyIcon />,
                execute: copyToNewOrder,
                requiredRoles: [UserRoles.COPY_ORDER],
            });
        default:
            break;
    }
}

export function getAllOrderActions() {
    return Object.values(OrderActions).map(u => getOrderAction(u));
}

export function getAllOrderActionsKeys() {
    return Object.values(OrderActions);
}
