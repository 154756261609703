import React, { useState, useContext, useEffect } from "react";

import MUIDataTable from "mui-datatables";
import PropTypes from "prop-types";

import { AuthContext } from "../../../contexts";

export default function DatatableView({
    title,
    data = [],
    columns = [],
    options = {},
}) {
    const { user: currentUser } = useContext(AuthContext);
    const localStorageId = `@dtas/u/${currentUser.id}/dt/${title.replace(
        /\s/g,
        "",
    )}/state`;
    const [tableState, setTableState] = useState({});

    useEffect(() => {
        const storedData = localStorage.getItem(localStorageId);
        if (storedData) {
            setTableState(JSON.parse(storedData));
        }
    }, []);

    useEffect(() => {
        localStorage.setItem(localStorageId, JSON.stringify(tableState));
    }, [tableState]);

    return (
        <MUIDataTable
            title={title}
            data={data}
            columns={columns}
            options={{ ...options, storageKey: localStorageId }}
            state={tableState}
            setState={setTableState}
        />
    );
}

DatatableView.propTypes = {
    title: PropTypes.string.isRequired,
    data: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    options: PropTypes.object.isRequired,
};
