/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";

import { LocalizationProvider, DatePicker } from "@mui/lab";
import AdapterMoment from "@mui/lab/AdapterMoment";
import { TextField, Button, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import moment from "moment";
import { Store as notifications } from "react-notifications-component";
import { Link, Redirect } from "react-router-dom";

import "./ProvisioningsForm.css";

import notificationOpts from "../../../constants/notification-options";
import {
    getDocumentData,
    updateDocument,
} from "../../../services/data-service";

const useStyles = makeStyles(theme => ({
    inputField: {
        marginTop: theme.spacing(2),
        minWidth: 380,
    },
    passwordField: {
        width: 144,
    },
    button: {
        marginRight: theme.spacing(2),
    },
    submitButton: {
        color: "var(--green)",
    },
    cancelButton: {
        color: "var(--red)",
    },
    buttonIcon: {
        marginLeft: theme.spacing(1),
    },
    formControl: {
        marginTop: theme.spacing(1),
        marginButton: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    projectPermissions: {
        marginTop: theme.spacing(1),
    },
    loadingIndicator: {
        color: "green",
        marginLeft: theme.spacing(2),
    },
    error: {
        backgroundColor: theme.palette.error.dark,
        minWidth: "auto",
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
}));

export default function ProvisioningsForm(props) {
    const provisioningId = props.match.params.id;
    const classes = useStyles();

    const [provisioning, setProvisioning] = useState(undefined);
    const [updated, setUpdated] = useState(false);

    useEffect(() => {
        getDocumentData(`provisionings/${provisioningId}`).then(data => {
            setProvisioning(data);
        });
    }, [provisioningId]);

    const onDateChange = name => date => {
        setProvisioning({ ...provisioning, [name]: date.format() });
    };

    const onSubmit = async e => {
        e.preventDefault();
        try {
            updateDocument(`provisionings/${provisioningId}`, {
                nextStudyProvisioning: provisioning.nextStudyStartAt
                    ? moment(provisioning.nextStudyStartAt)
                          .endOf("day")
                          .diff(moment().startOf("day"), "days")
                    : 0,
                nextStudyStartAt: provisioning.nextStudyStartAt,
            })
                .then(() => {
                    notifications.addNotification({
                        ...notificationOpts,
                        type: "success",
                        message: "Provisionamento atualizado com sucesso",
                    });
                    setUpdated(true);
                })
                .catch(error => {
                    console.error(
                        "Error updating provisioning id:" + provisioningId,
                        error,
                    );
                    notifications.addNotification({
                        ...notificationOpts,
                        type: "danger",
                        message: "Não foi possível atualizar, tente novamente",
                    });
                });
        } catch (e) {
            console.error(e);
            notifications.addNotification({
                ...notificationOpts,
                type: "danger",
                message:
                    "Ops... Ocorre um error, entre em contato com o suporte do sistema",
            });
        }
    };

    if (updated) {
        return (
            <Redirect
                to={{
                    pathname: "/provisionings",
                }}
            />
        );
    }

    return (
        <div className="provisionings-form">
            <div className={"header"}>
                <h1 className={"header-title"}>Editar Provisionamento</h1>
            </div>
            <div className={"content"}>
                <form
                    className={classes.container}
                    autoComplete="off"
                    onSubmit={onSubmit}
                >
                    <div className={"section"}>
                        <TextField
                            className={classes.inputField}
                            variant="filled"
                            type="text"
                            id="customerName"
                            label="Cliente"
                            InputProps={{
                                readOnly: true,
                            }}
                            value={
                                (provisioning && provisioning.customer.name) ||
                                ""
                            }
                        />
                    </div>
                    <div className={"section"}>
                        <TextField
                            className={classes.inputField}
                            type="text"
                            variant="filled"
                            id="lastProjectNumber"
                            label="Último projeto"
                            InputProps={{
                                readOnly: true,
                            }}
                            value={
                                (provisioning &&
                                    provisioning.lastProject &&
                                    provisioning.lastProject.number) ||
                                ""
                            }
                        />
                    </div>
                    <div className={"section"}>
                        <TextField
                            className={classes.inputField}
                            type="text"
                            variant="filled"
                            id="lastStudyName"
                            label="Último estudo"
                            InputProps={{
                                readOnly: true,
                            }}
                            value={
                                (provisioning &&
                                    provisioning.lastStudy &&
                                    provisioning.lastStudy.name) ||
                                ""
                            }
                        />
                    </div>
                    <div className={"section"}>
                        <TextField
                            className={classes.inputField}
                            type="text"
                            variant="filled"
                            id="lastProjectCompletedAt"
                            label="Data do último estudo"
                            InputProps={{
                                readOnly: true,
                            }}
                            value={
                                (provisioning &&
                                    moment(
                                        provisioning.lastProjectCompletedAt,
                                    ).format("DD/MM/YYYY")) ||
                                ""
                            }
                        />
                    </div>
                    <div className={"section"}>
                        <LocalizationProvider
                            dateAdapter={AdapterMoment}
                            adapterLocale="pt-br"
                        >
                            <DatePicker
                                className={classes.inputField}
                                format="DD/MM/YYYY"
                                margin="normal"
                                disablePast={true}
                                required={true}
                                labelFunc={date => {
                                    return provisioning &&
                                        provisioning.nextStudyStartAt
                                        ? date.format("DD/MM/YYYY")
                                        : "";
                                }}
                                label="Data prevista para o próximo estudo"
                                value={
                                    provisioning &&
                                    provisioning.nextStudyStartAt
                                        ? provisioning.nextStudyStartAt
                                        : null
                                }
                                onChange={onDateChange("nextStudyStartAt")}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        margin="dense"
                                    />
                                )}
                            />
                        </LocalizationProvider>
                    </div>
                    <div className={"section"}>
                        <div className={"days-info"}>
                            Dias para o próximo estudo:{" "}
                            {provisioning &&
                                provisioning.nextStudyStartAt &&
                                moment(provisioning.nextStudyStartAt)
                                    .endOf("day")
                                    .diff(moment().startOf("day"), "days")}
                        </div>
                    </div>
                    <div className={"footer"}>
                        <Link to={"/provisionings"}>
                            <Button
                                className={classNames(
                                    classes.button,
                                    classes.cancelButton,
                                )}
                            >
                                Cancelar
                            </Button>
                        </Link>
                        <Button
                            className={classNames(
                                classes.button,
                                classes.submitButton,
                            )}
                            type="submit"
                            disabled={!provisioning}
                        >
                            Salvar
                            {!provisioning && (
                                <CircularProgress
                                    size={18}
                                    thickness={4}
                                    className={classes.loadingIndicator}
                                />
                            )}
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );
}
