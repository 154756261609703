import { v4 as uuidv4 } from "uuid";

import OrderTaskStatus from "../constants/order-task-status";

export default function OrderTaskModel({
    id,
    form,
    description = "",
    amount = 1,
    amountCompleted = 0,
    note = "",
    data = {},
    images = {},
    status = OrderTaskStatus.PENDING,
    createdAt,
    updatedAt,
}) {
    this.id = id ? id : uuidv4();
    this.form = form;
    this.description = description;
    this.amount = amount;
    this.amountCompleted = amountCompleted;
    this.note = note;
    this.data = data;
    this.images = images;
    this.status = status;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.getImages = () => {
        return Object.values(this.images);
    };
    this.getData = () => {
        return Object.values(this.data);
    };
}
