import React, { createContext } from "react";

import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { ConfirmProvider } from "material-ui-confirm";
import { ReactNotifications } from "react-notifications-component";

import { theme } from "../components/layout";
import { AuthProvider, DataProvider } from "../contexts";

import { NotificationProvider } from "./notification.context";

const AppContext = createContext();

function AppProvider(props) {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <AuthProvider>
                <DataProvider>
                    <NotificationProvider>
                        <ConfirmProvider>
                            <ReactNotifications />
                            <AppContext.Provider value={{}} {...props} />
                        </ConfirmProvider>
                    </NotificationProvider>
                </DataProvider>
            </AuthProvider>
        </ThemeProvider>
    );
}

export { AppContext, AppProvider };
