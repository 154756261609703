import React from "react";

import ProjectList from "./project-list";
import ProjectData from "./project-data";
import ListViewDetails from "../commons/list-detail-view";

import {
    projectFilterType,
    projectPath,
} from "../../constants/project-filter-types";
import ProjectDatatable from "./project-datatable/ProjectDatatable";

export default function Projects(props) {
    const { filterType } = props;

    const ListView = props => {
        return <ProjectList {...props} filterType={filterType} />;
    };

    const DetailView = props => {
        return <ProjectData {...props} filterType={filterType} />;
    };

    const DatatableView = () => {
        return <ProjectDatatable {...props} filterType={filterType} />;
    };

    return (
        <ListViewDetails
            detailsPath={"/" + projectPath(filterType) + "/:id"}
            datatableView={DatatableView}
            listView={ListView}
            detailView={DetailView}
            {...props}
        />
    );
}

Projects.defaultProps = {
    filterType: projectFilterType.ALL,
};
