import { v4 as uuidv4 } from "uuid";

import OrderTaskSampleStatus from "../constants/order-task-sample-status";

export default function OrderSampleModel({
    id,
    name,
    status = OrderTaskSampleStatus.PENDING,
    matrix = "ABS",
    laboratory = "Acqualab",
    depth = "VOC",
    validity = "15 dias",
    parameters = ["TPH (Total)", "BTEX"],
    well,
    createdAt,
    updatedAt,
}) {
    this.id = id ? id : uuidv4();
    this.name = name;
    this.status = status;
    this.matrix = matrix;
    this.laboratory = laboratory;
    this.depth = depth;
    this.validity = validity;
    this.parameters = parameters;
    this.well = well;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
}
