/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from "react";

import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

import "./UserList.css";
import icon from "../user.png";

import { makeStyles } from "@mui/styles";
import { onCollectionSnapshot } from "../../../services/data-service";

const useStyles = makeStyles(() => ({
    newUserButton: {
        backgroundColor: "var(--dark-seafoam-green);",
        height: 60,
        fontFamily: "Roboto",
        fontSize: "20px",
        fontWeight: 500,
        fontStyle: "normal",
        fontStretch: "normal",
        lineHeight: "1.14",
        letterSpacing: "normal",
        textAlign: "center",
        color: "var(--white)",
        "&:hover": {
            backgroundColor: "var(--green)",
            borderColor: "#385cac",
        },
    },
}));

export default function UserList(props) {
    const classes = useStyles();
    const [state, setState] = useState({
        users: {},
        isLoading: true,
    });

    const { users, isLoading, filter } = state;
    const { onUserSelected } = props;

    // fetch data
    useEffect(() => {
        onCollectionSnapshot({
            path: "users",
            callback: snapshot => {
                if (snapshot.empty) {
                    setState({
                        ...state,
                        users: [],
                    });
                } else {
                    setState({
                        ...state,
                        users: snapshot.docs
                            .map(doc => doc.data())
                            .filter(user => !user.inactive)
                            .sort((a, b) => a.name.localeCompare(b.name)),
                        isLoading: false,
                    });
                }
            },
        });
    }, []);

    const handleFilterChange = event => {
        const filter = event.target.value;
        let { unfiltredUsers, users } = state;

        if (!unfiltredUsers) unfiltredUsers = users;

        const toUpper = value => {
            return value ? value.toString().toUpperCase() : "";
        };

        const filtredUsers = filter
            ? unfiltredUsers.filter(c => {
                  return (
                      toUpper(c.id).includes(filter.toUpperCase()) ||
                      toUpper(c.name).includes(filter.toUpperCase()) ||
                      toUpper(c.email).includes(filter.toUpperCase())
                  );
              })
            : unfiltredUsers;

        setState({
            ...state,
            filter: filter,
            users: filtredUsers,
            unfiltredUsers,
        });
    };

    const onNewUser = () => {
        if (onUserSelected) onUserSelected({});
    };

    const onSelectUser = user => {
        if (user && onUserSelected) onUserSelected(user);
    };

    return (
        <div className="created-users-form">
            <div className="select-container">
                <input
                    autoComplete="off"
                    className="select-field"
                    value={filter}
                    onChange={handleFilterChange}
                    type="search"
                    name="search"
                    placeholder="Buscar por nome ou e-mail"
                />
                <button className="select-field-button">
                    <FontAwesomeIcon
                        className="select-field-icon"
                        icon={faSearch}
                    />
                </button>
            </div>
            <div className={"content"}>
                <List className={classes.root}>
                    {Object.keys(users).map(key => (
                        <ListItem
                            key={users[key].id}
                            button
                            onClick={() => {
                                onSelectUser(users[key]);
                            }}
                        >
                            <ListItemAvatar>
                                <Avatar src={icon} />
                            </ListItemAvatar>
                            <ListItemText
                                primary={users[key].name}
                                secondary={users[key].email}
                            />
                        </ListItem>
                    ))}
                </List>
            </div>
            <div className="new-user">
                <Button
                    variant="contained"
                    className={classes.newUserButton}
                    onClick={onNewUser}
                    disabled={isLoading}
                >
                    Novo Usuário
                </Button>
            </div>
        </div>
    );
}
