import React, { useContext, useState, useEffect } from "react";

import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import Skeleton from "react-loading-skeleton";
import { MentionsInput, Mention } from "react-mentions";
import { Store as notifications } from "react-notifications-component";

import classNames from "./mention.module.css";
import notificationOpts from "../../../../constants/notification-options";
import notificationType from "../../../../constants/notification-types";
import { AuthContext } from "../../../../contexts/auth.context";

import "./ProjectInfo.css";
import { DataContext } from "../../../../contexts";
import { createDocument } from "../../../../services/data-service";

const useStyles = makeStyles(theme => ({
    addMessageButton: {
        backgroundColor: "var(--dark-seafoam-green);",
        height: "36px",
        fontFamily: "Roboto",
        fontSize: "14px",
        fontWeight: "500",
        fontStyle: "normal",
        fontStretch: "normal",
        lineHeight: "1.43",
        letterSpacing: "normal",
        textAlign: "left",
        color: "var(--white)",
        "&:hover": {
            backgroundColor: "var(--green)",
            borderColor: "#385cac",
        },
    },
    loadingIndicator: {
        color: "green",
        marginLeft: theme.spacing(2),
    },
    pendencyRoot: {
        color: "#4192d9",
        "&$checked": {
            color: "#4192d9",
        },
    },
    checked: {},
    phaseSelect: {
        marginLeft: theme.spacing(3),
        margin: theme.spacing(1),
        paddingRight: theme.spacing(2),
        width: "auto",
        minWidth: 180,
    },
}));

const theme = createTheme({
    palette: {
        primary: {
            main: "#3fb165",
        },
        secondary: {
            light: "#0066ff",
            main: "#0044ff",
            contrastText: "#ffcc00",
        },
    },
    typography: {
        useNextVariants: true,
    },
});

export default function ProjectInfo() {
    const classes = useStyles();
    const {
        user: currentUser,
        users,
        isLoading: isLoadingUsers,
    } = useContext(AuthContext);
    const { project, isLoadingProject } = useContext(DataContext);

    const [isLoading, setIsLoading] = useState(
        isLoadingProject || isLoadingUsers,
    );
    const [message, setMessage] = useState("");
    const [mentions, setMentions] = useState([]);
    const [plainTextMessage, setPlainTextMessage] = useState("");
    const [messageError, setMessageError] = useState("");

    useEffect(() => {
        setIsLoading(isLoadingProject || isLoadingUsers);
    }, [isLoadingProject, isLoadingUsers]);

    useEffect(() => {
        if (message) setMessageError("");
    }, [message]);

    const handleMessageChange = (
        event,
        newValue,
        newPlainTextValue,
        mentions,
    ) => {
        setMessage(newValue);
        setPlainTextMessage(newPlainTextValue);
        setMentions([...mentions]);
    };

    const handleSaveClick = pendencyMessage => {
        if (message === "") {
            setMessageError(
                "Você precisa escrever uma mensagem para executar essa ação!",
            );
        } else {
            const info = {
                project: { id: project.id, number: project.number },
                type: "info",
                author: {
                    id: currentUser.id,
                    email: currentUser.email,
                    name: currentUser.name,
                },
                message: pendencyMessage ? pendencyMessage : message,
                plainText: pendencyMessage ? pendencyMessage : plainTextMessage,
                mentions: mentions.map(mention => mention.id),
            };

            setIsLoading(true);
            createDocument(`projects/${project.id}/infos`, info)
                .then(() => {
                    notifications.addNotification({
                        ...notificationOpts,
                        type: notificationType.SUCCESS,
                        message: "Informação cadastrada com sucesso",
                    });
                })
                .catch(error => {
                    notifications.addNotification({
                        ...notificationOpts,
                        type: notificationType.ERROR,
                        message: "Não foi possível cadastrar informação",
                    });
                    console.error("Error creating info", error);
                })
                .finally(() => setIsLoading(false));
            setMessage("");
            setMessageError("");
        }
    };

    const renderUserSuggestion = (
        suggestion,
        search,
        highlightedDisplay,
        index,
        focused,
    ) => {
        return (
            <div className={`user ${focused ? "focused" : ""}`}>
                {highlightedDisplay}
            </div>
        );
    };

    return (
        <div className="project-info">
            <h5>Informações</h5>
            <div className="project-info-message">
                {isLoading ? (
                    <Skeleton height={100} />
                ) : (
                    <ThemeProvider theme={theme}>
                        <MentionsInput
                            value={message}
                            placeholder="Adicionar informação"
                            onChange={handleMessageChange}
                            className="mentions"
                            classNames={classNames}
                        >
                            <Mention
                                trigger="@"
                                markup="@[__display__](__id__)"
                                data={users.map(d => ({
                                    id: d.id,
                                    display: d.name,
                                }))}
                                renderSuggestion={renderUserSuggestion}
                                className={classNames.mentions__mention}
                            />
                        </MentionsInput>
                        {messageError && (
                            <Typography
                                color="error"
                                variant="caption"
                                display="block"
                                gutterBottom
                            >
                                {messageError}
                            </Typography>
                        )}
                    </ThemeProvider>
                )}
            </div>
            <div className="project-info-actions">
                <div className="actions-save">
                    <Button
                        variant="contained"
                        className={classes.addMessageButton}
                        onClick={() => handleSaveClick("", false)}
                        disabled={isLoading}
                    >
                        Salvar
                        {isLoading && (
                            <CircularProgress
                                size={18}
                                thickness={4}
                                className={classes.loadingIndicator}
                            />
                        )}
                    </Button>
                </div>
            </div>
        </div>
    );
}
