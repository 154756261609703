/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

import DomainIcon from "@mui/icons-material/Domain";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import "./CustomerList.css";

import { makeStyles } from "@mui/styles";

import { cnpjFormatter, cnpjParser } from "../../../lib/form-utils";
import { onCollectionGroupSnapshot } from "../../../services/data-service";
import ListFilter from "../../commons/list-filter";

const useStyles = makeStyles(() => ({
    newProjectButton: {
        backgroundColor: "var(--dark-seafoam-green);",
        height: 60,
        fontFamily: "Roboto",
        fontSize: "20px",
        fontWeight: 500,
        fontStyle: "normal",
        fontStretch: "normal",
        lineHeight: "1.14",
        letterSpacing: "normal",
        textAlign: "center",
        color: "var(--white)",
        "&:hover": {
            backgroundColor: "var(--green)",
            borderColor: "#385cac",
        },
    },
}));

export default function CustomerList(props) {
    const classes = useStyles();
    const [customers, setCustomers] = useState([]);
    const [unfiltredCustomers, setUnfiltredCustomers] = useState([]);
    const [filter, setFilter] = useState("");
    const [isLoading, setIsLoading] = useState(true);

    const { onCustomerSelected } = props;

    // fetch data
    useEffect(() => {
        onCollectionGroupSnapshot("customers", [], snapshot => {
            if (snapshot.empty) {
                setUnfiltredCustomers([]);
                setCustomers([]);
            } else {
                setUnfiltredCustomers([
                    ...snapshot.docs
                        .map(doc => doc.data())
                        .filter(customer => !customer.inactive)
                        .sort((a, b) => a.name.localeCompare(b.name)),
                ]);
            }
            setIsLoading(false);
        });
    }, []);

    useEffect(() => {
        filterCustomers();
    }, [unfiltredCustomers, filter]);

    const filterCustomers = () => {
        const toUpper = value => {
            return value ? value.toString().toUpperCase() : value;
        };

        const normalize = value => {
            return value
                ? value.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                : value;
        };

        const toFilter = value => {
            value = toUpper(value);
            value = normalize(value);
            return value ? value : "";
        };

        const checkCnpj = value => {
            if (value && cnpjParser(filter)) {
                return cnpjParser(value).includes(cnpjParser(filter));
            } else {
                return false;
            }
        };

        const filtredCustomers = filter
            ? unfiltredCustomers.filter(
                  c =>
                      toFilter(c.id).includes(toFilter(filter)) ||
                      toFilter(c.name).includes(toFilter(filter)) ||
                      checkCnpj(c.cnpj),
              )
            : unfiltredCustomers;

        setCustomers([...filtredCustomers]);
    };

    const handleFilterChange = value => {
        setFilter(value);
    };

    const onNewCustomer = () => {
        if (onCustomerSelected) onCustomerSelected({});
    };

    const onSelectCustomer = customer => {
        if (customer && onCustomerSelected) onCustomerSelected(customer);
    };

    return (
        <div className="created-customers-form">
            <div className={"content"}>
                <ListFilter
                    placeholder="Buscar por nome, CNPJ ou cidade"
                    onFilterChange={handleFilterChange}
                />
                <List className={classes.root}>
                    {customers.map(customer => (
                        <ListItem
                            key={customer.id}
                            button
                            onClick={() => {
                                onSelectCustomer(customer);
                            }}
                        >
                            <ListItemIcon>
                                <DomainIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={customer.name}
                                secondary={cnpjFormatter(customer.cnpj)}
                            />
                        </ListItem>
                    ))}
                </List>
            </div>
            <div className="new-project">
                <Button
                    variant="contained"
                    className={classes.newProjectButton}
                    onClick={onNewCustomer}
                    disabled={isLoading}
                >
                    Novo Cliente
                </Button>
            </div>
        </div>
    );
}
