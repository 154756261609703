import React, { useContext, useEffect, useState } from "react";

import styled from "@emotion/styled";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DateAdapter from "@mui/lab/AdapterMoment";
import DatePicker from "@mui/lab/DatePicker";
import LoadingButton from "@mui/lab/LoadingButton";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { Autocomplete, Chip, Grid, TextField, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { createTheme } from "@mui/material/styles";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import moment from "moment";
import { useHistory } from "react-router-dom";

import OrderActionsButton from "./OrderActionsButton";
import {
    SAMPLE_DEPTH,
    SAMPLE_LAB,
    SAMPLE_MATRIX,
    SAMPLE_VALIDITY,
} from "../../../constants/order-sample";
import UserRoles from "../../../constants/user-roles";
import { AuthContext } from "../../../contexts";
import sortBy from "../../../lib/sort-by";
import {
    orderTaskStatusColor,
    orderTaskStatusName,
} from "../../../lib/status.utils";
import { getSampleList, getTaskList, getUser } from "../../../services";
import { getCollectionData, where } from "../../../services/data-service";
import DetailView from "../../commons/detail-view";
import DownloadFormsButton from "../../commons/download-forms-button";
import FormSection from "../../commons/form-section/FormSection";
import HtmlTooltip from "../../commons/html-tooltip";
import StatusLabel from "../../commons/status-label/StatusLabel";

export default function OrderFormView({ order, dialog, onEdit, onCancel }) {
    return (
        <DetailView
            sx={{ ...(dialog && { padding: theme.spacing(2) }) }}
            headerTitle="Ordem de Serviço"
            subHeader1={<SubHeader1 order={order} />}
            headerActions={
                <HeaderActions
                    order={order}
                    onEdit={onEdit}
                    onCancel={onCancel}
                />
            }
            content={<Content order={order} />}
        />
    );
}

const HeaderActions = ({ order, onEdit }) => {
    const { hasRole } = useContext(AuthContext);
    return (
        <Stack direction="row" spacing={1}>
            <OrderActionsButton order={order} />

            {hasRole(UserRoles.DOWNLOAD_REPORTS) && (
                <DownloadFormsButton order={order} />
            )}

            {hasRole(UserRoles.UPDATE_ORDER) && (
                <LoadingButton
                    size="small"
                    variant="contained"
                    startIcon={<EditIcon />}
                    onClick={onEdit}
                >
                    Editar
                </LoadingButton>
            )}
        </Stack>
    );
};

const SubHeader1 = ({ order }) => {
    const { status } = order;
    return (
        <Box sx={{ pt: theme.spacing(1), pb: theme.spacing(1) }}>
            <Stack
                direction="row"
                // divider={<Divider orientation="vertical" flexItem />}
                spacing={2}
            >
                <StackItem>{order.number}</StackItem>
                <StackItem>
                    <StatusLabel status={status.name} color={status.color} />
                </StackItem>
            </Stack>
        </Box>
    );
};

const Content = ({ order }) => {
    return (
        <Box>
            <FormSection title="Dados do Serviço">
                <ServiceData order={order} />
            </FormSection>

            <FormSection title="Dados do Cliente">
                <CustomerData order={order} />
            </FormSection>

            <FormSection title="Plano de Amostragem">
                <SamplingPlan order={order} />
            </FormSection>

            <FormSection title="Atividades a serem desenvolvidas">
                <TaskContent order={order} />
            </FormSection>
        </Box>
    );
};

const ServiceData = ({ order }) => {
    const [isLoading, setLoading] = useState(true);
    const [assignees, setAssignees] = useState([]);

    // load assignees
    useEffect(() => {
        getCollectionData("users", [
            where("roles", "array-contains", UserRoles.CAMPO),
        ]).then(users => {
            setAssignees([...users.map(user => getUser(user))]);
            setLoading(false);
        });
    }, []);

    return (
        <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
            <Grid item xs={10} md={10} sm={12}>
                <TextField
                    fullWidth
                    id="description"
                    value={order.description || ""}
                    label="Descrição"
                    variant="standard"
                    disabled={isLoading}
                    InputProps={{
                        readOnly: true,
                    }}
                />
            </Grid>
            <Grid item xs={2} md={2} sm={12}>
                <LocalizationProvider dateAdapter={DateAdapter}>
                    <DatePicker
                        label="Data"
                        value={moment(order.date) || moment()}
                        disabled={isLoading}
                        readOnly={true}
                        renderInput={params => (
                            <TextField
                                fullWidth
                                {...params}
                                variant="standard"
                            />
                        )}
                    />
                </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    id="notes"
                    value={order.notes || ""}
                    label="Observações"
                    variant="standard"
                    disabled={isLoading}
                    multiline
                    rows={4}
                    InputProps={{
                        readOnly: true,
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <Autocomplete
                    multiple
                    id="assignees"
                    sx={{ mb: theme.spacing(3) }}
                    options={assignees}
                    getOptionLabel={option => option.name}
                    isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                    }
                    value={order.assignees || []}
                    disabled={isLoading}
                    disableClearable={true}
                    readOnly={true}
                    open={false}
                    renderInput={params => (
                        <TextField
                            {...params}
                            variant="standard"
                            label="Responsáveis"
                            InputProps={{
                                ...params.InputProps,
                                readOnly: true,
                            }}
                        />
                    )}
                />
            </Grid>
        </Grid>
    );
};

const CustomerData = ({ order }) => {
    const { project = {}, customer = {}, address = {} } = order;

    return (
        <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
            <Grid item xs={12}>
                <Grid item xs={4} md={4} sm={12}>
                    <TextField
                        fullWidth
                        id="project-number"
                        value={project.number}
                        label="RACP Nº"
                        readOnly={true}
                        variant="standard"
                    />
                </Grid>
            </Grid>
            <Grid item xs={7} md={7} sm={12}>
                <TextField
                    fullWidth
                    id="customer-name"
                    value={customer.name}
                    label="Cliente"
                    readOnly={true}
                    variant="standard"
                />
            </Grid>
            {address && (
                <Grid item xs={10} md={10} sm={12}>
                    <TextField
                        fullWidth
                        id="customer-address"
                        value={`${address.address}, ${address.number} - ${address.neighborhood} - ${address.city} - ${address.state} - ${address.cep}`}
                        label="Endereço"
                        readOnly={true}
                        variant="standard"
                    />
                </Grid>
            )}
        </Grid>
    );
};

const SamplingPlan = ({ order }) => {
    const samples = getSampleList(order);

    const columns = [
        {
            field: "matrix",
            headerName: "Matriz",
            width: 120,
            editable: false,
            type: "singleSelect",
            valueOptions: SAMPLE_MATRIX,
        },
        {
            field: "name",
            headerName: "ST ou PM",
            width: 140,
            editable: false,
        },
        {
            field: "laboratory",
            headerName: "Laboratório",
            width: 200,
            editable: false,
            type: "singleSelect",
            valueOptions: SAMPLE_LAB,
        },
        {
            field: "depth",
            headerName: "Profundidade",
            width: 120,
            editable: false,
            type: "singleSelect",
            valueOptions: SAMPLE_DEPTH,
        },
        {
            field: "validity",
            headerName: "Validade",
            width: 100,
            editable: false,
            type: "singleSelect",
            valueOptions: SAMPLE_VALIDITY,
        },
        {
            field: "parameters",
            headerName: "Parâmetros de Análise",
            // type: "singleSelect",
            editable: false,
            width: 250,
            valueGetter: (value, row) => {
                const { parameters = [] } = row;
                return `${parameters.join(", ")}`;
            },
            renderCell: ({ row }) => (
                <HtmlTooltip
                    title={
                        <Typography color="inherit">
                            {row.parameters.join(", ")}
                        </Typography>
                    }
                >
                    <Typography variant="body2" gutterBottom>
                        {row.parameters &&
                            row.parameters
                                .join(", ")
                                .replace(/(.{30})..+/gms, "$1…")}
                    </Typography>
                </HtmlTooltip>
            ),
        },
    ];

    return (
        <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
            <Grid container item xs={12}>
                <Grid item xs={12} md={4} sm={6}>
                    <TextField
                        fullWidth
                        id="pacNumber"
                        value={order.pacNumber || ""}
                        label="PAC Nº"
                        variant="standard"
                    />
                </Grid>
            </Grid>
            <Grid container item xs={12}>
                <Grid item xs={12} md={8} sm={10}>
                    <TextField
                        fullWidth
                        id="pacGoal"
                        value={order.pacGoal || ""}
                        label="Objetivo"
                        variant="standard"
                    />
                </Grid>
            </Grid>
            <Grid container item xs={12}>
                <Grid item xs={12} md={8} sm={10}>
                    <TextField
                        fullWidth
                        id="pacRequirements"
                        value={order.pacRequirements || ""}
                        label="Requisitos do Cliente"
                        variant="standard"
                    />
                </Grid>
            </Grid>
            <Grid container item xs={12} md={12} sm={12}>
                <Grid item xs={12} md={12} sm={12}>
                    <Box sx={{ height: 500, pt: theme.spacing(1) }}>
                        <DataGrid
                            rows={samples.sort(sortBy("name"))}
                            columns={columns}
                        />
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    );
};

const TaskContent = ({ order = {} }) => {
    const tasks = getTaskList(order);
    const history = useHistory();

    const columns = [
        {
            field: "actions",
            type: "actions",
            width: 80,
            getActions: params => [
                <GridActionsCellItem
                    key={1}
                    icon={<VisibilityIcon />}
                    label="Delete"
                    onClick={() =>
                        history.push(
                            `/orders/${order.id}/tasks/${params.row.id}`,
                        )
                    }
                />,
            ],
        },
        {
            field: "description",
            headerName: "Descrição",
            width: 400,
            editable: true,
        },
        {
            field: "amount",
            type: "number",
            headerName: "Quantidade",
            width: 100,
            editable: true,
            valueGetter: (value, row) => {
                const { amount = 0, amountCompleted = 0 } = row;
                console.log("row", row);

                return `${amountCompleted}/${amount}`;
            },
        },
        {
            field: "form",
            valueGetter: value => {
                if (!value) {
                    return "";
                }
                return value.shortName;
            },
            headerName: "Tipo",
            width: 100,
            editable: false,
        },
        {
            field: "status",
            headerName: "Status",
            width: 120,
            flex: 1,
            valueFormatter: ({ value }) => orderTaskStatusName(value),
            valueGetter: value => orderTaskStatusName(value),
            renderCell: ({ row }) => (
                <Chip
                    size="small"
                    label={orderTaskStatusName(row.status)}
                    color={orderTaskStatusColor(row.status)}
                />
            ),
        },
        {
            field: "note",
            headerName: "Observação",
            width: 250,
            editable: true,
            flex: 1,
            renderCell: ({ row }) => (
                <HtmlTooltip title={row.note}>
                    <Typography variant="body2" gutterBottom>
                        {row.note && row.note.replace(/(.{30})..+/gms, "$1…")}
                    </Typography>
                </HtmlTooltip>
            ),
        },
    ];

    return (
        <Grid container item xs={12} md={12} sm={12}>
            <Grid item xs={12} md={12} sm={12}>
                <Box sx={{ height: 500, pt: theme.spacing(1) }}>
                    <DataGrid
                        rows={tasks.sort(sortBy("description"))}
                        columns={columns}
                    />
                </Box>
            </Grid>
        </Grid>
    );
};

const StackItem = styled(Box)(() => ({
    textAlign: "center",
}));

const theme = createTheme();
