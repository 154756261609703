import React, { useState, useContext, useEffect } from "react";

import { makeStyles } from "@mui/styles";
import moment from "moment";
import Skeleton from "react-loading-skeleton";

import "./TaskDetails.css";

import ProjectStatus from "../../../../../constants/project.status";
import { DataContext } from "../../../../../contexts";
import { deadlineInfo } from "../../../../../lib/status.utils";

const useStyles = makeStyles(theme => ({
    deliveryProtocol: {
        color: "#004b8d",
        "&:hover": {
            color: "#00315c",
        },
    },
    detailsTitle: {
        fontFamily: "Roboto",
        fontSize: "16px",
        fontWeight: "bold",
        fontStyle: "normal",
        fontStretch: "normal",
        lineHeight: "1.19",
        letterSpacing: "normal",
        textAlign: "left",
        color: "#091b4b",
    },
    detailsItem: {
        fontFamily: "Roboto",
        fontSize: "14px",
        fontWeight: "500",
        fontStyle: "normal",
        fontStretch: "normal",
        lineHeight: "1.14",
        letterSpacing: "normal",
        textAlign: "left",
        color: "#091b4b",
        paddingRight: "15px",
        paddingTop: "20px",
    },
    detailsValue: {
        fontFamily: "Roboto",
        fontSize: "14px",
        fontWeight: "normal",
        fontStyle: "normal",
        fontStretch: "normal",
        lineHeight: "1.14",
        letterSpacing: "normal",
        textAlign: "left",
        color: "var(--dusk)",
        paddingTop: "20px",
    },
}));

export default function TaskDetails(props) {
    const classes = useStyles();

    const { phase, isLoadingProject, isLoadingPhase } = useContext(DataContext);

    const [isLoading, setIsLoading] = useState(
        isLoadingProject || isLoadingPhase,
    );

    const { elapsedDays } = deadlineInfo(
        phase.releasedAt,
        phase.plannedDurationDays,
    );

    useEffect(() => {
        setIsLoading(isLoadingProject || isLoadingPhase);
    }, [isLoadingProject, isLoadingPhase]);

    const data = {
        releasedAt: !phase.status
            ? "---"
            : moment(phase.releasedAt, moment.ISO_8601).format("LL"),
        plannedDeadline: !phase.status
            ? "---"
            : moment(phase.plannedDeadline, moment.ISO_8601).format("LL"),
        plannedDurationDays: !phase.status
            ? "---"
            : `${Math.round(phase.plannedDurationDays)} 
        ${Math.round(phase.plannedDurationDays) > 1 ? "  dias" : " dia"}`,
        elapsedDays: !phase.status
            ? "---"
            : `${Math.round(elapsedDays)} 
        ${Math.round(elapsedDays) > 1 ? "  dias" : " dia"}`,
    };

    return (
        <div className="task-details">
            <div className={classes.detailsTitle}>DETALHE DA ATIVIDADE</div>
            <table className="details-items">
                <tbody>
                    <tr>
                        <td className={classes.detailsItem}>Data de Inicio</td>
                        <td className={classes.detailsValue}>
                            {isLoading ? (
                                <Skeleton width={180} />
                            ) : (
                                data.releasedAt
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td className={classes.detailsItem}>
                            Prazo de entrega
                        </td>
                        <td className={classes.detailsValue}>
                            {isLoading ? (
                                <Skeleton width={180} />
                            ) : (
                                data.plannedDeadline
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td className={classes.detailsItem}>Prazo definido</td>
                        <td className={classes.detailsValue}>
                            {isLoading ? (
                                <Skeleton width={180} />
                            ) : (
                                data.plannedDurationDays
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td className={classes.detailsItem}>Tempo decorrido</td>
                        <td className={classes.detailsValue}>
                            {isLoading ? (
                                <Skeleton width={180} />
                            ) : (
                                data.elapsedDays
                            )}
                        </td>
                    </tr>
                    {!isLoading && phase.status === ProjectStatus.COMPLETED && (
                        <tr>
                            <td className={classes.detailsItem}>
                                Data de conclusão
                            </td>
                            <td className={classes.detailsValue}>
                                {isLoading ? (
                                    <Skeleton width={180} />
                                ) : (
                                    moment(phase.completedAt).format("LL")
                                )}
                            </td>
                        </tr>
                    )}
                    <tr>
                        <td className={classes.detailsItem}>Tipo do estudo</td>
                        <td className={classes.detailsValue}>
                            {isLoading ? (
                                <Skeleton width={180} />
                            ) : (
                                phase.study.name
                            )}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}
