const ProjectStatus = {
    IN_PROGRESS: "inProgress",
    CANCELLED: "Cancelled",
    RELEASED: "Released",
    COMPLETED: "Completed",
    INCOMPLETED: "Incompleted",
    PENDENCY: "Pendency",
    TASK_CANCELLED: "TaskCancelled",
    TASK_INCOMPLETED: "TaskIncompleted",
};

export default ProjectStatus;
