const textLabels = {
    body: {
        noMatch: "Nenhum registro encontrado",
        toolTip: "Ordenar",
        columnHeaderTooltip: column => `Ordenar por ${column.label}`,
    },
    pagination: {
        next: "Próxima Página",
        previous: "Página Anterior",
        rowsPerPage: "Linhas por pagina:",
        displayRows: "de",
    },
    toolbar: {
        search: "Pesquisar",
        downloadCsv: "Download CSV",
        print: "Imprimir",
        viewColumns: "Exibir Colunas",
        filterTable: "Filtrar",
    },
    filter: {
        all: "Todos",
        title: "FILTROS",
        reset: "LIMPAR",
    },
    viewColumns: {
        title: "Exibir Colunas",
        titleAria: "Exibir/Ocultar Colunas",
    },
    selectedRows: {
        text: "Linha(s) selecionada(s)",
        delete: "Excluir",
        deleteAria: "Excluir Linha(s) Selecionada(s)",
    },
};

export default textLabels;
