import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
import { AuthContext } from "../../contexts/auth.context";

export default function Logout(props) {
    const { isAuthenticated, logout } = useContext(AuthContext);

    if (isAuthenticated) {
        logout();
        return <div>loggin out..</div>;
    } else {
        return <Redirect to="/" />;
    }
}
