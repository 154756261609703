import UserRoles from "../constants/user-roles";
import UserRoleModel from "../models/user-role-model";

export function getUserRole(role) {
    switch (role) {
        case UserRoles.CADASTRAR_USUARIO:
            return new UserRoleModel({
                id: UserRoles.CADASTRAR_USUARIO,
                name: "Cadastrar Usuários",
            });
        case UserRoles.CADASTRAR_CLIENTE:
            return new UserRoleModel({
                id: UserRoles.CADASTRAR_CLIENTE,
                name: "Cadastrar Clientes",
            });
        case UserRoles.CONTROLE_FINANCEIRO:
            return new UserRoleModel({
                id: UserRoles.CONTROLE_FINANCEIRO,
                name: "Controle Financeiro",
            });
        case UserRoles.CADASTRAR_PROJETO:
            return new UserRoleModel({
                id: UserRoles.CADASTRAR_PROJETO,
                name: "Cadastrar Projetos",
            });
        case UserRoles.CADASTRAR_ESTUDO:
            return new UserRoleModel({
                id: UserRoles.CADASTRAR_ESTUDO,
                name: "Cadastrar Estudos",
            });
        case UserRoles.CADASTRAR_JUSTIFICATIVAS:
            return new UserRoleModel({
                id: UserRoles.CADASTRAR_JUSTIFICATIVAS,
                name: "Cadastrar Justificativas",
            });
        case UserRoles.ATRIBUICAO_RELATORISTA:
            return new UserRoleModel({
                id: UserRoles.ATRIBUICAO_RELATORISTA,
                name: "Atribuição de Relatorista",
            });
        case UserRoles.LOGISTICA:
            return new UserRoleModel({
                id: UserRoles.LOGISTICA,
                name: "Logística",
            });
        case UserRoles.PROJETOS:
            return new UserRoleModel({
                id: UserRoles.PROJETOS,
                name: "Projetos",
            });
        case UserRoles.QUALIDADE:
            return new UserRoleModel({
                id: UserRoles.QUALIDADE,
                name: "Qualidade",
            });
        case UserRoles.CONFERENCIA:
            return new UserRoleModel({
                id: UserRoles.CONFERENCIA,
                name: "Conferência",
            });
        case UserRoles.ANALISE_CRITICA:
            return new UserRoleModel({
                id: UserRoles.ANALISE_CRITICA,
                name: "Análise Crítica",
            });
        case UserRoles.PRE_REVISAO:
            return new UserRoleModel({
                id: UserRoles.PRE_REVISAO,
                name: "Pré-Revisão",
            });
        case UserRoles.REVISAO_TECNICA:
            return new UserRoleModel({
                id: UserRoles.REVISAO_TECNICA,
                name: "Revisão Técnica",
            });
        case UserRoles.CORRECAO:
            return new UserRoleModel({
                id: UserRoles.CORRECAO,
                name: "Correção",
            });
        case UserRoles.LABORATORIO:
            return new UserRoleModel({
                id: UserRoles.LABORATORIO,
                name: "Laboratório",
            });
        case UserRoles.DESENHISTA:
            return new UserRoleModel({
                id: UserRoles.DESENHISTA,
                name: "Desenhista",
            });
        case UserRoles.DIRETORIA:
            return new UserRoleModel({
                id: UserRoles.DIRETORIA,
                name: "Diretoria",
            });
        case UserRoles.RELATORISTA:
            return new UserRoleModel({
                id: UserRoles.RELATORISTA,
                name: "Relatorista",
            });
        case UserRoles.FINALIZACAO_DO_RELATORIO:
            return new UserRoleModel({
                id: UserRoles.FINALIZACAO_DO_RELATORIO,
                name: "Finalização do Relatório",
            });
        case UserRoles.CREATE_ROADMAP:
            return new UserRoleModel({
                id: UserRoles.CREATE_ROADMAP,
                name: "Cadastrar Ordens/Roteiros",
            });
        case UserRoles.UPDATE_ROADMAP:
            return new UserRoleModel({
                id: UserRoles.UPDATE_ROADMAP,
                name: "Atualizar Ordens/Roteiros",
            });
        case UserRoles.DELETE_ROADMAP:
            return new UserRoleModel({
                id: UserRoles.DELETE_ROADMAP,
                name: "Excluir Ordens/Roteiros",
            });
        case UserRoles.RELEASE_TASK:
            return new UserRoleModel({
                id: UserRoles.RELEASE_TASK,
                name: "Liberar Atividade",
            });
        case UserRoles.CADASTRAR_POCO:
            return new UserRoleModel({
                id: UserRoles.CADASTRAR_POCO,
                name: "Cadastrar Poços",
            });
        case UserRoles.CAMPO:
            return new UserRoleModel({
                id: UserRoles.CAMPO,
                name: "Campo",
            });
        case UserRoles.COMERCIAL:
            return new UserRoleModel({
                id: UserRoles.COMERCIAL,
                name: "Comercial",
            });
        case UserRoles.LOGIN_FIELD_APP:
            return new UserRoleModel({
                id: UserRoles.LOGIN_FIELD_APP,
                name: "Login Aplicativo (Campo)",
            });
        case UserRoles.CREATE_ORDER:
            return new UserRoleModel({
                id: UserRoles.CREATE_ORDER,
                name: "[Ordem de Serviço] - Criar Ordem",
            });
        case UserRoles.UPDATE_ORDER:
            return new UserRoleModel({
                id: UserRoles.UPDATE_ORDER,
                name: "[Ordem de Serviço] - Atualizar Ordem",
            });
        case UserRoles.DELETE_ORDER:
            return new UserRoleModel({
                id: UserRoles.DELETE_ORDER,
                name: "[Ordem de Serviço] - Excluir Ordem",
            });
        case UserRoles.RELEASE_ORDER:
            return new UserRoleModel({
                id: UserRoles.RELEASE_ORDER,
                name: "[Ordem de Serviço] - Liberar Ordem para Campo",
            });
        case UserRoles.START_ORDER_WORK:
            return new UserRoleModel({
                id: UserRoles.START_ORDER_WORK,
                name: "[Ordem de Serviço] - Iniciar Ordem para Trabalho de Campo",
            });
        case UserRoles.COMPLETE_ORDER_WORK:
            return new UserRoleModel({
                id: UserRoles.COMPLETE_ORDER_WORK,
                name: "[Ordem de Serviço] - Concluir Trabalho de Campo da Ordem",
            });
        case UserRoles.SUSPEND_ORDER_WORK:
            return new UserRoleModel({
                id: UserRoles.SUSPEND_ORDER_WORK,
                name: "[Ordem de Serviço] - Suspender Trabalho de Campo da Ordem",
            });
        case UserRoles.SEND_ORDER_TO_VERIFICATION:
            return new UserRoleModel({
                id: UserRoles.SEND_ORDER_TO_VERIFICATION,
                name: "[Ordem de Serviço] - Enviar Ordem para Verificação",
            });
        case UserRoles.PERFORM_ORDER_VERIFICATION:
            return new UserRoleModel({
                id: UserRoles.PERFORM_ORDER_VERIFICATION,
                name: "[Ordem de Serviço] - Realizar Verificação da Ordem",
            });
        case UserRoles.RELEASE_ORDER_TO_LAUNCH:
            return new UserRoleModel({
                id: UserRoles.RELEASE_ORDER_TO_LAUNCH,
                name: "[Ordem de Serviço] - Liberar Ordem para Lançamento",
            });
        case UserRoles.COMPLETE_ORDER:
            return new UserRoleModel({
                id: UserRoles.COMPLETE_ORDER,
                name: "[Ordem de Serviço] - Concluir Ordem",
            });
        case UserRoles.COPY_ORDER:
            return new UserRoleModel({
                id: UserRoles.COPY_ORDER,
                name: "[Ordem de Serviço] - Copiar Ordem",
            });
        case UserRoles.CANCEL_ORDER:
            return new UserRoleModel({
                id: UserRoles.CANCEL_ORDER,
                name: "[Ordem de Serviço] - Cancelar Ordem",
            });
        case UserRoles.DOWNLOAD_REPORTS:
            return new UserRoleModel({
                id: UserRoles.DOWNLOAD_REPORTS,
                name: "[Ordem de Serviço] - Baixar Relatórios",
            });
        default:
            break;
    }
}

export function getUserRoles() {
    return Object.values(UserRoles).map(u => getUserRole(u));
}

export function getUserRolesKeys() {
    return Object.values(UserRoles);
}
